import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Popover } from "@material-ui/core";
import ZonesAndPlacesDetail from "./ZonesAndPlacesDetail";
import BusinessIcon from "@material-ui/icons/Business";

import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Divider from "@material-ui/core/Divider";

import LocationDetailResumeData from "./../LocationDetailResumeData";
import { Link } from "react-router-dom";

import cityImage from "../../../../../../assets/backgroundPhotos/cityImageForLocation.jpg";
import EditZones from "./EditZones";
import VehicleDetailContainer from "../../../VehicleDetail/VehicleDetailContainer";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    margin: 10,
  },
  media: {
    height: 70,
  },
  content: {},
  cardHeader: {
    backgroundColor: "#36A9E1",
    color: "#1D2242",
  },
});

export default function LocationDetailZonesCard(props) {
  const {
    locationConfig,
    locationId,
    zonesData,
    activeUsers,
    vehicles,
    languageObject,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const [seeZoneConfig, setSeeZoneCongif] = useState(false);
  const [viewVehicle, setViewVehicle] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const classes = useStyles();

  const image = locationConfig.locationPhoto
    ? locationConfig.locationPhoto
    : cityImage;

  function callBackClickPlace(object) {
    console.log(object);
    setVehicle(object);
    setViewVehicle(true);
  }
  function callback() {
    console.log("cerrar");
    setVehicle({});
    setViewVehicle(false);
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        avatar={<BusinessIcon />}
        action={
          <IconButton
            onClick={() => setSeeZoneCongif(true)}
            aria-label="settings"
          >
            <SettingsIcon />
          </IconButton>
        }
        title={texts.locationZonesTitle}
        subheader=""
      />
      <Divider />

      <CardContent className={classes.content}>
        <ZonesAndPlacesDetail
          callBackClickPlace={callBackClickPlace}
          locationConfig={locationConfig}
          locationId={locationId}
          zonesData={zonesData}
          vehicles={vehicles}
          languageObject={languageObject}
        />
      </CardContent>
      <Popover
        children={
          <EditZones
            vehicles={vehicles}
            zonesData={zonesData}
            languageObject={languageObject}
            locationConfig={locationConfig}
            setSeeZoneCongif={setSeeZoneCongif}
            locationId={locationId}
          />
        }
        open={seeZoneConfig}
        PaperProps={{ style: { height: "100vh", width: "100vw" } }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />

      <Popover
        children={
          <VehicleDetailContainer vehicle={vehicle} callback={callback} />
        }
        open={viewVehicle}
        PaperProps={{ style: { height: "50vh", width: "50vw" } }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
    </Card>
  );
}
