import React from "react";
import { connect } from "react-redux";
import { styled } from "@material-ui/core/styles";
import LocationCard from "./LocationCard";
import AllLocationCard from "./AllLocationCard";
import {
  getBusinessLocationsConfig,
  getBusinessUsers,
} from "../../../../data-store/actions/valet-actions";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Typography, Container } from "@material-ui/core";
import AuthorizeUserDetail from "../BusinessUsers/AuthorizeUserDetail";
import Launch from "@material-ui/icons/Launch";
import AddLocationModal from "./AddLocationModal";
import FormDialog from "../../../Modals/FormDialog";

const ButtonContainer = styled(Container)({
  width: 700,
});

class LocationsContainerView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: null,
      addLocation: false,
    };
  }

  componentWillUpdate() {
    // if (!this.props.businessLocations) {
    //   this.props.businessLocationsFunc();
    // }
    // if (this.props.businessUsers == null) {
    //   this.props.setBusinessUser();
    // }
  }
  componentWillMount() {
    // if (this.props.businessLocations == null) {
    //   this.props.businessLocationsFunc();
    // }
    // if (this.props.businessUsers == null) {
    //   this.props.setBusinessUser();
    // }
  }

  render() {
    const texts = this.props.languageObject.authorizeUserdViews.locations
      .locationsContainer;
    function toggleAddLocation() {
      this.setState((prevState, props) => ({
        addLocation: !prevState.addLocation,
      }));
    }

    if (this.props.businessLocations == null) {
      return (
        <div>
          <Grid container direction="row" justify="center" alignItems="center">
            <ButtonContainer>
              <FormDialog
                buttomLabel={texts.add}
                ShowIcon={true}
                ShowLabel={true}
                icon={<Launch />}
                ButtonStyle={null}
                maxWidthProp={"md"}
                style={{
                  minHeight: "60vh",
                  maxHeight: "60vh",
                  backgroundColor: "#f5f5f5",
                }}
                report={
                  <AddLocationModal
                    languageObject={this.props.languageObject}
                  />
                }
              />
            </ButtonContainer>
            <CircularProgress color="secondary" />
          </Grid>
        </div>
      );
    }

    let locations = [];
    for (var i in this.props.businessLocations) {
      this.props.businessLocations[i].locationId = i;
      if (this.props.businessLocations[i].authorized) {
        locations.push(
          <LocationCard
            key={i}
            languageObject={this.props.languageObject}
            locationConfig={this.props.businessLocations[i].locationConfig}
            locationId={i}
            vehicles={this.props.vehicles}
            zonesData={
              this.props.zonesData != undefined ? this.props.zonesData[i] : null
            }
            activeUsers={this.props.activeUsers}
          />
        );
      }
    }

    return (
      <div>
        <Grid container direction="row" justify="center" alignItems="center">
          <AllLocationCard
            languageObject={this.props.languageObject}
            businessLocations={this.props.businessLocations}
            zonesData={this.props.zonesData}
            vehicles={this.props.vehicles}
            activeUsers={this.props.activeUsers}
          />
          {locations}
          <ButtonContainer>
            <FormDialog
              buttomLabel={texts.add}
              ShowIcon={true}
              ShowLabel={true}
              icon={<Launch />}
              ButtonStyle={null}
              maxWidthProp={"sm"}
              style={{
                minHeight: "60vh",
                maxHeight: "60vh",

                backgroundColor: "#f5f5f5",
              }}
              report={
                <AddLocationModal languageObject={this.props.languageObject} />
              }
            />
          </ButtonContainer>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageObject: state.auth.languageObject,
    businessId: state.valet.businessId,
    businessLocations: state.valet.businessLocations,
    activeUsers: state.valet.activeUsers,
    zonesData: state.vehicle.zonesData,
    vehicles: state.vehicle.vehicles,
    businessUsers: state.valet.businessUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // businessLocationsFunc: () => {
    //   dispatch(getBusinessLocationsConfig());
    // },
    // setBusinessUser: () => {
    //   dispatch(getBusinessUsers());
    // },
  };
};

const LocationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsContainerView);

export default LocationsContainer;
