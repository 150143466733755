import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { CSVLink } from "react-csv";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import "../../../../../src/index.css";
import {
  dateToYYYYMMDD,
  generateReport,
  savePDF,
  sixtyDaysAgo,
  today,
  yearAgo,
} from "./reportsUtils";
import { setLocationsVehiclesReports } from "../../../../data-store/actions/vehicle-actions";

const useStyles = makeStyles({
  root: {
    width: 700,
    margin: 10,
    backgroundColor: "#fff",
    color: "#000",
  },
  media: {
    height: 140,
  },
  content: {
    textAlign: "center",
  },
  button: {
    marginRight: 20,
    marginTop: 20,
  },
  buttonText: {
    color: "#fff",
  },
});

export default function ReportGenerator(props) {
  const dispatch = useDispatch();
  const {
    languageObject,
    businessLocations,
    vehicles,
    setCharts,
    businessUsers,
    setLocalReport,
  } = props;
  const texts = languageObject.authorizeUserdViews.reports;
  const classes = useStyles();
  const { handleSubmit, errors, control, register } = useForm();
  const [reportData, setReportData] = useState(null);
  const [title, setTitle] = useState(null);
  const businessId = useSelector((state) => state.valet.businessId);
  const userAccount = useSelector((state) => state.auth.userAccount);

  const locationsAuth =
    userAccount !== undefined &&
    userAccount.businessAuthorized &&
    userAccount.businessAuthorized[businessId] &&
    userAccount.businessAuthorized[businessId].locationsAuth
      ? userAccount.businessAuthorized[businessId].locationsAuth
      : null;

  const onSubmit = (data) => {
    data.startDate = new Date(data.startDate);
    console.log("reports", data);

    data.endDate = new Date(data.endDate);
    generateReport({
      languageObject,
      businessId,
      reportData: data,
      businessLocations,
      businessUsers,
    }).then((reports) => {
      resetGenerator();

      dispatch(setLocationsVehiclesReports(reports.vehiclesReport));
      console.log("reports", reports);
      if (data.reportType === "incomeOnlyParking") {
        setCharts(null);
      } else {
        setCharts(reports.charts);
      }
      setReportData(reports.csvData);
      setLocalReport(reports.csvData);
      reports.title ? setTitle(reports.title) : null;
    });
  };

  const resetGenerator = () => {
    setCharts(null);
    setReportData(null);
    setLocalReport(null);
  };

  const profilesIncomeReport = Object.values(locationsAuth)
    .map((a) => a.profile)
    .includes("incomeReport");
  console.log("locationsAuth", profilesIncomeReport);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FormControl style={{ minWidth: 300 }}>
              <InputLabel>{texts.reportTypeLabel}</InputLabel>

              {!profilesIncomeReport && (
                <Controller
                  name="reportType"
                  rules={{ required: "this is required" }}
                  control={control}
                  defaultValue=""
                  as={
                    <Select inputProps={{ onChange: () => resetGenerator() }}>
                      <MenuItem value="general">
                        {texts.reportTypes.general}
                      </MenuItem>
                      <MenuItem value="income">
                        {texts.reportTypes.income}
                      </MenuItem>
                      <MenuItem value="incomeOnlyParking">
                        {texts.reportTypes.incomeOnlyParking}
                      </MenuItem>
                      <MenuItem value="turnoverTime">
                        {texts.reportTypes.turnoverTime}
                      </MenuItem>
                      <MenuItem value="vehicleDetails">
                        {texts.reportTypes.vehicleDetails}
                      </MenuItem>
                      <MenuItem value="validations">
                        {texts.reportTypes.validations}
                      </MenuItem>
                      <MenuItem value="incidents">
                        {texts.reportTypes.incidents}
                      </MenuItem>
                    </Select>
                  }
                />
              )}

              {profilesIncomeReport && (
                <Controller
                  name="reportType"
                  rules={{ required: "this is required" }}
                  control={control}
                  defaultValue=""
                  as={
                    <Select inputProps={{ onChange: () => resetGenerator() }}>
                      <MenuItem value="incomeOnlyParking">
                        {texts.reportTypes.incomeOnlyParking}
                      </MenuItem>
                    </Select>
                  }
                />
              )}
              <FormHelperText></FormHelperText>
            </FormControl>

            <FormControl style={{ minWidth: 300 }}>
              <InputLabel id="demo-simple-select-label">
                {texts.reportLocationLabel}
              </InputLabel>

              <Controller
                name="locations"
                rules={{ required: "this is required" }}
                control={control}
                defaultValue=""
                as={
                  <Select
                    inputProps={{
                      onChange: () => resetGenerator(),
                      disabled: !businessLocations,
                    }}
                  >
                    <MenuItem value="all_locations">
                      {texts.allLocations}
                    </MenuItem>
                    {businessLocations
                      ? Object.keys(businessLocations)
                          .filter(
                            (locationKey) =>
                              businessLocations[locationKey].authorized
                          )
                          .map((locationKey) => (
                            <MenuItem key={locationKey} value={locationKey}>
                              {
                                businessLocations[locationKey].locationConfig
                                  .name
                              }
                            </MenuItem>
                          ))
                      : null}
                  </Select>
                }
              />
              <FormHelperText></FormHelperText>
            </FormControl>

            <FormControl style={{ minWidth: 300 }}>
              <TextField
                name="startDate"
                inputRef={register}
                rules={{ required: "this is required" }}
                control={control}
                defaultValue=""
                label={texts.reportStartDateLabel}
                type="datetime-local"
                defaultValue={dateToYYYYMMDD(yearAgo)}
                className={classes.textField}
                onChange={() => resetGenerator()}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: dateToYYYYMMDD(yearAgo),
                  max: dateToYYYYMMDD(today),
                  style: { borderBottom: "none", marginBottom: 0 },
                }}
              />
            </FormControl>

            <FormControl style={{ minWidth: 300 }}>
              <TextField
                name="endDate"
                inputRef={register}
                rules={{ required: "this is required" }}
                control={control}
                defaultValue=""
                label={texts.reportEndDateLabel}
                type="datetime-local"
                defaultValue={dateToYYYYMMDD(today)}
                className={classes.textField}
                onChange={() => resetGenerator()}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: dateToYYYYMMDD(sixtyDaysAgo),
                  max: dateToYYYYMMDD(today),
                  style: { borderBottom: "none", marginBottom: 0 },
                }}
              />
            </FormControl>
          </div>
        </form>

        {reportData === null ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!businessLocations}
            className={classes.button}
          >
            {texts.generateReport}
          </Button>
        ) : null}
        {reportData !== null ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            <CSVLink className={classes.buttonText} data={reportData}>
              {texts.CSVLink}
            </CSVLink>
          </Button>
        ) : null}

        {reportData !== null ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => savePDF({ languageObject, reportData, title })}
            className={classes.button}
          >
            {texts.pdf}
          </Button>
        ) : null}
      </CardContent>
    </Card>
  );
}
