import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useTotalAmount } from "./PaymentUtils";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import ValidationsVehicleDetail from "./ValidationsVehicleDetail";
import ValidationsVehicleCard from "./ValidationsVehicleCard";
import TextField from "@material-ui/core/TextField";
import {
  useGetBrandImage,
  useGetColorName,
  useGetColorValue,
  useSetStatusText,
} from "../utilsAuth/VehicleUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function ValidationsVehicleCardList(props) {
  const { locationId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const vehicles = useSelector((state) => state.vehicle.vehicles);

  const userID = useSelector((state) => state.auth.userID);
  const catalogue = useSelector((state) => state.valet.catalogue);
  const carsCatalogue = catalogue !== undefined ? catalogue.cars : {};
  const [valueToFind, setValueToFind] = useState("");
  useEffect(() => {}, [businessId]);

  function setValuesToFind(value) {
    var valueUp = value.toUpperCase();
    var valuesArray = valueUp;
    return valuesArray;
  }
  function getStrings(object) {
    // esta funcion ya no se usa por que ahora solo se buscan vehículos por numero de tarjeta. no se aceptan otros valores de busqueda
    var string = "";
    for (var i in object) {
      if (i == "color") {
        string = string + useGetColorName(object[i], languageObject);
      } else if (i == "status") {
        string =
          string + useSetStatusText(object[i], languageObject, carsCatalogue);
      } else if (i == "notes") {
        var notes = object[i];
        for (var e in notes) {
          string = string + notes[e].text;
        }
      } else if (typeof object[i] == "object") {
      } else {
        string = string + object[i];
      }
    }
    return string.toUpperCase();
  }
  const texts = languageObject.authorizeUserdViews.ticketsValidation;
  if (businessId === undefined) {
    return <div></div>;
  }
  function handleTextFieldChange(e) {
    console.log(e.target.value);
    setValueToFind(e.target.value);
  }
  return (
    <Grid container justify="start" direction="column" alignContent="center">
      <Grid className={classes.spacing} item xs={12} sm={12}>
        <Paper style={{ flex: 1, width: "500px", margin: "20px" }}>
          <Typography
            className={classes.text}
            gutterBottom
            variant={"h4"}
            component="h2"
          >
            {texts.findVehicle}
          </Typography>
          <TextField
            style={{ flex: 1, width: "90%", margin: "10px" }}
            id="findVehicle"
            label={texts.findeVehicleDetail}
            onChange={(e) => handleTextFieldChange(e)}
          />
        </Paper>
      </Grid>
      <Grid className={classes.spacing} item xs={12} sm={12}>
        {vehicles &&
          Object.keys(vehicles)
            .filter(
              (a) =>
                vehicles[a].card
                  .toUpperCase()
                  .indexOf(setValuesToFind(valueToFind), 0) >= 0 &&
                valueToFind != "" &&
                !vehicles[a].closed
            )
            .map((index) => (
              <ValidationsVehicleCard
                locationId={locationId}
                vehicle={vehicles[index]}
              />
            ))}
      </Grid>
    </Grid>
  );
}
