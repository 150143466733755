import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import firebase from "firebase";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {
  useGetBrandImage,
  useGetColorName,
  useGetColorValue,
} from "../../utilsAuth/VehicleUtils";
import { timestampToDate } from "../../../../../services/utils";
import { Paper } from "@material-ui/core";
import { updateVehicleAtribute } from "../../../../../data-store/actions/vehicle-actions";
import { useTotalAmountWidhValidations } from "../../TicketsValidation/PaymentUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    height: 150,
    backgroundColor: "#e0e0e0",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
    width: "50%",
  },
  buttonStyle: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  content1: {
    flex: "0.8 0 auto",
  },
  cover: {
    flex: 1,
    width: "50px",
    marginBottom: 5,
    marginTop: 5,
    minWidth: "70px",
    maxWidth: "70px",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function VehicleCard(props) {
  const { vehicle, callback } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const carsCatalogue = useSelector((state) => state.valet.catalogue.cars);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const userID = useSelector((state) => state.auth.userID);

  const texts = languageObject.authorizeUserdViews.vehicleDetail.card;

  const charges = useTotalAmountWidhValidations(
    businessLocations[vehicle.locationId].locationConfig.payment.rateConfig,
    vehicle
  );
  function applyPayment(amount) {
    const currentVehicle = vehicle;
    const access = businessUsers[userID].locationsAuth[vehicle.locationId];

    const date = Math.round(Date.now() / 1000);
    var paymentObject = {
      amount: -amount,
      currency: "USD",
      date: date,
      paymentMethod: "cash",
      user: userID,
    };

    var paymentNew = { ...vehicle.payment };
    paymentNew.list = {
      ...vehicle.payment.list,
      [date]: paymentObject,
    };
    console.log("paymentNew", paymentNew);
    dispatch(
      updateVehicleAtribute(currentVehicle, {
        payment: paymentNew,
        status: "taken",
        endStatus: true,
        closed: true,
        closedTime: firebase.firestore.Timestamp.fromDate(new Date()),
      })
    );
    callback(false);
  }

  return (
    <Paper
      style={{
        alignContent: "center",
        alignItems: "center",
        padding: 10,
        width: "100%",
      }}
    >
      <Card className={classes.root}>
        <div
          style={{
            width: "15px",
            borderRadius: 100,
            marginRight: 0,
            backgroundColor: useGetColorValue(vehicle.color, carsCatalogue),
          }}
        ></div>
        <CardMedia
          className={classes.cover}
          image={useGetBrandImage(vehicle.brand, carsCatalogue)}
          title=""
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {vehicle.card} / {vehicle.key}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {vehicle.brand} / {vehicle.plateNumber} /{" "}
              {useGetColorName(vehicle.color, languageObject)}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {texts.createdBy}:{" "}
              {businessUsers[vehicle.userScan] !== undefined
                ? businessUsers[vehicle.userScan].name +
                  " " +
                  businessUsers[vehicle.userScan].lastname
                : vehicle.userScan}
            </Typography>
          </CardContent>
        </div>
        <div className={classes.buttonStyle}>
          <CardContent className={classes.content1}>
            <Typography component="h4" variant="h4">
              Saldo: ${charges.pendingPay}{" "}
            </Typography>
          </CardContent>
          <div> </div>
        </div>
      </Card>
      <Button
        style={{ width: "100%", padding: 20 }}
        variant="contained"
        color="primary"
        onClick={() => applyPayment(charges.pendingPay)}
      >
        Registrar Pago y entregar vehículo
      </Button>
    </Paper>
  );
}
