// Initialize Firebase
// Initialize Firebase

import rootReducer from "../data-store/reducers";
import M from "materialize-css";
import { modalidadCateogoriaNameByCode } from "../services/utils";

const firebase = require("firebase");
require("firebase/firestore");

var config = {
  apiKey: "AIzaSyCYQe9kXFeeN848WWcWW14lblPIypq2Mq4",
  authDomain: "softicade.firebaseapp.com",
  databaseURL: "https://softicade.firebaseio.com",
  projectId: "softicade",
  storageBucket: "softicade.appspot.com",
  messagingSenderId: "900907314699",
  appId: "1:900907314699:web:9ea49b9a7b18ca9c28e10e",
  measurementId: "G-CHDE6D234J",
};

export var firebaseInit = firebase.initializeApp(config);

export var db = firebase.database();

export var firestore = firebase.firestore();

export var dbRef = firebase.database;

export var auth = firebase.auth();

// Get a reference to the storage service, which is used to create references in your storage bucket
export var storage = firebase.storage();

// Create a storage reference from our storage service
export var storageRef = storage.ref();

export const users = "users";
export const usersSmartValetRoute = "usersSmartValet";
export const catalogue = "catalogue";
export const business = "business";
export const businessUsers = "businessUsers";
export const usersList = "usersList";
export const businessInfo = "businessInfo";
export const locations = "locations";
export const locationConfig = "locationConfig";
export const locationData = "locationData";
export const locationsList = "locationsList";
export const vehicles = "vehicles";
export const incidents = "incidents";
export const activeUsers = "activeUsers";
export const locationsAuth = "locationsAuth";

export const chat = "chat";
