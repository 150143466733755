import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Divider from "@material-ui/core/Divider";

import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 345,
    margin: 10,
  },
  media: {
    height: 140,
  },
  content: {
    height: 160,
  },
});

export default function ValetScannerCard(props) {
  const { cardsData, languageObject } = props;
  const texts = languageObject.authorizeUserdViews.valetView;

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        avatar={<MonetizationOnIcon />}
        action={
          <IconButton aria-label="settings">
            <SettingsIcon />
          </IconButton>
        }
        title={texts.scannerTitle}
        subheader=""
      />
      <Divider />
      <CardContent className={classes.content}>
        aqui la vista para escanear y luego de escanerar se cierra y envía al
        detalle del veniculo y pone el dato del ultimo escanado para accedeor
        fácilmente
      </CardContent>
      <CardActions>
        <Button size="small" color="primary">
          {texts.scannerButtom}
        </Button>
      </CardActions>
    </Card>
  );
}
