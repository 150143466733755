import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LocationPlacesTable from "./LocationPlacesTable";
import { Link } from "react-router-dom";

import cityImage from "../../../../assets/backgroundPhotos/cityImageForLocation.jpg";
import EditableField from "../../../EditableField/EditableField";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: 345,
    margin: 10,
  },
  media: {
    height: 140,
  },
  content: {
    height: 160,
  },
});

export default function LocationCard(props) {
  const {
    locationConfig,
    locationId,
    zonesData,
    vehicles,
    languageObject,
    activeUsers,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationCard;

  const classes = useStyles();

  const image = locationConfig.locationPhoto
    ? locationConfig.locationPhoto
    : cityImage;
  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={image}
        title={`${locationConfig.name} image`}
      />
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h5" component="h2">
          {locationConfig.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {locationConfig.description}
          {locationConfig.adress}
        </Typography>
        <LocationPlacesTable
          languageObject={languageObject}
          locationConfig={locationConfig}
          locationId={locationId}
          zonesData={zonesData}
          vehicles={vehicles}
          activeUsers={activeUsers}
        />
      </CardContent>
      <CardActions>
        <Link to={"/locations/" + locationId + "/locationdetail/"}>
          <Button size="small" color="primary">
            {texts.openLocationDetailButtom}
          </Button>
        </Link>
        {/* <Link to={"/locations/" + locationId + "/valetview/"}>
          <Button size="small" color="primary">
            {texts.openValetViewButtom}
          </Button>
        </Link> */}
      </CardActions>
    </Card>
  );
}
