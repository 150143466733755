import React from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/logos/Frame.svg";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { BrowserRouter } from "react-router-dom";

import SideMenu from "./side-menu";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 50, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#1d2242",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
    fontSize: "60px",
  },
  menuButtonHidden: {
    display: "none",
    fontSize: "60px",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "absolute",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0),
    },
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  logo: {
    height: "40px",
    flexGrow: 1,
    align: "center",
  },
  search: {
    position: "relative",
    marginLeft: 0,
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: "white",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    "&:before": {
      color: "white",
    },
    "&:after": {
      color: "white",
    },
  },
}));

function AppBarCustom(props) {
  const {
    children,
    language,
    languageObject,
    sendNewLanguage,
    userAccount,
    setBusiness,
  } = props;
  const classes = useStyles();
  const [open, setOpen, BusinessId] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [state, setState] = React.useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    setBusiness(event.target.value);
    setState({
      ...state,
      [name]: event.target.value,
    });
    console.log(state);
  };

  let businessSelect;
  console.log(userAccount);
  if (userAccount != null && userAccount.businessAuthorized != undefined) {
    let businessQuantity = 0;
    let menuItemsArray = [];
    let businessObject = {};
    for (var i in userAccount.businessAuthorized) {
      businessQuantity++;
      menuItemsArray.push(
        <MenuItem key={i} value={i}>
          {userAccount.businessAuthorized[i].businessName}
        </MenuItem>
      );
      businessObject.businessName =
        userAccount.businessAuthorized[i].businessName;
      businessObject.businessId = i;
    }
    if (businessQuantity == 1) {
      businessSelect = <div>{businessObject.businessName}</div>;

      setBusiness(businessObject.businessId);
    } else if (businessQuantity >= 2) {
      setBusiness(businessObject.businessId);
      setState({
        ...state,
        BusinessId: businessObject.businessId,
      });

      businessSelect = (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">
            {languageObject.generalData.businessSelectTitle}
          </InputLabel>
          <Select
            onChange={handleChange}
            value={state.BusinessId}
            name="BusinessId"
            className={classes.select}
          >
            {menuItemsArray}
          </Select>
        </FormControl>
      );
    }
  }

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, open && classes.appBarShift)}
          colortheme
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon style={{ fontSize: 35 }} />
            </IconButton>

            <div style={{ flex: 1, width: "100%", alignContent: "center" }}>
              <img src={logo} height={35} style={{ marginLeft: "20px" }} />
            </div>
            {businessSelect}
            <Button onClick={(e) => sendNewLanguage(language)} color="inherit">
              {language == "en" ? "es" : "en"}
              <i className="material-icons right">language</i>
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          onClose={handleDrawerClose}
          open={open}
        >
          <SideMenu handleDrawerClose={handleDrawerClose} />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {children}
        </main>
      </div>
    </BrowserRouter>
  );
}

export default AppBarCustom;
