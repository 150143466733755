import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Paper } from "@material-ui/core";
import VehicleCard from "./VehicleCard";
import VehicleChangesList from "./VehicleChangesList";
import VehicleNotesList from "./VehicleNotesList";
import VehiclePaymentList from "./VehiclePaymentList";
import VehiclePhotosList from "./VehiclePhotosList";
import VehicleStatusUpdate from "./VehicleStatusUpdate";
import { getVehicleChanges } from "../../../../../data-store/actions/vehicle-actions";

const useStyles = makeStyles({
  root: { margin: 20 },
});

export default function VehicleDetailContainer(props) {
  const dispatch = useDispatch();
  const { vehicle, callback } = props;
  //const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const classes = useStyles();
  const businessId = useSelector((state) => state.valet.businessId);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const currentVehicle = useSelector((state) => state.vehicle.currentVehicle);
  console.log("currentVehicle", currentVehicle);
  useEffect(() => {
    dispatch(getVehicleChanges(vehicle, businessId));
  }, [vehicle]);

  if (
    currentVehicle !== null &&
    currentVehicle !== undefined &&
    businessLocations !== undefined
  ) {
    return (
      <Paper className={classes.root}>
        <Grid style={{ marginBottom: 30, padding: 20 }}>
          <Button onClick={() => callback(false)}>CERRAR</Button>
          <VehicleCard vehicle={currentVehicle} callback={callback} />
          <VehicleStatusUpdate vehicle={currentVehicle} />
          <hr />
          <VehiclePaymentList vehicle={currentVehicle} />
          <hr />
          <VehicleChangesList vehicle={currentVehicle} />
          <hr />
          <VehicleNotesList vehicle={currentVehicle} />
          <hr />
          <VehiclePhotosList vehicle={currentVehicle} />
        </Grid>
      </Paper>
    );
  }
  return <div>Loading...</div>;
}
