export function getPaymentTotalsByType(paymentMethods, vehicles, locationId) {
  let paymentTotals = {};
  Object.keys(paymentMethods).forEach((method) => {
    let methodTotal = 0;
    for (var vehicle in vehicles) {
      if (vehicles[vehicle].payment && vehicles[vehicle].locationId === locationId) {
        for (var payment in vehicles[vehicle].payment.list) {
          if (!vehicles[vehicle].payment.list[payment].deleted) {
            let paymentMethod = vehicles[vehicle].payment.list[payment].paymentMethod;
            if (method === paymentMethod) {
              methodTotal += parseFloat(vehicles[vehicle].payment.list[payment].amount) * -1;
            }
          }
        }
      }
    }
    paymentTotals[method] = methodTotal;
  })
  return paymentTotals;
}