import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {
  useGetBrandImage,
  useGetColorName,
  useGetColorValue,
} from "../utilsAuth/VehicleUtils";
import {
  useTotalAmount,
  useTotalAmountWidhValidations,
} from "../TicketsValidation/PaymentUtils";
import { timestampToDate } from "../../../../services/utils";
import { Paper } from "@material-ui/core";
import { updateVehicleAtribute } from "../../../../data-store/actions/vehicle-actions";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    height: 120,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
    width: "50%",
  },
  buttonStyle: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    flex: 1,
    width: "50px",
    marginBottom: 5,
    marginTop: 5,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function VehicleCard(props) {
  const { vehicle } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const carsCatalogue = useSelector((state) => state.valet.catalogue.cars);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const userID = useSelector((state) => state.auth.userID);

  const texts = languageObject.authorizeUserdViews.vehicleDetail.card;

  function sendWhatsappToOwner(businessId, locationId, vehicleId, vehicleData) {
    console.log("sendNotifications");

    const fromNumber = "122737687477784";
    let data = JSON.stringify({
      messaging_product: "whatsapp",
      to: "529997405097",
      type: "template",
      template: {
        name: "hello_world",
        language: {
          code: "en_US",
        },
      },
    });

    const miCel = "529997405097";
    const FabioCel = "50765842353";
    let dataNew = JSON.stringify({
      messaging_product: "whatsapp",
      to: miCel,
      type: "template",
      template: {
        name: "recibo_de_recepcion_de_vehiculo_en_valet_parking",
        language: {
          code: "es",
        },
        components: [
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: "Ford",
              },
              {
                type: "text",
                text: "Mérida",
              },
              {
                type: "text",
                text: "01-02-2023",
              },
              {
                type: "text",
                text: "01:20:20",
              },
              {
                type: "text",
                text: "T016",
              },
            ],
          },
          {
            type: "button",
            index: "0",
            sub_type: "url",
            parameters: [
              {
                type: "text",
                text:
                  "https://smartvalet.app/vpp?business=idBusiness_2&location=location_2&card=T016",
              },
            ],
          },
        ],
      },
    });

    const permanente =
      "EAAH7Uv9TiygBO1MOmSzP83xZC3yK3fjPXGpcWG1ZBOpA0pU5nRKgbNwCxNyFbOFLOJkLcpKvUAAq78ZBJDmkFmK8lnovj3YWXATyZAGtjHEwZBinF0fOM3EzKOV31ysDnAtPABZBy2scufMSSqYvklkpq4RD3pgEnZBTQlbTTHXQAqiLQaSuJhIZC2dZA8sbW75roT3lwPpXtMxFw5S4s";
    const temp =
      "EAAH7Uv9TiygBO7mupCLfPY7WjhudsOZArj54IX5GuiZCqIZB9AvEyvLSG5ksLJLGbr33N2lk5UIIB0VaHjm9IC6uleuqzZB9u1uOufqxr4fD7qhpAQkd6feykgQrehGpSwbL2svkQCG5D5Wy5AXx4PBcZAiSeDKx6j1ZCMlEjUKeN9LzeRssh80NoDFjx8EyDZBodwPhxnx2yXcNPiO1D9UFIX9W4uZAYKJN";
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://graph.facebook.com/v16.0/" + fromNumber + "/messages",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + permanente,
      },
      data: dataNew,
    };

    Axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <Paper
      style={{
        alignContent: "center",
        alignItems: "center",
        margin: 20,
        width: "500px",
      }}
    >
      {/* <Button onClick={() => sendWhatsappToOwner()}>Enviar Whats</Button> */}
      <Card className={classes.root}>
        <div
          style={{
            width: "15px",
            borderRadius: 100,
            marginRight: 10,
            backgroundColor: useGetColorValue(vehicle.color, carsCatalogue),
          }}
        ></div>
        <CardMedia
          className={classes.cover}
          image={useGetBrandImage(vehicle.brand, carsCatalogue)}
          title=""
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {vehicle.card} / {vehicle.key}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {vehicle.brand} / {vehicle.plateNumber} /{" "}
              {useGetColorName(vehicle.color, languageObject)}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {texts.createdBy}:{" "}
              {businessUsers[vehicle.userScan] !== undefined
                ? businessUsers[vehicle.userScan].name +
                  " " +
                  businessUsers[vehicle.userScan].lastname
                : vehicle.userScan}
            </Typography>
          </CardContent>
        </div>
        <div className={classes.buttonStyle}>
          <CardContent className={classes.content}>
            {/* <Typography component="h5" variant="h5">
            {
              useTotalAmount(
                businessLocations[locationId].locationConfig.payment.rateConfig,
                vehicle
              ).pendingPay
            }
          </Typography> */}
          </CardContent>
          <div></div>
        </div>
      </Card>
    </Paper>
  );
}
