import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTotalAmount } from "./PaymentUtils";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import ValidationsVehicleDetail from "./ValidationsVehicleDetail";
import { getBusinessUsers } from "../../../../data-store/actions/valet-actions";
import EditableField from "../../../EditableField/EditableField";
import FormRender from "../../../Fields/FormRender";
import ValidationsUserPermision from "./ValidationsUserPermision";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function ValidationsVehicleTableUsers(props) {
  const { locationId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  useEffect(() => {
    if (!businessUsers) {
      dispatch(getBusinessUsers());
    }
  }, []);

  const businessId = useSelector((state) => state.valet.businessId);
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const userID = useSelector((state) => state.auth.userID);

  useEffect(() => {}, [businessId]);

  const onSubmit = (values) => {
    const success = true;
    const changeObject = values;
    console.log(values);
  };

  const texts =
    languageObject.authorizeUserdViews.ticketsValidation.usersValidation;
  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid className={classes.spacing} item xs={12} sm={12}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">{texts.name}</TableCell>
                <TableCell align="center">{texts.locations}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businessUsers &&
                Object.keys(businessUsers).map((index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      padding={"default"}
                      component="th"
                      scope="row"
                    >
                      {businessUsers[index] !== undefined
                        ? businessUsers[index].name
                        : index}{" "}
                      {businessUsers[index] !== undefined
                        ? businessUsers[index].lastname
                        : index}{" "}
                      {businessUsers[index].lastname}
                    </TableCell>
                    <TableCell padding={"default"} align="center">
                      {businessUsers &&
                        businessUsers[index] &&
                        businessUsers[index].locationsAuth &&
                        Object.keys(businessUsers[index].locationsAuth).map(
                          (i) => {
                            if (
                              !businessUsers[index].locationsAuth[i].authorized
                            ) {
                              return <div></div>;
                            }
                            return (
                              <div style={{ margin: 10 }}>
                                <SimpleDialogDemo
                                  buttonText={
                                    businessUsers[index].locationsAuth[i]
                                      .locationName
                                  }
                                >
                                  <ValidationsUserPermision
                                    businessUser={businessUsers[index]}
                                    locationId={i}
                                    userIDToChange={index}
                                  />
                                </SimpleDialogDemo>
                              </div>
                            );
                          }
                        )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
