import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import defaultAvatar from "../../../../assets/images/default-user.jpg";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  FormLabel,
  IconButton,
  Icon,
  InputAdornment,
  Paper,
  Snackbar,
  List,
  InputLabel,
  Divider,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import EditableField from "../../../EditableField";
import EditableField1 from "../../../EditableField/EditableField";
import {
  updateLocationAuth,
  updateUserProfile,
} from "../../../../data-store/actions/valet-actions";
import LocationsSelectList from "./LocationsSelectList";
import UserPhoto from "./UserPhoto";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  root1: {},
  icon: {
    position: "absolute",
    right: 10,
  },
  select: {
    marginBottom: "15px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AuthorizeUserDetailView = ({
  languageObject,
  user,
  businessLocations,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // console.log('businessLocations -', businessLocations);
  const [authorizedLocations, setAuthorizedLocations] = useState(
    user.locationsAuth || {}
  );
  const userProperties =
    languageObject.authorizeUserdViews.users.userProperties;
  const userProfiles = languageObject.authorizeUserdViews.users.profiles;
  const errorMessages = languageObject.authorizeUserdViews.users.errorMessages;
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);

  // console.log(user)

  const openSuccessSnackBar = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccessSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const openFailureSnackBar = () => {
    setOpenFailure(true);
  };
  const handleCloseFailureSnackBar = (event, reason) => {
    setOpenFailure(false);
  };

  const onSubmit = (values) => {
    const success = true;
    const changeObject = values;
    // console.log('update user with these changes...', changeObject);
    dispatch(updateUserProfile(user.userId, changeObject));
    if (success) {
      openSuccessSnackBar();
    } else {
      openFailureSnackBar();
    }
  };

  const handleSubmitAuth = (location, newProfile) => {
    var locationData = businessLocations[location];
    var locationObject = {
      [location]: {
        authorized: newProfile != "unauthroized" ? true : false,
        locationName: locationData.locationConfig.name,
        profile: newProfile,
      },
    };
    dispatch(updateLocationAuth(user.userId, locationObject));
    const success = true; // implement a way to check for success on dispatch
    // console.log(`handleSubmitAuth for ${location}`, newProfile)
    // console.log(`new Auth locations`, { ...authorizedLocations, [location]: newProfile })
    if (success) {
      openSuccessSnackBar();
    } else {
      openFailureSnackBar();
    }
  };

  // vista para agregar usuario
  if (user == null) {
    return <h5>Agregar usuario</h5>;
  }

  // vista para ver usuario y editar datos
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={10}
      >
        <Grid item xs={12} sm={6}>
          <UserPhoto user={user} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography variant="h5">{userProperties.userTitle}</Typography>
            </Grid>
            <Grid item xs={12}>
              <EditableField
                fieldName="name"
                onSubmit={onSubmit}
                label={userProperties.name}
                errorMessage={errorMessages.name}
                defaultValue={user.name}
                required={true}
              />
            </Grid>

            <Grid item xs={12}>
              <EditableField
                fieldName="lastname"
                onSubmit={onSubmit}
                label={userProperties.lastname}
                errorMessage={errorMessages.lastname}
                defaultValue={user.lastname}
                required={true}
              />
            </Grid>

            <Grid item xs={12}>
              <EditableField
                fieldName="phoneNumber"
                onSubmit={onSubmit}
                label={userProperties.phoneNumber}
                errorMessage={errorMessages.phoneNumber}
                defaultValue={user.phoneNumber}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <EditableField
                fieldName="mail"
                onSubmit={onSubmit}
                label={userProperties.mail}
                errorMessage={errorMessages.mail}
                defaultValue={user.mail}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <EditableField1
                fieldObject={{
                  label: "Authorized",
                  errorMessage: "Authorized",
                  fieldName: "authorized",
                  type: "select",
                  defaultValue: user.authorized,
                  optionsArray: [
                    { value: true, label: "Authorized" },
                    { value: false, label: "NOT Authorized" },
                  ],
                  regex: "",
                  textSize: "h6",
                }}
                onSubmit={onSubmit}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="input-row">
                <Typography variant="body1">
                  {userProperties.id}: {user.userId}
                </Typography>
              </div>
              <div className="input-row">
                <Typography variant="body1">
                  {userProperties.registerDate}: {user.fechaRegistro}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {businessLocations == null ? (
                <CircularProgress color="secondary" />
              ) : (
                <LocationsSelectList
                  handleSubmitAuth={handleSubmitAuth}
                  user={user}
                  languageObject={languageObject}
                  businessLocations={businessLocations}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccessSnackBar}
      >
        <Alert onClose={handleCloseSuccessSnackBar} severity="success">
          {errorMessages.saveSuccess}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFailure}
        autoHideDuration={6000}
        onClose={handleCloseFailureSnackBar}
      >
        <Alert onClose={handleCloseFailureSnackBar} severity="success">
          {errorMessages.saveFailure}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languageObject: state.auth.languageObject,
    businessId: state.valet.businessId,
    businessUsers: state.valet.businessUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const AuthorizeUserDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizeUserDetailView);

export default AuthorizeUserDetail;
