import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import LocationDetailResumeData from "./LocationDetailResumeData";
import { Link } from "react-router-dom";

import cityImage from "../../../../../assets/backgroundPhotos/cityImageForLocation.jpg";
import { Popover } from "@material-ui/core";
import EditLocation from "./EditLocation";
import EditableField from "../../../../EditableField/EditableField";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 450,
    margin: 10,
  },
  media: {
    height: 70,
  },
  content: {
    height: 300,
    alignContent: "center",
    alignItems: "center",
  },
});

export default function LocationDetailResumeCard(props) {
  const {
    locationConfig,
    locationId,
    zonesData,
    activeUsers,
    vehicles,
    languageObject,
    callbackDay,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const classes = useStyles();
  const image = locationConfig.locationPhoto
    ? locationConfig.locationPhoto
    : cityImage;
  const [editLocation, setEditLocation] = useState(false);
  const [shiftDay, setShiftDay] = useState("today");

  function handleSetEditLocation(open) {
    setEditLocation(open);
  }

  const onSubmit = (values) => {
    const success = true;
    const changeObject = values;
    console.log("update location with these changes...", changeObject);
    setShiftDay(values.shift);
    callbackDay(values.shift);

    //    dispatch(updateLocationAtribute(locationId, changeObject));
  };
  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={image}
        title={`${locationConfig.name} image`}
      />
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h3" component="h2">
          {locationConfig.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {texts.locationDescription}: {locationConfig.description}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {texts.locationAdress}: {locationConfig.adress}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {texts.startShift} : {locationConfig.startShift}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {texts.endShift} : {locationConfig.endShift}
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p">
          <a
            target="_"
            href={`https://www.google.com/maps/@${locationConfig.lat},${locationConfig.lng},20.74z`}
          >
            <Button variant="contained" color="primary">
              {texts.viewLocationOnMap}
            </Button>
          </a>
        </Typography>

        <LocationDetailResumeData
          languageObject={languageObject}
          locationConfig={locationConfig}
          zonesData={zonesData}
          activeUsers={activeUsers}
          vehicles={vehicles}
          locationId={locationId}
        />
      </CardContent>
      <CardActions>
        {/* <Link to={"/locations/" + locationId + "/"}> */}

        <Button
          size="small"
          color="primary"
          onClick={() => handleSetEditLocation(true)}
        >
          {texts.openGeneralDataConfigButtom}
        </Button>
        <div style={{ width: 300 }}>
          <EditableField
            fieldObject={{
              label: "Día",
              errorMessage: "",
              fieldName: "shift",
              type: "select",
              defaultValue: shiftDay,
              optionsArray: [
                { value: "today", label: "Today" },
                { value: "yesterday", label: "Yesterday" },
                { value: "-2days", label: "2 days before" },
                { value: "-3days", label: "3 days before" },
              ],
              regex: "",
              textSize: "h6",
            }}
            onSubmit={onSubmit}
          />
        </div>
        {/* </Link> */}
        <Popover
          children={
            <EditLocation
              languageObject={languageObject}
              locationConfig={locationConfig}
              zonesData={zonesData}
              locationId={locationId}
              setEditLocation={setEditLocation}
            />
          }
          open={editLocation}
          PaperProps={{ style: { height: "100vh", width: "60vw" } }}
          anchorEl={document.body}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        />
      </CardActions>
    </Card>
  );
}
