import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import Rate from "./Rate";

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    width: "100%",
  },
  title: {
    marginBottom: 25,
  },
}));

export default function RateConfigContainer(props) {
  const { languageObject, rateConfig, onSubmit, onSubmitRemoveStep } = props;
  const classes = useStyles();
  const cleanRateConfigKeys = Object.keys(rateConfig).filter(
    (key) => rateConfig[key].name
  );
  const cleanRateConfig = {};
  cleanRateConfigKeys.forEach(
    (key) => (cleanRateConfig[key] = rateConfig[key])
  );
  // console.warn('rateConfig', rateConfig);

  // rateConfig should go up in props once ready
  // const cleanRateConfig = {
  //   rate_1: {
  //     isDefault: true,
  //     timeUnit: "hour",
  //     hide: false,
  //     name: "silver",
  //     steps: {
  //       step_1: {
  //         price: 1,
  //         timeLessthan: 1
  //       },
  //       step_2: {
  //         price: 10,
  //         timeLessthan: 2
  //       },
  //       step_3: {
  //         price: 30,
  //         timeLessthan: 3
  //       },
  //     }
  //   },
  //   rate_2: {
  //     isDefault: false,
  //     timeUnit: "hour",
  //     hide: false,
  //     name: "gold",
  //     steps: {
  //       step_1: {
  //         price: 0,
  //         timeLessthan: 1
  //       },
  //       step_2: {
  //         price: 5,
  //         timeLessthan: 2
  //       },
  //       step_3: {
  //         price: 8,
  //         timeLessthan: 3
  //       },
  //     }
  //   },
  //   rate_3: {
  //     isDefault: false,
  //     timeUnit: "hour",
  //     hide: false,
  //     name: "copper",
  //     steps: {
  //       step_1: {
  //         price: 0,
  //         timeLessthan: 1
  //       },
  //       step_2: {
  //         price: 1,
  //         timeLessthan: 2
  //       },
  //       step_3: {
  //         price: 2,
  //         timeLessthan: 3
  //       },
  //     }
  //   }
  // }
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;

  function getDefaultRateKey() {
    return Object.keys(cleanRateConfig).find(
      (rate) => cleanRateConfig[rate].isDefault === true
    );
  }
  const defaultRateKey = getDefaultRateKey();
  const [currentRateKey, setCurrentRateKey] = useState(null);
  useEffect(() => setCurrentRateKey(defaultRateKey), [defaultRateKey]);

  function handleOnSubmit(newRate) {
    const newRateConfig = { ...cleanRateConfig, ...newRate };
    // console.log(newRateConfig);
    onSubmit(newRateConfig);
  }

  function handleOnSubmitRemoveStep(newRate) {
    const newRateConfig = { ...cleanRateConfig, ...newRate };
    // console.log(newRateConfig);
    onSubmit(newRateConfig);
  }

  function handleChangeDefaultRate(newDefaultRateKey) {
    // console.warn(
    //   "handleChangeDefaultRate newDefaultRateKey",
    //   newDefaultRateKey
    // );
    let newRateConfig = { ...cleanRateConfig };
    Object.keys(newRateConfig).forEach((rateKey) => {
      // console.warn("newRateConfig[rateKey]", newRateConfig[rateKey]);
      newRateConfig[rateKey].isDefault = false;
    });
    newRateConfig[newDefaultRateKey].isDefault = true;
    // console.warn("handleChangeDefaultRate new", newRateConfig);
    onSubmit(newRateConfig);
  }

  function addRate() {
    const emptyRate = {
      isDefault: false,
      timeUnit: "hour",
      hide: false,
      name: "New",
      steps: {
        step_1: {
          price: 0,
          timeLessthan: 1,
        },
      },
    };
    // console.warn('Object.keys(rateConfig) ', Object.keys(rateConfig));
    const rateNames = Object.keys(rateConfig);
    let previousRateNumber = rateNames.length;
    function checkName() {
      if (rateNames.includes(`rate_${previousRateNumber + 1}`)) {
        previousRateNumber += 1;
        checkName();
      }
    }

    checkName();

    let newRate = {
      [`rate_${previousRateNumber + 1}`]: {
        ...emptyRate,
        rateID: `rate_${previousRateNumber + 1}`,
      },
    };
    let newRateConfig = { ...rateConfig, ...newRate };

    onSubmit(newRateConfig);
  }

  return (
    <Grid container direction="row" justify="flex-start">
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h4" component="h1">
          {texts.rateConfigTitle}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <FormControl variant="outlined">
          <InputLabel>{texts.currentRate}</InputLabel>
          <Select
            label={texts.currentRate}
            value={currentRateKey}
            onChange={(e) => setCurrentRateKey(e.target.value)}
          >
            {Object.keys(cleanRateConfig).map((rateKey) => (
              <MenuItem key={rateKey} value={rateKey}>
                {cleanRateConfig[rateKey].name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <FormControl variant="outlined">
          <InputLabel>{texts.defaultRate}</InputLabel>
          <Select
            label={texts.defaultRate}
            value={defaultRateKey}
            onChange={(e) => handleChangeDefaultRate(e.target.value)}
          >
            {Object.keys(cleanRateConfig).map((rateKey) => (
              <MenuItem key={rateKey} value={rateKey}>
                {cleanRateConfig[rateKey].name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Button
          // className={classes.addButton}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => addRate()}
          aria-label={texts.addRate}
        >
          {texts.addRate}
        </Button>
      </Grid>
      {cleanRateConfig && cleanRateConfig[currentRateKey] ? (
        <Rate
          rate={cleanRateConfig[currentRateKey]}
          rateKey={currentRateKey}
          languageObject={languageObject}
          onSubmit={handleOnSubmit}
          onSubmitRemoveStep={handleOnSubmitRemoveStep}
        />
      ) : null}
    </Grid>
  );
}
