import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import UserTable from "./UserTable";
import Messages from "./Messages";
import { getBusinessUsers } from "../../../../data-store/actions/valet-actions";
import { getChatData } from "../../../../data-store/actions/chat-actions";

export default function ChatContainerView() {
  const dispatch = useDispatch();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessId = useSelector((state) => state.valet.businessId);
  const userID = useSelector((state) => state.auth.userID);
  const chatMessages = useSelector((state) => state.chat.chatMessages);

  const [currentChat, setCurrentChat] = useState(null);
  console.log(currentChat);

  useEffect(() => {
    if (!businessUsers) {
      //dispatch(getBusinessUsers());
    }
    if (!chatMessages && businessId !== undefined && userID !== undefined) {
      dispatch(getChatData(businessId, userID));
    }
  }, [businessId, userID]);

  function changeCurrentChat(id) {
    console.log(id);
    setCurrentChat(id);
  }

  return (
    <Grid
      container
      style={{ minHeight: "1200px" }}
      direction="row"
      alignContent="flex-start"
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          {languageObject.authorizeUserdViews.chat.menuName}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <UserTable callback={changeCurrentChat} counterPartId={currentChat} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Messages counterPartId={currentChat} />
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Grid>
  );
}
