import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import { uploadLocationPhoto } from "../../../../../../data-store/actions/valet-actions";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import InputFileButton from "../../../../../InputFileButton1.0";
import cityImage from "../../../../../../assets/backgroundPhotos/cityImageForLocation.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function LocationPhoto(props) {
  const { locationConfig, locationId } = props;
  const dispatch = useDispatch();
  const businessId = useSelector((state) => state.valet.businessId);
  const classes = useStyles();
  const image = locationConfig.locationPhoto
    ? locationConfig.locationPhoto
    : cityImage;

  const callback = (photo) => {
    //    for(var i in data.photos){
    dispatch(
      uploadLocationPhoto(locationId, businessId, photo, "locationPhoto")
    );
    //  }
  };

  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        alt={"location photo"}
        height="200"
        image={image}
        title={locationConfig.name}
      />
      <CardActions>
        <InputFileButton type="image/*" callback={callback} text="Subir Foto" />
      </CardActions>
    </Card>
  );
}
