import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import {
  useGetBrandImage,
  useGetColorName,
  useGetColorValue,
} from "../utilsAuth/VehicleUtils";
import {
  useTotalAmount,
  useTotalAmountWidhValidations,
} from "../TicketsValidation/PaymentUtils";
import { timestampToDate } from "../../../../services/utils";
import { Paper } from "@material-ui/core";
import { updateVehicleAtribute } from "../../../../data-store/actions/vehicle-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "500px",
    height: 120,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
    width: "50%",
  },
  buttonStyle: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    flex: 1,
    marginBottom: 5,
    marginTop: 5,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function VehicleChangesList(props) {
  const { vehicle } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const carsCatalogue = useSelector((state) => state.valet.catalogue.cars);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const userID = useSelector((state) => state.auth.userID);

  const texts = languageObject.authorizeUserdViews.vehicleDetail.changes;
  function translateChange(change, locationConfig, vehicleChanges) {
    console.log("locationConfig", locationConfig);
    if (change.atribute == "zone") {
      return `${change.atribute} : 
        ${
          locationConfig.zones[change.previous] !== undefined &&
          locationConfig.zones[change.previous] !== ""
            ? locationConfig.zones[change.previous].zoneName
            : change.previous
        }
           > 
          ${
            locationConfig.zones[change.newValue] !== undefined &&
            locationConfig.zones[change.newValue] !== ""
              ? locationConfig.zones[change.newValue].zoneName
              : change.newValue
          }`;
    } else if (change.atribute == "place") {
      const zone = Object.values(vehicleChanges).filter(
        (a) => a.date == change.date && a.atribute === "zone"
      );
      console.log("zone", zone);
      if (zone[0] !== undefined) {
        return `${change.atribute} : 
        ${
          locationConfig.zones[zone[0]] !== undefined &&
          locationConfig.zones[zone[0]] !== ""
            ? locationConfig.zones[zone[0]].zonePlaces[change.previous].name
            : change.previous
        }
           > 
          ${
            locationConfig.zones[zone[0]] !== undefined &&
            locationConfig.zones[zone[0]] !== ""
              ? locationConfig.zones[zone[0]].zonePlaces[change.newValue].name
              : change.newValue
          }`;
      } else {
        return (
          change.atribute + " : " + change.previous + " > " + change.newValue
        );
      }
    } else {
      return (
        change.atribute + " : " + change.previous + " > " + change.newValue
      );
    }
  }
  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid item xs={12} sm={12} style={{ textAlign: "center", marginTop: 30 }}>
        <Typography gutterBottom variant={"h3"} component="h2">
          {texts.title}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{texts.date}</TableCell>
                <TableCell align="center">{texts.user}</TableCell>
                <TableCell align="center">{texts.description}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicle.changes &&
                Object.values(vehicle.changes)
                  .sort((a, b) => b.date - a.date)
                  .map((change) => (
                    <TableRow
                      key={change.date + Math.random(10000)}
                      style={
                        change.deleted
                          ? { backgroundColor: "grey" }
                          : { backgroundColor: "" }
                      }
                    >
                      <TableCell
                        align="center"
                        padding={"default"}
                        component="th"
                        scope="row"
                      >
                        {timestampToDate(change.date * 1000)}
                      </TableCell>
                      <TableCell padding={"default"} align="center">
                        {businessUsers[change.userID] !== undefined
                          ? businessUsers[change.userID].name
                          : change.userID}{" "}
                        {businessUsers[change.userID] !== undefined
                          ? businessUsers[change.userID].lastname
                          : ""}{" "}
                      </TableCell>
                      <TableCell
                        align="center"
                        padding={"default"}
                        component="th"
                        scope="row"
                      >
                        {translateChange(
                          change,
                          businessLocations[vehicle.locationId].locationConfig,
                          vehicle.changes
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              <TableRow>
                <TableCell padding={"default"} align="center">
                  {timestampToDate(vehicle.startTime.seconds * 1000)}
                </TableCell>

                <TableCell padding={"default"} align="center">
                  {businessUsers[vehicle.userScan] !== undefined
                    ? businessUsers[vehicle.userScan].name
                    : vehicle.userScan}{" "}
                  {businessUsers[vehicle.userScan] !== undefined
                    ? businessUsers[vehicle.userScan].lastname
                    : ""}
                </TableCell>
                <TableCell
                  align="center"
                  padding={"default"}
                  component="th"
                  scope="row"
                >
                  Create:
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
