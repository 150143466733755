import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTotalAmountWidhValidationsAndDates } from "../TicketsValidation/PaymentUtils";
import {
  getZonePlaceName,
  useGetColorName,
  useSetStatusText,
} from "../utilsAuth/VehicleUtils";
import {
  useGetGateName,
  useGetLocationVehiclesByShift,
} from "../Locations/LocationsUtils";
import { updateVehicleAtribute } from "../../../../data-store/actions/vehicle-actions";
import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
}));

export default function CloseAllActivesVehicles(props) {
  const { locationId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const vehiclesAll = useSelector((state) => state.vehicle.vehicles);

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const userID = useSelector((state) => state.auth.userID);

  const catalogue = useSelector((state) => state.valet.catalogue);
  const carsCatalogue = catalogue !== undefined ? catalogue.cars : {};
  const [valueToFind, setValueToFind] = useState("");
  const [viewPaymentDetails, setviewPaymentDetails] = useState(false);
  const [viewVehicles, setviewVehicles] = useState(3);
  const [sortBy, setSortBy] = useState("dateTime");

  const [shiftDay, setShiftDay] = useState("today");
  const [vehiclesData, setVehicles] = useState(vehiclesAll);

  const [showConfirm, setshowConfirm] = useState(false);

  useEffect(() => {
    callbackDay(shiftDay);
  }, [businessId, vehiclesAll]);

  function callbackDay(shiftDayVar) {
    setShiftDay(shiftDayVar);
    setVehicles(
      useGetLocationVehiclesByShift(
        locationId,
        vehiclesAll,
        shiftDayVar,
        businessLocations[locationId].locationConfig
          ? businessLocations[locationId].locationConfig.startShift
          : "00:00:00",
        businessLocations[locationId].locationConfig
          ? businessLocations[locationId].locationConfig.endShift
          : "23:59:59"
      )
    );
  }

  if (businessId === undefined) {
    return <div></div>;
  }

  let vehicles = vehiclesData;

  const texts = languageObject.authorizeUserdViews.ticketsValidation.table;
  const texts1 = languageObject.authorizeUserdViews.ticketsValidation;

  if (vehiclesData && businessLocations && carsCatalogue && locationId) {
    vehicles = Object.keys(vehiclesData).map(
      (i) =>
        (vehiclesData[i] = {
          ...vehiclesData[i],
          colorNew: useGetColorName(vehiclesData[i].color, languageObject),
          gateNew: useGetGateName(
            businessLocations[locationId].locationConfig,
            vehiclesData[i].gate
          ),
          statusNew: `${useSetStatusText(
            vehiclesData[i].status,
            languageObject,
            carsCatalogue
          )} ${
            vehiclesData[i].status === "parked"
              ? " | " +
                getZonePlaceName(
                  businessLocations[locationId].locationConfig.zones,
                  vehiclesData[i].zone,
                  vehiclesData[i].place
                ).zoneName +
                " - " +
                getZonePlaceName(
                  businessLocations[locationId].locationConfig.zones,
                  vehiclesData[i].zone,
                  vehiclesData[i].place
                ).placeName
              : ""
          }`,
        })
    );
  }
  console.log("vehicles", vehicles);
  function applyPayment(amount, currentVehicle) {
    const access =
      businessUsers[userID].locationsAuth[currentVehicle.locationId];

    const date = Math.round(Date.now() / 1000);
    var paymentObject = {
      amount: -amount,
      currency: "USD",
      date: date,
      paymentMethod: "cash",
      user: userID,
    };

    var paymentNew = { ...currentVehicle.payment };
    paymentNew.list = {
      ...currentVehicle.payment.list,
      [date]: paymentObject,
    };
    console.log("paymentNew", paymentNew);
    dispatch(
      updateVehicleAtribute(currentVehicle, {
        payment: paymentNew,
        status: "taken",
        endStatus: true,
        closed: true,
        closedTime: firebase.firestore.Timestamp.fromDate(new Date()),
      })
    );
  }

  function closeAllActives() {
    if (vehicles) {
      Object.keys(vehicles)
        .filter(
          (a) => vehicles[a].locationId == locationId && !vehicles[a].closed
        )
        .sort((a, b) =>
          vehicles[a][sortBy] > vehicles[b][sortBy]
            ? 1
            : vehicles[b][sortBy] > vehicles[a][sortBy]
            ? -1
            : 0
        )
        .map((index) => {
          const charges = useTotalAmountWidhValidationsAndDates(
            businessLocations[locationId].locationConfig.payment.rateConfig,
            vehicles[index]
          );

          setTimeout(applyPayment(charges.pendingPay, vehicles[index]), 1000);
        });
      setshowConfirm(false);
    }
  }
  const userProfile = businessUsers[userID].locationsAuth[locationId].profile;

  return (
    <Grid container justify="start" direction="column" alignContent="center">
      <Grid className={classes.spacing} item xs={12} sm={6}>
        {showConfirm && (
          <Grid
            className={classes.spacing}
            item
            xs={12}
            sm={12}
            alignContent="center"
          >
            <Typography className={classes.text} variant={"h5"} component="h2">
              {texts1.closeAllConfirm}
            </Typography>
            <Button
              style={{ margin: 20, padding: 30, fontSize: 20 }}
              variant="contained"
              color="primary"
              onClick={() => closeAllActives()}
            >
              {texts1.closeAllConfirmYes}
            </Button>
            <Button
              style={{ margin: 20, padding: 30, fontSize: 20 }}
              variant="contained"
              color="primary"
              onClick={() => setshowConfirm(false)}
            >
              {texts1.closeAllConfirmNO}
            </Button>
          </Grid>
        )}
        {!showConfirm && (
          <Grid className={classes.spacing} item xs={12} sm={12}>
            <Button
              style={{ margin: 20, padding: 20 }}
              variant="contained"
              color="primary"
              onClick={() => setshowConfirm(true)}
            >
              {texts1.closeAll}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
