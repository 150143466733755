import * as types from "./action-types";
import {
  firestore,
  users,
  auth,
  catalogue,
  usersSmartValetRoute,
} from "../../firebase/firebasejs";
import { Strings } from "../../Strings";
import store from "../configureStore";

import { sumarSegAFecha } from "../../services/utils";

//###################################################################################
export function setLanguage(language) {
  return (dispatch) => {
    localStorage.setItem("language", language);
    dispatch({
      type: types.LANGUAGE,
      language: language,
      languageObject: Strings[language],
    });
  };
}
//###################################################################################
export function userAccount() {
  // getDataFromFirestore();
  //getDataFromFirestoreCollection();

  // uploadData();
  // uploadDataArray();

  console.log("Monitorear cuenta");
  return (dispatch) => {
    var userData = null;
    auth.onAuthStateChanged((user) => {
      if (user) {
        if (user != null) {
          var name = user.displayName;
          var email = user.email;
          var photoUrl = user.photoURL;
          var uid = user.uid;
          var IDCompetidor = uid; // The user's ID, unique to the Firebase project. Do NOT use
          // this value to authenticate with your backend server, if
          // you have one. Use User.getToken() instead.
        }

        var DatosUsuario = firestore.collection(users).doc(uid);
        return DatosUsuario.onSnapshot((userData) => {
          if (userData != null && userData.exists) {
            let userAccountFinal = getLocationAuthorizedList(
              userData.data(),
              dispatch
            );

            dispatch({
              type: types.USER_ACCOUNT,
              userAccount: userAccountFinal,
              userID: uid,
            });
            getProfileSmartValet(dispatch, uid);
          }

          getCatalogueFunc(dispatch);
        });
      } else {
        return dispatch({
          type: types.USER_ACCOUNT,
          userAccount: null,
          userID: null,
        });
      }
    });
  };
}
//###################################################################################
export function getCatalogueFunc(dispatch) {
  var catalogueRef = firestore.collection(catalogue);
  return catalogueRef
    .get()
    .then((querySnapshot) => {
      var catalogueFinal = {};
      querySnapshot.forEach(function (doc) {
        catalogueFinal[doc.id] = doc.data();
      });
      dispatch({
        type: types.CATALOGUE,
        catalogue: catalogueFinal,
      });
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
    });
}

//###################################################################################
export function getProfileSmartValet(dispatch, userID) {
  console.log("Usuario autorizado Master", "getSV profile");
  console.log("Usuario autorizado Master", userID);

  return firestore
    .collection(usersSmartValetRoute)
    .doc(userID)
    .get()

    .then((doc) => {
      if (doc.exists && doc.data().authorized) {
        console.log("Document data:", doc.data());
        console.log("Usuario autorizado Master", "ok");
        dispatch({
          type: types.USER_ACCOUNT_SMART_VALET,
          smartValetProfile: doc.data().profile,
        });
      } else {
        console.log("No such document!");
        console.log("Usuario autorizado Master", "fail");
        dispatch({
          type: types.USER_ACCOUNT_SMART_VALET,
          smartValetProfile: null,
        });
      }
    })
    .catch((err) => {
      console.log("Error getting document", err);
      dispatch({
        type: types.USER_ACCOUNT_SMART_VALET,
        smartValetProfile: null,
      });
    });
}

//###################################################################################
function getLocationAuthorizedList(userAccount, dispatch) {
  let userProfiles = [];
  let userBusinessLocations = [];

  if (userAccount != null && userAccount.businessAuthorized != undefined) {
    let setBusiness = true;

    for (var i in userAccount.businessAuthorized) {
      if (setBusiness) {
        dispatch({
          type: types.CURRENTBUSINESS,
          businessId: i,
          businessObject: null,
        });
        setBusiness = false;
      }
      const locationsAuth = userAccount.businessAuthorized[i].locationsAuth;
      for (var e in locationsAuth) {
        if (locationsAuth[e].authorized) {
          userProfiles.push(locationsAuth[e].profile);
        }
      }
    }
  }
  if (userProfiles.length > 0) {
    userAccount.userProfiles = userProfiles;
  } else {
    userAccount.userProfiles = [];
    // userAccount.userProfiles.push("valet");
  }
  if (userBusinessLocations.length > 0) {
    userAccount.userBusinessLocations = userBusinessLocations;
  }
  console.log(userBusinessLocations);
  return userAccount;
}

function uploadDataArray() {
  if (false) {
    console.log("upload");
    var obj = {
      aa: "aa",
      bb: "bb",
    };
    for (var i in userList) {
      firestore
        .collection("users")
        .doc("idbusiness_2")
        .collection("locations")
        .doc("location_1")
        .collection("vehicles")
        .doc(i)
        .set(userList[i], { merge: true })
        .then(function (docRef) {
          console.log("Document written");
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }

    // .collection("locations")
    // .doc("location_1")
  }
}

function uploadData() {
  if (true) {
    console.log("upload");

    firestore
      .collection("business")
      .doc("idbusiness_2")
      .collection("locations")
      .doc("location_1")
      .set(payment, { merge: true })
      .then(function (docRef) {
        console.log("Document written");
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    // .collection("locations")
    // .doc("location_1")
  }
}

var payment = {
  locationConfig: {
    adress: "calle....",
    description: "torre yuc...",
    name: "torre yuc",
    lat: 1,
    lng: 1,
    payment: {
      rateConfig: {
        timeUnit: "hour",
        steps: {
          step_1: {
            timeLessthan: 1,
            price: 1,
          },
          step_2: {
            timeLessthan: 1.5,
            price: 1.5,
          },
          step_3: {
            timeLessthan: 2,
            price: 2,
          },
          step_4: {
            timeLessthan: 3,
            price: 3,
          },
          step_5: {
            timeLessthan: 4,
            price: 4,
          },
          step_6: {
            timeLessthan: 5,
            price: 5,
          },
          step_7: {
            timeLessthan: 100000,
            price: 20,
          },
        },
      },
      paymentMethods: {
        cash: {
          available: true,
          name: "Efectivo",
        },
        onlinePayment: {
          available: true,
          name: "Transacción en linea",
        },
        pos: {
          available: true,
          name: "Terminal punto de venta",
        },
      },
    },
    receipts: {
      card: true,
      print: true,
      printConfig: {
        business: "prueba",
        web: "www.smartvalet.app",
      },
    },
    zones: {
      zone_1: {
        overPlaces: 1,
        priority: 1,
        zoneName: "Piso 1",
        placesNamed: false,
        zonePlacesNumber: 5,
      },
    },
  },
};
var rateTypes = {
  bytime: {
    name: "Variable por tiempo",
    units: ["minutes", "hours", "days"],
  },
  fixed: {
    name: "Monto fijo por el servicio",
  },
  mix: {
    name: "Monto fijo un periodo y variable por tiempo",
  },
};
var profiles = {
  locationLeader: {
    defaultView: "reports",
    description:
      "Perfil para el lider de la locación, puede autorizar usuarios, ver todo el estatus de la operación recibir pagos y estacionar vehiculos",
    permisions: {
      appAccess: true,
      chat: {
        view: true,
      },
      liveView: {
        view: true,
      },
      locations: {
        create: true,
        delete: true,
        edit: true,
        view: true,
      },
      reports: {
        downloadReports: true,
        view: true,
      },
      users: {
        authorizeUsers: true,
        deleteUsers: true,
        view: true,
      },
      vehicleRequest: {
        view: true,
      },
    },
    profileName: "locationLeader",
  },
  master: {
    defaultView: "reports",
    description: "Acceso a todo",
    permisions: {
      appAccess: true,
      chat: {
        view: true,
      },
      liveView: {
        view: false,
      },
      locations: {
        create: true,
        delete: true,
        edit: true,
        view: true,
      },
      payRoll: {
        view: true,
      },
      reports: {
        downloadReports: true,
        view: true,
      },
      users: {
        authorizeUsers: true,
        deleteUsers: true,
        view: true,
      },
      vehicleRequest: {
        view: false,
      },
    },
    profileName: "master",
  },
  ticketsValidation: {
    defaultView: "ticketsValidation",
    description:
      "Perfil validar tickets por parte del cliente al marcarlos como pagados se cargan al cliente que contrató el servicio",
    permisions: {
      ticketsValidation: {
        view: true,
      },
    },
    profileName: "ticketsValidation",
  },
  valet: {
    defaultView: "vehicleRequest",
    description: "Conductos de los vehiculos y custodio de las llaves",
    permisions: {
      appAccess: true,
      chat: {
        view: true,
      },
      liveView: {
        view: true,
      },
      vehicleRequest: {
        view: true,
      },
    },
    profileName: "valet",
  },
};

var paymentMethods = {
  cash: {
    available: true,
    name: "Efectivo",
  },
  onlinePayment: {
    available: true,
    name: "Transacción en linea",
  },
  pos: {
    available: true,
    name: "Terminal punto de venta",
  },
};

var locationConfig = {
  locationConfig: {
    adress: "Calle 1 con 20 y 22 numero 200",
    authorizeUsers: {
      dsaasdas: {
        name: "Eduardo",
        profile: "master",
      },
      dzasdassaasdas: {
        name: "Pedro",
        profile: "Driver",
      },
    },
    description: "Empresa de carros...",
    lat: 1,
    lng: 1,
    locationPhoto:
      "https://firebasestorage.googleapis.com/v0/b/softicade.appspot.com/o/Business%2FidBusinnes_2%2Flocations%2Fvalet-parking-panama-257251-i-640w.jpg?alt=media&token=7ca929e1-9ce0-4990-a5bd-d9f4536a0677",
    name: "Torre Mérida",
    zones: {
      zone_1: {
        overPlaces: 0,
        zoneName: "Lado Derecho",
        zonePlaces: {
          place_1: "A1",
          place_2: "A2",
          place_3: "A3",
          place_4: "A4",
          place_5: "A5",
        },
        zonePlacesNumber: 5,
      },
      zone_2: {
        overPlaces: 5,
        zoneName: "Lado Izquierdo",
        zonePlaces: {
          place_1: "B1",
          place_2: "B2",
        },
        zonePlacesNumber: 2,
      },
    },
  },
};

var userList = {
  ZJw4A6Q8EebKA1Z3RDdhK2INpw73: {
    businessAuthorized: {
      idbusiness_2: {
        authorized: true,
        businessName: "Valet Mérida",
        locationsAuth: {
          location_1: {
            authorized: true,
            locationName: "Torre Yuc",
            profile: "master",
          },
          location_2: {
            authorized: true,
            locationName: "Torre Mérida",
            profile: "master",
          },
        },
        profile: "master",
      },
    },
    email: "eduardomadrid84@gmail.com",
    lastname: "Madrid",
    name: "Eduardo",
    registryDate: "2020/03/31 19:57:11",
    registryFrom: "web",
  },
  gmyHuOpELHa6PXNLW2UncWAFEPQ2: {
    businessAuthorized: {
      idbusiness_2: {
        authorized: true,
        businessName: "Valet Mérida",
        profile: "master",
      },
    },
    email: "demo@smartvalet.app",
    lastname: "Smart Valet",
    name: "Demo",
    registryDate: "2020/04/18 12:57:55",
    registryFrom: "web",
  },
  user_1: {
    businessAuthorized: {
      idbusiness_2: {
        authorized: true,
        businessName: "Valet Yucatán",
        perfil: "mater",
      },
      idbusiness_3: {
        authorized: true,
        businessName: "Valet Merida",
        perfil: "mater",
      },
    },
    fechaRegistro: "2020-01-01 00:00:00",
    lastname: "Madrid",
    mail: "eduardomadrid84@hahoo.com",
    name: "Eduardo",
    phoneNumber: "99999999",
  },
  user_2: {
    businessAuthorized: {
      idbusiness_1: {
        authorized: true,
        businessName: "Valet Panama",
        perfil: "mater",
      },
    },
    fechaRegistro: "2020-01-01 00:00:00",
    lastname: "Franchini",
    mail: "franchini@yahoo.com",
    name: "Tomas",
    phoneNumber: "99999999",
  },
  user_3: {
    businessAuthorized: {
      idbusiness_1: {
        authorized: true,
        businessName: "Valet Panama",
        perfil: "mater",
      },
    },
    fechaRegistro: "2020-01-01 00:00:00",
    lastname: "Botella",
    mail: "botella@yahoo.com",
    name: "Fabio",
    phoneNumber: "99999999",
  },
};

var vehiclesList = {
  dsfgsdvcx: {
    brand: "Chevrolet",
    card: "BSD1",
    changes: {
      change_1: {
        date: 123131,
        descriptionChange: "el carro ...",
        typeChange: 1,
        userChange: "asdasdas",
      },
      change_2: {
        date: 123132,
        descriptionChange: "el carro ...",
        typeChange: 2,
        userChange: "asdasdas",
      },
    },
    color: "white",
    key: "F3w4324",
    model: "aveo",
    payment: {
      payment_1: {
        amount: 10,
        currency: "USD",
        date: 1234343,
        paymentMethod: "card",
        user: "asdasds",
      },
    },
    place: "A1",
    registrationNumber: "CCC-123",
    startTime: 12343234,
    status: "taken",
    userPark: "asdasasd",
    zone: "piso 1",
  },
  fgc345: {
    brand: "Toyota",
    card: "BBB",
    changes: {
      change_1: {
        date: 123131,
        descriptionChange: "el carro ...",
        typeChange: 1,
        userChange: "asdasdas",
      },
      change_2: {
        date: 123132,
        descriptionChange: "el carro ...",
        typeChange: 2,
        userChange: "asdasdas",
      },
    },
    color: "blue",
    key: "B3w4624",
    model: "corolla",
    payment: {
      payment_1: {
        amount: 10,
        currency: "USD",
        date: 1234343,
        paymentMethod: "card",
        user: "asdasds",
      },
    },
    place: "A1",
    registrationNumber: "aaa-123",
    startTime: 12343234,
    status: "parked",
    userPark: "asdasasd",
    zone: "piso 1",
  },
  rtydv: {
    brand: "Toyota",
    card: "BBB2",
    changes: {
      change_1: {
        date: 123131,
        descriptionChange: "el carro ...",
        payment: {
          payment_1: {
            amount: 10,
            currency: "USD",
            date: 1234343,
            paymentMethod: "card",
            user: "asdasds",
          },
        },
        place: "A1",
        status: 1,
        typeChange: 1,
        userChange: "asdasdas",
        zone: "piso 1",
      },
      change_2: {
        date: 123132,
        descriptionChange: "el carro ...",
        typeChange: 2,
        userChange: "asdasdas",
      },
    },
    color: "red",
    key: "C3w4324",
    model: "Yaris",
    payment: {
      payment_1: {
        amount: 10,
        currency: "USD",
        date: 1234343,
        paymentMethod: "card",
        user: "asdasds",
      },
    },
    place: "A1",
    registrationNumber: "bbb-123",
    startTime: 12343234,
    status: "scanned",
    userPark: "asdasasd",
    zone: "piso 1",
  },
  vafasdweqfawe: {
    brand: "Nissan",
    card: "ASD1",
    changes: {
      change_1: {
        date: 123131,
        descriptionChange: "el carro ...",
        typeChange: 1,
        userChange: "asdasdas",
      },
      change_2: {
        date: 123132,
        descriptionChange: "el carro ...",
        typeChange: 2,
        userChange: "asdasdas",
      },
    },
    color: "grey",
    key: "A3w4314",
    model: "Yaris",
    payment: {
      payment_1: {
        amount: 10,
        currency: "USD",
        date: 1234343,
        paymentMethod: "card",
        user: "asdasds",
      },
    },
    registrationNumber: "bbb-123",
    startTime: 12343234,
    status: "requested",
    userPark: "asdasasd",
  },
};
