import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { updateUserProfile } from '../../../../data-store/actions/valet-actions';
import EditableField from '../../../EditableField';
import MuiAlert from '@material-ui/lab/Alert';
import ShiftCard from './ShiftCard';
import { dateToHHMM, dateToYYYYMMDD } from '../Reports/reportsUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  root1: {
  },
  icon: {
    position: 'absolute',
    right: 10
  },
  select: {
    marginBottom: '15px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}));


export default function EmployeePayRollDetail({ languageObject, user, businessLocations }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const texts = languageObject.authorizeUserdViews.users.payRoll;
  const errorMessages = languageObject.authorizeUserdViews.users.errorMessages;
  const { payRollConfig } = user;
  let hourlyWage;
  if (payRollConfig && payRollConfig.hourlyWage) {
    hourlyWage = payRollConfig.hourlyWage;
  } else {
    hourlyWage = 0;
  }
  let shifts;
  let getEightHoursLater = function (date) {
    let eightHoursLater = date;
    date.setTime(date.getTime() + (8 * 60 * 60 * 1000));
    return eightHoursLater;
  }
  const newShift = { date: dateToYYYYMMDD(new Date()), startTime: dateToHHMM(new Date()), endTime: dateToHHMM(getEightHoursLater(new Date())), shiftHourlyPay: hourlyWage }
  if (payRollConfig && payRollConfig.shifts) {
    shifts = payRollConfig.shifts;
  } else {
    shifts = {};
  }
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  console.log(user)

  const openSuccessSnackBar = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccessSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const openFailureSnackBar = () => {
    setOpenFailure(true);
  };
  const handleCloseFailureSnackBar = (event, reason) => {
    setOpenFailure(false);
  };
  const onSubmit = values => {
    const success = true;
    const changeObject = {
      payRollConfig: {
        ...payRollConfig,
        ...values
      }
    };
    console.log('update user with these changes...', changeObject);
    dispatch(updateUserProfile(user.userId, changeObject))
    if (success) {
      openSuccessSnackBar();
    } else {
      openFailureSnackBar();
    }
  }

  function onAddShift(value) {
    let newShifts = shifts;
    newShifts[+ new Date()] = newShift
    console.log('newShifts', { shifts: { ...newShifts } });
    onSubmit({ shifts: { ...newShifts } });

  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={10}
      >

        <Grid item xs={12}>
          <ShiftCard languageObject={languageObject} businessLocations={businessLocations} shifts={shifts} hourlyWage={hourlyWage} onAddShift={onAddShift} onEditShift={onSubmit} />
        </Grid>
      </Grid>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccessSnackBar}>
        <Alert onClose={handleCloseSuccessSnackBar} severity="success">
          {errorMessages.saveSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailure} autoHideDuration={6000} onClose={handleCloseFailureSnackBar}>
        <Alert onClose={handleCloseFailureSnackBar} severity="success">
          {errorMessages.saveFailure}
        </Alert>
      </Snackbar>
    </div>
  )
}