import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import PersonIcon from "@material-ui/icons/Person";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

import SettingsIcon from "@material-ui/icons/Settings";

import LocationDetailResumeData from "./../LocationDetailResumeData";
import { Link } from "react-router-dom";

import cityImage from "../../../../../../assets/backgroundPhotos/cityImageForLocation.jpg";
import { Paper, Grid } from "@material-ui/core";
import { useEffect } from "react";
import AutorizeUsersList from "../../../BusinessUsers/AuthorizeUsersList";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 330,
    margin: 10,
  },
  media: {
    height: 70,
  },
  content: {
    height: 300,
  },
  cardHeader: {
    backgroundColor: "#36A9E1",
    color: "#1D2242",
  },
});

export default function LocationDetailUsersCard(props) {
  const {
    locationConfig,
    locationId,
    zonesData,
    activeUsers,
    vehicles,
    languageObject,
  } = props;
  console.log("LocationDetailUsersCard locationId", locationId);
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  let businessUsers = useSelector((state) => state.valet.businessUsers);
  console.log("LocaitonDetailUsersCard businessUsers", businessUsers);
  // Por ahora, utliza todos los usuarios. Cuando se entegra BE, solo debe incluir los usuarios de esta location
  let filterObject = (obj, predicate) =>
    Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ((res[key] = obj[key]), res), {});
  businessUsers = businessUsers == undefined ? {} : businessUsers;

  var locationUsers = filterObject(
    businessUsers,
    (user) =>
      user.locationsAuth &&
      user.locationsAuth[locationId] &&
      user.locationsAuth[locationId].authorized
  );
  console.log(locationUsers);

  const classes = useStyles();

  const image = locationConfig.locationPhoto
    ? locationConfig.locationPhoto
    : cityImage;

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        avatar={<PersonIcon />}
        action={
          <Link to={"/users"}>
            <IconButton aria-label="settings">
              <SettingsIcon />
            </IconButton>
          </Link>
        }
        title={texts.locationUsersTitle}
        subheader=""
      />
      <Divider />
      <CardContent className={classes.content}>
        <AutorizeUsersList short locationUsers={locationUsers} />
      </CardContent>
    </Card>
  );
}
