export const USER_ACCOUNT = "USER_ACCOUNT";
export const USER_ACCOUNT_SMART_VALET = "USER_ACCOUNT_SMART_VALET";
export const LANGUAGE = "LANGUAGE";
export const LANGUAGE_OBJECT = "LANGUAGE_OBJECT";
export const PROFILES = "PROFILES";
export const CATALOGUE = "CATALOGUE";
export const CURRENTBUSINESS = "CURRENTBUSINESS";
export const BUSINESSUSERSLIST = "BUSINESSUSERSLIST";
export const BUSINESSLOCATIONS = "BUSINESSLOCATIONS";

export const UPLOADING_PHOTOS = "UPLOADING_PHOTOS";

export const VEHICLES_INIT = "VEHICLES_INIT";
export const CURRENT_VEHICLE = "CURRENT_VEHICLE";
export const VEHICLE_ADD = "VEHICLE_ADD";
export const VEHICLE_CHANGE = "VEHICLE_CHANGE";
export const VEHICLE_REMOVE = "VEHICLE_REMOVE";
export const VEHICLES_REPORT = "VEHICLES_REPORT";
export const INCIDENTS_REPORT = "INCIDENTS_REPORT";
export const ACTIVEUSERSDATA = "ACTIVEUSERSDATA";
export const ACTIVEUSERSDATA_ADD = "ACTIVEUSERSDATA_ADD";
export const ACTIVEUSERSDATA_CHANGE = "ACTIVEUSERSDATA_CHANGE";
export const ACTIVEUSERSDATA_REMOVE = "ACTIVEUSERSDATA_REMOVE";
export const UPDATE_ZONES_DATA = "UPDATE_ZONES_DATA";

export const SOUND_STATE = "SOUND_STATE";

export const ADD_USER_TO_BUSINESS = "ADD_USER_TO_BUSINESS";

export const GLOBAL_BUSINESS_LIST = "GLOBAL_BUSINESS_LIST";

export const CHATDATA = "CHATDATA";
export const CHATDATA_ADD = "CHATDATA_ADD";
export const CHATDATA_CHANGE = "CHATDATA_CHANGE";
export const CHATDATA_REMOVE = "CHATDATA_REMOVE";
export const CURRENT_CHAT = "CURRENT_CHAT";
