import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import { timestampToDate } from "../../../../../services/utils";
import { Paper } from "@material-ui/core";
import { updateVehicleAtribute } from "../../../../../data-store/actions/vehicle-actions";
import EditableField from "../../../../EditableField/EditableField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "500px",
    height: 120,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
    width: "50%",
  },
  buttonStyle: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    flex: 1,
    marginBottom: 5,
    marginTop: 5,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function VehicleStatusUpdate(props) {
  const { vehicle } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const carsCatalogue = useSelector((state) => state.valet.catalogue.cars);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const userID = useSelector((state) => state.auth.userID);

  const texts = languageObject.authorizeUserdViews.vehicleDetail.status;
  const onSubmit = (values) => {
    const changeObject = values;
    console.log("update location with these changes...", changeObject);
    dispatch(updateVehicleAtribute(vehicle, values));
  };
  const closeVehicle = (closed) => {
    if (closed) {
      dispatch(
        updateVehicleAtribute(vehicle, {
          closed: closed,
          closedTime: firebase.firestore.Timestamp.fromDate(new Date()),
        })
      );
    } else {
      dispatch(
        updateVehicleAtribute(vehicle, {
          closed: closed,
          closedTime: firebase.firestore.Timestamp.fromDate(
            new Date("2100-01-01")
          ),
        })
      );
    }
  };

  const userProfile =
    businessUsers[userID].locationsAuth[vehicle.locationId].profile;

  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid item xs={12} sm={12} style={{ textAlign: "center", marginTop: 30 }}>
        <Typography gutterBottom variant={"h3"} component="h2">
          {texts.title}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
        {userProfile === "valet" && vehicle.status === "taken" ? (
          <Typography gutterBottom variant={"h3"} component="h2">
            {texts.taken}
          </Typography>
        ) : (
          <EditableField
            style={{ fontSize: 30 }}
            fieldObject={{
              label: texts.changeStatus,
              errorMessage: "",
              fieldName: "status",
              type: "select",
              defaultValue: vehicle.status,
              optionsArray: [
                { value: "scanned", label: texts.scanned },
                { value: "parking", label: texts.parking },
                { value: "parked", label: texts.parked },
                { value: "recovering", label: texts.recovering },
                { value: "platform", label: texts.platform },
                { value: "taken", label: texts.taken },
              ],
              regex: "",
              textSize: "h6",
            }}
            onSubmit={onSubmit}
          />
        )}
      </Grid>
      {vehicle.status === "taken" && (
        <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
          <Typography gutterBottom variant={"subtitle1"} component="h2">
            {texts.closeVehicle}
          </Typography>
          {!vehicle.closed && (
            <Button
              style={{ fontSize: 30 }}
              onClick={() => closeVehicle(true)}
              variant="contained"
              color="primary"
            >
              {texts.closeVehicleButton}
            </Button>
          )}
          {vehicle.closed && userProfile !== "valet" && (
            <Button
              style={{ fontSize: 30 }}
              onClick={() => closeVehicle(false)}
              variant="contained"
              color="light"
            >
              {texts.openVehicleButton}
            </Button>
          )}
          {vehicle.closed && userProfile === "valet" && (
            <Typography gutterBottom variant={"h3"} component="h2">
              {texts.openVehicleButton}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}
