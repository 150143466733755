import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import DriveEtaIcon from "@material-ui/icons/DriveEta";
import BusinessIcon from "@material-ui/icons/Business";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  useGetLocationVehiclesCount,
  useGetLocationIncome,
  useGetLocationZones,
  useGetLocationActiveUsers,
} from "../LocationsUtils";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  table: {},
  icons: {
    margin: 20,
    fontSize: 100,
  },
});

export default function LocationDetailResumeData(props) {
  const {
    locationConfig,
    locationId,
    zonesData,
    activeUsers,
    vehicles,
    languageObject,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationCard;

  const classes = useStyles();

  let totalPlaces = useGetLocationZones(locationConfig);
  let ocupade = useGetLocationVehiclesCount(locationId, vehicles);
  let income = useGetLocationIncome(locationId, vehicles);
  let activeUsersCount = useGetLocationActiveUsers(locationId, activeUsers);

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Badge
        max={999}
        className={classes.icons}
        color="primary"
        badgeContent={ocupade}
      >
        <Tooltip title={texts.ocupedPlaces}>
          <DriveEtaIcon style={{ fontSize: 40 }} />
        </Tooltip>
      </Badge>
      <Badge
        max={999}
        className={classes.icons}
        color="primary"
        badgeContent={totalPlaces}
      >
        <Tooltip title={texts.totalPlaces}>
          <BusinessIcon style={{ fontSize: 40 }} />
        </Tooltip>
      </Badge>
      <span> {Math.round((ocupade / totalPlaces) * 1000) / 10}% </span>
      <Badge
        max={999}
        className={classes.icons}
        color="primary"
        badgeContent={activeUsersCount}
      >
        <Tooltip title={texts.activeUsersCount}>
          <PersonIcon style={{ fontSize: 40 }} />
        </Tooltip>
      </Badge>
      <Badge
        max={9999}
        className={classes.icons}
        color="primary"
        badgeContent={-income}
      >
        <Tooltip title={texts.income}>
          <MonetizationOnIcon />
        </Tooltip>
      </Badge>
    </Grid>
  );
}
