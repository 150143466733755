import * as types from "./action-types";
import {
  business,
  locations,
  locationsList,
  businessInfo,
  vehicles,
  incidents,
  activeUsers,
  firestore,
} from "../../firebase/firebasejs";
import { Strings } from "../../Strings";
import store from "../configureStore";
import { fecha } from "../../services/utils";
import { useSelector, useDispatch } from "react-redux";

//##########################################################################
export function saveVehicleData(businessId, locationId, vehicle) {
  console.log("saveVehicle Data");
  return (dispatch) => {
    if (businessId != undefined && locationId != undefined) {
      var newVehicleRef = firestore
        .collection(business)
        .doc(businessId)
        .collection(locations)
        .doc(locationId)
        .collection(vehicles)
        .doc(vehicle.id);

      return newVehicleRef.set(vehicle).then(() => {
        console.log("vehiculo agregado");
        return "ok";
      });
    }
  };
}
//##########################################################################
//##########################################################################
export function updateVehicleAtribute(vehicle, changesObject) {
  // changesObject = {"status","scanned"};
  console.log("update Vehicle Status");
  const businessId = store.getState().valet.businessId;
  const locationId = vehicle.locationId;
  const userID = store.getState().auth.userID;

  return (dispatch) => {
    if (businessId != undefined && locationId != undefined) {
      var newVehicleRef = firestore
        .collection(business)
        .doc(businessId)
        .collection(locations)
        .doc(locationId)
        .collection(vehicles)
        .doc(vehicle.id);

      for (var i in changesObject) {
        console.log(changesObject[i]);
        if (i === "status" && changesObject[i] === "parked") {
        } else if (
          i === "status" &&
          (changesObject[i] === "platform" || changesObject[i] === "taken")
        ) {
          // si el coche cambia a estatus platform o taken elimino su ocupación en el puesto que tenia
          changesObject.zone = "";
          changesObject.place = "";
        }
      }
      changesObject.changes = updateChangesList(vehicle, changesObject, userID);

      return newVehicleRef.set(changesObject, { merge: true }).then(() => {
        console.log("vehiculo ajustado");
        return "ok";
      });
    }
  };
}
//##########################################################################
export function getVehicleChanges(vehicle, businessId) {
  return (dispatch) => {
    dispatch({
      type: types.CURRENT_VEHICLE,
      currentVehicle: null,
    });

    return firestore
      .collection(business)
      .doc(businessId)
      .collection(locations)
      .doc(vehicle.locationId)
      .collection(vehicles)
      .doc(vehicle.id)
      .onSnapshot((doc) => {
        dispatch({
          type: types.CURRENT_VEHICLE,
          currentVehicle: { ...doc.data(), locationId: vehicle.locationId },
        });
      });
  };
}
//##########################################################################
export function getLocationsVehiclesRealTimeChanges(
  businessId,
  locationId,
  reset
) {
  //store.dispatch(initVehicles());

  console.log(getLocationsVehiclesRealTimeChanges);
  var date = new Date();
  date.setDate(date.getDate() - 3);
  var startQuery = date; //;

  var Ref = firestore
    .collection(business)
    .doc(businessId)
    .collection(locations)
    .doc(locationId)
    .collection(vehicles)
    .where("closedTime", ">", startQuery);

  return (dispatch) => {
    return Ref.onSnapshot(function (snapshot) {
      if (snapshot == undefined) {
        return;
      }
      snapshot.docChanges().forEach(function (change) {
        if (change == undefined) {
          return;
        }
        let previousValue = { zone: "", place: "" };
        if (
          store.getState().vehicle.vehicles != undefined &&
          store.getState().vehicle.vehicles[change.doc.id]
        ) {
          previousValue = store.getState().vehicle.vehicles[change.doc.id];
        }
        const currentValue = { ...change.doc.data(), id: change.doc.id };

        if (change.type === "added") {
          const getRequestedTime = Object.values(
            change.doc.data().changes
          ).filter(
            (a) => a.atribute === "status" && a.newValue === "requested"
          );
          console.log("getRequestedTime", getRequestedTime);

          var vehicleNew = {
            ...change.doc.data(),
            locationId: locationId,
            requestedTime:
              getRequestedTime && getRequestedTime.length > 0
                ? getRequestedTime[0].date
                : "null",
          };
          console.log("Vehicle create: ");
          dispatch({
            type: types.VEHICLE_ADD,
            vehicle: vehicleNew,
            vehicleId: change.doc.id,
          });
        }
        if (change.type === "modified") {
          console.log("Modified vehicle: ");
          console.log("requestedTime", change.doc.data().changes);
          const getRequestedTime = Object.values(
            change.doc.data().changes
          ).filter(
            (a) => a.atribute === "status" && a.newValue === "requested"
          );
          console.log("getRequestedTime", getRequestedTime);

          var vehicleNew = {
            ...change.doc.data(),
            locationId: locationId,
            requestedTime:
              getRequestedTime && getRequestedTime.length > 0
                ? getRequestedTime[0].date
                : "null",
          };

          if (vehicleNew.status === "requested") {
            console.log("vehicle requested");
            dispatch({
              type: types.SOUND_STATE,
              soundState: true,
            });
          }
          dispatch({
            type: types.VEHICLE_CHANGE,
            vehicle: vehicleNew,
            vehicleId: change.doc.id,
          });
        }
        if (change.type === "removed") {
          console.log("Removed vehicle: ");
          var vehicleNew = { ...change.doc.data(), locationId: locationId };
          dispatch({
            type: types.VEHICLE_REMOVE,
            vehicle: vehicleNew,
            vehicleId: change.doc.id,
          });
        }
        // aquí actualizo las zonas en caso de haber un cambio
        if (
          currentValue.zone != previousValue.zone ||
          currentValue.place != previousValue.place
        ) {
          dispatch(updateZonesData(locationId));
        }
      });
    });
  };
}

//##########################################################################
export function setSoundState(sound) {
  return (dispatch) => {
    dispatch({
      type: types.SOUND_STATE,
      soundState: sound,
    });
  };
}
//##########################################################################
export function initVehicles() {
  dispatch({
    type: types.VEHICLES_INIT,
    vehicles: {},
  });
}
//##########################################################################
export function getLocationsVehiclesReports(
  businessId,
  locationId,
  startDate,
  endDate,
  reset
) {
  // reseteo la data de vehiclesReport para vaciarla antes de la consulta
  if (reset) {
    dispatch({
      type: types.VEHICLES_REPORT,
      vehiclesReport: vehicleNew,
    });
  }

  // calculo la fecha de inicio del reporte, ahora no se usa esta sección
  console.log(getLocationsVehiclesReports);
  var date = new Date();
  date.setDate(date.getDate() - 4);
  var startQuery = date; //;

  var Ref = firestore
    .collection(business)
    .doc(businessId)
    .collection(locations)
    .doc(locationId)
    .collection(vehicles)
    .where("startTime", ">=", startDate)
    .where("startTime", "<=", endDate);

  return (dispatch) => {
    return Ref.get().then((querySnapshot) => {
      if (querySnapshot == undefined) {
        return;
      }
      let vehiclesReport = {};
      querySnapshot.forEach((doc) => {
        console.log("Vehicle create: ", doc.data());

        const vehicleNew = {
          ...doc.data(),
          id: doc.id,
          locationId: locationId,
        };
        vehiclesReport[doc.id] = vehicleNew;
      });
      dispatch({
        type: types.VEHICLES_REPORT,
        vehiclesReport: vehiclesReport,
      });
    });
  };
}
//##########################################################################
export function setLocationsVehiclesReports(vehiclesReport) {
  return (dispatch) => {
    dispatch({
      type: types.VEHICLES_REPORT,
      vehiclesReport: vehiclesReport,
    });
  };
}
//##########################################################################
export function updateZonesData() {
  console.log("updateZonesData");
  var vehicles = store.getState().vehicle.vehicles;
  var zonesData = {};
  for (var i in vehicles) {
    var locationId = vehicles[i].locationId;
    if (vehicles[i].zone != "" && vehicles[i].place != "") {
      if (zonesData[locationId] == undefined) {
        zonesData[locationId] = {};
      }
      // agrego aquí si tiene zona y place definido
      if (zonesData[locationId][vehicles[i].zone] == undefined) {
        zonesData[locationId][vehicles[i].zone] = {};
      }
      if (zonesData[locationId][vehicles[i].zone].zonePlaces == undefined) {
        zonesData[locationId][vehicles[i].zone].zonePlaces = {};
      }
      if (zonesData[locationId][vehicles[i].zone].overPlaces == undefined) {
        zonesData[locationId][vehicles[i].zone].overPlaces = [];
      }
      zonesData[locationId][vehicles[i].zone].zonePlaces[vehicles[i].place] = i;
    } else if (vehicles[i].zone != "") {
      if (zonesData[locationId] == undefined) {
        zonesData[locationId] = {};
      }
      // agrego aquí si tiene zona solamente
      if (zonesData[locationId][vehicles[i].zone] == undefined) {
        zonesData[locationId][vehicles[i].zone] = {};
      }
      if (zonesData[locationId][vehicles[i].zone].overPlaces == undefined) {
        zonesData[locationId][vehicles[i].zone].overPlaces = [];
      }
      zonesData[locationId][vehicles[i].zone].overPlaces.push(i);
    }
  }

  return (dispatch) => {
    dispatch({
      type: types.UPDATE_ZONES_DATA,
      zonesData: zonesData,
    });
  };
}
//##########################################################################
export function updateChangesList(vehicle, changeObject, userID) {
  let changes = vehicle.changes;
  const atributesToSaveChanges = [
    "card",
    "key",
    "brand",
    "color",
    "plateNumber",
    "status",
    "place",
    "zone",
  ];
  const atributesToAdd = ["notes", "photos"];

  for (var i in changeObject) {
    if (atributesToSaveChanges.indexOf(i) >= 0) {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: vehicle[i] == undefined ? "" : vehicle[i],
        newValue: changeObject[i],
      };
    } else if (atributesToAdd.indexOf(i) >= 0) {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: "",
        newValue: i + " ADD",
      };
    } else if (i == "payment") {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      var previous = Object.values(vehicle[i].list);
      var newValue = Object.values(changeObject[i].list);
      var add = "ADD";
      if (previous.length == newValue.length) {
        add = "CHANGE";
      }
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: "",
        newValue: i + " " + add,
      };
    }
  }
  return changes;
}
//##########################################################################
export function getLocationsIncidents(businessId, locationId, page) {
  // calculo la fecha de inicio del reporte, ahora no se usa esta sección
  var date = new Date();
  date.setDate(date.getDate() - 4);
  var startQuery = date; //;

  var Ref = firestore
    .collection(business)
    .doc(businessId)
    .collection(locations)
    .doc(locationId)
    .collection(incidents)
    .orderBy("date", "desc")
    .limit(15 * page || 1);

  return (dispatch) => {
    return Ref.get().then((querySnapshot) => {
      if (querySnapshot == undefined) {
        return;
      }
      let incidentsReport = {};
      querySnapshot.forEach((doc) => {
        console.log("incident create: ", doc.data());

        const incidentNew = {
          ...doc.data(),
          id: doc.id,
          locationId: locationId,
        };
        incidentsReport[doc.id] = incidentNew;
      });
      dispatch({
        type: types.INCIDENTS_REPORT,
        incidentsReport: incidentsReport,
      });
    });
  };
}
//##########################################################################
export function saveIncident(businessId, locationId, incident) {
  console.log("saveVehicle Data");
  return (dispatch) => {
    if (businessId != undefined && locationId != undefined) {
      var newVehicleRef = firestore
        .collection(business)
        .doc(businessId)
        .collection(locations)
        .doc(locationId)
        .collection(incidents)
        .doc();

      return newVehicleRef.set(incident).then(() => {
        console.log("incident agregado");
        return "ok";
      });
    }
  };
}
