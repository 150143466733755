import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
} from "@material-ui/core";
import ReactHookFormSelect from "../../../../../ReactHookFormSelect";
import { updateLocationAtribute } from "../../../../../../data-store/actions/valet-actions";

export default function AddZoneForm(props) {
  const { languageObject, locationId } = props;
  const { register, handleSubmit, errors, control, watch } = useForm();
  const placesNamed = watch("placesNamed");
  console.log("AddZoneForm - locationID", locationId);

  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  console.log("AddZoneForm - busniessLocations", businessLocations);
  const zones = businessLocations[locationId].locationConfig.zones;
  console.log("AddZoneForm - zones", zones);

  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const errorMessages = languageObject.authorizeUserdViews.users.errorMessages;
  const dispatch = useDispatch();
  function getNewZoneName(zones) {
    const zoneKeys = Object.keys(zones);
    for (let i = 1; i <= zoneKeys.length + 1; i++) {
      if (!zoneKeys.includes(`zone_${i}`)) {
        return `zone_${i}`;
      }
    }
  }

  console.log("getting new name", getNewZoneName(zones));

  const onSubmit = (data) => {
    const newZone = {
      zonePlacesNumber:
        data.placesNamed == "nonamed" ? data.zonePlacesNumber / 1 : 1,
      zonePlaces: {
        place_1: {
          priority: 1,
          name: "",
        },
      },
      zoneName: data.zoneName,
      priority: data.priority,
      hide: false,
      placesNamed: data.placesNamed == "nonamed" ? false : true,
      overPlaces: 0,
    };
    console.log(businessLocations[locationId].locationConfig.zones);
    let newName = getNewZoneName(
      businessLocations[locationId].locationConfig.zones
    );
    dispatch(
      updateLocationAtribute(locationId, {
        zones: { [newName]: { ...newZone } },
      })
    );
    console.log("Adding New Zone", newZone);
  };

  // TODO: Agregar boton para clasificar la zona como zona con puestos nombrados o puestos no nombrados
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              width: "100%",
              fontSize: "1.5rem",
              fontWeight: 300,
              padding: 10,
            }}
          >
            {texts.editZoneName}:{" "}
          </Typography>
          <TextField
            style={{ width: "100%" }}
            helperText={errors.zoneName ? errorMessages.zoneName : null}
            inputProps={{ style: { borderBottom: "none", marginBottom: 0 } }}
            error={Boolean(errors.zoneName)}
            fullWidth
            inputRef={register({ required: true, min: 1, max: 100 })}
            name="zoneName"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              width: "100%",
              fontSize: "1.5rem",
              fontWeight: 300,
              padding: 10,
            }}
          >
            {texts.editPlaceSelectTitle}
          </Typography>
          <ReactHookFormSelect
            id="placesNamed"
            name="placesNamed"
            label="Lugares con nombre"
            control={control}
            defaultValue={"named"}
            variant="outlined"
            margin="normal"
            style={{ width: "100%" }}
          >
            <MenuItem value={"named"}>{texts.editPlaceSelectNamed}</MenuItem>
            <MenuItem value={"nonamed"}>
              {texts.editPlaceSelectNoNamed}
            </MenuItem>
          </ReactHookFormSelect>
        </Grid>

        {placesNamed === "nonamed" && (
          <Grid zeroMinWidth item xs={12}>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                fontSize: "1.5rem",
                fontWeight: 300,
                padding: 10,
              }}
            >
              {texts.zonePlacesNumber}:{" "}
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={
                errors.zonePlacesNumber ? errorMessages.zonePlacesNumber : null
              }
              inputProps={{ style: { borderBottom: "none", marginBottom: 0 } }}
              error={Boolean(errors.zonePlacesNumber)}
              fullWidth
              inputRef={register({ required: true, pattern: /^\d+$/ })}
              name="zonePlacesNumber"
            />
          </Grid>
        )}
        <Grid zeroMinWidth item xs={12}>
          <Typography
            variant="body1"
            style={{
              width: "100%",
              fontSize: "1.5rem",
              fontWeight: 300,
              padding: 10,
            }}
          >
            {texts.priority}:{" "}
          </Typography>
          <TextField
            style={{ width: "100%" }}
            helperText={errors.priority ? errorMessages.priority : null}
            inputProps={{ style: { borderBottom: "none", marginBottom: 0 } }}
            error={Boolean(errors.priority)}
            fullWidth
            inputRef={register({ required: true, pattern: /^\d+$/ })}
            name="priority"
          />
        </Grid>
        <Grid
          zeroMinWidth
          item
          xs={12}
          style={{ textAlign: "center", margin: 20 }}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
          >
            {texts.save}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
