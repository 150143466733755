import * as types from "../actions/action-types";

const initialState = [];
export default function valetReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_ACCOUNT:
      return Object.assign({}, state, {
        userAccount: action.userAccount,
        userID: action.userID,
      });
    case types.USER_ACCOUNT_SMART_VALET:
      return Object.assign({}, state, {
        smartValetProfile: action.smartValetProfile,
      });
    case types.LANGUAGE:
      return Object.assign({}, state, {
        language: action.language,
        languageObject: action.languageObject,
      });
    default:
      return state;
  }
}
