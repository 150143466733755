import React from 'react';
import { connect} from 'react-redux'

const VehicleRequestContainerView = ({languageObject}) => {

	return (
		<div>
			<h5>{languageObject.authorizeUserdViews.vehicleRequest.menuName}</h5>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
		</div>
	)
}


const mapStateToProps = (state) => {
	return {
		languageObject : state.auth.languageObject
	}
  }


const mapDispatchToProps = (dispatch) => {
		return {
		}
  }

const VehicleRequestContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(VehicleRequestContainerView);

export default VehicleRequestContainer;
