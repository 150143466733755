import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import ZonesAndPlacesDetail from "../Locations/LocationDetail/LocationZones/ZonesAndPlacesDetail";

import ValetScanner from "./ValetScanner";
import ValetVehicleCardList from "./ValetVehicleCardList";
import VehicleDetailContainer from "./VehicleDetail/VehicleDetailContainer";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import MoneyIcon from "@material-ui/icons/Money";
import WarningIcon from "@material-ui/icons/Warning";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import BusinessIcon from "@material-ui/icons/Business";
import ValetVehicleTable from "./ValetVehicleTable";
import ValetIncidents from "./ValetIncidents";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {},
  icons: {
    color: "grey",
  },
  selected: {
    color: "grey",
  },
});

export default function ValetNavigationContainer(props) {
  const {
    locationId,
    businessLocations,
    locationConfig,
    languageObject,
    carsCatalogue,
    callback,
  } = props;
  const texts = languageObject.authorizeUserdViews.valetView;
  const classes = useStyles();
  const [value, setValue] = React.useState("vehicleList");
  const [vehicleCode, setVehicleCode] = React.useState("dsfgsdvcx");

  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const userID = useSelector((state) => state.auth.userID);
  const userProfile = businessUsers[userID].locationsAuth[locationId].profile;

  let view;
  if (value == "vehicleList") {
    if (locationConfig != undefined && carsCatalogue != undefined) {
      view = (
        <ValetVehicleCardList
          locationId={locationId}
          carsCatalogue={carsCatalogue}
          languageObject={languageObject}
          locationConfig={locationConfig}
          callback={callback}
        />
      );
    } else {
      view = <h1>Cargando</h1>;
    }
  } else if (value == "payments") {
    if (locationConfig != undefined && carsCatalogue != undefined) {
      view = (
        <ValetVehicleTable
          locationId={locationId}
          locationConfig={locationConfig}
        />
      );
    } else {
      view = <h1>Cargando</h1>;
    }
  } else if (value == "incidents") {
    if (locationConfig != undefined && carsCatalogue != undefined) {
      view = <ValetIncidents locationId={locationId} />;
    } else {
      view = <h1>Cargando</h1>;
    }
  } else if (value == "vehicleRequested") {
    if (locationData != undefined && carsCatalogue != undefined) {
      view = (
        <ZonesAndPlacesDetail
          carsCatalogue={carsCatalogue}
          callBackClickPlace={callBackClickPlace}
          languageObject={languageObject}
          locationConfig={locationConfig}
        />
      );
    } else {
      view = <h1>Cargando</h1>;
    }
  }

  function callBackClickPlace(object) {
    alert(JSON.stringify(object));
  }
  return (
    <div>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          className={classes.icons}
          label={texts.vehiclesReport}
          value="vehicleList"
          icon={<DriveEtaIcon />}
        />
        <BottomNavigationAction
          className={classes.icons}
          label={texts.paymentsReport}
          value="payments"
          icon={<MoneyIcon />}
        />
        {userProfile !== "valet" && (
          <BottomNavigationAction
            className={classes.icons}
            label={texts.incidents}
            value="incidents"
            icon={<WarningIcon />}
          />
        )}

        {/* <BottomNavigationAction
          className={classes.icons}
          label={texts.vehicleRequested}
          value="vehicleRequested"
          icon={<LocationOnIcon />}
        /> */}
      </BottomNavigation>
      {view}
    </div>
  );
}
