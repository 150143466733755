import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import FormDialog from "../../../../../Modals/FormDialog";
import AddZoneForm from "./AddZoneForm";
import ZoneDetail from "./ZoneDetail";

export default function EditZones(props) {
  const {
    setSeeZoneCongif,
    locationConfig,
    zonesData,
    vehicles,
    languageObject,
    locationId,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;

  function handleClose() {
    setSeeZoneCongif(false);
  }

  //  const zones = Object.keys(locationConfig.zones).sort();
  const zones = Object.keys(locationConfig.zones)
    .map((a) => {
      return { ...locationConfig.zones[a], key: a };
    })
    .sort((a, b) =>
      a.zoneName > b.zoneName ? 1 : b.zoneName > a.zoneName ? -1 : 0
    );

  return (
    <div style={{ position: "relative", padding: 25 }}>
      <Typography variant="h3">{texts.editZoneTitle}</Typography>
      <IconButton
        style={{ position: "absolute", top: 10, right: 10 }}
        onClick={() => handleClose()}
        aria-label="settings"
      >
        <CloseIcon />
      </IconButton>
      <FormDialog
        buttomLabel={texts.add}
        ShowIcon={true}
        ShowLabel={true}
        icon={<Add />}
        ButtonStyle={null}
        maxWidthProp={"sm"}
        style={{
          minHeight: "50vh",
          maxHeight: "80vh",
        }}
        report={
          <AddZoneForm
            languageObject={languageObject}
            locationId={locationId}
          />
        }
      />
      {zones.map((zone) => (
        <ZoneDetail
          key={zone.key}
          vehicles={vehicles}
          zonesData={zone}
          zoneKey={zone.key}
          languageObject={languageObject}
          locationConfig={locationConfig}
          locationId={locationId}
        />
      ))}
    </div>
  );
}
