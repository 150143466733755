import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, children, fullScreen } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {children}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogLounge(props) {
  const { buttonText, children, icon, fullScreen } = props;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      {icon && <IconButton onClick={handleClickOpen}>{icon}</IconButton>}
      {!icon && (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          {buttonText}
        </Button>
      )}
      <SimpleDialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <Grid
          container
          style={{ backgroundColor: "#191a2c", flex: 1 }}
          justify="start"
          direction="row"
          alignContent="top"
        >
          <IconButton
            onClick={() => handleClose()}
            color="primary"
            aria-label="cerrar"
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Grid item xs={12} md={12}>
            <div style={{ backgroundColor: "white", flex: 1 }}>{children}</div>
          </Grid>
        </Grid>
      </SimpleDialog>
    </div>
  );
}
