import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  IconButton,
  Typography,
  Paper,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { Checkbox } from "@material-ui/core";
import SwitchField from "../../../../../SwichField";

const useStyles = makeStyles({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  iconEditContainer: {
    // position: "absolute",
    // bottom: 0,
    // top: 0,
    // right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 20,
    paddingBottom: 20
  },
  paper: {
    width: '100%',
    position: 'relative',
    paddingTop: 20,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
    maxWidth: '100%',
  },
  input: {
    paddingRight: 10,
    paddingLeft: 10,
    width: "100%",
  },
});

export default function AdditionalItem(props) {
  const {
    onSubmit,
    onRemoveItem,
    errorMessage,
    defaultName,
    defaultCheck,
    hidden,
    textSize,
    texts,
    productKey,
    editable
  } = props;
  const classes = useStyles();
  const { register, handleSubmit, errors, setValue } = useForm();
  const [editMode, setEditMode] = useState(editable);
  const [active, setActive] = useState(defaultCheck);

  function clearValues() {
    setValue('name', '');
  }

  function onSave(values) {
    console.warn('values', values);
    console.warn('productKey', productKey);
    editable ? clearValues() : setEditMode(false);
    onSubmit(values, productKey);
  }

  function handleRemoveItem() {
    console.log('removing item');
    onRemoveItem({ name: defaultName, checkbox: defaultCheck, hidden: true }, productKey)
  }

  function handleCheck(event) {
    setActive(event.target.checked);
  }

  function handleToggleHide() {
    // console.log('toggleingHide item -', { name: defaultName, description: defaultDescription, price: defaultPrice, hidden: !hidden });
    onSubmit({ name: defaultName, checkbox: defaultCheck, hidden: !hidden }, productKey)
  }

  return editMode ? (
    <Paper className={classes.paper}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              variant="outlined"
              margin="none"
              label={texts.productName}
              className={classes.textField}
              // style={{ width: "25%" }}
              disabled={!editMode}
              helperText={errors ? errorMessage : null}
              error={Boolean(errors.name)}
              defaultValue={defaultName}
              inputRef={register({ required: true })}
              name="name"
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>

            <FormControlLabel
              value={active}
              control={<Checkbox checked={active} onChange={handleCheck} name="checkbox" />}
              label={texts.checkbox}
              labelPlacement="start"
              inputRef={register}
            />
          </Grid>
        </Grid>
      </form>
      <IconButton
        className={classes.iconSave}
        onClick={handleSubmit(onSave)}
      >
        <SaveIcon />
      </IconButton>
    </Paper>
  ) : (
      <div className={classes.textContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <Typography
              className={classes.text}
              noWrap
              gutterBottom
              variant={"subtitle1"}
              component="h2"
            >
              {texts.productName}:
        </Typography>
            <Typography
              className={classes.text}
              noWrap
              gutterBottom
              variant={textSize || "subtitle1"}
              component="h2"
            >
              {defaultName || texts.newName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography
              className={classes.text}
              noWrap
              gutterBottom
              variant={"subtitle1"}
              component="h2"
            >
              {texts.checkbox}:
        </Typography>
            <Typography
              className={classes.text}
              noWrap
              gutterBottom
              variant={textSize || "subtitle1"}
              component="h2"
            >
              {defaultCheck ? texts.yes : texts.no}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <SwitchField
              onSubmit={handleToggleHide}
              value={hidden}
              atribute="hidden"
              label={texts.hidden}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <div className={classes.iconEditContainer}>
              <IconButton
                size={
                  !textSize ||
                    textSize === "h1" ||
                    textSize === "h2" ||
                    textSize === "h3" ||
                    textSize === "h4" ||
                    textSize === "h5"
                    ? "medium"
                    : "small"
                }
                onClick={() => setEditMode(true)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </div>
    );
}