import React, { Component } from "react";

import { Paper } from "@material-ui/core";
import QrReader from "react-qr-reader";

export default class VehicleScanner extends Component {
  state = {
    result: "No result",
  };

  handleScan = (data) => {
    if (data) {
      this.props.callbackDataQR(data);
    }
  };
  handleError = (err) => {
    console.error(err);
  };
  render() {
    return (
      <Paper
        style={{
          alignContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: "100%" }}
        />{" "}
        {/* <QrReader
        constraints={{
          facingMode: "environment",
        }}
        onResult={(result, error) => {
          if (!!result) {
            callbackDataQR(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: "100%" }}
      /> */}
      </Paper>
    );
  }
}
