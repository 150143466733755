import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Grid, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { makeStyles } from "@material-ui/core/styles";
import QRCode from "qrcode.react";
import { fechahora } from "../../../../../../services/utils";
const useStyles = makeStyles({
  form: {
    margin: 20,
    padding: 10,
    width: "100%",
    heigth: "auto",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  text: {},
  detailGrid: {
    marginTop: "30px",
    width: "250px",
  },
});

export default function PreviewReceipt(props) {
  const { locationConfig, languageObject, locationId } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const classes = useStyles();

  const printConfig = locationConfig.receipts.printConfig;
  console.log(printConfig);
  const vehicle = {
    card: "AAAA",
    model: "Model",
    zone: "Zone 1",
    place: "place 1",
    brand: "Brand X",
    color: "white",
    rate: "rate_1",
    gate: "gate_1",
  };
  function renderItem(item, locationConfig) {
    var date = new Date();
    if (!item.visible) {
      return;
    }
    if (item.type == "text") {
      return item.value;
    } else if (item.type == "QR") {
      return <QRCode value={renderQrString(printConfig.qr, locationConfig)} />;
    } else if (item.type == "card") {
      return vehicle.card;
    } else if (item.type == "key") {
      return vehicle.key;
    } else if (item.type == "zone") {
      return vehicle.zone;
    } else if (item.type == "place") {
      return vehicle.place;
    } else if (item.type == "plate/model") {
      return vehicle.plateNumber;
    } else if (item.type == "brand") {
      return vehicle.brand;
    } else if (item.type == "color") {
      return vehicle.color;
    } else if (item.type == "gate") {
      return vehicle.gate;
    } else if (item.type == "rate") {
      if (
        vehicle.rate &&
        locationConfig &&
        locationConfig.payment &&
        locationConfig.payment.rateConfig &&
        locationConfig.payment.rateConfig[vehicle.rate]
      ) {
        return locationConfig.payment.rateConfig[vehicle.rate].name;
      }

      return "";
    } else if (item.type == "rateID") {
      return vehicle.rate || "";
    } else if (item.type == "rateIdCustom") {
      if (
        vehicle.rate &&
        locationConfig &&
        locationConfig.payment &&
        locationConfig.payment.rateConfig &&
        locationConfig.payment.rateConfig[vehicle.rate]
      ) {
        return locationConfig.payment.rateConfig[vehicle.rate].rateIdCustom;
      }

      return "";
    } else if (item.type == "date") {
      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      );
    } else if (item.type == "datetime") {
      return (
        date.getFullYear() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    } else if (item.type == "CAME-PARKARE-Datetime") {
      const oneMinute = 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date("1993/01/01");
      //date = new Date(2023, 4, 15, 23, 0, 0, 0);
      const secondtDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      return (
        Math.round(
          (secondtDate.getTime() - firstDate.getTime()) / oneMinute / 24 / 60
        ) *
          24 *
          60 +
        date.getHours() * 60 +
        date.getMinutes()
      );
    } else if (item.type == "CAME-PARKARE-Seconds") {
      return date.getSeconds() < 10
        ? "0" + date.getSeconds()
        : date.getSeconds(); //"15";
    } else if (item.type == "CAME-PARKARE-Date") {
      const oneMinute = 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date("1993/01/01");
      //date = new Date(2023, 4, 15, 23, 0, 0, 0);
      const secondtDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      return (
        Math.round(
          (secondtDate.getTime() - firstDate.getTime()) / oneMinute / 24 / 60
        ) *
        24 *
        60
      );
    } else {
      return "";
    }
    return;
  }
  function renderQrString(qrObject, locationConfig) {
    let string = "";
    for (var i in qrObject) {
      string = string + renderItem(qrObject[i], locationConfig);
    }
    return string;
  }
  function generateDates() {
    let string = [];
    for (var i = 0; i <= 1500; i++) {
      const date = new Date(
        +new Date() + Math.floor(Math.random() * 100000000000)
      );
      const oneMinute = 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date("1993/01/01");
      //date = new Date(2023, 4, 15, 23, 0, 0, 0);
      const secondtDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      const came =
        Math.round(
          (secondtDate.getTime() - firstDate.getTime()) / oneMinute / 24 / 60
        ) *
          24 *
          60 +
        date.getHours() * 60 +
        date.getMinutes();

      string.push({ date: fechahora(date / 1000), came: came });
    }
    return string;
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      className={classes.detailGrid}
    >
      <Paper style={{ padding: "20px" }}>
        {printConfig.receipt &&
          Object.values(printConfig.receipt).map((item) => (
            <Grid xs={12} style={{ textAlign: "center" }}>
              {renderItem(item, locationConfig)}
            </Grid>
          ))}
      </Paper>
    </Grid>
  );
}
