import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import formsConfig from "./formsConfig.json";
import BusinessDetail from "./BusinessDetail";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
  },
  buttons: {
    margin: 30,
    color: "black",
  },
}));

export default function BusinessList() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.auth.userID);
  const businessList = useSelector((state) => state.valet.businessList);

  const createEventObject = formsConfig;

  const classes = useStyles();

  function onSubmit(data) {
    console.log(data);
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell align="center">País</TableCell>
                <TableCell align="center">Ciudad</TableCell>
                <TableCell align="center">Teléfono</TableCell>
                <TableCell align="center">Web</TableCell>
                <TableCell align="center">Abrir</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businessList &&
                Object.values(businessList).map((row) => (
                  <TableRow key={row.businessInfo.name}>
                    <TableCell component="th" scope="row">
                      {row.businessInfo.businessName}
                    </TableCell>
                    <TableCell align="center">
                      {row.businessInfo.country}
                    </TableCell>
                    <TableCell align="center">
                      {row.businessInfo.city}
                    </TableCell>
                    <TableCell align="center">
                      {row.businessInfo.phone}
                    </TableCell>
                    <TableCell align="center">
                      {row.businessInfo.webSite}
                    </TableCell>
                    <TableCell align="center">
                      <BusinessDetail business={row} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
