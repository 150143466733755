import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Launch from "@material-ui/icons/Launch";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormDialog from "../../../Modals/FormDialog";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  shortTable: {
    minWidth: 300,
    maxHeight: "80%",
  },
  shortPaper: {
    maxHeight: "90%",
  },
});

export default function UserTable({
  businessLocations,
  short,
  userListArray,
  languageObject,
  report,
  businessUsers,
}) {
  const [showAll, setShowAll] = useState(false);
  const classes = useStyles();
  const tableTitles =
    languageObject.authorizeUserdViews.users.userListTableTitles;
  const Report = report;
  return (
    <div>
      <Button
        variant={showAll ? "outlined" : "contained"}
        color="primary"
        onClick={() => setShowAll(!showAll)}
        style={{ margin: "20px" }}
      >
        {showAll ? "Hide arachived Employees" : "Show arachived Employees"}
      </Button>

      <TableContainer
        component={Paper}
        className={short ? classes.shortPaper : null}
      >
        <Table
          className={short ? classes.shortTable : classes.table}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">{tableTitles.open}</TableCell>
              <TableCell align="left">{tableTitles.name}</TableCell>
              <TableCell align="left">{tableTitles.lastname}</TableCell>
              {short ? null : (
                <TableCell align="left">{tableTitles.mail}</TableCell>
              )}
              {short ? null : (
                <TableCell align="left">{tableTitles.phoneNumber}</TableCell>
              )}
              {short ? null : (
                <TableCell align="left">{tableTitles.profile}</TableCell>
              )}
              <TableCell align="left">Auth</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userListArray &&
              userListArray.map((row, i) =>
                row.authorized || showAll ? (
                  <TableRow
                    key={i}
                    style={{ backgroundColor: row.authorized ? "" : "#00BFBF" }}
                  >
                    <TableCell align="center">
                      <FormDialog
                        buttomLabel={tableTitles.open}
                        ShowIcon={true}
                        ShowLabel={true}
                        icon={<Launch />}
                        ButtonStyle={null}
                        maxWidthProp={"md"}
                        style={{
                          minHeight: "80vh",
                          maxHeight: "80vh",
                          backgroundColor: "#f5f5f5",
                        }}
                        report={
                          <Report
                            user={row}
                            businessLocations={businessLocations}
                            languageObject={languageObject}
                          />
                        }
                      />
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.lastname}</TableCell>
                    {short ? null : (
                      <TableCell align="left">{row.mail}</TableCell>
                    )}
                    {short ? null : (
                      <TableCell align="left">{row.phoneNumber}</TableCell>
                    )}
                    {short ? null : (
                      <TableCell align="left">
                        {businessUsers &&
                          row.locationsAuth &&
                          Object.keys(row.locationsAuth).map((i) => {
                            if (!row.locationsAuth[i].authorized) {
                              return <div></div>;
                            }
                            return (
                              <div
                                style={{
                                  padding: 10,
                                  border: "1px solid rgb(212, 212, 212)",
                                }}
                              >
                                {row.locationsAuth[i].locationName}
                                {" : "}
                                {row.locationsAuth[i].profile}
                              </div>
                            );
                          })}
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {row.authorized ? "YES" : "NO"}
                    </TableCell>
                  </TableRow>
                ) : null
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
