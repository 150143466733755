import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  Grid
} from "@material-ui/core";
import Field from "./Field";
import FieldSelect from "./FieldSelect";
import FieldDate from "./FieldDate";

export default function FormRender(props) {
  const { formObject, callback, submitButtonText } = props;

  const { register, handleSubmit, errors, watch } = useForm();
  console.log("errors", errors);

  const onSubmit = (data) => {
    console.log(data);
    callback(data);
  };
  console.log(watch);
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
    <Grid container direction="column">
      {Object.values(formObject).map((field, index) => (
    <Grid item xs={12}>
        <Field
          fieldObject={field}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          watch={watch}
        />
        </Grid>
      ))}
    <Grid item xs={12}>
      <Button
        type="submit"
        style={{ width: "100%" }}
        variant="contained"
        color="primary"
      >
        {submitButtonText}
      </Button>
        </Grid>
        </Grid>
    </form>
  );
}
