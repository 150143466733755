import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AllLocationPlacesTable from "./AllLocationPlacesTable";

const useStyles = makeStyles({
  root: {
    width: 700,
    height: 150,
    margin: 10,
    backgroundColor: "#1d2242",
    color: "white",
  },
  media: {
    height: 140,
  },
  content: {
    height: 160,
  },
});

export default function AllLocationCard(props) {
  const {
    businessLocations,
    vehicles,
    zonesData,
    languageObject,
    activeUsers,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationCard;

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" align="center" component="h2">
            {texts.allLocationCard}
          </Typography>

          <AllLocationPlacesTable
            languageObject={languageObject}
            businessLocations={businessLocations}
            vehicles={vehicles}
            zonesData={zonesData}
            activeUsers={activeUsers}
          />
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          {texts.openButtom}
        </Button>
      </CardActions>
    </Card>
  );
}
