import React from 'react';
import { connect, dispatch} from 'react-redux'

const TechView = ({languageObject}) => {

	return (
		<div>
			<h5>{languageObject.techView.menuName}</h5><br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

		</div>
	)
}


const mapStateToProps = (state) => {
	return {
		languageObject : state.auth.languageObject
	}
  }


  const mapDispatchToProps = (dispatch) => {
		return {
		}
  }

const Tech = connect(
	mapStateToProps,
	mapDispatchToProps
)(TechView);

export default Tech;
