import React, { useState } from "react";
import EditableField from "../../../../../EditableField";
import { Grid, Typography, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  button: {},
}));

export default function RateStep(props) {
  const {
    languageObject,
    timeLessthan,
    index,
    onRemoveStep,
    onSubmitStep,
    price,
    stepKey,
    rate,
  } = props;
  const { timeUnit } = rate;
  const texts = languageObject.authorizeUserdViews.locations.rates;
  const classes = useStyles();
  const today = new Date();
  let hours;
  let minutes;
  if (timeUnit === "hour") {
    hours = Math.floor(timeLessthan);
    minutes = Math.floor((timeLessthan * 60) % 60);
  } else if (timeUnit === "minutes") {
    // this needs testing, currently no location using minute timeUnit
    hours = Math.floor(timeLessthan / 60);
    minutes = Math.floor(timeLessthan % 60);
  }

  function onSubmitChange(change) {
    // console.log("onSubmitChange change", change);
    let newPrice;
    let newtimeLessthan;
    let hourAsInt;
    let minutesAsInt;

    if (change.price !== undefined) {
      let priceAsInt = parseFloat(change.price);
      newPrice = priceAsInt;
    }
    if (change.hours !== undefined) {
      hourAsInt = Math.floor(parseInt(change.hours));
      console.log("new hours", hourAsInt);
    }
    if (change.minutes !== undefined) {
      console.log("we have a change in minutes");
      minutesAsInt = Math.floor(parseInt(change.minutes));
    }
    if (change.hours !== undefined || change.minutes !== undefined) {
      if (timeUnit === "hour") {
        console.log("hourAsInt", hourAsInt);
        console.log("hours", hours);
        console.log("minutesAsInt", minutesAsInt);
        console.log("minutes", minutes);

        newtimeLessthan =
          (hourAsInt !== undefined ? hourAsInt : Math.floor(parseInt(hours))) +
          (minutesAsInt !== undefined
            ? minutesAsInt
            : Math.floor(parseInt(minutes))) /
            60;
        console.log("we are in hours - hourAsInt", hourAsInt);
        console.log("we are in hours - newtimeLessthan", newtimeLessthan);
      } else if (timeUnit === "minutes") {
        console.log("we are in minutes");

        newtimeLessthan =
          (hourAsInt !== undefined ? hourAsInt : hours) * 60 +
          (minutesAsInt !== undefined ? minutesAsInt : minutes);
      } else {
        console.log("we have a problem, timeUnit is not hour or minute");
      }
    }
    let newStep = {
      price: newPrice !== undefined ? newPrice : price,
      timeLessthan:
        newtimeLessthan !== undefined ? newtimeLessthan : timeLessthan,
    };
    onSubmitStep({ [stepKey]: newStep });
    console.log("RateStep sent this to onSubmitStep", { [stepKey]: newStep });
  }

  function onDeleteStep() {
    let newRate = { ...rate };
    newRate.steps[stepKey] = null;
    console.log("onDeleteStep newRateConfig", stepKey);
    console.log("onDeleteStep newRateConfig", newRate);
    onRemoveStep(newRate);
  }

  return (
    <Grid container direction="row" justify="flex-start">
      <Grid item xs={2}>
        <Typography variant="h5" component="h1">
          {texts.step} {parseInt(index) + 1}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <EditableField
          fieldName="price"
          onSubmit={(value) => onSubmitChange(value)}
          label={texts.price}
          errorMessage={texts.error}
          defaultValue={price}
          min={0}
          required={true}
        />
      </Grid>

      <Grid item xs={2}>
        <EditableField
          fieldName="hours"
          onSubmit={(value) => onSubmitChange(value)}
          label={texts.hours}
          errorMessage={texts.error}
          defaultValue={hours}
          min={0}
          required={true}
        />
      </Grid>

      <Grid item xs={2}>
        <EditableField
          fieldName="minutes"
          onSubmit={(value) => onSubmitChange(value)}
          label={texts.minutes}
          errorMessage={texts.error}
          defaultValue={minutes}
          min={0}
          max={59}
          required={true}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={() => onDeleteStep()}
        >
          {texts.removeStep}
        </Button>
      </Grid>
    </Grid>
  );
}
