import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import {
  useGetBrandImage,
  useGetColorName,
  useGetColorValue,
} from "../utilsAuth/VehicleUtils";

import { useTotalAmount, useTotalAmountWidhValidations } from "./PaymentUtils";
import { timestampToDate } from "../../../../services/utils";
import { Paper } from "@material-ui/core";
import { updateVehicleAtribute } from "../../../../data-store/actions/vehicle-actions";
import FormRender from "../../../Fields/FormRender";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "500px",
    height: 120,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
    width: "50%",
  },
  buttonStyle: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    flex: 1,
    marginBottom: 5,
    marginTop: 5,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function ValidationsVehicleCard(props) {
  const { vehicle, locationId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const carsCatalogue = useSelector((state) => state.valet.catalogue.cars);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const userID = useSelector((state) => state.auth.userID);

  const charges = useTotalAmountWidhValidations(
    businessLocations[vehicle.locationId].locationConfig.payment.rateConfig,
    vehicle
  );
  const texts =
    languageObject.authorizeUserdViews.ticketsValidation.tablePayment;

  function applyValidation(data) {
    const currentVehicle = vehicle;
    const access = businessUsers[userID].locationsAuth[vehicle.locationId];

    const date = Math.round(Date.now() / 1000);
    var paymentObject = {
      amount: 0,
      currency: "USD",
      date: date,
      paymentMethod: "auth",
      user: userID,
      info: data.info,
      validation: access.validation || "notAllowed",
      validationValue: access.validationValue || 0,
    };

    var paymentNew = { ...vehicle.payment };
    paymentNew.list = {
      ...vehicle.payment.list,
      [date]: paymentObject,
    };
    console.log("paymentNew", paymentNew);
    dispatch(updateVehicleAtribute(currentVehicle, { payment: paymentNew }));
  }
  function applyItemValidation(pay) {
    const currentVehicle = vehicle;
    const access = businessUsers[userID].locationsAuth[vehicle.locationId];

    const date = Math.round(Date.now() / 1000);
    var paymentObject = {
      amount: -pay.amount,
      currency: "USD",
      date: date,
      paymentMethod: "auth",
      user: userID,
      info: pay.name,
      name: "Validate: Item " + pay.name,
      validation: "amount",
      validationValue: pay.amount,
    };

    var paymentNew = { ...vehicle.payment };
    paymentNew.list = {
      ...vehicle.payment.list,
      [date]: paymentObject,
    };
    console.log("paymentNew", paymentNew);
    dispatch(updateVehicleAtribute(currentVehicle, { payment: paymentNew }));
  }

  function removePayment(currentVehicle, index) {
    console.log("removing payment");
    var paymentObject = currentVehicle.payment.list[index];
    if (paymentObject.deleted && paymentObject.deleted != undefined) {
      paymentObject.deleted = !paymentObject.deleted;
    } else {
      paymentObject.deleted = true;
    }
    var paymentNew = { ...currentVehicle.payment };
    paymentNew.list = {
      ...currentVehicle.payment.list,
      [index]: paymentObject,
    };
    dispatch(updateVehicleAtribute(currentVehicle, { payment: paymentNew }));
  }

  return (
    <Paper
      style={{
        alignContent: "center",
        alignItems: "center",
        margin: 20,
        width: "500px",
      }}
    >
      <Card className={classes.root}>
        <div
          style={{
            width: "15px",
            borderRadius: 100,
            marginRight: 10,
            backgroundColor: useGetColorValue(vehicle.color, carsCatalogue),
          }}
        ></div>
        <CardMedia
          className={classes.cover}
          image={useGetBrandImage(vehicle.brand, carsCatalogue)}
          title=""
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {vehicle.card} / {vehicle.key}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {vehicle.brand} / {vehicle.plateNumber} /{" "}
              {useGetColorName(vehicle.color, languageObject)}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {businessLocations[vehicle.locationId].locationConfig.name}
            </Typography>
          </CardContent>
        </div>
        <div className={classes.buttonStyle}>
          <CardContent className={classes.content}>
            {/* <Typography component="h5" variant="h5">
            {
              useTotalAmount(
                businessLocations[locationId].locationConfig.payment.rateConfig,
                vehicle
              ).pendingPay
            }
          </Typography> */}
          </CardContent>
          <div></div>
        </div>
      </Card>
      <Grid container justify="start" direction="column" alignContent="center">
        <Grid item xs={12} sm={12}>
          <div style={{ padding: "30px" }}>
            {businessUsers[userID].locationsAuth[vehicle.locationId]
              .validation &&
              vehicle.payment &&
              vehicle.payment.list &&
              Object.values(vehicle.payment.list).filter(
                (a) => a.paymentMethod === "auth"
              ).length > 0 && (
                <Typography component="h5" variant="h5">
                  {texts.validated}
                </Typography>
              )}

            {businessUsers[userID].locationsAuth[vehicle.locationId]
              .validation &&
              vehicle.payment &&
              vehicle.payment.list &&
              Object.values(vehicle.payment.list).filter(
                (a) => a.paymentMethod === "auth"
              ).length === 0 && (
                <FormRender
                  callback={applyValidation}
                  formObject={{
                    "4web": {
                      label: texts.info,
                      errorMessage: "",
                      fieldName: "info",
                      type: "text",
                      defaultValue: "",
                      required: false,
                      regex: "",
                      textSize: "h6",
                    },
                  }}
                  submitButtonText={
                    texts.validate +
                    " " +
                    businessUsers[userID].locationsAuth[vehicle.locationId]
                      .validationValue +
                    " - " +
                    businessUsers[userID].locationsAuth[vehicle.locationId]
                      .validation
                  }
                />
              )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{texts.date}</TableCell>
                  <TableCell align="center">{texts.user}</TableCell>
                  <TableCell align="center">{texts.name}</TableCell>
                  <TableCell align="center">{texts.amount}</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    padding={"default"}
                    component="th"
                    scope="row"
                  >
                    {timestampToDate(new Date())}
                  </TableCell>
                  <TableCell padding={"default"} align="center">
                    System
                  </TableCell>
                  <TableCell padding={"default"} align="center"></TableCell>
                  <TableCell padding={"default"} align="center">
                    {charges.charge}
                  </TableCell>
                </TableRow>

                {Object.values(vehicle.payment.list).map((pay) => {
                  const payFinal = pay;
                  return (
                    <TableRow
                      key={pay.date}
                      style={
                        pay.deleted
                          ? { backgroundColor: "grey" }
                          : { backgroundColor: "" }
                      }
                    >
                      <TableCell
                        align="center"
                        padding={"default"}
                        component="th"
                        scope="row"
                      >
                        {timestampToDate(payFinal.date * 1000)}
                      </TableCell>
                      <TableCell padding={"default"} align="center">
                        {businessUsers[payFinal.user] !== undefined
                          ? businessUsers[payFinal.user].name
                          : payFinal.user}{" "}
                        {businessUsers[payFinal.user] !== undefined
                          ? businessUsers[payFinal.user].lastname
                          : ""}
                      </TableCell>
                      <TableCell padding={"default"} align="center">
                        {payFinal.name}
                      </TableCell>

                      <TableCell padding={"default"} align="center">
                        {payFinal.amount}
                      </TableCell>
                      <TableCell padding={"default"} align="center">
                        {userID === payFinal.user && (
                          <IconButton
                            disabled={payFinal.deleted ? true : false}
                            aria-label="delete"
                            onClick={() =>
                              removePayment(vehicle, payFinal.date)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}

                        {businessUsers[userID].locationsAuth[vehicle.locationId]
                          .validation && (
                          <IconButton
                            onClick={() => applyItemValidation(payFinal)}
                          >
                            <CheckBoxIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell padding={"default"} align="center"></TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell
                    align="center"
                    padding={"default"}
                    component="th"
                    scope="row"
                  ></TableCell>

                  <TableCell padding={"default"} align="center"></TableCell>

                  <TableCell padding={"default"} align="center">
                    {texts.pending}
                  </TableCell>

                  <TableCell padding={"default"} align="center">
                    {charges.pendingPay}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
}
