import * as types from "../actions/action-types";

const initialState = [];
export default function valetReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHATDATA_ADD:
      var userID = action.userID;
      var chatItemId = action.chatItemId;
      var chatItem = action.chatItem;
      chatItem.id = chatItem;
      chatItem.sync = true;
      return Object.assign({}, state, {
        chatMessages: Object.assign({}, state.chatMessages, {
          [chatItemId]: chatItem,
        }),
      });
    case types.CHATDATA_REMOVE:
      var chatItemId = action.chatItemId;
      var chatNew = { ...state.chat };
      delete chatNew[chatItemId];
      return Object.assign({}, state, {
        chatMessages: chatNew,
      });

    case types.CHATDATA_CHANGE:
      var userID = action.userID;
      var chatItem = action.chatItem;
      chatItem.id = chatItemId;
      chatItem.sync = true;
      return Object.assign({}, state, {
        chatMessages: Object.assign({}, state.chatMessages, {
          [chatItemId]: chatItem,
        }),
      });
    case types.CHATDATA:
      return Object.assign({}, state, {
        chatMessages: action.chatMessages,
      });
    case types.CURRENT_CHAT:
      return Object.assign({}, state, {
        currentChat: action.currentChat,
      });
    default:
      return state;
  }
}
