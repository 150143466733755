import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import RateStep from "./RateStep";
import { makeStyles } from "@material-ui/core/styles";
import EditableField from "../../../../../EditableField";
import SwitchField from "../../../../../SwichField";
import InputFileButton from "../../../../../Fields/InputFileButton1.1";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    width: "100%",
  },
  currentRate: {
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 20,
    width: "100%",
    display: "flex",
    marginTop: 15,
    marginBottom: 15,
    padding: 15,
  },
  table: {
    width: "80%",
  },
}));

export default function SetDefaultProducts(props) {
  const { rate, rateKey, onSubmit, languageObject } = props;
  const { timeUnit, name, hide, rateIdCustom } = rate;
  console.warn(`name: ${name}, hide ${hide}, timeUnit ${timeUnit}`);
  const classes = useStyles();
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;

  const locationConfig = useSelector((state) =>
    state.valet.businessLocations != undefined &&
    state.valet.businessLocations[locationId] != undefined
      ? state.valet.businessLocations[locationId].locationConfig
      : undefined
  );
  const shopItems = locationConfig.extraProducts || {};

  const [stepsList, setStepsList] = useState({});
  const [message, setMessage] = useState("");
  const [showButtom, setShowButtom] = useState(false);

  function onSubmitStep() {
    // console.log("onSubmitStep newSteps", steps);
    let newRate = { ...rate, steps: stepsList };
    console.log("onSubmitStep newRateConfig", newRate);
    onSubmit({ [rateKey]: newRate });
    setMessage("Steps Updated");
  }

  const steps = rate.steps;

  return (
    <Grid container style={{ padding: 20 }}>
      <Grid item style={{ margin: 10 }} xs={12} sm={12} md={12}>
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Set Default Products
        </Typography>
      </Grid>
      <Grid item style={{ margin: 20 }} xs={12} sm={12} md={12}>
        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          Add the steps to the file acording to to your unit time set rate
          (hours or minutes) the price for each step should be acumulative, for
          example step 1 - 1 hours, price 1 USD, step 2 - 2 hours, price 1 uds
          plus step 1, total 2 USD.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Button
          color="primary"
          variant="contained"
          href="https://firebasestorage.googleapis.com/v0/b/softicade.appspot.com/o/SamplesFile%2FSmartValet-Import%20Rates%20Example.csv?alt=media&token=462fdf14-e0de-40f2-8702-7cb5e37afcce"
        >
          Download sample file here
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InputFileButton
          type="csv"
          callback={onSubmitFile}
          uploadingPhotos={null}
          uploadingPhotosProgress={null}
          text="Select File to upload"
        />
      </Grid>
      {showButtom && stepsList !== {} && (
        <Grid style={{ margin: 30 }} item xs={12} sm={12} md={12}>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            The imported data will replace all prevous steps of this rate.
          </Typography>
          <Button
            style={{ width: "100%", padding: 20 }}
            onClick={() => onSubmitStep()}
            color="primary"
            variant="contained"
          >
            Save Steps
          </Button>
        </Grid>
      )}

      <Grid item style={{ margin: 10 }} xs={12} sm={12} md={12}>
        <Typography variant="h2" style={{ textAlign: "center" }}>
          {message}{" "}
        </Typography>
      </Grid>
      <Grid item style={{ margin: 10 }} xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Step</TableCell>
                <TableCell align="center">Time Less Than</TableCell>
                <TableCell align="center">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(stepsList).map((key) => (
                <TableRow key={key}>
                  <TableCell align="center" component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="center">
                    {stepsList[key].timeLessthan}
                  </TableCell>
                  <TableCell align="center">{stepsList[key].price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
