import { fecha, fechahora, sumarSegAFecha } from "../../../../services/utils";

export function useGetBusinessVehiclesCount(vehicles) {
  let count = 0;
  // cuento como válidos los vehiculos con endStatus false finalizado;
  for (var i in vehicles) {
    if (!vehicles[i].closed) {
      count++;
    }
  }
  return count;
}

export function useGetLocationVehiclesCount(locationId, vehicles) {
  let count = 0;
  // cuento como válidos los vehiculos con endStatus false finalizado;
  for (var i in vehicles) {
    if (vehicles[i].locationId == locationId && !vehicles[i].closed) {
      count++;
    }
  }
  return count;
}

export function useGetLocationVehiclesByShift(
  locationId,
  vehicles,
  shiftDay,
  startShift,
  endShift
) {
  console.log("fecha", fecha(0));
  const millisecondsByDay = 24 * 60 * 60;
  const currentDate = fecha(0);
  const currentDateTime = new Date();
  const currentTime =
    currentDateTime.getHours() * 60 * 60 +
    currentDateTime.getMinutes() * 60 +
    currentDateTime.getSeconds();
  let dateStart;
  let dateEnd;
  if (endShift.indexOf("+1") >= 0) {
    console.log("nextDay", currentTime, currentDateTime, currentDate);
    // entra en este condicional para ver que rango de fechas tomar en caso que el turno de la ubicación inicie un día y finaliza el día siguiente
    const endShiftNew = endShift.split(" ")[1];
    const startShiftNewSplit = startShift.split(":");
    const startShiftInt =
      (startShiftNewSplit[0] / 1) * 60 * 60 +
      (startShiftNewSplit[1] / 1) * 60 +
      startShiftNewSplit[2] / 1;

    if (currentTime < startShiftInt) {
      // si la hora actual es menor que la hora de inicio de turno regreso un día a la fecha de inicio
      console.log("nextDay", "menor", currentTime, startShiftInt);
      dateStart =
        new Date(currentDate + "T" + startShift + ".000").getTime() / 1000 -
        millisecondsByDay;
      dateEnd =
        new Date(currentDate + "T" + endShiftNew + ".000").getTime() / 1000;
    } else {
      // si la hora actual es mayor que la hora de inicio de turno sumo un día a la fecha de cierre;
      console.log("nextDay", "mayor", currentTime, startShiftInt);
      dateStart =
        new Date(currentDate + "T" + startShift + ".000").getTime() / 1000;
      dateEnd =
        new Date(currentDate + "T" + endShiftNew + ".000").getTime() / 1000 +
        millisecondsByDay;
    }
    console.log("nextDay", startShift, endShift);

    console.log("nextDay", fecha(dateStart * 1000), fecha(dateEnd * 1000));
    // le sumo un día a la hora seteada para incluir los vehículos aunque el turno cierre el día siguiente
  } else {
    dateStart =
      new Date(currentDate + "T" + startShift + ".000").getTime() / 1000;
    dateEnd = new Date(currentDate + "T" + endShift + ".000").getTime() / 1000;
  }

  if (shiftDay == "yesterday") {
    dateStart = dateStart - millisecondsByDay;
    dateEnd = dateEnd - millisecondsByDay;
  } else if (shiftDay == "-2days") {
    dateStart = dateStart - millisecondsByDay * 2;
    dateEnd = dateEnd - millisecondsByDay * 2;
  } else if (shiftDay == "-3days") {
    dateStart = dateStart - millisecondsByDay * 3;
    dateEnd = dateEnd - millisecondsByDay * 3;
  }

  console.log(dateEnd);

  let count = 0;
  let vehiclesNew = {};
  // cuento como válidos los vehiculos con endStatus false finalizado;
  for (var i in vehicles) {
    // console.log("vehicle", vehicles[i].startTime.seconds);
    // console.log("start", dateStart);
    // console.log("end", dateEnd);
    // console.log("dif", vehicles[i].startTime.seconds - dateStart);

    // Filtra los vehiculos de día correspondiente al turno seleccionado mas los vehuculos que no han sido cerrados en días previos

    function getVehicleToList(vehicle, dateStart, dateEnd, locationId) {
      console.log("dateStart", dateStart);
      const lastAjusted = Object.values(vehicles[i].changes).filter(
        (a) => a.date > dateStart
      );

      if (
        vehicle.locationId === locationId &&
        new Date(vehicle.startTime.seconds * 1000) <= new Date(dateEnd * 1000)
      ) {
        if (
          new Date(vehicle.startTime.seconds * 1000) >=
          new Date(dateStart * 1000)
        ) {
          return true;
        } else if (
          lastAjusted.length > 0 ||
          vehicle.closed === undefined ||
          !vehicle.closed
        ) {
          return true;
        } else {
          return false;
        }
      }

      return false;
    }

    if (getVehicleToList(vehicles[i], dateStart, dateEnd, locationId)) {
      vehiclesNew[i] = { ...vehicles[i] };
    }
  }
  console.log(vehicles);
  console.log(vehiclesNew);
  return vehiclesNew;
}

export function useGetLocationZones(locationConfig) {
  var zones = locationConfig.zones;
  var total = 0;

  for (var i in zones) {
    if (!zones[i].hide) {
      if (zones[i].placesNamed) {
        total = total + Object.keys(zones[i].zonePlaces).length / 1;
        // console.log("cuenta ", Object.keys(zones[i].zonePlaces).length);
      } else {
        total = total + zones[i].zonePlacesNumber / 1;
        // console.log("cuenta ", zones[i].zonePlacesNumber);
      }
    }
  }
  return total;
}
export function useGetGateName(locationConfig, gateId) {
  if (
    locationConfig &&
    locationConfig.payment &&
    locationConfig.payment.gateConfig &&
    locationConfig.payment.gateConfig[gateId]
  ) {
    return locationConfig.payment.gateConfig[gateId].name || "NA";
  } else {
    return "NA";
  }
}
export function useGetBusinessZones(businessLocations) {
  var total = 0;
  for (var e in businessLocations) {
    var zones = businessLocations[e].locationConfig.zones;
    for (var i in zones) {
      if (!zones[i].hide) {
        if (zones[i].placesNamed) {
          total = total + Object.keys(zones[i].zonePlaces).length / 1;
        } else {
          total = total + zones[i].zonePlacesNumber / 1;
        }
      }
    }
  }
  return total;
}
export function useGetBusinessIncome(vehicles) {
  let income = 0;
  // cuento como válidos los vehiculos con endStatus false finalizado;
  for (var i in vehicles) {
    var payments = vehicles[i].payment.list;
    for (var e in payments) {
      if (!payments[e].deleted) {
        income = income + payments[e].amount / 1;
      }
    }
  }
  return income;
}
export function useGetLocationIncome(locationId, vehicles) {
  let income = 0;
  // console.log("location", locationId);
  // cuento como válidos los vehiculos con endStatus false finalizado;
  for (var i in vehicles) {
    if (vehicles[i].locationId == locationId) {
      var payments = vehicles[i].payment.list;
      for (var e in payments) {
        if (!payments[e].deleted) {
          income = income + payments[e].amount / 1;
        }
      }
    }
  }
  return income;
}

export function useGetBusinessActiveUsers(activeUsers) {
  let usersCount = 0;
  // cuento como válidos los vehiculos con endStatus false finalizado;
  for (var i in activeUsers) {
    if (activeUsers[i].active) {
      usersCount++;
    }
  }
  return usersCount;
}
export function useGetLocationActiveUsers(locationId, activeUsers) {
  let usersCount = 0;
  // cuento como válidos los vehiculos con endStatus false finalizado;
  for (var i in activeUsers) {
    if (activeUsers[i].active && activeUsers[i].locationId == locationId) {
      usersCount++;
    }
  }
  return usersCount;
}
