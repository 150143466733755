export function generatePayRollReport({ languageObject, reportData, userListArray, businessLocations }) {
  const { reportType, startDate, endDate } = reportData;
  const texts = languageObject.authorizeUserdViews.users.payRoll.reportGenerator;
  const locationsArr = Object.keys(businessLocations);
  console.log('generating pay roll report- usersListArray', userListArray);

  let csvData = [];
  let header = [];
  let body = [];
  let footer = [];
  let title;

  function generateMultiUserReport(userArray, locationKey) {
    let reportBody = [];
    userArray.forEach((user) => {
      console.log('user for payroll -', user);
      const name = `${user.name} ${user.lastname}`;
      let numberOfShifts = 0;
      let hoursWorked = 0;
      let totalPay = 0;
      if (user.payRollConfig && user.payRollConfig.shifts) {
        Object.keys(user.payRollConfig.shifts).forEach((shiftKey) => {
          let thisShift = user.payRollConfig.shifts[shiftKey];
          if (new Date(reportData.startDate) <= new Date(thisShift.date) && new Date(thisShift.date) <= new Date(reportData.endDate)) {
            if ((locationKey && thisShift.location === locationKey) || !locationKey) {
              let thisShiftHours = getDifferenceHHMM(thisShift.startTime, thisShift.endTime);
              console.log('shift for payroll ', thisShift);
              numberOfShifts += 1;
              hoursWorked += thisShiftHours
              totalPay += Math.round(thisShiftHours * thisShift.shiftHourlyPay);
            }
          }
        });
        let avgPay = Math.round(totalPay / hoursWorked);
        let avgHoursPerShift = Math.round((hoursWorked / numberOfShifts + 0.00001) * 100) / 100;
        reportBody.push([name, numberOfShifts, Math.round((hoursWorked + 0.00001) * 100) / 100, avgHoursPerShift || 0, avgPay || 0, Math.round((totalPay + 0.00001) * 100) / 100])
      }
    })
    return reportBody;
  }

  if (reportType === "all_users") {
    title = `${texts.allUsers}: ${reportData.startDate} - ${reportData.endDate} `
    header = [texts.name, texts.numberOfShifts, texts.hoursWorked, texts.avgHoursPerShift, texts.avgPay, texts.totalPay];
    body = generateMultiUserReport(userListArray);
  } else if (locationsArr.some(locationKey => locationKey === reportType)) {
    let locationKey = reportType;
    let locationName = businessLocations[locationKey].locationConfig.name
    title = `${locationName}: ${reportData.startDate} - ${reportData.endDate} `
    header = [texts.name, texts.numberOfShifts, texts.hoursWorked, texts.avgHoursPerShift, texts.avgPay, texts.totalPay];
    body = generateMultiUserReport(userListArray, locationKey);
  } else if (userListArray.some(user => user.userId === reportType)) {
    let user = userListArray.filter(user => user.userId === reportType)[0];
    console.log('found user for payroll report', user);
    title = `${user.name} ${user.lastname}: ${reportData.startDate} - ${reportData.endDate} `
    header = [texts.date, texts.location, texts.startTime, texts.endTime, texts.hoursWorked, texts.hourlyWage, texts.shiftPay];
    let hoursWorked = 0;
    let totalPay = 0;
    if (user.payRollConfig && user.payRollConfig.shifts) {
      Object.keys(user.payRollConfig.shifts).forEach((shiftKey) => {
        let thisShift = user.payRollConfig.shifts[shiftKey];
        if (new Date(reportData.startDate) <= new Date(thisShift.date) && new Date(thisShift.date) <= new Date(reportData.endDate)) {
          let thisShiftHours = getDifferenceHHMM(thisShift.startTime, thisShift.endTime);
          let shiftLocation;
          if (thisShift.location) {
            shiftLocation = businessLocations[thisShift.location].locationConfig.name;
          }
          hoursWorked += thisShiftHours;
          totalPay += thisShiftHours * thisShift.shiftHourlyPay;

          let shiftPay = Math.round(thisShiftHours * thisShift.shiftHourlyPay);
          console.log('shift for payroll ', thisShift);
          body.push([thisShift.date, shiftLocation, thisShift.startTime, thisShift.endTime, thisShiftHours, thisShift.shiftHourlyPay, shiftPay])
        }
      })
      footer = ['', '', '', `${texts.hoursWorked}:`, `${Math.round((hoursWorked + 0.00001) * 100) / 100}`, `${texts.totalPay}:`, `$${Math.round((totalPay + 0.00001) * 100) / 100}`]
    }
  }

  csvData.push(header);
  csvData.push(...body);
  csvData.push(footer);
  console.log('report data', csvData)
  return { csvData, title }
}

export function getDifferenceHHMM(time1, time2) {
  const time1Split = time1.split(":");
  const time2Split = time2.split(":");
  var date1 = new Date(2020, 0, 1, time1Split[0], time1Split[1]);
  var date2 = new Date(2020, 0, 1, time2Split[0], time2Split[1]);

  // por si el turno pasó la medianoche
  if (date2 < date1) {
    date2.setDate(date2.getDate() + 1);
  }

  var diff = date2 - date1;
  var hh = Math.round((diff / 1000 / 60 / 60 + 0.00001) * 100) / 100;
  return hh;
}

export function getCurrentQuincenaDates(date) {
  const thisMonth = date.getMonth();
  const thisYear = date.getFullYear();
  const lastDayOfMonth = new Date(thisYear, thisMonth, 0).getDate() - 1;
  let quincenaStart;
  let quincenaEnd;
  if (date.getDate() <= 15) {
    quincenaStart = new Date(thisYear, thisMonth, 1);
    quincenaEnd = new Date(thisYear, thisMonth, 15);
  } else {
    quincenaStart = new Date(thisYear, thisMonth, 16);
    quincenaEnd = new Date(thisYear, thisMonth, lastDayOfMonth);
  }
  return { quincenaStart, quincenaEnd }
}

export function getTotalPayroll(startDate, endDate, userListArray) {
  let hoursWorked = 0;
  let totalPay = 0;
  Object.keys(userListArray).forEach((userKey) => {
    let user = userListArray[userKey];
    if (user.payRollConfig && user.payRollConfig.shifts) {
      Object.keys(user.payRollConfig.shifts).forEach((shiftKey) => {
        let thisShift = user.payRollConfig.shifts[shiftKey];
        if (new Date(startDate) <= new Date(thisShift.date) && new Date(thisShift.date) <= new Date(endDate)) {
          let thisShiftHours = getDifferenceHHMM(thisShift.startTime, thisShift.endTime);
          hoursWorked += Math.round((thisShiftHours + 0.00001) * 100) / 100;
          totalPay += Math.round(thisShiftHours * thisShift.shiftHourlyPay);
        }
      });
    }
  })
  return { totalPay, hoursWorked: Math.round((hoursWorked + 0.00001) * 100) / 100 || 0 }
}
