import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import SesionView from "../Authentication/SesionView";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Home from "@material-ui/icons/Home";
import Info from "@material-ui/icons/Info";
import Computer from "@material-ui/icons/Computer";
import Phone from "@material-ui/icons/Phone";
import SvgIcon from "@material-ui/core/SvgIcon";

import Divider from "@material-ui/core/Divider";
import AuthorizedMenuItems from "./AuthorizedMenuItems";

const useStyles = makeStyles((theme) => ({
  linkcss: {
    margin: theme.spacing(1),
    color: "#1d2242",
  },
}));

const SideMenuView = ({
  userAccount,
  smartValetProfile,
  handleDrawerClose,
  languageObject,
  match,
}) => {
  const classes = useStyles();

  let authorizedUsers = (
    <AuthorizedMenuItems handleDrawerClose={handleDrawerClose} />
  );

  return (
    <div>
      <SesionView handleDrawerClose={handleDrawerClose} />
      {authorizedUsers}
      <Divider />
      <List>
        {smartValetProfile == "master" && (
          <ListItem button onClick={handleDrawerClose}>
            <ListItemIcon className={classes.linkcss}>
              <Info />
            </ListItemIcon>
            <Link to={"sv/"}>
              <ListItemText
                className={classes.linkcss}
                primary={"Autorizados SV"}
              />
            </Link>
          </ListItem>
        )}
        {/* <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.linkcss}>
            <Home />
          </ListItemIcon>
          <Link to={languageObject.homeView.link}>
            <ListItemText
              className={classes.linkcss}
              primary={languageObject.homeView.menuName}
            />
          </Link>
        </ListItem>

        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.linkcss}>
            <Info />
          </ListItemIcon>
          <Link to={languageObject.aboutView.link}>
            <ListItemText
              className={classes.linkcss}
              primary={languageObject.aboutView.menuName}
            />
          </Link>
        </ListItem>

        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.linkcss}>
            <Computer />
          </ListItemIcon>
          <Link to={languageObject.techView.link}>
            <ListItemText
              className={classes.linkcss}
              primary={languageObject.techView.menuName}
            />
          </Link>
        </ListItem>

        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.linkcss}>
            <Phone />
          </ListItemIcon>
          <Link to={languageObject.contactView.link}>
            <ListItemText
              className={classes.linkcss}
              primary={languageObject.contactView.menuName}
            />
          </Link>
        </ListItem>
                <Divider />
 */}
      </List>
    </div>
  );
};

const mapStateToProps = (state, { match }) => {
  return {
    userAccount: state.auth.userAccount,
    smartValetProfile: state.auth.smartValetProfile,
    languageObject: state.auth.languageObject,
    route: match,
  };
};

const SideMenu = connect(mapStateToProps)(SideMenuView);

export default SideMenu;
