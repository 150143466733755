import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
  },
});

export default function FieldSelect(props) {
  const { fieldObject, register, errors } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    optionsArray,
  } = fieldObject;
  const classes = useStyles();
  const [editMode, setEditMode] = useState(true);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  function handleChange(event) {
    console.log(event.target.value);
    setCurrentValue(event.target.value);
  }
  return (
    <div>
      <input
        style={{ display: "none" }}
        type="select"
        name={fieldName}
        ref={register}
        value={currentValue}
      />
      <TextField
        margin="none"
        fullWidth
        select
        label={label}
        disabled={false}
        onChange={handleChange}
        helperText={errors[fieldName] ? errorMessage : null}
        error={Boolean(errors[fieldName])}
        defaultValue={defaultValue}
        inputRef={register({ required: required })}
        name={fieldName}
        type={max !== undefined || min !== undefined ? "number" : undefined}
        InputProps={{
          className: classes.input,
          inputProps: {
            max: max,
            min: min,
          },
        }}
      >
        {optionsArray.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
