import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import ValetVehicleCard from "./ValetVehicleCard";
import TextField from "@material-ui/core/TextField";
import {
  useGetBrandImage,
  useGetColorName,
  useGetColorValue,
  useSetStatusText,
} from "../utilsAuth/VehicleUtils";
import ReactAudioPlayer from "react-audio-player";
import ReactHowler from "react-howler";
import audio from "./iphone-notificacion.mp3";
import { setSoundState } from "../../../../data-store/actions/vehicle-actions";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function ValetRequested(props) {
  const { locationId, callback } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const vehicles = useSelector((state) => state.vehicle.vehicles);

  const userID = useSelector((state) => state.auth.userID);
  const catalogue = useSelector((state) => state.valet.catalogue);
  const soundState = useSelector((state) => state.vehicle.soundState);

  const carsCatalogue = catalogue !== undefined ? catalogue.cars : {};
  const [valueToFind, setValueToFind] = useState("");

  const texts = languageObject.authorizeUserdViews.ticketsValidation;
  const texts1 = languageObject.authorizeUserdViews.valetView;

  const [vehiclesRequested, setVehiclesRequested] = useState([]);
  const [sound, setSound] = useState(false);
  const [count, setCount] = useState(false);

  const prevList = usePrevious(vehiclesRequested);
  const prevCount = usePrevious(count);

  useEffect(() => {
    if (vehicles) {
      let vehicleNew = Object.keys(vehicles)
        .filter(
          (a) =>
            vehicles[a].status === "requested" &&
            vehicles[a].locationId == locationId
        )
        // para ordenar por fecha de solicitud
        .sort((a, b) => vehicles[a].requestedTime - vehicles[b].requestedTime);
      setCount(vehicleNew.length);
      setVehiclesRequested(vehicleNew);

      console.log("soundState", soundState);

      if (soundState) {
        setTimeout(function () {
          dispatch(setSoundState(false));
        }, 2000);
      }
      // if (count > prevCount) {
      //   setSound(true);
      //   setTimeout(function () {
      //     setSound(false);
      //   }, 2000);
      // }

      // if (prevList && vehicleNew && vehicleNew.length > prevList.length) {
      //   setSound(true);
      //   setTimeout(function () {
      //     setSound(false);
      //   }, 2000);
      // }
    }

    console.log("prevCount", prevCount, count, prevList);
  }, [vehicles, soundState]);

  if (businessId === undefined) {
    return <div></div>;
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
  }

  return (
    <Paper
      style={{
        flex: 1,
        width: "100%",
        marginBottom: 20,
        padding: 10,
        height: "auto",
        backgroundColor: "#e0e0e0",
      }}
    >
      <Grid container justify="start" direction="row" alignContent="center">
        <Grid className={classes.spacing} item xs={12} sm={12}>
          <Typography
            className={classes.text}
            gutterBottom
            variant={"h5"}
            component="h2"
          >
            {texts1.vehicleRequested} : {count}
          </Typography>
          <Typography
            className={classes.text}
            gutterBottom
            variant={"subtitle1"}
            component="h2"
          >
            {texts1.vehicleRequestedDetail}
          </Typography>
        </Grid>
        {vehicles &&
          vehiclesRequested.map((index) => (
            <Grid className={classes.spacing} key={index} item xs={4} sm={2}>
              <Button
                style={{
                  margin: 5,
                  fontSize: 20,
                  backgroundColor: "#c3334c",
                  color: "#ffffff",
                }}
                variant="contained"
                onClick={() => callback(vehicles[index])}
              >
                {vehicles[index].card}
              </Button>
            </Grid>
          ))}
      </Grid>
      {soundState && (
        <div style={{ display: "none" }}>
          <ReactHowler src={audio} playing={true} />

          {/* <ReactAudioPlayer
            src={
              "https://firebasestorage.googleapis.com/v0/b/softicade.appspot.com/o/Audios%2Fiphone-notificacion.mp3?alt=media&token=331c535e-cb36-4eb3-ace4-60a12bea9a89"
            }
            autoPlay
            controls
          /> */}
        </div>
      )}
    </Paper>
  );
}
