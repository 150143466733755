import React from 'react';
import { connect} from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import FormDialog from '../../../Modals/FormDialog';
import AuthorizeUserDetail from './AuthorizeUserDetail'
import AddUsersForm from './AddUsersForm';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

 
  
const AddAutorizeUsersView = ({languageObject, businessUsers}) => {
	const classes = useStyles();
	const tableTitles = languageObject.authorizeUserdViews.users;

	return (
		<FormDialog 
			buttomLabel={tableTitles.addUserButtom}
			ShowIcon={true}
			ShowLabel={true}
			icon={<Add/>}
			ButtonStyle={null}
			maxWidthProp={'md'}
			style={{
					minHeight: '80vh',
					maxHeight: '80vh',
			}}
			report={<AddUsersForm/>}
			/>
	)
}


const mapStateToProps = (state) => {
	return {
		languageObject : state.auth.languageObject,
		businessId : state.valet.businessId,
		businessUsers : state.valet.businessUsers
	}
  }


const mapDispatchToProps = (dispatch) => {
		return {
		}
  }

const AddAutorizeUsers = connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAutorizeUsersView);

export default AddAutorizeUsers;
