import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Divider from "@material-ui/core/Divider";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import paymentMethods from "../../../../../../assets/backgroundPhotos/paymentMethods.png";
import LocationPaymentDetail from "./LocationPaymentDetail";
import EditPaymentDetails from "./EditPaymentDetails";
import { Popover } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 330,
    margin: 10,
    position: "relative",
  },
  media: {
    height: 70,
  },
  content: {
    height: 220,
  },
  cardHeader: {
    backgroundColor: "#36A9E1",
    color: "#1D2242",
  },
});

export default function LocationDetailPaymentCard(props) {
  const {
    locationConfig,
    locationId,
    zonesData,
    activeUsers,
    vehicles,
    languageObject,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const [seeEditPaymentDetails, setSeeEditPaymentDetails] = useState(false);
  const classes = useStyles();

  const image = paymentMethods;
  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        avatar={<MonetizationOnIcon />}
        action={
          <IconButton
            onClick={() => setSeeEditPaymentDetails(true)}
            aria-label="settings"
          >
            <SettingsIcon />
          </IconButton>
        }
        title={texts.locationPaymentTitle}
        subheader=""
      />
      <Divider />
      <CardContent className={classes.content}>
        {/* Aquí va el detalle de ingresos del turno, los metodos de pago y el detalle de las tarifas
        En la configuración se debe poder setear todos esos valores. Tarifas por minuto, por hora, fija, etc */}
        <LocationPaymentDetail
          languageObject={languageObject}
          locationConfig={locationConfig}
          zonesData={zonesData}
          activeUsers={activeUsers}
          vehicles={vehicles}
          locationId={locationId}
        />
      </CardContent>
      <Popover
        children={
          <EditPaymentDetails
            vehicles={vehicles}
            zonesData={zonesData}
            languageObject={languageObject}
            locationConfig={locationConfig}
            setSeeEditPaymentDetails={setSeeEditPaymentDetails}
            locationId={locationId}
          />
        }
        open={seeEditPaymentDetails}
        // locationId={locationId}
        PaperProps={{ style: { height: "80vh", width: "80vw" } }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
    </Card>
  );
}
