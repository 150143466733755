import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import Gate from "./Gate";

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    width: "100%",
  },
  title: {
    marginBottom: 25,
  },
}));

export default function GateConfigContainer(props) {
  const { languageObject, gateConfig, onSubmit, onSubmitRemoveStep } = props;
  const classes = useStyles();
  const cleangateConfigKeys = Object.keys(gateConfig).filter(
    (key) => gateConfig[key].name
  );
  const cleangateConfig = {};
  cleangateConfigKeys.forEach(
    (key) => (cleangateConfig[key] = gateConfig[key])
  );
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;

  function getDefaultgateKey() {
    return Object.keys(cleangateConfig).find(
      (gate) => cleangateConfig[gate].isDefault === true
    );
  }
  const defaultgateKey = getDefaultgateKey();
  const [currentgateKey, setCurrentgateKey] = useState(null);
  useEffect(() => setCurrentgateKey(defaultgateKey), [defaultgateKey]);

  function handleOnSubmit(newgate) {
    const newgateConfig = { ...cleangateConfig, ...newgate };
    // console.log(newgateConfig);
    onSubmit(newgateConfig);
  }

  function handleOnSubmitRemoveStep(newgate) {
    const newgateConfig = { ...cleangateConfig, ...newgate };
    // console.log(newgateConfig);
    onSubmit(newgateConfig);
  }

  function handleChangeDefaultgate(newDefaultgateKey) {
    // console.warn(
    //   "handleChangeDefaultgate newDefaultgateKey",
    //   newDefaultgateKey
    // );
    let newgateConfig = { ...cleangateConfig };
    Object.keys(newgateConfig).forEach((gateKey) => {
      // console.warn("newgateConfig[gateKey]", newgateConfig[gateKey]);
      newgateConfig[gateKey].isDefault = false;
    });
    newgateConfig[newDefaultgateKey].isDefault = true;
    // console.warn("handleChangeDefaultgate new", newgateConfig);
    onSubmit(newgateConfig);
  }

  function addgate() {
    const emptygate = {
      isDefault: false,
      hide: false,
      name: "New",
    };
    // console.warn('Object.keys(gateConfig) ', Object.keys(gateConfig));
    const gateNames = Object.keys(gateConfig);
    let previousgateNumber = gateNames.length;
    function checkName() {
      if (gateNames.includes(`gate_${previousgateNumber + 1}`)) {
        previousgateNumber += 1;
        checkName();
      }
    }

    checkName();

    let newgate = {
      [`gate_${previousgateNumber + 1}`]: {
        ...emptygate,
        gateID: `gate_${previousgateNumber + 1}`,
      },
    };
    let newgateConfig = { ...gateConfig, ...newgate };

    onSubmit(newgateConfig);
  }

  return (
    <Grid container direction="row" justify="flex-start">
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h4" component="h1">
          {texts.gateConfigTitle}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <FormControl variant="outlined">
          <InputLabel>{texts.currentGate}</InputLabel>
          <Select
            label={texts.currentGate}
            value={currentgateKey}
            onChange={(e) => setCurrentgateKey(e.target.value)}
          >
            {Object.keys(cleangateConfig).map((gateKey) => (
              <MenuItem key={gateKey} value={gateKey}>
                {cleangateConfig[gateKey].name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <FormControl variant="outlined">
          <InputLabel>{texts.defaultGate}</InputLabel>
          <Select
            label={texts.defaultgate}
            value={defaultgateKey}
            onChange={(e) => handleChangeDefaultgate(e.target.value)}
          >
            {Object.keys(cleangateConfig).map((gateKey) => (
              <MenuItem key={gateKey} value={gateKey}>
                {cleangateConfig[gateKey].name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Button
          // className={classes.addButton}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => addgate()}
          aria-label={texts.addGate}
        >
          {texts.addGate}
        </Button>
      </Grid>
      {cleangateConfig && cleangateConfig[currentgateKey] ? (
        <Gate
          gate={cleangateConfig[currentgateKey]}
          gateKey={currentgateKey}
          languageObject={languageObject}
          onSubmit={handleOnSubmit}
          onSubmitRemoveStep={handleOnSubmitRemoveStep}
        />
      ) : null}
    </Grid>
  );
}
