import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MoneyIcon from '@material-ui/icons/Money';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ComputerIcon from '@material-ui/icons/Computer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { FormControl, FormControlLabel, Switch, Typography, Icon } from '@material-ui/core';
import { getPaymentMethodIcon } from '../LocationDetailUtils';


const useStyles = makeStyles({
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  icon: {
    marginRight: 5
  }
});

export default function PaymentMethodSwitch(props) {

  const { onSubmit, paymentMethods, method, locationId } = props;
  const [active, setActive] = useState(paymentMethods[method].available);
  const classes = useStyles();

  function handleChange(event) {

    setActive((bool) => !bool)
    var chageObject = { payment : {
      paymentMethods :{
        [method]: {
          available : event.target.checked 
        }
      }
    }
  };

    
    onSubmit(chageObject)
  }
  return (
    <FormControl component="fieldset">
      <FormControlLabel
        value={method}
        control={<Switch checked={active} color="primary" />}
        label={
          <Typography className={classes.wrapIcon} color="primary">
            {getPaymentMethodIcon(method, {className: classes.icon})}
            {paymentMethods[method].name}
          </Typography>
        }
        labelPlacement="start"
        onChange={(event) => handleChange(event)}
      />
    </FormControl>
  )
}