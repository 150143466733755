import { combineReducers } from "redux";
import valet from "./valet-reducer";
import auth from "./auth-reducer";
import vehicle from "./vehicle-reducer";
import chat from "./chat-reducer";

export default combineReducers({
  valet,
  auth,
  vehicle,
  chat,
});
