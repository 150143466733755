import React from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import RateStep from "./RateStep";
import { makeStyles } from "@material-ui/core/styles";
import EditableField from "../../../../../EditableField";
import SwitchField from "../../../../../SwichField";
import SimpleDialogDemo from "../../../../../Modals/SimpleDialog";
import RateImportSteps from "./RateImportSteps";
import RateTestTime from "./RateTestTime";
import SetDefaultProducts from "./SetDefaultProducts";

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginTop: 0,
    marginBottom: 0,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    width: "100%",
  },
  currentRate: {
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 20,
    width: "100%",
    display: "flex",
    marginTop: 15,
    marginBottom: 15,
    padding: 15,
  },
}));

export default function Rate(props) {
  const { rate, rateKey, onSubmit, onSubmitRemoveStep, languageObject } = props;
  const { timeUnit, name, hide, rateIdCustom, valetRate, freeTime } = rate;
  console.warn(`name: ${name}, hide ${hide}, timeUnit ${timeUnit}`);
  const classes = useStyles();
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;

  function onSubmitStep(step) {
    let steps = { ...rate.steps, ...step };
    // console.log("onSubmitStep newSteps", steps);
    let newRate = { ...rate, steps };
    console.log("onSubmitStep newRateConfig", newRate);
    onSubmit({ [rateKey]: newRate });
  }

  function onRemoveStep(newRate) {
    onSubmitRemoveStep({ [rateKey]: newRate });
  }

  function addStep() {
    let previousStepNumber = Object.keys(rate.steps).length;
    let newStep = {
      [`step_${previousStepNumber + 1}`]: {
        price: 0,
        timeLessthan: rate.steps[`step_${previousStepNumber}` + 1] || 0,
      },
    };
    let steps = { ...rate.steps, ...newStep };
    let newRate = { ...rate, steps };
    // console.log("addStep newRate", newRate);

    onSubmit({ [rateKey]: newRate });
  }

  function onChangeTimeUnit(newUnit) {
    let timeUnit = { timeUnit: newUnit };
    // console.log("onChangeTimeUnit timeUnit", timeUnit);
    let newRate = { ...rate, ...timeUnit };
    console.log("onChangeTimeUnit newRate", newRate);
    onSubmit({ [rateKey]: newRate });
  }

  function onChangeName(newName) {
    let name = { name: newName.rateName };
    let newRate = { ...rate, ...name };
    console.log("onChangeName newRate", newRate);
    onSubmit({ [rateKey]: newRate });
  }
  function onChangeRateId(newrateIdCustom) {
    let rateIdCustom = { rateIdCustom: newrateIdCustom.rateIdCustom };
    let newRate = { ...rate, ...rateIdCustom };
    console.log("onChangeName newRate", newRate);
    onSubmit({ [rateKey]: newRate });
  }

  function onChangeValetRate(valetRate) {
    let name = { valetRate: valetRate.valetRate };
    let newRate = { ...rate, ...name };
    console.log("onChangeName newRate", newRate);
    onSubmit({ [rateKey]: newRate });
  }
  function onChangeFreeTime(freeTime) {
    let name = { freeTime: freeTime.freeTime };
    let newRate = { ...rate, ...name };
    console.log("onChangeName newRate", newRate);
    onSubmit({ [rateKey]: newRate });
  }

  function onChangeHide(hideObject) {
    let newRate = { ...rate, ...hideObject };
    console.warn("onChangeHide newRateConfig", { [rateKey]: newRate });
    onSubmit({ [rateKey]: newRate });
  }

  function sortSteps(steps) {
    return Object.keys(steps).sort((a, b) => {
      let numA = parseInt(a.split("_")[1]);
      let numB = parseInt(b.split("_")[1]);
      return numA - numB;
    });
  }

  const steps = rate.steps;
  const stepsKeys = sortSteps(steps);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.currentRate}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={3} md={3}>
              <EditableField
                fieldName="rateName"
                onSubmit={onChangeName}
                label={texts.rateName}
                errorMessage={texts.rateNameError}
                defaultValue={name}
                required={true}
                // textSize
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <EditableField
                fieldName="rateIdCustom"
                onSubmit={onChangeRateId}
                label={texts.rateIdCustom}
                errorMessage={texts.rateIdCustomError}
                defaultValue={rateIdCustom || ""}
                required={true}
                // textSize
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <form>
                <FormControl variant="outlined">
                  <InputLabel>{texts.timeUnit}</InputLabel>
                  <Select
                    label={texts.timeUnit}
                    value={timeUnit}
                    onChange={(e) => onChangeTimeUnit(e.target.value)}
                  >
                    <MenuItem value="minutes">{texts.minute}</MenuItem>
                    <MenuItem value="hour">{texts.hour}</MenuItem>
                  </Select>
                </FormControl>
              </form>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <EditableField
                fieldName="valetRate"
                onSubmit={onChangeValetRate}
                label={texts.valetRate}
                errorMessage={texts.valetRateError}
                defaultValue={valetRate || ""}
                required={true}
                // textSize
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <EditableField
                fieldName="freeTime"
                onSubmit={onChangeFreeTime}
                label={texts.freeTime}
                errorMessage={texts.freeTimeError}
                defaultValue={freeTime || 15}
                required={true}
                // textSize
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Button
                className={classes.addButton}
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => addStep()}
                aria-label={texts.addStep}
              >
                {texts.addStep}
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <SimpleDialogDemo buttonText={texts.importSteps}>
                <RateImportSteps
                  rate={rate}
                  rateKey={rateKey}
                  onSubmit={onSubmit}
                  languageObject={languageObject}
                />
              </SimpleDialogDemo>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <SwitchField
                onSubmit={onChangeHide}
                value={hide}
                atribute="hide"
                label={texts.hidden}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>

      {stepsKeys.map((stepKeyVar, i) => {
        const stepKey = stepKeyVar;
        if (steps[stepKey] == null) {
          return null;
        }
        return (
          <Grid item xs={12} sm={12} md={12}>
            <RateStep
              key={stepKey}
              languageObject={languageObject}
              price={steps[stepKey].price}
              timeLessthan={steps[stepKey].timeLessthan}
              index={i}
              onSubmitStep={onSubmitStep}
              onRemoveStep={onRemoveStep}
              stepKey={stepKey}
              rate={rate}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
