import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { updateUserProfile } from "../../../../data-store/actions/valet-actions";
import EditableField from "../../../EditableField";
import MuiAlert from "@material-ui/lab/Alert";
import ShiftCard from "./ShiftCard";
import { dateToHHMM, dateToYYYYMMDD } from "../Reports/reportsUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  root1: {},
  icon: {
    position: "absolute",
    right: 10,
  },
  select: {
    marginBottom: "15px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function EmployeePayRollCalendar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const texts = languageObject.authorizeUserdViews.users.payRoll;
  const onSubmit = (values) => {
    const success = true;
    const changeObject = {
      payRollConfig: {
        ...payRollConfig,
        ...values,
      },
    };
    console.log("update user with these changes...", changeObject);
  };

  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "Saturday",
  ];

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={10}
      >
        <Grid item xs={12}>
          Botones
        </Grid>
        {days.map((day) => (
          <Grid item xs={12}>
            {day}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
