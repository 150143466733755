import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getBusinessLocationsConfig,
  getBusinessUsers,
} from "../../../../../data-store/actions/valet-actions";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import Paper from "@material-ui/core/Paper";
import LocationDetailResumeCard from "./LocationDetailResumeCard";
import LocationDetailZonesCard from "./LocationZones/LocationDetailZonesCard";
import LocationDetailUsersCard from "./LocationUsers/LocationDetailUsersCard";
import LocationDetailPaymentCard from "./LocationPayment/LocationDetailPaymentCard";
import { useGetLocationVehiclesByShift } from "../LocationsUtils";
import LocationVehicleTable from "../LocationVehicleTable";
import LocationVehicleChart from "../LocationVehicleChart";
//import LocationVehicleTableSortable from "../LocationVehicleTableSortable";

export default function LocationDetailContainer(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const locationId = params.locationId;

  console.log(params);
  const businessId = useSelector((state) => state.valet.businessId);
  const vehiclesAll = useSelector((state) => state.vehicle.vehicles);
  const userID = useSelector((state) => state.auth.userID);
  const userAccount = useSelector((state) => state.auth.userAccount);
  const businessUsers = useSelector((state) => state.valet.businessUsers);

  const languageObject = useSelector((state) => state.auth.languageObject);
  const locationConfig = useSelector((state) =>
    state.valet.businessLocations != undefined &&
    state.valet.businessLocations[locationId] != undefined
      ? state.valet.businessLocations[locationId].locationConfig
      : undefined
  );
  const activeUsers = useSelector((state) => state.valet.activeUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const zonesData = useSelector((state) =>
    state.vehicle.zonesData != undefined
      ? state.vehicle.zonesData[locationId]
      : undefined
  );
  const [shiftDay, setShiftDay] = useState("today");
  const [vehicles, setVehicles] = useState(vehiclesAll);

  useEffect(() => {
    callbackDay(shiftDay);
  }, [businessId, userAccount, vehiclesAll]);

  if (!businessLocations) {
    return <CircularProgress color="secondary" />;
  }
  console.log(vehicles);

  function callbackDay(shiftDayVar) {
    setShiftDay(shiftDayVar);
    setVehicles(
      useGetLocationVehiclesByShift(
        locationId,
        vehiclesAll,
        shiftDayVar,
        locationConfig ? locationConfig.startShift : "00:00:00",
        locationConfig ? locationConfig.endShift : "23:59:59"
      )
    );
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <LocationDetailResumeCard
          languageObject={languageObject}
          locationConfig={locationConfig}
          zonesData={zonesData}
          activeUsers={activeUsers}
          vehicles={vehicles}
          locationId={locationId}
          callbackDay={callbackDay}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocationDetailPaymentCard
          languageObject={languageObject}
          locationConfig={locationConfig}
          zonesData={zonesData}
          activeUsers={activeUsers}
          vehicles={vehicles}
          locationId={locationId}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocationDetailUsersCard
          languageObject={languageObject}
          locationConfig={locationConfig}
          zonesData={zonesData}
          activeUsers={activeUsers}
          vehicles={vehicles}
          locationId={locationId}
        />
      </Grid>
      <Grid item xs={12}>
        <LocationDetailZonesCard
          languageObject={languageObject}
          locationConfig={locationConfig}
          zonesData={zonesData}
          activeUsers={activeUsers}
          vehicles={vehicles}
          locationId={locationId}
        />
      </Grid>
      <Grid item xs={12}>
        <LocationVehicleTable vehiclesData={vehicles} locationId={locationId} />
      </Grid>
    </Grid>
  );
}
