import * as types from "./action-types";
import {
  firestore,
  storage,
  users,
  business,
  businessUsers,
  locations,
  activeUsers,
} from "../../firebase/firebasejs";
import { Strings } from "../../Strings";
import store from "../configureStore";
import { getLocationsVehiclesRealTimeChanges } from "./vehicle-actions";
import axios from "axios";

import { getPhotoFileToUpload } from "../../services/utils";

//###################################################################################
export function setCurrentBusiness(businessId) {
  return (dispatch) => {
    getBusinessLocationsConfig(businessId);

    let businessRef = firestore.collection(business).doc(businessId);
    let getDoc = businessRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          dispatch({
            type: types.CURRENTBUSINESS,
            businessId: businessId,
            businessObject: null,
          });
        } else {
          dispatch({
            type: types.CURRENTBUSINESS,
            businessId: businessId,
            businessObject: doc.data(),
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };
}
//###################################################################################
export function addNewUserToBusiness(
  email,
  authorized,
  businessIdParam,
  businessNameParam,
  profile,
  addLocation
) {
  const businessId = businessIdParam || store.getState().valet.businessId;
  const businessName =
    businessNameParam ||
    store.getState().valet.businessObject.businessInfo.businessName;
  const userID = store.getState().auth.userID;

  return (dispatch) => {
    var params = {
      userID: userID,
      businessId: businessId,
      businessName: businessName,
      authorized: authorized,
      email: email,
      profile: profile || "valet",
      addLocation: addLocation,
    };
    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    axios
      .post(
        "https://us-central1-softicade.cloudfunctions.net/authorizeUser",
        params,
        headers
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: types.ADD_USER_TO_BUSINESS,
          addUserBusiness: businessId,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
//###################################################################################
export function updateUserProfile(userIDToChange, changeObject) {
  const businessId = store.getState().valet.businessId;
  const businessName = store.getState().valet.businessObject.businessInfo
    .businessName;
  const userID = store.getState().auth.userID;

  return (dispatch) => {
    firestore
      .collection(business)
      .doc(businessId)
      .collection(businessUsers)
      .doc(userIDToChange)
      .set(changeObject, { merge: true })
      .then(() => {
        console.log("Perfil actualizado en el negocio!");

        return firestore
          .collection(users)
          .doc(userIDToChange)
          .set(changeObject, { merge: true });
      })
      .then(() => {
        console.log("Perfil actualizado en los datos del usuario!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###################################################################################
export function updateLocationAuth(userIDToChange, locationObject) {
  const businessId = store.getState().valet.businessId;

  var changeObjectUser = {
    businessAuthorized: {
      [businessId]: {
        locationsAuth: locationObject,
      },
    },
  };
  var changeObjectBusiness = {
    locationsAuth: locationObject,
  };

  return (dispatch) => {
    firestore
      .collection(business)
      .doc(businessId)
      .collection(businessUsers)
      .doc(userIDToChange)
      .set(changeObjectBusiness, { merge: true })
      .then(() => {
        console.log("Perfil actualizado en el negocio!");

        return firestore
          .collection(users)
          .doc(userIDToChange)
          .set(changeObjectUser, { merge: true });
      })
      .then(() => {
        console.log("Perfil actualizado en los datos del usuario!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###################################################################################
export function getBusinessUsers() {
  console.log("get business Users");
  return (dispatch) => {
    let businessId;
    if (store.getState().valet.businessId != undefined) {
      console.log(store.getState().valet.businessId);
      businessId = store.getState().valet.businessId;

      var Ref = firestore
        .collection(business)
        .doc(businessId)
        .collection(businessUsers);
      return Ref.onSnapshot((querySnapshot) => {
        if (querySnapshot == undefined || querySnapshot == null) {
          return;
        }
        var usersData = {};
        querySnapshot.forEach(function (doc) {
          let user = doc.data();
          user.id = doc.id;
          usersData[doc.id] = user;
        });
        dispatch({
          type: types.BUSINESSUSERSLIST,
          businessUsers: usersData,
        });
      });
    }
  };
}
//###################################################################################
//###################################################################################
export function getBusinessLocationsConfig() {
  console.log("getBusinessLocationsConfig");

  let businessId;
  if (store.getState().valet.businessId != undefined) {
    console.log(store.getState().valet.businessId);
    businessId = store.getState().valet.businessId || null;
    const userAccount = store.getState().auth.userAccount;
    const locationsAuth =
      userAccount !== undefined &&
      userAccount.businessAuthorized &&
      userAccount.businessAuthorized[businessId] &&
      userAccount.businessAuthorized[businessId].locationsAuth
        ? userAccount.businessAuthorized[businessId].locationsAuth
        : null;
    console.log(businessId);
    console.log("USUARIOAAA", business, businessId, locations);
    var Ref = firestore
      .collection(business)
      .doc(businessId)
      .collection(locations);
    return (dispatch) => {
      return Ref.onSnapshot((querySnapshot) => {
        if (querySnapshot == undefined || querySnapshot == null) {
          return;
        }
        var locations = {};
        querySnapshot.forEach(function (doc) {
          locations[doc.id] = doc.data();
          if (
            userAccount &&
            locationsAuth &&
            locationsAuth[doc.id] !== undefined &&
            locationsAuth[doc.id].authorized
          ) {
            locations[doc.id].authorized = true;
            if (store.getState().vehicle.vehicles == undefined) {
              dispatch(getLocationsVehiclesRealTimeChanges(businessId, doc.id));
              dispatch(getLocationActiveUserChanges(businessId, doc.id));
            }
          }
        });
        dispatch({
          type: types.BUSINESSLOCATIONS,
          businessLocations: locations,
          businessLocationsReady: true,
        });
      });
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: types.BUSINESSLOCATIONS,
        businessLocations: null,
        businessLocationsReady: false,
      });
    };
  }
}
//###################################################################################
export function getLocationActiveUserChanges(businessId, locationId) {
  var Ref = firestore
    .collection(business)
    .doc(businessId)
    .collection(locations)
    .doc(locationId)
    .collection(activeUsers);
  return (dispatch) => {
    return Ref.onSnapshot(function (snapshot) {
      if (snapshot == undefined) {
        return;
      }
      snapshot.docChanges().forEach(function (change) {
        if (change == undefined) {
          return;
        }
        if (change.type === "added") {
          console.log("user added: ", change.doc.data());
          let userStatus = { ...change.doc.data(), locationId: locationId };
          dispatch({
            type: types.ACTIVEUSERSDATA_ADD,
            activeUser: userStatus,
            activeUserId: change.doc.id,
          });
        }
        if (change.type === "modified") {
          console.log("Modified user: ", change.doc.data());
          let userStatus = { ...change.doc.data(), locationId: locationId };
          dispatch({
            type: types.ACTIVEUSERSDATA_CHANGE,
            activeUser: userStatus,
            activeUserId: change.doc.id,
          });
        }
        if (change.type === "removed") {
          console.log("Removed user: ", change.doc.data());
          let userStatus = { ...change.doc.data(), locationId: locationId };
          dispatch({
            type: types.ACTIVEUSERSDATA_REMOVE,
            activeUser: userStatus,
            activeUserId: change.doc.id,
          });
        }
      });
    });
  };
}
//###################################################################################
export function updateLocationAtribute(locationId, changeObject) {
  const businessId = store.getState().valet.businessId;
  const businessName = store.getState().valet.businessObject.businessInfo
    .businessName;
  const userID = store.getState().auth.userID;

  var locationConfig = {
    locationConfig: changeObject,
  };

  return (dispatch) => {
    firestore
      .collection(business)
      .doc(businessId)
      .collection(locations)
      .doc(locationId)
      .set(locationConfig, { merge: true })
      .then(() => {
        console.log("Location Ajustada!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###################################################################################
export function createLocation(locationConfig) {
  const businessId = store.getState().valet.businessId;
  const businessLocations = store.getState().valet.businessLocations;
  const businessName = store.getState().valet.businessObject.businessInfo
    .businessName;
  const userID = store.getState().auth.userID;

  let locationId = "";
  if (businessLocations == null || businessLocations == undefined) {
    locationId = "locationId_1";
  } else {
    locationId = "locationId_" + (Object.keys(businessLocations).length + 1);
  }

  return (dispatch) => {
    firestore
      .collection(business)
      .doc(businessId)
      .collection(locations)
      .doc(locationId)
      .set(locationConfig)
      .then(() => {
        console.log("Location Ajustada!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###################################################################################
export function updateLocationAtributeReplace(locationID, changeObject) {
  const businessId = store.getState().valet.businessId;
  const businessName = store.getState().valet.businessObject.businessInfo
    .businessName;
  const userID = store.getState().auth.userID;

  const locationConfig = {
    locationConfig: changeObject,
  };
  return (dispatch) => {
    const ref = firestore
      .collection(business)
      .doc(businessId)
      .collection(locations)
      .doc(locationID)
      .update(locationConfig)
      .then(() => {
        console.log("Location Ajustada!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###################################################################################
//###########################################################################
export function uploadLocationPhoto(locationId, businessId, file, photoType) {
  return (dispatch) => {
    console.log("uploadPhoto");
    return getPhotoFileToUpload(file, 800, photoType).then((image) => {
      if (image) {
        let fotoSinJPG = file.name.substring(0, file.name.length - 4);
        let nombreRandom =
          fotoSinJPG + "_" + Math.floor(Math.random() * 100000 + 1) + ".JPG";

        let storageRef = storage.ref();
        let uploadTask = storageRef
          .child(
            "Business/" +
              businessId +
              "/businessPhotos/" +
              locationId +
              "/" +
              nombreRandom
          )
          .put(image);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: file.name,
              uploadingPhotosProgress: progress,
            });

            console.log("Upload is " + progress + "% done");
          },
          function (error) {
            // Handle unsuccessful uploads
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: null,
              uploadingPhotosProgress: null,
            });
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                dispatch({
                  type: types.UPLOADING_PHOTOS,
                  uploadingPhotos: null,
                  uploadingPhotosProgress: null,
                });
                dispatch(
                  updateLocationAtribute(locationId, {
                    [photoType]: downloadURL,
                  })
                );
                console.log("File available at", downloadURL);
              });
          }
        );
      }
    });
  };
}
//###########################################################################
export function uploadUserPhoto(userId, businessId, file, photoType) {
  return (dispatch) => {
    console.log("uploadPhoto");
    return getPhotoFileToUpload(file, 800, photoType).then((image) => {
      if (image) {
        let fotoSinJPG = file.name.substring(0, file.name.length - 4);
        let nombreRandom =
          fotoSinJPG + "_" + Math.floor(Math.random() * 100000 + 1) + ".JPG";

        let storageRef = storage.ref();
        let uploadTask = storageRef
          .child(
            "Business/" +
              businessId +
              "/businessPhotos/" +
              userId +
              "/" +
              nombreRandom
          )
          .put(image);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: file.name,
              uploadingPhotosProgress: progress,
            });

            console.log("Upload is " + progress + "% done");
          },
          function (error) {
            // Handle unsuccessful uploads
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: null,
              uploadingPhotosProgress: null,
            });
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                dispatch({
                  type: types.UPLOADING_PHOTOS,
                  uploadingPhotos: null,
                  uploadingPhotosProgress: null,
                });
                dispatch(
                  updateUserProfile(userId, {
                    [photoType]: downloadURL,
                  })
                );
                console.log("File available at", downloadURL);
              });
          }
        );
      }
    });
  };
}
//###################################################################################
export function createBusiness(userID, objectChage) {
  return (dispatch) => {
    return firestore
      .collection(business)
      .add(objectChage)
      .then(function (docRef) {
        console.log("Negocio creado!");
        // crear primer location
        let locationId = "locationId_1";

        const blankLocation = {
          locationConfig: {
            locationPhoto: false,
            adress: "",
            lat: 0,
            name: "Location 1",
            startShift: "00:00:00",
            endShift: "23:59:59",
            receipts: {
              card: false,
              print: false,
              printConfig: {
                business: "",
                web: "",
              },
            },
            payment: {
              rateConfig: {
                rate_1: {
                  steps: {
                    step_1: {
                      price: 0,
                      timeLessthan: 1,
                      timeUnit: "hour",
                    },
                  },
                  timeUnit: "hour",
                  hide: false,
                  name: "step 1",
                  rateID: "rate_1",
                  isDefault: true,
                },
              },
              paymentMethods: {
                pos: {
                  name: "Terminal punto de venta",
                  available: false,
                },
                onlinePayment: {
                  available: false,
                  name: "Transacción en linea",
                },
                cash: {
                  name: "Efectivo",
                  available: false,
                },
              },
            },
            lng: 0,
            description: "Location 1",
            zones: {
              zone_1: {
                placesNamed: false,
                zoneName: "",
                overPlaces: "",
                zonePlacesNumber: 1,
                priority: 1,
                zonePlaces: {
                  place_1: {
                    name: "A1",
                    priority: 1,
                  },
                },
              },
            },
          },
        };

        return firestore
          .collection(business)
          .doc(docRef.id)
          .collection(locations)
          .doc(locationId)
          .set(blankLocation)
          .then(() => {
            console.log("Location 1 Creada!");
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  };
}
//###########################################################################

export function updateBusinessAtribute(businessID, objectChage) {
  return (dispatch) => {
    return firestore
      .collection(business)
      .doc(businessID)
      .set(objectChage, { merge: true })
      .then(() => {
        console.log("Dato del Negocio actualizado!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###########################################################################
export function getBusinessList() {
  return (dispatch) => {
    console.log("getbusiness data");
    return firestore.collection(business).onSnapshot(
      function (querySnapshot) {
        var businessListARRAY = [];
        querySnapshot.forEach(function (doc) {
          const business = { ...doc.data(), businessID: doc.id };
          businessListARRAY.push(business);
        });
        dispatch({
          type: types.GLOBAL_BUSINESS_LIST,
          businessList: businessListARRAY,
        });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };
}

//###################################################################################

function getDataFromFirestore() {
  var docRef = firestore.collection("business").doc("idbusiness_2");

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log("Document data:", doc.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}

function getDataFromFirestoreCollection() {
  var docRef = firestore
    .collection("business")
    .doc("idbusiness_2")
    .collection("userList");

  docRef
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
      });
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}

function uploadData() {
  if (false) {
    var obj = {
      aa: "aa",
      bb: "bb",
    };
    for (var i in userList) {
      firestore
        .collection("business")
        .doc("idbusiness_1")
        .collection("businessUsers")
        .doc(i)
        .set(userList[i], { merge: true })
        .then(function (docRef) {
          console.log("Document written");
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }

    firestore
      .collection("business")
      .doc("idbusiness_1")
      .collection("businessUsers")
      .doc("location_1")
      .set(obj, { merge: true })
      .then(function (docRef) {
        console.log("Document written");
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    // .collection("locations")
    // .doc("location_1")
  }
}

var userList = {
  ZJw4A6Q8EebKA1Z3RDdhK2INpw73: {
    authorized: true,
    fechaRegistro: "2020-01-01 00:00:00",
    lastname: "Madrid",
    mail: "eduardomadrid84@gmail.com",
    name: "Eduardo",
    phoneNumber: "99999999",
    profile: "master",
  },
  gmyHuOpELHa6PXNLW2UncWAFEPQ2: {
    authorized: true,
    fechaRegistro: "2020-01-01 00:00:00",
    lastname: "Smart Valet",
    mail: "demo@smartvalet.app",
    name: "Demo",
    phoneNumber: "99999999",
    profile: "master",
  },
};
