import React from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import EditableField from "../../../../../EditableField";
import SwitchField from "../../../../../SwichField";

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    width: "100%",
  },
  currentgate: {
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 20,
    width: "100%",
    display: "flex",
    marginTop: 15,
    marginBottom: 15,
    padding: 15,
  },
}));

export default function Gate(props) {
  const { gate, gateKey, onSubmit, onSubmitRemoveStep, languageObject } = props;
  const { timeUnit, name, hide } = gate;
  console.warn(`name: ${name}, hide ${hide}, timeUnit ${timeUnit}`);
  const classes = useStyles();
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;

  function onChangeName(newName) {
    let name = { name: newName.gateName };
    let newgate = { ...gate, ...name };
    console.log("onChangeName newgate", newgate);
    onSubmit({ [gateKey]: newgate });
  }

  function onChangeHide(hideObject) {
    let newgate = { ...gate, ...hideObject };
    console.warn("onChangeHide newgateConfig", { [gateKey]: newgate });
    onSubmit({ [gateKey]: newgate });
  }

  return (
    <Grid container>
      <div className={classes.currentgate}>
        <Grid item xs={12} sm={12} md={4}>
          <EditableField
            fieldName="gateName"
            onSubmit={onChangeName}
            label={texts.gateName}
            errorMessage={texts.gateNameError}
            defaultValue={name}
            required={true}
            // textSize
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <SwitchField
            onSubmit={onChangeHide}
            value={hide}
            atribute="hide"
            label={texts.hidden}
          />
        </Grid>
      </div>
    </Grid>
  );
}
