import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getBusinessLocationsConfig } from "../../../../data-store/actions/valet-actions";
import UserTable from "./UserTable";
import AuthorizeUserDetail from "./AuthorizeUserDetail";

const AutorizeUsersListView = ({
  businessId,
  languageObject,
  businessUsers,
  businessLocations,
  businessLocationsFunc,
  short,
  locationUsers,
}) => {
  useEffect(() => {
    if (businessLocations == null) {
      // console.log('running busLocFunc', businessLocations)
      //businessLocationsFunc();
    } else {
      // console.log('got some locations', businessLocations)
    }
  });

  // console.log('businessUsers-', businessUsers);
  // console.log('businessLocations-', businessLocations);
  // console.log('businessId-', businessId);

  let userListArray = [];
  if (locationUsers) {
    // console.log('locationUsers-', locationUsers);
    for (var i in locationUsers) {
      locationUsers[i].userId = i;
      userListArray.push(locationUsers[i]);
    }
  } else {
    for (var i in businessUsers) {
      businessUsers[i].userId = i;
      userListArray.push(businessUsers[i]);
    }
  }
  // console.log('AuthroizeUserList - userListArray', userListArray);

  return (
    <UserTable
      report={AuthorizeUserDetail}
      languageObject={languageObject}
      short={short}
      businessLocations={businessLocations}
      userListArray={userListArray}
      businessUsers={businessUsers}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    languageObject: state.auth.languageObject,
    businessId: state.valet.businessId,
    businessUsers: state.valet.businessUsers,
    businessLocations: state.valet.businessLocations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    businessLocationsFunc: () => {
      dispatch(getBusinessLocationsConfig());
    },
  };
};

const AutorizeUsersList = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutorizeUsersListView);

export default AutorizeUsersList;
