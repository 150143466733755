import React from "react";
import {
  helperMultisort,
  timestampToTimeDay,
} from "../../../../services/utils";

export function useTotalAmount(rateConfig, vehicle) {
  console.log(rateConfig);

  var startTime = vehicle.startTime
    ? vehicle.startTime.seconds
    : Math.round(Date.now() / 1000);
  var finalTime = getTimeByUnitAndStartTime(
    rateConfig[vehicle.rate].timeUnit,
    startTime,
    vehicle
  );
  console.log("finalTime");
  console.log(finalTime);
  const steps = helperMultisort(
    Object.values(rateConfig[vehicle.rate].steps).filter((x) => x),
    ["timeLessthan"],
    ["DESC"]
  );
  var charge = 0;
  for (var i in steps) {
    console.log("steps[i]");
    console.log(steps[i]);
    if (steps[i].timeLessthan >= finalTime.finalTime) {
      console.log(steps[i].timeLessthan);
      console.log(finalTime.finalTime);
      charge = steps[i].price;
    } else {
      //      break;
    }
  }
  var pendingPay = getPendingPay(charge, vehicle);

  return { charge: charge, pendingPay: pendingPay };
}
export function useTotalAmountWidhValidations(rateConfig, vehicle) {
  console.log(rateConfig);

  var startTime = vehicle.startTime
    ? vehicle.startTime.seconds
    : Math.round(Date.now() / 1000);
  var finalTime = getTimeByUnitAndStartTime(
    rateConfig[vehicle.rate].timeUnit,
    startTime,
    vehicle
  );
  console.log("finalTime");
  console.log(finalTime);
  const steps = helperMultisort(
    Object.values(rateConfig[vehicle.rate].steps).filter((x) => x),
    ["timeLessthan"],
    ["DESC"]
  );
  var charge = 0;
  for (var i in steps) {
    console.log("steps[i]");
    console.log(steps[i]);
    if (steps[i].timeLessthan >= finalTime.finalTime) {
      console.log(steps[i].timeLessthan);
      console.log(finalTime.finalTime);
      charge = steps[i].price;
    } else {
      //      break;
    }
  }
  var pendingPay = getPendingPayWithValidations(charge, vehicle);
  var payByType = getPaymentsVehicleByType(vehicle);

  return {
    charge: Math.round(charge * 100) / 100,
    pendingPay: Math.round(pendingPay * 100) / 100,
    payByType: payByType,
    finalDate: finalTime.finalDate,
  };
}
export function useTotalAmountWidhValidationsAndDates(
  rateConfig,
  vehicle,
  startDate,
  endDate
) {
  console.log(rateConfig);

  var startTime = vehicle.startTime
    ? vehicle.startTime.seconds
    : Math.round(Date.now() / 1000);
  var finalTime = getTimeByUnitAndStartTime(
    rateConfig[vehicle.rate].timeUnit,
    startTime,
    vehicle
  );
  console.log("finalTime");
  console.log(finalTime);
  const steps = helperMultisort(
    Object.values(rateConfig[vehicle.rate].steps).filter((x) => x),
    ["timeLessthan"],
    ["DESC"]
  );
  var charge = 0;
  for (var i in steps) {
    //console.log("steps[i]");
    //console.log(steps[i]);
    if (steps[i].timeLessthan >= finalTime.finalTime) {
      //console.log(steps[i].timeLessthan);
      //console.log(finalTime.finalTime);
      charge = steps[i].price;
    } else {
      //      break;
    }
  }

  var pendingPay = getPendingPayWithValidations(charge, vehicle);
  var payByType = getPaymentsVehicleByType(vehicle, startDate, endDate);

  return {
    charge: Math.round(charge * 100) / 100,
    pendingPay: Math.round(pendingPay * 100) / 100,
    payByType: payByType,
    finalDate: finalTime.finalDate,
  };
}
//####################################################################
export function useTotalAmountTest(rateConfig, rateKey, Segundos) {
  console.log(rateConfig);

  var finalTime = 0;

  console.log(Segundos);

  if (rateConfig.timeUnit == "minutes") {
    finalTime = Math.floor(Segundos / 60);
  } else {
    finalTime = Math.floor((Segundos / 3600) * 100) / 100;
  }

  console.log("finalTime", rateConfig.timeUnit);

  console.log("finalTime", finalTime);
  const steps = helperMultisort(
    Object.values(rateConfig.steps).filter((x) => x),
    ["timeLessthan"],
    ["DESC"]
  );
  var charge = 0;
  var stepFinal = 0;
  for (var i in steps) {
    console.log("steps[i]");
    console.log(steps[i]);
    if (steps[i].timeLessthan >= finalTime.finalTime) {
      console.log(steps[i].timeLessthan);
      console.log(finalTime.finalTime);
      charge = steps[i].price;
      stepFinal = i;
    } else {
      //      break;
    }
  }

  return {
    charge: charge,
    step: stepFinal,
  };
}
//####################################################################
function getPendingPayWithValidations(charge, vehicle) {
  var pendingPay = charge;
  if (vehicle.payment.list != undefined) {
    var list = vehicle.payment.list;
    for (var i in list) {
      if (list[i].deleted == undefined || !list[i].deleted) {
        if (list[i].validation !== undefined) {
          list[i].amount = applyValidation(charge, list[i]);
        }
        if (parseFloat(pendingPay) + parseFloat(list[i].amount) >= 0) {
          pendingPay = parseFloat(pendingPay) + parseFloat(list[i].amount);
        } else {
          pendingPay = 0;
        }
      }
    }
  }
  return pendingPay;
}

function applyValidation(charge, row) {
  if (row.validation === "percent") {
    return (parseFloat(row.validationValue) / 100) * -charge;
  } else if (row.validation === "amount") {
    return -parseFloat(row.validationValue);
  }
  return 0;
}

function getPendingPay(charge, vehicle) {
  var pendingPay = charge;
  if (vehicle.payment.list != undefined) {
    var list = vehicle.payment.list;
    for (var i in list) {
      if (list[i].deleted == undefined || !list[i].deleted) {
        pendingPay = parseFloat(pendingPay) + parseFloat(list[i].amount);
      }
    }
  }
  return pendingPay;
}
function getTimeByUnitAndStartTime(unit, startTime, vehicle) {
  const getCloseTime = Object.values(vehicle.changes).filter(
    (change) => change.atribute == "closed" && change.newValue == true
  );
  console.log("getCloseTime", getCloseTime);
  console.log("getCloseTime", vehicle.brand);

  var Segundos = Date.now() / 1000 - startTime;
  console.log(Segundos);
  if (getCloseTime.length > 0 && vehicle.closed) {
    Segundos = getCloseTime[getCloseTime.length - 1].date - startTime;
  }

  if (unit == "minutes") {
    return {
      finalTime: Math.floor(Segundos / 60),
      finalDate:
        getCloseTime.length > 0 && vehicle.closed
          ? getCloseTime[getCloseTime.length - 1].date
          : Date.now() / 1000,
    };
  } else {
    return {
      finalTime: Math.floor((Segundos / 3600) * 100) / 100,
      finalDate:
        getCloseTime.length > 0 && vehicle.closed
          ? getCloseTime[getCloseTime.length - 1].date
          : Date.now() / 1000,
    };
  }
}

export function getPaymentTotalsByType(vehicles, status) {
  let cash = 0;
  let onlinePayment = 0;
  let pos = 0;
  let auth = 0;
  let total = 0;
  for (var vehicle in vehicles) {
    if (vehicles[vehicle].payment) {
      for (var payment in vehicles[vehicle].payment.list) {
        let method = vehicles[vehicle].payment.list[payment].paymentMethod;
        if (!vehicles[vehicle].payment.list[payment].deleted) {
          if (
            status == 1 ||
            (status == 2 && vehicles[vehicle].closed) ||
            (status == 3 && !vehicles[vehicle].closed)
          ) {
            if (method === "cash") {
              cash += parseFloat(
                vehicles[vehicle].payment.list[payment].amount
              );
            } else if (method === "onlinePayment") {
              onlinePayment += parseFloat(
                vehicles[vehicle].payment.list[payment].amount
              );
            } else if (method === "pos") {
              pos += parseFloat(vehicles[vehicle].payment.list[payment].amount);
            } else if (method === "auth") {
              auth += parseFloat(
                vehicles[vehicle].payment.list[payment].amount
              );
            }
            total += parseFloat(vehicles[vehicle].payment.list[payment].amount);
          }
        }
      }
    }
  }
  return {
    cashTotal: Math.round(cash * 100) / 100,
    onlinePaymentTotal: Math.round(onlinePayment * 100) / 100,
    posTotal: Math.round(pos * 100) / 100,
    authTotal: Math.round(auth * 100) / 100,
    total: Math.round(total * 100) / 100,
  };
}

export function getPendingPayTotal(vehicles, rateConfig) {
  let total = 0;
  for (var vehicle in vehicles) {
    total += useTotalAmount(rateConfig, vehicles[vehicle]).charge;
  }
  return total;
}

export function getPaymentsByType(vehicles) {
  let cash = {};
  let onlinePayment = {};
  let pos = {};
  let auth = {};
  for (var vehicle in vehicles) {
    if (vehicles[vehicle].payment) {
      for (var payment in vehicles[vehicle].payment.list) {
        let method = vehicles[vehicle].payment.list[payment].paymentMethod;
        if (method === "cash") {
          cash[vehicle] = vehicles[vehicle];
        } else if (method === "onlinePayment") {
          onlinePayment[vehicle] = vehicles[vehicle];
        } else if (method === "pos") {
          pos[vehicle] = vehicles[vehicle];
        } else if (method === "auth") {
          auth[vehicle] = vehicles[vehicle];
        }
      }
    }
  }
  let result = {
    cash: cash,
    onlinePayment: onlinePayment,
    pos: pos,
    auth: auth,
  };
  return result;
}
export function getPaymentsVehicleByType(
  vehicle,
  startDate = 0,
  endDate = 99999999999999
) {
  let result = {
    cash: 0,
    onlinePayment: 0,
    onlineType: "",
    PagueloFacil: 0,
    Yappy: 0,
    pos: 0,
    auth: 0,
  };
  if (vehicle.payment) {
    for (var payment in vehicle.payment.list) {
      let method = vehicle.payment.list[payment].paymentMethod;
      let onlineType = vehicle.payment.list[payment].onlineType;
      const date = vehicle.payment.list[payment].date;
      if (result[method] === undefined) {
        result[method] = 0;
      }
      if (
        !vehicle.payment.list[payment].deleted &&
        date >= startDate &&
        date <= endDate
      ) {
        result[method] =
          result[method] +
          Math.round(vehicle.payment.list[payment].amount * 100) / 100;
        if (onlineType) {
          result["onlineType"] = result["onlineType"] + " " + onlineType;
          result[onlineType] =
            result[onlineType] +
            Math.round(vehicle.payment.list[payment].amount * 100) / 100;
        }
      }
    }
  }

  return result;
}

export function getAllPayments(vehicles) {
  let allPayments = {};
  for (var vehicle in vehicles) {
    if (vehicles[vehicle].payment) {
      for (var payment in vehicles[vehicle].payment.list) {
        allPayments[payment] = {
          ...vehicles[vehicle].payment.list[payment],
          vehicleId: vehicle,
        };
      }
    }
  }
  console.log("allPayments -", allPayments);
  return allPayments;
}

function getPaymentIcon(method) {
  switch (method) {
    case "cash":
      return cashWhite;
    case "pos":
      return posWhite;
    case "onlinePayment":
      return cardWhite;
    case "auth":
      return authWhite;
    default:
      break;
  }
}

export function usePaymentList(vehicle) {}
