import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
  Icon,
} from "@material-ui/core";

const useStyles = makeStyles({
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  icon: {
    marginRight: 5,
  },
});

export default function SwitchField(props) {
  const { onSubmit, value, atribute, locationId, label } = props;
  // console.warn('switch has value of', value);
  const [active, setActive] = useState(value === true ? true : false);
  const classes = useStyles();

  useEffect(() => setActive(value), [value]);

  function handleChange(event) {
    var changeObject = {
      [atribute]: !active,
    };
    setActive(!active);
    onSubmit(changeObject);
  }
  return (
    <FormControl component="fieldset">
      <FormControlLabel
        value={atribute}
        control={<Switch checked={active} color="primary" />}
        label={
          <Typography className={classes.wrapIcon} color="primary">
            {label}
          </Typography>
        }
        labelPlacement="start"
        onChange={(event) => handleChange(event)}
      />
    </FormControl>
  );
}
