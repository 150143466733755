import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";

const store = createStore(
  rootReducer,
  {
    vehicle: {
      currentVehicle: null,
    },
    vehiclesReport: {},
    auth: {
      smartValetProfile: null,
      userAccount: undefined,
    },
    valet: {
      businessId: false,
      businessList: false,
      businessUsers: false,
      businessLocations: false,
      businessLocationsReady: false,
    },
    chat: {
      chatMessages: false,
    },
  },
  applyMiddleware(thunk, logger)
);

export default store;
