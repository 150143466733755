import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

export default function Home() {
  const languageObject = useSelector((state) => state.auth.languageObject);
  const texts = languageObject.homeView;
  return (
    <div style={{ textAlign: "center", paddingBottom: "150px" }}>
      <div style={{ marging: "auto" }}>
        <Typography variant={"h1"} component="h2">
          {texts.title}
        </Typography>
        <Typography variant={"h5"} component="h2">
          {texts.text}
        </Typography>
      </div>
    </div>
  );
}
