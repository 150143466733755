import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { CardHeader } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: 345,
    height: 345,
    margin: 10,
  },
  media: {
    height: 140,
  },
  content: {
    height: 160,
  },
  contentFlex: {
    flex: 1,
  },
});

export default function ReportCard(props) {
  const { languageObject, title, image, description } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationCard;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title={title} subheader={description}></CardHeader>
      <CardContent className={classes.contentFlex} className={classes.content}>
        {image}
      </CardContent>
    </Card>
  );
}
