import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { updateLocationAuth } from "../../../../data-store/actions/valet-actions";
import FormRender from "../../../Fields/FormRender";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function ValidationsUserPermision(props) {
  const { businessUser, userIDToChange, locationId } = props;
  console.log(locationId);
  console.log(businessUser);
  const dispatch = useDispatch();
  const classes = useStyles();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const userID = useSelector((state) => state.auth.userID);

  useEffect(() => {}, [businessId]);

  const onSubmit = (values) => {
    const success = true;
    const changeObject = values;
    console.log({ ...values, validationValue: values.validationValue / 1 });
    console.log(businessUser);
    var locationObject = {
      [locationId]: { ...values, validationValue: values.validationValue / 1 },
    };
    dispatch(updateLocationAuth(userIDToChange, locationObject));
  };

  // [
  //   {
  //     value: "notAllowed",
  //     label: texts.no,
  //   },
  //   {
  //     value: "percent",
  //     label: texts.percent,
  //   },
  //   {
  //     value: "amount",
  //     label: texts.amount,
  //   },
  //   {
  //     value: "timeBased",
  //     label: texts.timeBased,
  //   },
  // ]

  const texts =
    languageObject.authorizeUserdViews.ticketsValidation.usersValidation;
  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid className={classes.spacing} item xs={12} sm={12}>
        <div
          style={{
            width: 500,
            padding: "30px",
            margin: "30",
          }}
        >
          <Typography
            className={classes.text}
            gutterBottom
            variant={"h3"}
            component="h2"
          >
            {businessUser.name} {businessUser.lastname}
          </Typography>
          <Typography
            className={classes.text}
            gutterBottom
            variant={"h5"}
            component="h2"
          >
            {businessUser &&
              businessUser.locationsAuth[locationId].locationName}
          </Typography>

          {businessUser.locationsAuth[locationId].authorized && (
            <FormRender
              callback={onSubmit}
              formObject={{
                type: {
                  label: texts.labelSelect,
                  errorMessage: "",
                  fieldName: "validation",
                  type: "select",
                  defaultValue:
                    (businessUser &&
                      businessUser.locationsAuth[locationId].validation) ||
                    "No definido",
                  optionsArray: [
                    {
                      value: "notAllowed",
                      label: texts.no,
                    },
                    {
                      value: "percent",
                      label: texts.percent,
                    },
                    {
                      value: "amount",
                      label: texts.amount,
                    },
                  ],
                  regex: "",
                  textSize: "h6",
                  required: true,
                },
                value: {
                  label: texts.value,
                  errorMessage: "",
                  fieldName: "validationValue",
                  type: "number",
                  defaultValue:
                    (businessUser &&
                      businessUser.locationsAuth[locationId].validationValue) ||
                    "",
                  required: true,
                  regex: "",
                  textSize: "h6",
                },
              }}
              submitButtonText={"Guardar"}
            />
          )}

          {!businessUser.locationsAuth[locationId].authorized && (
            <Typography
              className={classes.text}
              gutterBottom
              variant={"h3"}
              component="h2"
            >
              {texts.notAuthorizedInLocation}
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
