import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { compareStrings } from "./Strings";

import "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import "./App.css";
import Root from "./Root";
//import Router from './components/navigation/Router'

import { BrowserRouter } from "react-router-dom";

import Footer from "./components/footer/";

import AppBarContainer from "./components/navigation-ui/AppBarContainer";

import { userAccount, setLanguage } from "./data-store/actions/auth-actions";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins-Regular,Helvetica, Arial, sans-serif",
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 700,
    fontWeightMedium: 500,
    color: "#000000",
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1d2242",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#36A9E1",
      main: "#36A9E1",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#000000",
    },
    // error: will use the default color
  },
  spacing: 4,
});

const App = ({ store }) => {
  store.dispatch(userAccount());

  var localLang = localStorage.getItem("language");
  console.log(localLang);
  console.log(localStorage);
  if (localLang == null) {
    store.dispatch(setLanguage("es"));
  } else {
    store.dispatch(setLanguage(localStorage.getItem("language")));
  }

  const element = (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AppBarContainer store={store}>
            <Container
              maxWidth="md"
              style={{ marginTop: "150px", marginBottom: "50px" }}
            >
              <Root />
            </Container>
            <Footer />
          </AppBarContainer>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );

  return element;
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
