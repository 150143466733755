import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  IconButton,
  Card,
  Grid,
  Paper,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Snackbar,
  Icon,
  FormLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import PaymentMethodSwitch from "./PaymentMethodSwitch";
import EditableField from "../../../../../EditableField";
import {
  updateLocationAtribute,
  updateLocationAtributeReplace,
} from "../../../../../../data-store/actions/valet-actions";
import RateStep from "./RateStep";
import RateConfigContainer from "./RateConfigContainer";
import ShopItems from "./ShopItems";
import AdditionalItems from "./AdditionalItems";
import FormRender from "../../../../../Fields/FormRender";
import { makeidKeyCard } from "../../../../../../services/utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { CSVDownload } from "react-csv";
import SimpleDialogDemo from "../../../../../Modals/SimpleDialog";
import EditReceiptToPrintItem from "./EditReceiptToPrintItem";
import PreviewReceipt from "./PreviewReceipt";
import GateConfigContainer from "./GateConfigContainer";
import { WhatsApp } from "@material-ui/icons";
const useStyles = makeStyles({
  zone: {
    whiteSpace: "nowrap",
    marginBottom: 3,
    backgroundColor: "#e0e0e0",
    margin: 20,
    padding: 10,
    width: "100%",
    heigth: "auto",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  icon: {
    marginRight: 5,
  },
  detailGrid: {
    position: "relative",
  },
});

export default function EditPaymentDetails(props) {
  const dispatch = useDispatch();

  const {
    setSeeEditPaymentDetails,
    locationConfig,
    zonesData,
    vehicles,
    languageObject,
    locationId,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const errorMessages = languageObject.authorizeUserdViews.users.errorMessages;
  const classes = useStyles();
  const rateConfig = locationConfig.payment.rateConfig;
  const gateConfig = locationConfig.payment.gateConfig || {};
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [printer, setPrinter] = useState(locationConfig.receipts.print);
  const [card, setCard] = useState(locationConfig.receipts.card);
  const [whatsapp, setWhatsapp] = useState(locationConfig.receipts.whatsapp);
  const paymentMethods = locationConfig.payment.paymentMethods;
  const businessId = useSelector((state) => state.valet.businessId);
  const [array, setArray] = useState(false);

  function handleClose() {
    setSeeEditPaymentDetails(false);
  }

  const handleCheck = (event) => {
    if (event.target.name === "print") {
      setPrinter((bool) => !bool);
    } else if (event.target.name === "card") {
      setCard((bool) => !bool);
    } else if (event.target.name === "whatsapp") {
      setWhatsapp((bool) => !bool);
    }
    onSubmit({ receipts: { [event.target.name]: event.target.checked } });
  };
  const onSubmitPrinterConfig = (values) => {
    const changeObject = values;
    console.log("update printer config...", changeObject);

    onSubmit({
      receipts: {
        printConfig: changeObject,
      },
    });
  };

  const onSubmitRateConfig = (newRateConfig) => {
    onSubmit({
      payment: {
        rateConfig: newRateConfig,
      },
    });
  };
  const onSubmitGateConfig = (newGateConfig) => {
    onSubmit({
      payment: {
        gateConfig: newGateConfig,
      },
    });
  };

  const onChangeShopItem = (newShopList) => {
    onSubmit({
      extraProducts: {
        ...newShopList,
      },
    });
  };

  const onChangeAdditionalItems = (additionalItemList) => {
    onSubmit({
      additionalItems: {
        ...additionalItemList,
      },
    });
  };

  const onSubmit = (values) => {
    const success = true;
    const changeObject = values;

    dispatch(updateLocationAtribute(locationId, changeObject));
    if (success) {
      openSuccessSnackBar();
    } else {
      openFailureSnackBar();
    }
  };

  const onSubmitDefaultCountryCode = (values) => {
    const success = true;
    const changeObject = values;
    console.log("update location with these changes...", changeObject);
    dispatch(
      updateLocationAtribute(locationId, {
        receipts: { whatsapp: changeObject },
      })
    );

    if (success) {
      openSuccessSnackBar();
    } else {
      openFailureSnackBar();
    }
  };

  const openSuccessSnackBar = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccessSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const openFailureSnackBar = () => {
    setOpenFailure(true);
  };
  const handleCloseFailureSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  function exportList(data) {
    console.log(data);
    let array = [];
    for (var i = 0; i <= data.quantity; i++) {
      console.log(i);
      const currentIndex = i;
      const number = parseFloat(currentIndex) + parseFloat(data.init);
      console.log(number);
      console.log(number.length);
      const current = "0".repeat(3 - number.toString().length) + number;
      const card = makeidKeyCard(1) + current;
      const key = makeidKeyCard(1) + current;
      array.push({
        cardURL:
          data.web +
          "?business=" +
          businessId +
          "&location=" +
          locationId +
          "&card=" +
          card,
        card: card,
        keyURL:
          data.web +
          "?business=" +
          businessId +
          "&location=" +
          locationId +
          "&key=" +
          key,
        key: key,
      });
    }
    console.log(array);
    // TODO pendiente proceso de exportado
    setArray(array);
    setArray(false);
  }

  console.log(
    "EditPaymentDetail location rateConfig",
    locationConfig.payment.rateConfig
  );

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      className={classes.detailGrid}
    >
      <Paper className={classes.zone}>
        <Grid item xs={12}>
          <IconButton
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => handleClose()}
            aria-label="settings"
          >
            <CloseIcon />
          </IconButton>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1">
              {texts.paymentMethodsTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {Object.keys(paymentMethods)
              .sort()
              .map((method) => {
                return (
                  <Grid key={method} item xs={12}>
                    <PaymentMethodSwitch
                      onSubmit={onSubmit}
                      paymentMethods={paymentMethods}
                      method={method}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.zone}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={12}>
            <Typography variant="h4" component="h1">
              {texts.receiptMethodsTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <form>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={card}
                        onChange={(event) => handleCheck(event)}
                        name="card"
                      />
                    }
                    label={
                      <Typography className={classes.wrapIcon} color="primary">
                        <CreditCardIcon className={classes.icon} />
                        {texts.card}
                      </Typography>
                    }
                  />
                </FormGroup>
              </FormControl>
            </form>

            <Grid item xs={8} md={8}>
              <FormRender
                callback={exportList}
                formObject={{
                  "4web": {
                    label: texts.web,
                    errorMessage: "",
                    fieldName: "web",
                    type: "text",
                    defaultValue: "",
                    required: true,
                    regex: "",
                    textSize: "h6",
                  },
                  "4initialNumber": {
                    label: "Número Inicial",
                    errorMessage: "",
                    fieldName: "init",
                    type: "text",
                    defaultValue: "1",
                    required: true,
                    regex: "",
                    textSize: "h6",
                    min: 1,
                    max: 999,
                  },
                  "4quantity": {
                    label: "Cantidad",
                    errorMessage: "",
                    fieldName: "quantity",
                    type: "text",
                    defaultValue: "10",
                    required: true,
                    regex: "",
                    textSize: "h6",
                    min: 1,
                    max: 999,
                  },
                }}
                submitButtonText={"Descargar Lista"}
              />
              {array && <CSVDownload data={array} />}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <form>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={printer}
                        onChange={(event) => handleCheck(event)}
                        name="print"
                      />
                    }
                    label={
                      <Typography className={classes.wrapIcon} color="primary">
                        <PrintIcon className={classes.icon} />
                        {texts.printer}
                      </Typography>
                    }
                  />
                </FormGroup>
              </FormControl>
            </form>

            {printer ? (
              <Grid item xs={12} md={12}>
                <Typography className={classes.wrapIcon} color="primary">
                  {texts.receiptConfig}
                </Typography>

                <SimpleDialogDemo
                  fullScreen={false}
                  buttonText={"Agregar Linea visible al recibo"}
                >
                  <EditReceiptToPrintItem
                    languageObject={languageObject}
                    locationId={locationId}
                    type="receipt"
                    index={
                      locationConfig &&
                      locationConfig.receipts != undefined &&
                      locationConfig.receipts.printConfig != undefined &&
                      locationConfig.receipts.printConfig.receipt != undefined
                        ? Object.keys(
                            locationConfig.receipts.printConfig.receipt
                          ).length + 1
                        : 0
                    }
                    item={{ type: "text", value: "", visible: true }}
                  />
                </SimpleDialogDemo>

                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Type</TableCell>
                        <TableCell align="center">Value</TableCell>
                        <TableCell align="center">Visible</TableCell>
                        <TableCell align="center">Edit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {locationConfig &&
                        locationConfig.receipts != undefined &&
                        locationConfig.receipts.printConfig != undefined &&
                        locationConfig.receipts.printConfig.receipt !=
                          undefined &&
                        Object.keys(
                          locationConfig.receipts.printConfig.receipt
                        ).map((index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {
                                locationConfig.receipts.printConfig.receipt[
                                  index
                                ].type
                              }
                            </TableCell>
                            <TableCell align="center">
                              {
                                locationConfig.receipts.printConfig.receipt[
                                  index
                                ].value
                              }
                            </TableCell>
                            <TableCell align="center">
                              {locationConfig.receipts.printConfig.receipt[
                                index
                              ].visible
                                ? "Yes"
                                : "No"}
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              <SimpleDialogDemo
                                fullScreen={false}
                                buttonText={"Editar"}
                              >
                                <EditReceiptToPrintItem
                                  languageObject={languageObject}
                                  locationId={locationId}
                                  type="receipt"
                                  index={index}
                                  item={
                                    locationConfig.receipts.printConfig.receipt[
                                      index
                                    ]
                                  }
                                />
                              </SimpleDialogDemo>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography className={classes.wrapIcon} color="primary">
                  {texts.qrConfig}
                </Typography>

                <SimpleDialogDemo
                  fullScreen={false}
                  buttonText={"Agregar Linea para el código QR"}
                >
                  <EditReceiptToPrintItem
                    languageObject={languageObject}
                    locationId={locationId}
                    type="qr"
                    index={
                      locationConfig &&
                      locationConfig.receipts != undefined &&
                      locationConfig.receipts.printConfig != undefined &&
                      locationConfig.receipts.printConfig.qr != undefined
                        ? Object.keys(locationConfig.receipts.printConfig.qr)
                            .length + 1
                        : 0
                    }
                    item={{ type: "text", value: "", visible: true }}
                  />
                </SimpleDialogDemo>

                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Type</TableCell>
                        <TableCell align="center">Value</TableCell>
                        <TableCell align="center">Visible</TableCell>
                        <TableCell align="center">Edit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {locationConfig &&
                        locationConfig.receipts != undefined &&
                        locationConfig.receipts.printConfig != undefined &&
                        locationConfig.receipts.printConfig.qr != undefined &&
                        Object.keys(locationConfig.receipts.printConfig.qr).map(
                          (index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {
                                  locationConfig.receipts.printConfig.qr[index]
                                    .type
                                }
                              </TableCell>
                              <TableCell align="center">
                                {
                                  locationConfig.receipts.printConfig.qr[index]
                                    .value
                                }
                              </TableCell>
                              <TableCell align="center">
                                {locationConfig.receipts.printConfig.qr[index]
                                  .visible
                                  ? "Yes"
                                  : "No"}
                              </TableCell>
                              <TableCell align="center">
                                {" "}
                                <SimpleDialogDemo
                                  fullScreen={false}
                                  buttonText={"Editar"}
                                >
                                  <EditReceiptToPrintItem
                                    languageObject={languageObject}
                                    locationId={locationId}
                                    type="qr"
                                    index={index}
                                    item={
                                      locationConfig.receipts.printConfig.qr[
                                        index
                                      ]
                                    }
                                  />
                                </SimpleDialogDemo>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <PreviewReceipt
                  languageObject={languageObject}
                  locationId={locationId}
                  locationConfig={locationConfig}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid item xs={12} md={6} style={{ padding: 20 }}>
            <Grid container direction="row" justify="space-between">
              <Grid item xs={12}>
                <Typography variant="h4" component="h4">
                  Whatsapp
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <form style={{ padding: 20 }}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={whatsapp}
                            onChange={(event) => handleCheck(event)}
                            name="whatsapp"
                          />
                        }
                        label={
                          <Typography
                            className={classes.wrapIcon}
                            color="primary"
                          >
                            <WhatsApp className={classes.icon} />
                            WHATSAPP
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </form>
                <EditableField
                  fieldName="defaultCountryCode"
                  onSubmit={onSubmitDefaultCountryCode}
                  label={texts.defaultCountryCode}
                  errorMessage={errorMessages.defaultCountryCode}
                  defaultValue={
                    locationConfig &&
                    locationConfig.receipts &&
                    locationConfig.receipts.whatsapp &&
                    locationConfig.receipts.whatsapp.defaultCountryCode
                      ? locationConfig.receipts.whatsapp.defaultCountryCode
                      : ""
                  }
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Paper>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    style={{ padding: 20 }}
                  >
                    {texts.whatsappDetail}
                  </Typography>
                </Paper> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackBar}
        >
          <Alert onClose={handleCloseSuccessSnackBar} severity="success">
            {errorMessages.saveSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openFailure}
          autoHideDuration={6000}
          onClose={handleCloseFailureSnackBar}
        >
          <Alert onClose={handleCloseFailureSnackBar} severity="success">
            {errorMessages.saveFailure}
          </Alert>
        </Snackbar>
      </Paper>

      <Paper className={classes.zone}>
        <RateConfigContainer
          rateConfig={rateConfig}
          languageObject={languageObject}
          onSubmit={onSubmitRateConfig}
          onSubmitRemoveStep={onSubmitRateConfig}
        />
      </Paper>
      <Paper className={classes.zone}>
        <GateConfigContainer
          gateConfig={gateConfig}
          languageObject={languageObject}
          onSubmit={onSubmitGateConfig}
          onSubmitRemoveStep={onSubmitGateConfig}
        />
      </Paper>

      <Paper className={classes.zone}>
        <ShopItems
          languageObject={languageObject}
          locationConfig={locationConfig}
          onChangeShopItem={onChangeShopItem}
        />
      </Paper>

      <Paper className={classes.zone}>
        <AdditionalItems
          languageObject={languageObject}
          locationConfig={locationConfig}
          onChangeAdditionalItem={onChangeAdditionalItems}
        />
      </Paper>
    </Grid>
  );
}
