import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import firebase from "firebase";

import { makeStyles } from "@material-ui/core/styles";
import ReportsSummaryCard from "./ReportsSummaryCard";
import ReportCard from "./ReportCard";
import ReportGenerator from "./ReportGenerator";
import {
  getBusinessIncome,
  getVehiclesByDay,
  getTrafficByDateChartData,
  getIncomeByLocationChartData,
} from "./reportsUtils";
import { Pie } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { useState } from "react";
import { updateVehicleAtribute } from "../../../../data-store/actions/vehicle-actions";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    margin: 10,
    backgroundColor: "#fff",
    color: "#000",
  },
  even: {
    backgroundColor: "#1d2242",
    width: "100%",
  },
  odd: {
    backgroundColor: "#000",
    width: "100%",
  },
});

export default function ReportsContainer() {
  const dispatch = useDispatch();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessId = useSelector((state) => state.valet.businessId);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const activeUsers = useSelector((state) => state.valet.activeUsers);
  const zonesData = useSelector((state) => state.vehicle.zonesData);
  const vehicles = useSelector((state) => state.vehicle.vehiclesReport);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const texts = languageObject.authorizeUserdViews.reports;
  const [charts, setCharts] = useState(null);
  const [localReport, setLocalReport] = useState(null);
  const classes = useStyles();

  // No he podido usar esto en el useEffect de abajo.
  // const setBusinessUser = () => {
  // 	dispatch(getBusinessUsers())
  // }
  // const businessLocationsFunc = () => {
  // 	dispatch(getBusinessLocationsConfig());
  // }
  useEffect(() => {
    // if (!businessLocations) {
    //   dispatch(getBusinessLocationsConfig());
    // }
    // if (!businessUsers) {
    //   dispatch(getBusinessUsers());
    // }
  });

  console.log("vehiclesReport", vehicles);
  console.log("localReport", localReport);
  const incomeReport = getBusinessIncome({ businessLocations, vehicles });

  const vehiclesByDay = vehicles
    ? getVehiclesByDay(vehicles)
    : {
        loading: {
          vehicles: [{}],
        },
      };

  function closeVehicleTEMP() {
    let x = 0;
    for (var i in vehicles) {
      x++;
      console.log(x, "NO", vehicles[i]);

      if (
        vehicles[i].closedTime &&
        vehicles[i].closedTime.seconds >= 4002444800 &&
        vehicles[i].closed == true
      ) {
        console.log(x, "SI", vehicles[i].card, vehicles[i].closedTime.seconds);

        // dispatch(
        //   updateVehicleAtribute(vehicles[i], {
        //     closedTime: vehicles[i].startTime,
        //     closed: true,
        //   })
        // );
      }
    }
  }
  // firebase.firestore.Timestamp.fromDate(new Date("2024-05-27")
  return (
    <div>
      <Grid container direction="row" justify="center" alignItems="center">
        <Typography variant={"h1"} component="h2">
          {texts.menuName}
        </Typography>

        {/* <ReportsSummaryCard
          businessId={businessId}
          businessUsers={businessUsers}
          languageObject={languageObject}
          businessLocations={businessLocations}
          zonesData={zonesData}
          vehicles={vehicles}
          activeUsers={activeUsers}
        /> */}
        <ReportGenerator
          languageObject={languageObject}
          businessLocations={businessLocations}
          vehicles={vehicles}
          setCharts={setCharts}
          setLocalReport={setLocalReport}
          businessUsers={businessUsers}
        />
        {/* <Button onClick={() => closeVehicleTEMP()}>marcar cerrado</Button> */}

        {localReport ? (
          <Card className={classes.root}>
            <CardContent className={classes.content}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {localReport[0].map((column) => (
                        <TableCell key={column} align="left">
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {localReport.map((row, i) => {
                      let rowArr = [];
                      if (i !== 0) {
                        row.forEach((column, j) => {
                          rowArr.push(
                            <TableCell key={`${i}-${j}${Math.random(10000)}`}>
                              {column}
                            </TableCell>
                          );
                        });
                      }
                      return (
                        <TableRow key={row + Math.random(10000)}>
                          {rowArr}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        ) : null}
        {charts && charts[0] ? (
          <ReportCard
            languageObject={languageObject}
            title={charts[0].title}
            description={charts[0].description}
            image={charts[0].image}
          />
        ) : null}

        {charts && charts[1] ? (
          <ReportCard
            languageObject={languageObject}
            title={charts[1].title}
            description={charts[1].description}
            image={charts[1].image}
          />
        ) : null}

        {charts && (
          <ReportCard
            languageObject={languageObject}
            title={texts.income}
            description={`${texts.incomeDescription}: ${
              Math.round(incomeReport.total * 100) / 100
            }`}
            image={
              <Pie
                data={getIncomeByLocationChartData(
                  incomeReport,
                  businessLocations
                )}
              />
            }
          />
        )}
        {charts && (
          <ReportCard
            languageObject={languageObject}
            title={texts.traffic}
            description={`${texts.trafficDescription}: ${
              vehicles ? Object.keys(vehicles).length : texts.loading
            }`}
            image={
              <Line
                height={200}
                data={getTrafficByDateChartData(vehiclesByDay, texts)}
              />
            }
          />
        )}
      </Grid>
    </div>
  );
}
