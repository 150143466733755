import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Typography from "@material-ui/core/Typography";
import { timestampToDate } from "../../../../../services/utils";
import { Paper } from "@material-ui/core";
import { updateVehicleAtribute } from "../../../../../data-store/actions/vehicle-actions";
import FormRender from "../../../../Fields/FormRender";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "500px",
    height: 120,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
    width: "50%",
  },
  buttonStyle: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    flex: 1,
    marginBottom: 5,
    marginTop: 5,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function VehicleNotesList(props) {
  const { vehicle } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const carsCatalogue = useSelector((state) => state.valet.catalogue.cars);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const userID = useSelector((state) => state.auth.userID);

  const texts = languageObject.authorizeUserdViews.vehicleDetail.notes;

  function callbackNotes(data) {
    var date = Math.round(Date.now() / 1000);
    const note = {
      text: data.note,
      alert: true,
      userID: userID,
      date: date,
    };
    const newNotes = vehicle.notes;
    newNotes[note.date] = note;
    console.log("notes");
    console.log(vehicle);
    dispatch(updateVehicleAtribute(vehicle, { notes: newNotes }));
  }

  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid item xs={12} sm={12} style={{ textAlign: "center", marginTop: 30 }}>
        <Typography gutterBottom variant={"h3"} component="h2">
          {texts.title}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{texts.date}</TableCell>
                <TableCell align="center">{texts.description}</TableCell>
                <TableCell align="center">{texts.user}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicle.notes &&
                Object.values(vehicle.notes).map((note) => (
                  <TableRow
                    key={note.date + Math.random(10000)}
                    style={
                      note.deleted
                        ? { backgroundColor: "grey" }
                        : { backgroundColor: "" }
                    }
                  >
                    <TableCell
                      align="center"
                      padding={"default"}
                      component="th"
                      scope="row"
                    >
                      {timestampToDate(note.date * 1000)}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding={"default"}
                      component="th"
                      scope="row"
                    >
                      {note.text}
                    </TableCell>
                    <TableCell padding={"default"} align="center">
                      {businessUsers[note.userID] !== undefined
                        ? businessUsers[note.userID].name
                        : note.userID}{" "}
                      {businessUsers[note.userID] !== undefined
                        ? businessUsers[note.userID].lastname
                        : ""}{" "}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        style={{
          textAlign: "center",
          padding: 10,
          paddingRight: 60,
          paddingLeft: 60,
        }}
      >
        <FormRender
          callback={callbackNotes}
          formObject={{
            note: {
              label: texts.addNote,
              errorMessage: "",
              fieldName: "note",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
          }}
          submitButtonText={texts.saveNote}
        />
      </Grid>
    </Grid>
  );
}
