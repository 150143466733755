import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Icon, Typography } from "@material-ui/core";
import MoneyIcon from "@material-ui/icons/Money";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ComputerIcon from "@material-ui/icons/Computer";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PrintIcon from "@material-ui/icons/Print";
import { getPaymentMethodIcon } from "../LocationDetailUtils";
import { getPaymentTotalsByType } from "./LoctionPaymentUtils";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 330,
    margin: 10,
  },
  media: {
    height: 70,
  },
  total: {
    position: "absolute",
    bottom: 10,
  },
  paper: {
    padding: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
  },
  iconParent: {
    fontSize: 72,
  },
  icon: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: "auto",
    marginBottom: "auto",
    fontSize: 30,
  },
  methodContianer: {
    textAlign: "center",
  },
  receiptMethods: {
    height: 30,
  },
});

export default function LocationPaymentDetail(props) {
  const {
    locationConfig,
    locationId,
    zonesData,
    languageObject,
    vehicles,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const classes = useStyles();
  const paymentMethods = locationConfig.payment.paymentMethods;
  const paymentTotalsByType = getPaymentTotalsByType(
    paymentMethods,
    vehicles,
    locationId
  );
  const paymentsTotalAll = Object.values(paymentTotalsByType).reduce(
    (a, b) => (a += b)
  );
  // console.log('LocationPaymentDetail paymentTotalsByType -', paymentTotalsByType);

  return (
    <Grid
      container
      direction="row"
      justify="space-evenly"
      alignItems="flex-start"
      className={classes.iconParent}
    >
      <Grid className={classes.receiptMethods} item xs={12}>
        <Typography variant="h5" component="h1">
          {texts.receiptMethodsTitle}:
          <PrintIcon
            className={classes.icon}
            color={locationConfig.receipts.print ? "primary" : "disabled"}
          />
          <CreditCardIcon
            className={classes.icon}
            color={locationConfig.receipts.print ? "primary" : "disabled"}
          />
        </Typography>
      </Grid>
      {/* <Grid item xs={6} direction="column" className={classes.receiptMethods}>
        <Grid item xs={3}>

        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid> */}

      {Object.keys(paymentMethods).map((method) => {
        return (
          <Grid key={method} className={classes.methodContianer} item xs={4}>
            {getPaymentMethodIcon(method, {
              fontSize: "inherit",
              color: paymentMethods[method].available ? "primary" : "disabled",
            })}
            <Typography variant="h5" component="p">
              $ {Math.round(paymentTotalsByType[method] * 100) / 100}
            </Typography>
          </Grid>
        );
      })}

      <Grid className={classes.total} item xs={12}>
        <Typography variant="h4" component="p">
          Total: ${Math.round(paymentsTotalAll * 100) / 100}
        </Typography>
      </Grid>
    </Grid>
  );
}
