import React from 'react';
import { useForm } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button, FormControl, Grid, TextField } from '@material-ui/core';
import { dateToYYYYMMDD, dateToHHMM } from './payRollUtils';
import ShiftCardItem from './ShiftCardItem';
import EditableField from '../../../EditableField';

const useStyles = makeStyles({
  card: {
    margin: 10
  },
  media: {
    height: 140,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    minWidth: 100,
  }
});

export default function ShiftCard(props) {
  const { languageObject, shifts, hourlyWage, onAddShift, onEditShift, businessLocations } = props;
  const texts = languageObject.authorizeUserdViews.users.payRoll;



  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
    // spacing={10}
    >
      <Grid item xs={6}>
        <Typography gutterBottom variant="h5" component="h2">
          {texts.shifts}
        </Typography>
        <Button onClick={onAddShift} variant="contained" color="primary">Add shift</Button>
      </Grid>
      <Grid item xs={6}>
        <EditableField
          fieldName="hourlyWage"
          onSubmit={(value) => onEditShift(value)}
          label={texts.hourlyWage}
          errorMessage={texts.error}
          defaultValue={hourlyWage}
          min={0}
          required={true}
        />
      </Grid>
      {Object.keys(shifts).map(
        (shiftKey) => <ShiftCardItem
          key={shiftKey}
          shiftKey={shiftKey}
          languageObject={languageObject}
          shifts={shifts}
          hourlyWage={hourlyWage}
          onAddShift={onAddShift}
          onEditShift={onEditShift}
          businessLocations={businessLocations}
        />)}
    </Grid>

  )
}