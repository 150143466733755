import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  linkcss: {
    margin: theme.spacing(1),
    color: "#1d2242",
  },
}));

const MenuItemRow = ({ iconComponent, link, menuName, handleDrawerClose }) => {
  const classes = useStyles();

  return (
    <ListItem button onClick={handleDrawerClose}>
      <ListItemIcon className={classes.linkcss}>{iconComponent}</ListItemIcon>
      <Link to={link}>
        <ListItemText className={classes.linkcss} primary={menuName} />
      </Link>
    </ListItem>
  );
};

export default MenuItemRow;
