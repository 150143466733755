import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTotalAmount } from "./PaymentUtils";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    padding: 20,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function ValidationsVehicleDetail(props) {
  const { locationId, vehicle } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const userID = useSelector((state) => state.auth.userID);

  useEffect(() => {}, [businessId]);
  const texts = languageObject.authorizeUserdViews.ticketsValidation.table;
  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid className={classes.spacing} item xs={12} sm={12}>
        <Typography
          className={classes.text}
          gutterBottom
          variant={"h6"}
          component="h2"
        >
          {texts.brand}:{vehicle.brand}
        </Typography>
        <Typography
          className={classes.text}
          gutterBottom
          variant={"h6"}
          component="h2"
        >
          {texts.model}:{vehicle.model}
        </Typography>
        <Typography
          className={classes.text}
          gutterBottom
          variant={"h6"}
          component="h2"
        >
          {texts.color}:{vehicle.color}
        </Typography>
        <Typography
          className={classes.text}
          gutterBottom
          variant={"h6"}
          component="h2"
        >
          {texts.key}:{vehicle.key}
        </Typography>
        <Typography
          className={classes.text}
          gutterBottom
          variant={"h6"}
          component="h2"
        >
          {texts.card}:{vehicle.card}
        </Typography>
      </Grid>
      <Grid className={classes.spacing} item xs={12} sm={12}>
        Por pagar:
        {
          useTotalAmount(
            businessLocations[locationId].locationConfig.payment.rateConfig,
            vehicle
          ).pendingPay
        }
      </Grid>
    </Grid>
  );
}
