import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { fechahora, helperMultisort } from "../../../../services/utils";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import SendIcon from "@material-ui/icons/Send";

import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { sendMessage } from "../../../../data-store/actions/chat-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    height: 500,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  menu: {
    flex: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 50,
    width: 100,
  },
  container: {
    overflow: "scroll",
    height: "600px",
  },
}));

export default function Messages(props) {
  const { counterPartId } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.auth.userID);
  const chatMessages = useSelector((state) => state.chat.chatMessages);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const languageObject = useSelector((state) => state.auth.languageObject);

  const classes = useStyles();
  const { register, handleSubmit, errors, reset } = useForm();
  console.log(counterPartId);

  const onSubmit = (data, e) => {
    var messageObject = {
      to: [counterPartId],
      from: userID,
      date: Math.round(Date.now() / 1000),
      text: data.message,
    };
    e.target.reset();
    dispatch(sendMessage(messageObject));
  };

  function filterChat(chatMessages, userID, counterPartId) {
    if (!chatMessages) {
      return [];
    }
    const newChatObject = Object.values(chatMessages).filter((a) => {
      return (
        (a.from === userID && a.to.includes(counterPartId)) ||
        (a.from === counterPartId && a.to.includes(userID))
      );
    });

    return helperMultisort(newChatObject, ["date"], ["DESC"]);
  }
  const filteredChatMessages = filterChat(chatMessages, userID, counterPartId);

  return (
    <div className={classes.root}>
      {counterPartId && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Paper style={{ padding: 10, backgroundColor: "#ffcdd2" }}>
                <Grid container direction="row">
                  {languageObject.authorizeUserdViews.chat.sendMessage}

                  <Grid item xs={10}>
                    <input name="message" ref={register} />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton type="submit">
                      <SendIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
          <Grid item xs={12} className={classes.container}>
            {filteredChatMessages.map((messageData, i) => {
              const message = messageData;
              return (
                <Paper key={i} style={{ padding: 5, margin: 5 }}>
                  <Grid
                    container
                    spacing={3}
                    style={userID === message.from ? {} : {}}
                  >
                    <Grid item xs={2}>
                      <div style={{ textAlign: "center" }}>
                        <Avatar
                          alt="chat"
                          src={
                            businessUsers[message.from] !== undefined
                              ? businessUsers[message.from].photoUrl
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography gutterBottom variant="subtitle1">
                        {message.text}
                      </Typography>
                      <span style={{ fontSize: "12px" }}>
                        {fechahora(message.date)}
                      </span>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        </Grid>
      )}
    </div>
  );
}
