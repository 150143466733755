import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GroupIcon from '@material-ui/icons/Group';
import { useGetBusinessIncome, useGetBusinessZones, useGetBusinessVehiclesCount, useGetBusinessActiveUsers } from '../Locations/LocationsUtils'
import { getCurrentQuincenaDates, getTotalPayroll } from './payRollUtils';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
  },
  icons: {
    margin: 20,
    color: 'acion'
  }
});

export default function AllEmployeesBadges(props) {
  const { quincenaDates, userListArray, languageObject } = props;
  const { quincenaStart, quincenaEnd } = quincenaDates;
  const { hoursWorked, totalPay } = getTotalPayroll(quincenaStart, quincenaEnd, userListArray);
  const classes = useStyles();
  const texts = languageObject.authorizeUserdViews.payRoll.badgeDescriptions;
  console.log("userListArray", userListArray);
  let numberOfWorkers = userListArray.length;

  return (

    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
    >

      {/* Number of workers */}
      <Badge max={999} className={classes.icons} color="primary" badgeContent={numberOfWorkers || '0'}>
        <Tooltip title={texts.numberOfWorkers}>
          <GroupIcon style={{ fontSize: 40 }} />
        </Tooltip>
      </Badge>
      {/* total hours worked this quincena */}
      <Badge max={999} className={classes.icons} color="primary" badgeContent={hoursWorked || '0'}>
        <Tooltip title={texts.hoursWorked}>
          <AccessTimeIcon style={{ fontSize: 40 }} />
        </Tooltip>
      </Badge>
      {/* Payroll for this quincena */}
      <Badge max={9999} className={classes.icons} color="primary" badgeContent={`$${totalPay || '0'}`}>
        <Tooltip title={texts.payRollTotal}>
          <MonetizationOnIcon style={{ fontSize: 40 }} />
        </Tooltip>
      </Badge>

    </Grid>
  );
}