import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Typography,
  IconButton,
  Paper,
  Grid,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import EditableField from "../../../../EditableField";
import EditableField1 from "../../../../EditableField/EditableField";
import LocationPhoto from "./LocationPhoto/LocationPhoto";
import { updateLocationAtribute } from "../../../../../data-store/actions/valet-actions";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 330,
    margin: 10,
  },
  iconSave: {
    position: "absolute",
    right: 10,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
  },
  media: {
    height: 70,
  },
  content: {
    height: 220,
  },
  paper: {
    padding: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
  },
  zone: {
    whiteSpace: "nowrap",
    marginBottom: 3,
    backgroundColor: "#ffffff",
    margin: 20,
    padding: 20,
    position: "relative",
  },
});

export default function EditLocation(props) {
  const {
    languageObject,
    locationConfig,
    zonesData,
    locationId,
    setEditLocation,
  } = props;
  const dispatch = useDispatch();

  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const errorMessages = languageObject.authorizeUserdViews.users.errorMessages;
  const classes = useStyles();

  function handleClose() {
    setEditLocation(false);
  }

  const onSubmit = (values) => {
    const success = true;
    const changeObject = values;
    console.log("update location with these changes...", changeObject);
    dispatch(updateLocationAtribute(locationId, changeObject));

    if (success) {
      openSuccessSnackBar();
    } else {
      openFailureSnackBar();
    }
  };

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const openSuccessSnackBar = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccessSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const openFailureSnackBar = () => {
    setOpenFailure(true);
  };
  const handleCloseFailureSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Paper className={classes.zone}>
      <Grid container direction="row" justify="flex-start">
        <Grid item xs={12} style={{ margin: "10px" }}>
          <IconButton
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => handleClose()}
            aria-label="settings"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <LocationPhoto
            locationConfig={locationConfig}
            locationId={locationId}
          />
        </Grid>
        <Grid item xs={12}>
          <EditableField
            fieldName="name"
            onSubmit={onSubmit}
            label={texts.editLocationName}
            errorMessage={errorMessages.locationName}
            defaultValue={locationConfig.name}
            required={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <EditableField
            fieldName="description"
            onSubmit={onSubmit}
            label={texts.editLocationDescription}
            errorMessage={errorMessages.locationDescription}
            defaultValue={locationConfig.description}
            required={true}
            textSize="body2"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <EditableField
            fieldName="adress"
            onSubmit={onSubmit}
            label={texts.editLocationAdress}
            errorMessage={errorMessages.editLocationAdress}
            defaultValue={locationConfig.adress}
            required={true}
            textSize="body2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditableField1
            fieldObject={{
              label: texts.startShift,
              errorMessage: texts.startShift,
              fieldName: "startShift",
              type: "select",
              defaultValue: locationConfig.startShift || "NA",
              optionsArray: [
                { value: "00:00:00", label: "00:00:00" },
                { value: "00:15:00", label: "00:15:00" },
                { value: "00:30:00", label: "00:30:00" },
                { value: "00:45:00", label: "00:45:00" },
                { value: "01:00:00", label: "01:00:00" },
                { value: "01:15:00", label: "01:15:00" },
                { value: "01:30:00", label: "01:30:00" },
                { value: "01:45:00", label: "01:45:00" },
                { value: "02:00:00", label: "02:00:00" },
                { value: "02:15:00", label: "02:15:00" },
                { value: "02:30:00", label: "02:30:00" },
                { value: "02:45:00", label: "02:45:00" },
                { value: "03:00:00", label: "03:00:00" },
                { value: "03:15:00", label: "03:15:00" },
                { value: "03:30:00", label: "03:30:00" },
                { value: "03:45:00", label: "03:45:00" },
                { value: "04:00:00", label: "04:00:00" },
                { value: "04:15:00", label: "04:15:00" },
                { value: "04:30:00", label: "04:30:00" },
                { value: "04:45:00", label: "04:45:00" },
                { value: "05:00:00", label: "05:00:00" },
                { value: "05:15:00", label: "05:15:00" },
                { value: "05:30:00", label: "05:30:00" },
                { value: "05:45:00", label: "05:45:00" },
                { value: "06:00:00", label: "06:00:00" },
                { value: "06:15:00", label: "06:15:00" },
                { value: "06:30:00", label: "06:30:00" },
                { value: "06:45:00", label: "06:45:00" },
                { value: "07:00:00", label: "07:00:00" },
                { value: "07:15:00", label: "07:15:00" },
                { value: "07:30:00", label: "07:30:00" },
                { value: "07:45:00", label: "07:45:00" },
                { value: "08:00:00", label: "08:00:00" },
                { value: "08:15:00", label: "08:15:00" },
                { value: "08:30:00", label: "08:30:00" },
                { value: "08:45:00", label: "08:45:00" },
                { value: "09:00:00", label: "09:00:00" },
                { value: "09:15:00", label: "09:15:00" },
                { value: "09:30:00", label: "09:30:00" },
                { value: "09:45:00", label: "09:45:00" },
                { value: "10:00:00", label: "10:00:00" },
                { value: "10:15:00", label: "10:15:00" },
                { value: "10:30:00", label: "10:30:00" },
                { value: "10:45:00", label: "10:45:00" },
                { value: "11:00:00", label: "11:00:00" },
                { value: "11:15:00", label: "11:15:00" },
                { value: "11:30:00", label: "11:30:00" },
                { value: "11:45:00", label: "11:45:00" },
                { value: "12:00:00", label: "12:00:00" },
                { value: "12:15:00", label: "12:15:00" },
                { value: "12:30:00", label: "12:30:00" },
                { value: "12:45:00", label: "12:45:00" },
                { value: "13:00:00", label: "13:00:00" },
                { value: "13:15:00", label: "13:15:00" },
                { value: "13:30:00", label: "13:30:00" },
                { value: "13:45:00", label: "13:45:00" },
                { value: "14:00:00", label: "14:00:00" },
                { value: "14:15:00", label: "14:15:00" },
                { value: "14:30:00", label: "14:30:00" },
                { value: "14:45:00", label: "14:45:00" },
                { value: "15:00:00", label: "15:00:00" },
                { value: "15:15:00", label: "15:15:00" },
                { value: "15:30:00", label: "15:30:00" },
                { value: "15:45:00", label: "15:45:00" },
                { value: "16:00:00", label: "16:00:00" },
                { value: "16:15:00", label: "16:15:00" },
                { value: "16:30:00", label: "16:30:00" },
                { value: "16:45:00", label: "16:45:00" },
                { value: "17:00:00", label: "17:00:00" },
                { value: "17:15:00", label: "17:15:00" },
                { value: "17:30:00", label: "17:30:00" },
                { value: "17:45:00", label: "17:45:00" },
                { value: "18:00:00", label: "18:00:00" },
                { value: "18:15:00", label: "18:15:00" },
                { value: "18:30:00", label: "18:30:00" },
                { value: "18:45:00", label: "18:45:00" },
                { value: "19:00:00", label: "19:00:00" },
                { value: "19:15:00", label: "19:15:00" },
                { value: "19:30:00", label: "19:30:00" },
                { value: "19:45:00", label: "19:45:00" },
                { value: "20:00:00", label: "20:00:00" },
                { value: "20:15:00", label: "20:15:00" },
                { value: "20:30:00", label: "20:30:00" },
                { value: "20:45:00", label: "20:45:00" },
                { value: "21:00:00", label: "21:00:00" },
                { value: "21:15:00", label: "21:15:00" },
                { value: "21:30:00", label: "21:30:00" },
                { value: "21:45:00", label: "21:45:00" },
                { value: "22:00:00", label: "22:00:00" },
                { value: "22:15:00", label: "22:15:00" },
                { value: "22:30:00", label: "22:30:00" },
                { value: "22:45:00", label: "22:45:00" },
                { value: "23:00:00", label: "23:00:00" },
                { value: "23:15:00", label: "23:15:00" },
                { value: "23:30:00", label: "23:30:00" },
                { value: "23:45:00", label: "23:45:00" },
                { value: "23:59:59", label: "23:59:59" },
              ],
              regex: "",
              textSize: "h6",
            }}
            onSubmit={onSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditableField1
            fieldObject={{
              label: texts.endShift,
              errorMessage: texts.endShift,
              fieldName: "endShift",
              type: "select",
              defaultValue: locationConfig.endShift || "NA",
              optionsArray: [
                { value: "00:00:00", label: "00:00:00" },
                { value: "00:15:00", label: "00:15:00" },
                { value: "00:30:00", label: "00:30:00" },
                { value: "00:45:00", label: "00:45:00" },
                { value: "01:00:00", label: "01:00:00" },
                { value: "01:15:00", label: "01:15:00" },
                { value: "01:30:00", label: "01:30:00" },
                { value: "01:45:00", label: "01:45:00" },
                { value: "02:00:00", label: "02:00:00" },
                { value: "02:15:00", label: "02:15:00" },
                { value: "02:30:00", label: "02:30:00" },
                { value: "02:45:00", label: "02:45:00" },
                { value: "03:00:00", label: "03:00:00" },
                { value: "03:15:00", label: "03:15:00" },
                { value: "03:30:00", label: "03:30:00" },
                { value: "03:45:00", label: "03:45:00" },
                { value: "04:00:00", label: "04:00:00" },
                { value: "04:15:00", label: "04:15:00" },
                { value: "04:30:00", label: "04:30:00" },
                { value: "04:45:00", label: "04:45:00" },
                { value: "05:00:00", label: "05:00:00" },
                { value: "05:15:00", label: "05:15:00" },
                { value: "05:30:00", label: "05:30:00" },
                { value: "05:45:00", label: "05:45:00" },
                { value: "06:00:00", label: "06:00:00" },
                { value: "06:15:00", label: "06:15:00" },
                { value: "06:30:00", label: "06:30:00" },
                { value: "06:45:00", label: "06:45:00" },
                { value: "07:00:00", label: "07:00:00" },
                { value: "07:15:00", label: "07:15:00" },
                { value: "07:30:00", label: "07:30:00" },
                { value: "07:45:00", label: "07:45:00" },
                { value: "08:00:00", label: "08:00:00" },
                { value: "08:15:00", label: "08:15:00" },
                { value: "08:30:00", label: "08:30:00" },
                { value: "08:45:00", label: "08:45:00" },
                { value: "09:00:00", label: "09:00:00" },
                { value: "09:15:00", label: "09:15:00" },
                { value: "09:30:00", label: "09:30:00" },
                { value: "09:45:00", label: "09:45:00" },
                { value: "10:00:00", label: "10:00:00" },
                { value: "10:15:00", label: "10:15:00" },
                { value: "10:30:00", label: "10:30:00" },
                { value: "10:45:00", label: "10:45:00" },
                { value: "11:00:00", label: "11:00:00" },
                { value: "11:15:00", label: "11:15:00" },
                { value: "11:30:00", label: "11:30:00" },
                { value: "11:45:00", label: "11:45:00" },
                { value: "12:00:00", label: "12:00:00" },
                { value: "12:15:00", label: "12:15:00" },
                { value: "12:30:00", label: "12:30:00" },
                { value: "12:45:00", label: "12:45:00" },
                { value: "13:00:00", label: "13:00:00" },
                { value: "13:15:00", label: "13:15:00" },
                { value: "13:30:00", label: "13:30:00" },
                { value: "13:45:00", label: "13:45:00" },
                { value: "14:00:00", label: "14:00:00" },
                { value: "14:15:00", label: "14:15:00" },
                { value: "14:30:00", label: "14:30:00" },
                { value: "14:45:00", label: "14:45:00" },
                { value: "15:00:00", label: "15:00:00" },
                { value: "15:15:00", label: "15:15:00" },
                { value: "15:30:00", label: "15:30:00" },
                { value: "15:45:00", label: "15:45:00" },
                { value: "16:00:00", label: "16:00:00" },
                { value: "16:15:00", label: "16:15:00" },
                { value: "16:30:00", label: "16:30:00" },
                { value: "16:45:00", label: "16:45:00" },
                { value: "17:00:00", label: "17:00:00" },
                { value: "17:15:00", label: "17:15:00" },
                { value: "17:30:00", label: "17:30:00" },
                { value: "17:45:00", label: "17:45:00" },
                { value: "18:00:00", label: "18:00:00" },
                { value: "18:15:00", label: "18:15:00" },
                { value: "18:30:00", label: "18:30:00" },
                { value: "18:45:00", label: "18:45:00" },
                { value: "19:00:00", label: "19:00:00" },
                { value: "19:15:00", label: "19:15:00" },
                { value: "19:30:00", label: "19:30:00" },
                { value: "19:45:00", label: "19:45:00" },
                { value: "20:00:00", label: "20:00:00" },
                { value: "20:15:00", label: "20:15:00" },
                { value: "20:30:00", label: "20:30:00" },
                { value: "20:45:00", label: "20:45:00" },
                { value: "21:00:00", label: "21:00:00" },
                { value: "21:15:00", label: "21:15:00" },
                { value: "21:30:00", label: "21:30:00" },
                { value: "21:45:00", label: "21:45:00" },
                { value: "22:00:00", label: "22:00:00" },
                { value: "22:15:00", label: "22:15:00" },
                { value: "22:30:00", label: "22:30:00" },
                { value: "22:45:00", label: "22:45:00" },
                { value: "23:00:00", label: "23:00:00" },
                { value: "23:15:00", label: "23:15:00" },
                { value: "23:30:00", label: "23:30:00" },
                { value: "23:45:00", label: "23:45:00" },
                { value: "23:59:59", label: "23:59:59" },
                { value: "+1 00:15:00", label: texts.nextDay + " 00:15:00" },
                { value: "+1 00:30:00", label: texts.nextDay + " 00:30:00" },
                { value: "+1 00:45:00", label: texts.nextDay + " 00:45:00" },
                { value: "+1 01:00:00", label: texts.nextDay + " 01:00:00" },
                { value: "+1 01:15:00", label: texts.nextDay + " 01:15:00" },
                { value: "+1 01:30:00", label: texts.nextDay + " 01:30:00" },
                { value: "+1 01:45:00", label: texts.nextDay + " 01:45:00" },
                { value: "+1 02:00:00", label: texts.nextDay + " 02:00:00" },
                { value: "+1 02:15:00", label: texts.nextDay + " 02:15:00" },
                { value: "+1 02:30:00", label: texts.nextDay + " 02:30:00" },
                { value: "+1 02:45:00", label: texts.nextDay + " 02:45:00" },
                { value: "+1 03:00:00", label: texts.nextDay + " 03:00:00" },
                { value: "+1 03:15:00", label: texts.nextDay + " 03:15:00" },
                { value: "+1 03:30:00", label: texts.nextDay + " 03:30:00" },
                { value: "+1 03:45:00", label: texts.nextDay + " 03:45:00" },
                { value: "+1 04:00:00", label: texts.nextDay + " 04:00:00" },
                { value: "+1 04:15:00", label: texts.nextDay + " 04:15:00" },
                { value: "+1 04:30:00", label: texts.nextDay + " 04:30:00" },
                { value: "+1 04:45:00", label: texts.nextDay + " 04:45:00" },
                { value: "+1 05:00:00", label: texts.nextDay + " 05:00:00" },
                { value: "+1 05:15:00", label: texts.nextDay + " 05:15:00" },
                { value: "+1 05:30:00", label: texts.nextDay + " 05:30:00" },
                { value: "+1 05:45:00", label: texts.nextDay + " 05:45:00" },
                { value: "+1 06:00:00", label: texts.nextDay + " 06:00:00" },
                { value: "+1 06:15:00", label: texts.nextDay + " 06:15:00" },
                { value: "+1 06:30:00", label: texts.nextDay + " 06:30:00" },
                { value: "+1 06:45:00", label: texts.nextDay + " 06:45:00" },
                { value: "+1 07:00:00", label: texts.nextDay + " 07:00:00" },
                { value: "+1 07:15:00", label: texts.nextDay + " 07:15:00" },
                { value: "+1 07:30:00", label: texts.nextDay + " 07:30:00" },
                { value: "+1 07:45:00", label: texts.nextDay + " 07:45:00" },
                { value: "+1 08:00:00", label: texts.nextDay + " 08:00:00" },
                { value: "+1 08:15:00", label: texts.nextDay + " 08:15:00" },
                { value: "+1 08:30:00", label: texts.nextDay + " 08:30:00" },
                { value: "+1 08:45:00", label: texts.nextDay + " 08:45:00" },
                { value: "+1 09:00:00", label: texts.nextDay + " 09:00:00" },
                { value: "+1 09:15:00", label: texts.nextDay + " 09:15:00" },
                { value: "+1 09:30:00", label: texts.nextDay + " 09:30:00" },
                { value: "+1 09:45:00", label: texts.nextDay + " 09:45:00" },
                { value: "+1 10:00:00", label: texts.nextDay + " 10:00:00" },
                { value: "+1 10:15:00", label: texts.nextDay + " 10:15:00" },
                { value: "+1 10:30:00", label: texts.nextDay + " 10:30:00" },
                { value: "+1 10:45:00", label: texts.nextDay + " 10:45:00" },
                { value: "+1 11:00:00", label: texts.nextDay + " 11:00:00" },
                { value: "+1 11:15:00", label: texts.nextDay + " 11:15:00" },
                { value: "+1 11:30:00", label: texts.nextDay + " 11:30:00" },
                { value: "+1 11:45:00", label: texts.nextDay + " 11:45:00" },
                { value: "+1 12:00:00", label: texts.nextDay + " 12:00:00" },
                { value: "+1 12:15:00", label: texts.nextDay + " 12:15:00" },
                { value: "+1 12:30:00", label: texts.nextDay + " 12:30:00" },
                { value: "+1 12:45:00", label: texts.nextDay + " 12:45:00" },
                { value: "+1 13:00:00", label: texts.nextDay + " 13:00:00" },
                { value: "+1 13:15:00", label: texts.nextDay + " 13:15:00" },
                { value: "+1 13:30:00", label: texts.nextDay + " 13:30:00" },
                { value: "+1 13:45:00", label: texts.nextDay + " 13:45:00" },
                { value: "+1 14:00:00", label: texts.nextDay + " 14:00:00" },
                { value: "+1 14:15:00", label: texts.nextDay + " 14:15:00" },
                { value: "+1 14:30:00", label: texts.nextDay + " 14:30:00" },
                { value: "+1 14:45:00", label: texts.nextDay + " 14:45:00" },
                { value: "+1 15:00:00", label: texts.nextDay + " 15:00:00" },
                { value: "+1 15:15:00", label: texts.nextDay + " 15:15:00" },
                { value: "+1 15:30:00", label: texts.nextDay + " 15:30:00" },
                { value: "+1 15:45:00", label: texts.nextDay + " 15:45:00" },
                { value: "+1 16:00:00", label: texts.nextDay + " 16:00:00" },
                { value: "+1 16:15:00", label: texts.nextDay + " 16:15:00" },
                { value: "+1 16:30:00", label: texts.nextDay + " 16:30:00" },
                { value: "+1 16:45:00", label: texts.nextDay + " 16:45:00" },
                { value: "+1 17:00:00", label: texts.nextDay + " 17:00:00" },
                { value: "+1 17:15:00", label: texts.nextDay + " 17:15:00" },
                { value: "+1 17:30:00", label: texts.nextDay + " 17:30:00" },
                { value: "+1 17:45:00", label: texts.nextDay + " 17:45:00" },
                { value: "+1 18:00:00", label: texts.nextDay + " 18:00:00" },
                { value: "+1 18:15:00", label: texts.nextDay + " 18:15:00" },
                { value: "+1 18:30:00", label: texts.nextDay + " 18:30:00" },
                { value: "+1 18:45:00", label: texts.nextDay + " 18:45:00" },
                { value: "+1 19:00:00", label: texts.nextDay + " 19:00:00" },
                { value: "+1 19:15:00", label: texts.nextDay + " 19:15:00" },
                { value: "+1 19:30:00", label: texts.nextDay + " 19:30:00" },
                { value: "+1 19:45:00", label: texts.nextDay + " 19:45:00" },
                { value: "+1 20:00:00", label: texts.nextDay + " 20:00:00" },
                { value: "+1 20:15:00", label: texts.nextDay + " 20:15:00" },
                { value: "+1 20:30:00", label: texts.nextDay + " 20:30:00" },
                { value: "+1 20:45:00", label: texts.nextDay + " 20:45:00" },
                { value: "+1 21:00:00", label: texts.nextDay + " 21:00:00" },
                { value: "+1 21:15:00", label: texts.nextDay + " 21:15:00" },
                { value: "+1 21:30:00", label: texts.nextDay + " 21:30:00" },
                { value: "+1 21:45:00", label: texts.nextDay + " 21:45:00" },
                { value: "+1 22:00:00", label: texts.nextDay + " 22:00:00" },
                { value: "+1 22:15:00", label: texts.nextDay + " 22:15:00" },
                { value: "+1 22:30:00", label: texts.nextDay + " 22:30:00" },
                { value: "+1 22:45:00", label: texts.nextDay + " 22:45:00" },
                { value: "+1 23:00:00", label: texts.nextDay + " 23:00:00" },
                { value: "+1 23:15:00", label: texts.nextDay + " 23:15:00" },
                { value: "+1 23:30:00", label: texts.nextDay + " 23:30:00" },
                { value: "+1 23:45:00", label: texts.nextDay + " 23:45:00" },
                { value: "+1 23:59:59", label: texts.nextDay + " 23:59:59" },
              ],
              regex: "",
              textSize: "h6",
            }}
            onSubmit={onSubmit}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <EditableField
            fieldName="lat"
            onSubmit={onSubmit}
            label={texts.editLocationLat}
            errorMessage={errorMessages.editLocationLat}
            defaultValue={locationConfig.lat}
            required={true}
            textSize="body2"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <EditableField
            fieldName="lng"
            onSubmit={onSubmit}
            label={texts.editLocationLng}
            errorMessage={errorMessages.editLocationLng}
            defaultValue={locationConfig.lng}
            required={true}
            textSize="body2"
          />
        </Grid>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackBar}
        >
          <Alert onClose={handleCloseSuccessSnackBar} severity="success">
            {errorMessages.saveSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openFailure}
          autoHideDuration={6000}
          onClose={handleCloseFailureSnackBar}
        >
          <Alert onClose={handleCloseFailureSnackBar} severity="success">
            {errorMessages.saveFailure}
          </Alert>
        </Snackbar>
      </Grid>
    </Paper>
  );
}
