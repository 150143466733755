import React from "react";

const ContactUs = ({ contactUsFooterText }) => (
  <div>
    <h5 className="white-text">{contactUsFooterText.title}</h5>
    <ul>
      <li>
        <a
          className="white-text left-align"
          href={`mailto:{contactUsFooterText.mail}`}
        >
          <i className="fa fa-envelope" />
          &nbsp;
          {contactUsFooterText.mail}
        </a>
      </li>
      <li>{contactUsFooterText.phone}</li>
      {/* <li>
			<a className="white-text left-align"
			   href={contactUsFooterText.facebookLink}
			   target="_blank"
			>
				<i className="fa fa-facebook-official"/>
				&nbsp;
				{contactUsFooterText.facebookText}
			</a>
		</li> */}
    </ul>
  </div>
);
export default ContactUs;
