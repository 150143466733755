import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Distance from "./Distance";

const useStyles = makeStyles({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
  },
});

export default function FieldEvent(props) {
  const { fieldObject, register, errors, watch } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    optionsArray,
    watchArray,
  } = fieldObject;
  const classes = useStyles();
  const [editMode, setEditMode] = useState(true);
  const [categoryObject, setCategoryObject] = useState({
    distance: null,
    gender: null,
    category: null,
  });

  console.log(watch());

  function setCategoryFunc(value) {
    setCategoryObject(value);
  }

  const eventDate = watch("eventDate");
  const gender = watch("gender");
  const birth = watch("birth");

  if (gender === undefined || birth === undefined) {
    return (
      <Typography
        className={classes.text}
        gutterBottom
        color="primary"
        variant={"h6"}
        component="h2"
      >
        Selecciona el genero y fecha de nacimiento para continuar
      </Typography>
    );
  }

  return (
    <div>
      <input
        style={{ display: "none" }}
        type="text"
        name={"distance"}
        ref={register}
        value={categoryObject.distance}
      />
      <input
        style={{ display: "none" }}
        type="text"
        name={"category"}
        ref={register}
        value={categoryObject.category}
      />
      {Object.values(optionsArray).map((distance) => (
        <Distance
          callback={setCategoryFunc}
          distance={distance}
          eventDate={watch("eventDate")}
          categoryObject={categoryObject}
          gender={watch("gender")}
          birth={watch("birth")}
        />
      ))}
    </div>
  );
}
