import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
  },
});

export default function Category(props) {
  const {
    distance,
    category,
    gender,
    birth,
    eventDate,
    callback,
    categoryObject,
  } = props;
  const classes = useStyles();

  if (gender === undefined || birth === undefined) {
    return "Selecciona el genero y fecha de nacimiento para continuar";
  }

  console.log(categoryObject);

  function handleClick(category) {
    callback({
      distance: distance,
      gender: gender,
      category: category,
    });
  }

  return (
    <div>
      <Button
        variant={
          categoryObject.category === category.codigo &&
          categoryObject.distance === distance
            ? "contained"
            : "outlined"
        }
        size="large"
        color="primary"
        style={{ margin: "5px", marginLeft: "20px", width: "100%" }}
        onClick={() => handleClick(category.codigo)}
      >
        {category.nombreCategoria}
      </Button>
    </div>
  );
}
