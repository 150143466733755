import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import defaultAvatar from "../../../../assets/images/default-user.jpg";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  FormLabel,
  IconButton,
  Icon,
  InputAdornment,
  Paper,
  Snackbar,
  List,
  InputLabel,
  Button,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import EditableField from "../../../EditableField";
import { createLocation } from "../../../../data-store/actions/valet-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 25,
  },
  icon: {
    position: "absolute",
    right: 10,
  },
  select: {
    marginBottom: "15px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddLocationModal({ languageObject }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const texts = languageObject.authorizeUserdViews.locations.locationsContainer;
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const errorMessages = languageObject.authorizeUserdViews.users.errorMessages;
  const [locationName, setLocationName] = useState("");
  const [locationDescription, setLocationDescription] = useState("");

  const blankLocation = {
    locationConfig: {
      locationPhoto: false,
      adress: "",
      lat: 0,
      name: "",
      startShift: "00:00:00",
      endShift: "23:59:59",
      receipts: {
        card: false,
        print: false,
        printConfig: {
          business: "",
          web: "",
        },
      },
      payment: {
        rateConfig: {
          rate_1: {
            steps: {
              step_1: {
                price: 0,
                timeLessthan: 1,
                timeUnit: "hour",
              },
            },
            timeUnit: "hour",
            hide: false,
            name: "step 1",
            rateID: "rate_1",
            isDefault: true,
          },
        },
        paymentMethods: {
          pos: {
            name: "Terminal punto de venta",
            available: false,
          },
          onlinePayment: {
            available: false,
            name: "Transacción en linea",
          },
          cash: {
            name: "Efectivo",
            available: false,
          },
        },
      },
      lng: 0,
      description: "",
      zones: {
        zone_1: {
          placesNamed: false,
          zoneName: "",
          overPlaces: "",
          zonePlacesNumber: 1,
          priority: 1,
          zonePlaces: {
            place_1: {
              name: "A1",
              priority: 1,
            },
          },
        },
      },
    },
  };

  const openSuccessSnackBar = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccessSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const openFailureSnackBar = () => {
    setOpenFailure(true);
  };
  const handleCloseFailureSnackBar = (event, reason) => {
    setOpenFailure(false);
  };

  const submitLocation = (values) => {
    const { name, description } = values;
    console.log(`creating a new location - ${JSON.stringify(values)}`);
    let newLocation = { ...blankLocation };
    newLocation.locationConfig.name = name;
    newLocation.locationConfig.description = description;
    console.log("newLocation -", newLocation);
    // DISPATCH to create new location here
    dispatch(createLocation(newLocation));

    const success = true;
    if (success) {
      openSuccessSnackBar();
    } else {
      openFailureSnackBar();
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={10}
      >
        <form onSubmit={handleSubmit(submitLocation)} style={{ width: "100%" }}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                fontSize: "1.5rem",
                fontWeight: 300,
                padding: 10,
              }}
            >
              {texts.locationName}:{" "}
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={errors.name ? texts.locationNameError : null}
              inputProps={{ style: { borderBottom: "none", marginBottom: 0 } }}
              error={Boolean(errors.name)}
              fullWidth
              inputRef={register({ required: true })}
              name="name"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                fontSize: "1.5rem",
                fontWeight: 300,
                padding: 10,
              }}
            >
              {texts.locationDescription}:{" "}
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={
                errors.description ? texts.locationDescriptionError : null
              }
              inputProps={{ style: { borderBottom: "none", marginBottom: 0 } }}
              error={Boolean(errors.description)}
              fullWidth
              inputRef={register({ required: true })}
              name="description"
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", margin: 20 }}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              {texts.add}
            </Button>
          </Grid>
        </form>
      </Grid>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccessSnackBar}
      >
        <Alert onClose={handleCloseSuccessSnackBar} severity="success">
          {errorMessages.saveSuccess}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFailure}
        autoHideDuration={6000}
        onClose={handleCloseFailureSnackBar}
      >
        <Alert onClose={handleCloseFailureSnackBar} severity="success">
          {errorMessages.saveFailure}
        </Alert>
      </Snackbar>
    </div>
  );
}
