import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  IconButton,
  Card,
  Grid,
  Paper,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Snackbar,
  Icon,
  FormLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import PaymentMethodSwitch from "./PaymentMethodSwitch";
import EditableField from "../../../../../EditableField/EditableField";
import {
  updateLocationAtribute,
  updateLocationAtributeReplace,
} from "../../../../../../data-store/actions/valet-actions";
import RateStep from "./RateStep";
import RateConfigContainer from "./RateConfigContainer";
import ShopItems from "./ShopItems";
import AdditionalItems from "./AdditionalItems";
import FormRender from "../../../../../Fields/FormRender";
import { makeidKeyCard } from "../../../../../../services/utils";

const useStyles = makeStyles({
  form: {
    margin: 20,
    padding: 10,
    width: "100%",
    heigth: "auto",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  text: {
    marginBottom: 20,
  },
  detailGrid: {
    margin: "60px",
  },
});

export default function EditReceiptToPrintItem(props) {
  const dispatch = useDispatch();

  const { index, item, languageObject, locationId, type } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const classes = useStyles();
  const businessId = useSelector((state) => state.valet.businessId);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);

  const onSubmitPrinterConfig = (values) => {
    const changeObject = values;
    console.log("update printer config...", changeObject);

    onSubmit({
      receipts: {
        printConfig: { [type]: { [index]: changeObject } },
      },
    });
  };

  const onSubmit = (values) => {
    const success = true;
    const changeObject = values;
    console.log(values);
    dispatch(updateLocationAtribute(locationId, changeObject));
    if (success) {
      openSuccessSnackBar();
    } else {
      openFailureSnackBar();
    }
  };

  const openSuccessSnackBar = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccessSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const openFailureSnackBar = () => {
    setOpenFailure(true);
  };
  const handleCloseFailureSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      className={classes.detailGrid}
    >
      <Grid xs={8}>
        <Typography className={classes.text} variant={"h5"} component="h2">
          {texts.receiptConfigTitle}
        </Typography>
      </Grid>
      <Grid xs={8}>
        <FormRender
          callback={onSubmitPrinterConfig}
          formObject={{
            type: {
              label: texts.type,
              errorMessage: "",
              fieldName: "type",
              type: "select",
              defaultValue: item.type || "text",
              required: true,
              regex: "",
              textSize: "h6",
              optionsArray: [
                {
                  value: "text",
                  label: "Text",
                },
                {
                  value: "QR",
                  label: "Mostrar QR",
                },
                {
                  value: "card",
                  label: "Card ID",
                },
                {
                  value: "key",
                  label: "Key ID",
                },
                {
                  value: "zone",
                  label: "Zone",
                },
                {
                  value: "place",
                  label: "Place",
                },
                {
                  value: "plate/model",
                  label: "Plate / Model",
                },
                {
                  value: "brand",
                  label: "Brand",
                },
                {
                  value: "color",
                  label: "Color",
                },
                {
                  value: "gate",
                  label: "Gate",
                },
                {
                  value: "date",
                  label: "Date",
                },
                {
                  value: "datetime",
                  label: "DateTime",
                },
                {
                  value: "rate",
                  label: "Rate",
                },
                {
                  value: "rateID",
                  label: "Rate ID",
                },
                {
                  value: "rateIdCustom",
                  label: "Rate ID Custom",
                },
                {
                  value: "CAME-PARKARE-Datetime",
                  label: "CAME-PARKARE-Datetime (para qr software CAME)",
                },
                {
                  value: "CAME-PARKARE-Seconds",
                  label: "CAME-PARKARE-Seconds (para qr software CAME)",
                },
                {
                  value: "CAME-PARKARE-Date",
                  label: "CAME-PARKARE-Date (para qr software CAME)",
                },
              ],
            },
            value: {
              label: texts.value,
              errorMessage: "",
              fieldName: "value",
              type: "text",
              defaultValue: item.value || "",
              required: false,
              regex: "",
              textSize: "h6",
            },
            visible: {
              label: texts.visible,
              errorMessage: "",
              fieldName: "visible",
              type: "switch",
              defaultValue: item.visible || true,
              required: true,
              regex: "",
              textSize: "h6",
            },
          }}
          submitButtonText={"Guardar"}
        />
      </Grid>
    </Grid>
  );
}
