import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTotalAmount } from "./PaymentUtils";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import ValidationsVehicleDetail from "./ValidationsVehicleDetail";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function ValidationsVehicleTable(props) {
  const { locationId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const userID = useSelector((state) => state.auth.userID);

  useEffect(() => {}, [businessId]);
  const texts = languageObject.authorizeUserdViews.ticketsValidation.table;
  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid className={classes.spacing} item xs={12} sm={12}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{texts.brand}</TableCell>
                <TableCell align="right">{texts.model}</TableCell>
                <TableCell align="right">{texts.color}</TableCell>
                <TableCell align="right">{texts.key}</TableCell>
                <TableCell align="right">{texts.card}</TableCell>
                <TableCell align="right">{texts.charges}</TableCell>
                <TableCell align="right">{texts.pending}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicles &&
                Object.keys(vehicles)
                  .filter((a) => vehicles[a].locationId == locationId)
                  .map((index) => (
                    <TableRow key={index}>
                      <TableCell padding={"default"} component="th" scope="row">
                        <Button variant="contained" color="primary">
                          Liberar
                        </Button>

                        {/* <SimpleDialogDemo buttonText={"Ver"}>
                          <ValidationsVehicleDetail
                            locationId={locationId}
                            vehicle={vehicles[index]}
                          />
                        </SimpleDialogDemo> */}
                      </TableCell>
                      <TableCell padding={"default"} component="th" scope="row">
                        {vehicles[index].brand}
                      </TableCell>
                      <TableCell padding={"default"} align="right">
                        {vehicles[index].model}
                      </TableCell>
                      <TableCell padding={"default"} align="right">
                        {vehicles[index].color}
                      </TableCell>
                      <TableCell padding={"default"} align="right">
                        {vehicles[index].key}
                      </TableCell>
                      <TableCell padding={"default"} align="right">
                        {vehicles[index].card}
                      </TableCell>
                      <TableCell padding={"default"} align="right">
                        {
                          useTotalAmount(
                            businessLocations[locationId].locationConfig.payment
                              .rateConfig,
                            vehicles[index]
                          ).charge
                        }
                      </TableCell>
                      <TableCell padding={"default"} align="right">
                        {
                          useTotalAmount(
                            businessLocations[locationId].locationConfig.payment
                              .rateConfig,
                            vehicles[index]
                          ).pendingPay
                        }
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
