import * as types from "./action-types";
import {
  catalogue,
  business,
  locations,
  locationsList,
  businessInfo,
  vehicles,
  activeUsers,
  chat,
  firestore,
} from "../../firebase/firebasejs";
import store from "../configureStore";

let refChat;
let refChatFrom;

//##########################################################################
export function getChatData(businessId, userID) {
  console.log(userID);

  console.log("getChat", userID);
  return (dispatch) => {
    refChat = firestore
      .collection(business)
      .doc(businessId)
      .collection(chat)
      //.where('to', '==', userID)
      .where("to", "array-contains", userID);

    refChat.onSnapshot(
      (snapshot) => {
        if (snapshot && userID) {
          snapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
              console.log("AAAA", change.doc.data());
              dispatch({
                type: types.CHATDATA_ADD,
                chatItem: change.doc.data(),
                chatItemId: change.doc.id,
                userID: userID,
              });
            }
            if (change.type === "modified") {
              dispatch({
                type: types.CHATDATA_CHANGE,
                chatItem: change.doc.data(),
                chatItemId: change.doc.id,
                userID: userID,
              });
            }
            if (change.type === "removed") {
              dispatch({
                type: types.CHATDATA_REMOVE,
                chatItem: change.doc.data(),
                chatItemId: change.doc.id,
                userID: userID,
              });
            }
          });
        }
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
    refChatFrom = firestore
      .collection(business)
      .doc(businessId)
      .collection(chat)
      .where("from", "==", userID);

    refChatFrom.onSnapshot((snapshot) => {
      if (snapshot && userID) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            dispatch({
              type: types.CHATDATA_ADD,
              chatItem: change.doc.data(),
              chatItemId: change.doc.id,
              userID: userID,
            });
          }
          if (change.type === "modified") {
            dispatch({
              type: types.CHATDATA_CHANGE,
              chatItem: change.doc.data(),
              chatItemId: change.doc.id,
              userID: userID,
            });
          }
          if (change.type === "removed") {
            dispatch({
              type: types.CHATDATA_REMOVE,
              chatItem: change.doc.data(),
              chatItemId: change.doc.id,
              userID: userID,
            });
          }
        });
      }
    });
  };
}
// #######################################################################
export function sendMessage(message) {
  const businessId = store.getState().valet.businessId;

  return (dispatch) => {
    var id = Date.now() + "-" + Math.round(Math.random() * 100);

    var chatRef = firestore
      .collection(business)
      .doc(businessId)
      .collection(chat)
      .doc(id);

    return chatRef.set(message).then(() => {
      console.log("mensaje enviado");
      return true;
    });
  };
}
//##########################################################################
export function setCurrentChat(chat = {}) {
  console.log("setCurrentChat");

  return (dispatch) => {
    dispatch({
      type: types.CURRENT_CHAT,
      currentChat: chat,
    });
  };
}
//##########################################################################
export function stopListerChat() {
  console.log("stop listener");

  if (typeof refChat === "function") {
    refChat();
  }
  if (typeof refChatFrom === "function") {
    refChatFrom();
  }
}
//##########################################################################
