import * as types from "../actions/action-types";

const initialState = [];
export default function valetReducer(state = initialState, action) {
  switch (action.type) {
    case types.CATALOGUE:
      return Object.assign({}, state, {
        catalogue: action.catalogue,
      });
    case types.CURRENTBUSINESS:
      return Object.assign({}, state, {
        businessId: action.businessId,
        businessObject: action.businessObject,
      });
    case types.BUSINESSUSERSLIST:
      return Object.assign({}, state, {
        businessUsers: action.businessUsers,
      });
    case types.BUSINESSLOCATIONS:
      return Object.assign({}, state, {
        businessLocations: action.businessLocations,
        businessLocationsReady: action.businessLocationsReady,
      });
    case types.ACTIVEUSERSDATA_ADD:
      var activeUserId = action.activeUserId;
      return Object.assign({}, state, {
        activeUsers: Object.assign({}, state.activeUsers, {
          [activeUserId]: action.activeUser,
        }),
      });
    case types.ACTIVEUSERSDATA_CHANGE:
      var activeUserId = action.activeUserId;
      return Object.assign({}, state, {
        activeUsers: Object.assign({}, state.activeUsers, {
          [activeUserId]: action.activeUser,
        }),
      });
    case types.ACTIVEUSERSDATA_REMOVE:
      var activeUserId = action.activeUserId;
      var activeUserNew = { ...state.activeUsers };
      delete activeUserNew[activeUserId];
      return Object.assign({}, state, {
        activeUsers: activeUserNew,
      });
    case types.UPLOADING_PHOTOS:
      return Object.assign({}, state, {
        uploadingPhotos: action.uploadingPhotos,
        uploadingPhotosProgress: action.uploadingPhotosProgress,
      });
    case types.GLOBAL_BUSINESS_LIST:
      return Object.assign({}, state, {
        businessList: action.businessList,
      });

    default:
      return state;
  }
}
