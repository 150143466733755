import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import logo from "../src/assets/logos/Frame.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Loading(match) {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#1D2242",
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <Grid
          item
          style={{ width: "auto", margin: "50px", marginTop: "15%" }}
          alignItems="center"
          alignContent="center"
        >
          <img src={logo} alt="smart Valet" height={150} />
        </Grid>
        <CircularProgress color="secondary" />
      </Grid>
    </div>
  );
}
