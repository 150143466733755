import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CreateAccount from "./components/Authentication/CreateAccount";
import Login from "./components/Authentication/Login";
import PasswordRestore from "./components/Authentication/PasswordRestore";

import Home from "./components/pages/Home";
import Tech from "./components/pages/PublicPages/Tech";
import About from "./components/pages/PublicPages/About";
import Contact from "./components/pages/PublicPages/Contact";

import LiveView from "./components/pages/AuthorizedUsers/LiveView/LiveViewContainer";
import VehicleRequest from "./components/pages/AuthorizedUsers/VehicleRequest/VehicleRequestContainer";
import Reports from "./components/pages/AuthorizedUsers/Reports/ReportsContainer";
import Locations from "./components/pages/AuthorizedUsers/Locations/LocationsContainer";
import LocationDetailContainer from "./components/pages/AuthorizedUsers/Locations/LocationDetail/LocationDetailContainer";
import ValetContainer from "./components/pages/AuthorizedUsers/ValetView/ValetContainer";
import BusinessUsers from "./components/pages/AuthorizedUsers/BusinessUsers/BusinessUsersContainer";
import TicketsValidation from "./components/pages/AuthorizedUsers/TicketsValidation/TicketsValidationContainer";
import Chat from "./components/pages/AuthorizedUsers/Chat/ChatContainer";
import PayRollContainer from "./components/pages/AuthorizedUsers/PayRoll/PayRollContainer";
import SVAuthorizedusers from "./components/pages/SVAuthorizedUsers/SVAuthorizedusers";

import {
  getBusinessLocationsConfig,
  getBusinessUsers,
} from "./data-store/actions/valet-actions";
import Loading from "./Loading";
import ValetLocations from "./components/pages/AuthorizedUsers/ValetView/ValetLocations";

function Root() {
  const dispatch = useDispatch();
  const businessId = useSelector((state) => state.valet.businessId);
  const userAccount = useSelector((state) => state.auth.userAccount);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessLocationsReady = useSelector(
    (state) => state.valet.businessLocationsReady
  );
  const businessUsers = useSelector((state) => state.valet.businessUsers);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      !businessLocationsReady &&
      businessId !== null &&
      businessId &&
      userAccount !== undefined &&
      userAccount !== null
    ) {
      console.log("getBusinessLocationsConfig");
      dispatch(getBusinessLocationsConfig());
    }
    if (
      !businessUsers &&
      businessId !== null &&
      businessId &&
      userAccount !== undefined &&
      userAccount !== null
    ) {
      dispatch(getBusinessUsers());
    }
    setTimeout(() => setLoading(false), 5000);
  }, [businessId, userAccount]);

  if (loading) {
    return <Loading />;
  }
  return (
    <Switch>
      <Route exact path="/createaccount" component={CreateAccount} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/password-restore" component={PasswordRestore} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/tech" component={Tech} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contact} />

      <Route exact path="/liveview" component={LiveView} />
      <Route exact path="/reports" component={Reports} />
      <Route exact path="/vehicleRequest/" component={VehicleRequest} />
      <Route exact path="/locations" component={Locations} />
      <Route
        exact
        path="/locations/:locationId/locationdetail"
        component={LocationDetailContainer}
      />
      <Route exact path="/users" component={BusinessUsers} />
      <Route exact path="/valet" component={ValetLocations} />
      <Route exact path="/valet/:locationId" component={ValetContainer} />
      <Route exact path="/chat" component={Chat} />
      <Route exact path="/ticketsValidation" component={TicketsValidation} />
      <Route exact path="/payroll" component={PayRollContainer} />
      <Route exact path="/sv" component={SVAuthorizedusers} />

      <Route exact path="/" component={Home} />
    </Switch>
  );
}

export default Root;
