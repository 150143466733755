import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import AllEmployeesBadges from "./AllEmployeesBadges";

const useStyles = makeStyles({
  root: {
    width: 700,
    height: 150,
    margin: 10,
    backgroundColor: "#1d2242",
    color: "white",
  },
  media: {
    height: 140,
  },
  content: {
    height: 160,
  },
});

export default function AllEmployeesCard(props) {
  const { languageObject, businessUsers, quincenaDates, userListArray } = props;
  const texts = languageObject.authorizeUserdViews.payRoll;
  console.log("PayRollContainer", businessUsers);

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h5" align="center" component="h2">
          {texts.menuName}
        </Typography>
        <AllEmployeesBadges
          quincenaDates={quincenaDates}
          userListArray={userListArray}
          languageObject={languageObject}
        />
      </CardContent>
    </Card>
  );
}
