import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../components/Fields/FormRender";
import { createBusiness } from "../../../data-store/actions/valet-actions";
import formsConfig from "./formsConfig.json";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CreateBusiness() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.auth.userID);
  const createEventObject = formsConfig.business;

  const classes = useStyles();

  function onSubmit(data) {
    console.log(data);

    dispatch(
      createBusiness(userID, {
        businessInfo: {
          ...data,
          userCreate: userID,
          date: Math.round(Date.now() / 1000),
        },
      })
    );
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Crea un nuevo Negocio
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <FormRender
              callback={onSubmit}
              formObject={createEventObject}
              submitButtonText={"Crear Negocio"}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Crear un Negocio"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
