import React from "react";
import ReactDOM from "react-dom";

import store from "./data-store/configureStore";

import $ from "jquery";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

$(document).ready(function () {
  ReactDOM.render(<App store={store} />, document.getElementById("root"));
  //registerServiceWorker();
});
