import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import PlaceDetailEditable from "./PlaceDetailEditable";
import SimpleDialogDemo from "../../../../../Modals/SimpleDialog";
import VehicleDetailContainer from "../../../VehicleDetail/VehicleDetailContainer";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import { checkPropTypes } from "prop-types";
import { Launch } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 330,
    margin: 10,
  },
  media: {
    height: 70,
  },
  content: {
    height: 220,
  },
  place: {
    margin: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
    height: 100,
  },
  placeOcupade: {
    margin: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
    height: 100,
    backgroundColor: "#212121",
    color: "#FFFFFF",
  },
  overPlace: {
    margin: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
    height: 100,
    backgroundColor: "maroon",
    color: "#FFFFFF",
  },
  zone: {
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
    backgroundColor: "#e0e0e0",
    margin: 20,
  },
  paper: {
    padding: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
  },
});
// TODO: agregar al objeto del place ocupado el siguiente objeto
/* {
  brand: "toyota",
  model:"corolla",
  placa:"aaa-123",
  color:"blue",
  notes:"raya en el lado derecho",
  card:"A234",
  key:"A3w4324",
  userPark:"asdasasd"
}
*/

export default function PlaceDetail(props) {
  const {
    zone,
    placeName,
    placeObject,
    placeKey,
    languageObject,
    callBackClickPlace,
    addPlace,
    editable,
    overPlace,
    locationId,
    zonesData,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const classes = useStyles();

  let place;
  if (placeObject != undefined) {
    let status = "Ocupado";
    const colorText = languageObject.generalData.colors[placeObject.color];
    return (place = (
      <Paper className={overPlace ? classes.overPlace : classes.placeOcupade}>
        <SimpleDialogDemo fullScreen={false} buttonText={placeObject.card}>
          <VehicleDetailContainer vehicle={placeObject} />
        </SimpleDialogDemo>

        <Typography variant="body2" component="span">
          {placeName.name}
          <br />
          {placeName && placeName.priority
            ? `PR : ${placeName.priority}`
            : null}
          <b>{placeObject.brand}</b>
        </Typography>
      </Paper>
    ));
  } else if (addPlace) {
    return (
      <Paper className={classes.place}>
        <Typography variant="body2" color="#FFFFFF" component="p">
          <br />
          {texts.add}
        </Typography>
        <AddIcon />
      </Paper>
    );
  } else if (editable) {
    return (
      <PlaceDetailEditable
        zone={zone}
        locationId={locationId}
        classes={classes}
        placeObject={placeObject}
        placeName={placeName}
        languageObject={languageObject}
        zonesData={zonesData}
        placeKey={placeKey}
      />
    );
  } else {
    return (
      <Paper className={classes.place}>
        <Typography variant="body2" component="p">
          {placeName.name}
          <br />
          {placeName && placeName.priority
            ? `PR : ${placeName.priority}`
            : null}
          <br />
          {texts.placeEmpty}
          <br />
        </Typography>
      </Paper>
    );
  }
}
