import React from 'react';
import { connect} from 'react-redux'
import AppBarCustom from './AppBarCustom'
import { setLanguage } from '../../data-store/actions/auth-actions';
import { setCurrentBusiness } from '../../data-store/actions/valet-actions';


const AppBarContainerView = ({children, sendNewLanguage, language, languageObject, userAccount, setBusiness}) => {

	return (
    <AppBarCustom children={children} 
                  sendNewLanguage={sendNewLanguage} 
                  language={language}
                  languageObject={languageObject}
				  userAccount={userAccount}
				  setBusiness={setBusiness}
				  />
	)
}



const mapDispatchToProps = (dispatch) => {
	return {
	    sendNewLanguage: (language) => {
			if(language == "es"){
				dispatch(setLanguage("en"))
			}else{
				dispatch(setLanguage("es"))
			}
		},
		setBusiness: (BusinessID) => {
			dispatch(setCurrentBusiness(BusinessID))
		}
	}

}


const mapStateToProps = (state) => {
    return {
      language : state.auth.language,
	  languageObject : state.auth.languageObject,
	  userAccount : state.auth.userAccount
    }
  }


const AppBarContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(AppBarContainerView);

export default AppBarContainer;
