import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { IconButton, TextField } from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateLocationAtribute } from "../../../../../../data-store/actions/valet-actions";

export default function PlaceDetailEditable(props) {
  const {
    classes,
    placeObject,
    placeName,
    placeKey,
    languageObject,
    changePlace,
    locationId,
    zone,
    zonesData,
  } = props;
  const { register, handleSubmit, errors } = useForm();
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  console.log();
  function removePlace(e, placeObject) {
    window.confirm(texts.alert.removePlaceTitle) &&
      console.log("remove place", placeObject);
    e.stopPropagation();
    if (placeObject == undefined) {
      removePlaceConfirmed();
    }
  }

  function removePlaceConfirmed() {
    console.log("addPlace zoneKey", zone);
    console.log("addPlace zonesData", zonesData);

    if (zonesData.placesNamed) {
      const hide = {
        hide: true,
      };
      const places = parseInt(zonesData.zonePlacesNumber) - 1;
      dispatch(
        updateLocationAtribute(locationId, {
          zones: {
            [zone]: {
              ...zonesData,
              zonePlacesNumber: places,
              zonePlaces: { ...zonesData.zonePlaces, [placeKey]: hide },
            },
          },
        })
      );
      console.log("Hidding place", placeKey);
    } else {
      const places = parseInt(zonesData.zonePlacesNumber) - 1;
      dispatch(
        updateLocationAtribute(locationId, {
          zones: {
            [zone]: {
              zonePlacesNumber: places,
            },
          },
        })
      );
      console.log("hidding place");
    }
  }
  function editPlace(e, placeObject) {
    setEditMode(true);
    e.stopPropagation();
    console.log("edit place");
  }

  function cancelEdit() {
    setEditMode(false);
  }

  const saveEdit = (data) => {
    console.log("old Place", placeObject);
    console.log("new Place", data);
    const newPlace = {
      name: data.placeName,
      priority: data.priority,
    };
    console.log(`locationId: ${locationId}`);
    console.log(`zone: ${zone}`);
    console.log(`zonesData: ${JSON.stringify(zonesData)}`);
    console.log(
      `zonesData.zonePlaces: ${JSON.stringify(zonesData.zonePlaces)}`
    );
    // console.log('zonesData[zone].zonePlaces', zonesData[zone].zonePlaces);
    console.log("placeKey", placeKey);
    console.log(`newPlace: ${newPlace}`);
    let changeObject = {
      zones: {
        [zone]: {
          ...zonesData,
          zonePlaces: { ...zonesData.zonePlaces, [placeKey]: newPlace },
        },
      },
    };

    dispatch(updateLocationAtribute(locationId, changeObject));
    console.log("Adding New Place", newPlace);
    setEditMode(false);
  };

  return editMode ? (
    <Paper className={classes.place} style={{ height: "150px" }}>
      <form
        className="edit-place-form"
        noValidate
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          style={{ width: "70%" }}
          label="Name"
          defaultValue={placeName.name}
          inputProps={{
            style: { borderBottom: "none", marginBottom: 0, height: "2rem" },
          }}
          error={Boolean(errors.placeName)}
          inputRef={register({ required: true })}
          name="placeName"
        />
        <TextField
          style={{ width: "70%" }}
          label="Priority"
          defaultValue={placeName.priority}
          inputProps={{
            style: { borderBottom: "none", marginBottom: 0, height: "2rem" },
          }}
          error={Boolean(errors.priority)}
          inputRef={register({ required: true })}
          name="priority"
        />
      </form>
      <div className="input-row" style={{ justifyContent: "space-evenly" }}>
        <IconButton className="button-edit" onClick={handleSubmit(saveEdit)}>
          <SaveIcon fontSize="small" />
        </IconButton>
        <IconButton className="button-remove" onClick={() => cancelEdit()}>
          <RemoveCircleOutlineIcon fontSize="small" />
        </IconButton>
      </div>
    </Paper>
  ) : (
    <Paper className={classes.place}>
      <Typography variant="body2" color="#FFFFFF" component="p">
        {placeName.name}
        <br />
        {placeName && placeName.priority ? `PR : ${placeName.priority}` : null}
        <br />
        {texts.placeEmpty}
        <br />
      </Typography>

      <div className="input-row" style={{ justifyContent: "space-evenly" }}>
        {zonesData.placesNamed && (
          <IconButton
            className="button-edit"
            onClick={(e) => editPlace(e, placeObject)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        <IconButton className="button-remove" onClick={(e) => removePlace(e)}>
          <RemoveCircleOutlineIcon fontSize="small" />
        </IconButton>
      </div>
    </Paper>
  );
}
