import React from "react";
import { connect } from "react-redux";
import { Button, Input, Icon, Row } from "react-materialize";

import {
  fechahora,
  formatfechaDDMMYYYFromYYYYMMDD,
  fechaDiagonal,
} from "../../services/utils";
import M from "materialize-css";
import { auth, db, users, firestore } from "../../firebase/firebasejs";
import { Toast } from "react-materialize";

class CreateAccountView extends React.Component {
  constructor(props) {
    super(props);

    var ahora = fechahora();
    this.state = {
      name: "",
      lastname: "",
      email: "",
      registryDate: ahora,
      password: "",
      phone: "",
      passwordConfirm: "",
      registryFrom: "web",
      cuentaCreada: false,
    };
  }

  getValue(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  }

  validatePassword(e) {
    if (this.state.password != this.state.passwordConfirm) {
      M.toast(
        this.props.languageObject.account.CreateAcount.passwordConfirmError,
        5000
      );
      return;
    }
  }
  cuentaCreada() {}
  sendForm(e) {
    var today = fechahora();
    e.preventDefault();
    return auth
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((userAdd) => {
        console.log(userAdd);

        // var user = auth().currentUser;
        // user.updateProfile({
        // displayName: this.state.name + " " + this.state.lastname,
        // photoURL: ""})

        var userData = {
          lastname: this.state.lastname,
          email: this.state.email.toLowerCase(),
          name: this.state.name,
          phone: this.state.phone,
          registryDate: today,
          registryFrom: "web",
        };
        var newUserRef = firestore.collection(users).doc(userAdd.user.uid);
        console.log(userData);
        setTimeout(function () {
          newUserRef.set(userData);
        }, 500);
        this.setState({ cuentaCreada: true });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode);

        if (errorCode == "auth/email-already-in-use") {
          M.toast(
            this.props.languageObject.account.CreateAccount.emailUsed,
            7000
          );
        } else if (errorCode == "auth/invalid-email") {
          M.toast(
            this.props.languageObject.account.CreateAccount.emailError,
            7000
          );
        } else {
          M.toast(errorMessage, 7000);
        }
        // ...
      });
  }

  render() {
    let strings = this.props.languageObject.account.CreateAcount;
    let required = this.props.languageObject.generalData.required;

    var element = "";
    if (this.state.cuentaCreada) {
      element = (
        <Row className="red white-text z-depth-5">
          <div className="col s10 offset-s1">
            <h3>{strings.registryConfirmation}</h3>
          </div>
        </Row>
      );
    } else {
      element = (
        <div className="row" id="RegistroForm">
          <div className="col s12 m6 offset-m3 l6 offset-l3 z-depth-5">
            <h5> {strings.title}</h5>
            <form className="col s12">
              <Row>
                <Input
                  name="name"
                  onChange={(e) => this.getValue(e)}
                  value={this.state.nombres}
                  placeholder={strings.nameInput}
                  s={12}
                  label={strings.nameInput}
                  validate={true}
                  error={required}
                />
                <Input
                  name="lastname"
                  onChange={(e) => this.getValue(e)}
                  value={this.state.apellidos}
                  placeholder={strings.lastnameInput}
                  s={12}
                  label={strings.lastnameInput}
                  validate={true}
                  error={required}
                />
              </Row>
              <Row>
                <Input
                  name="phone"
                  onChange={(e) => this.getValue(e)}
                  type="text"
                  label={strings.phoneInput}
                  error={strings.phoneError}
                  validate
                  s={12}
                />
              </Row>
              <Row>
                <Input
                  name="email"
                  onChange={(e) => this.getValue(e)}
                  type="email"
                  label={strings.emailInput}
                  error={strings.emailError}
                  validate
                  s={12}
                />
              </Row>
              <Row>
                <Input
                  name="password"
                  onChange={(e) => this.getValue(e)}
                  type="password"
                  label={strings.passwordInput}
                  error="Mínimo 6 caracteres"
                  validate
                  s={12}
                />
              </Row>
              <Row>
                <Input
                  name="passwordConfirm"
                  onBlur={(e) => this.validatePassword(e)}
                  onChange={(e) => this.getValue(e)}
                  type="password"
                  label={strings.passwordConfirmInput}
                  error="Mínimo 6 caracteres"
                  validate
                  s={12}
                />
              </Row>
              <Row>
                <Button onClick={(e) => this.sendForm(e)} waves="light">
                  {strings.logInButtom}
                  <Icon left>send</Icon>
                </Button>
              </Row>
            </form>
          </div>
        </div>
      );
    }
    return element;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    languageObject: state.auth.languageObject,
  };
};

const CreateAccount = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccountView);

export default CreateAccount;
