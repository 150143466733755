import React from 'react';
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { dateToYYYYMMDD } from '../Reports/reportsUtils';

const useStyles = makeStyles({
  button: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 3,
    marginRight: 3
  },
  card: {
    margin: 10
  },
  media: {
    height: 140,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    minWidth: 100,
  }
});

export default function ShiftCardItem(props) {
  const { languageObject, shifts, hourlyWage, onAddShift, onEditShift, shiftKey, businessLocations } = props;
  const texts = languageObject.authorizeUserdViews.users.payRoll;
  const classes = useStyles();
  const { handleSubmit, errors, control, register } = useForm();

  let { startTime, endTime, date, shiftHourlyPay, location } = shifts[shiftKey];
  function onSubmit(value) {
    const thisShiftKey = shiftKey;
    handleSubmitShift(value, thisShiftKey);
  }

  function handleSubmitShift(value, shiftKey) {
    console.log('shiftCard OnSubmit value', value);
    console.log('shiftKey', shiftKey);
    let newShift = { [shiftKey]: value }
    let newShifts = { ['shifts']: { ...shifts, ...newShift } };
    onEditShift(newShifts);
    console.log('submit shift change', newShifts);
  }

  function changeDate(event) {
    console.log('changing shift date to ', event.target.value)
  }

  function changeStartTime(event) {
    console.log('changing shift startTime to ', event.target.value)
  }

  function changeEndTime(event) {
    console.log('changing shift endTime to ', event.target.value)
  }

  function changeHourlyWage(event) {
    console.log('changing shift hourly to ', event.target.value)
  }

  const today = new Date();
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  let thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  let sixtyDaysAgo = new Date(today);
  sixtyDaysAgo.setDate(today.getDate() - 60);

  let yearAgo = new Date(today);
  yearAgo.setDate(today.getDate() - 365);

  console.log('shift', JSON.stringify(shifts[shiftKey]));
  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <CardContent>
          <Grid item xs={12}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={6} md={3}>
                <FormControl className={classes.formFields}>
                  <TextField
                    name="date"
                    inputRef={register}
                    rules={{ required: "this is required" }}
                    control={control}
                    label={texts.date}
                    type="date"
                    defaultValue={date}
                    className={classes.textField}
                    onChange={(value) => changeDate(value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: dateToYYYYMMDD(yearAgo),
                      max: dateToYYYYMMDD(today),
                      style: { borderBottom: 'none', marginBottom: 0 }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={3}>
                <FormControl className={classes.formFields}>
                  <InputLabel>
                    {texts.location}
                  </InputLabel>
                  <Controller
                    name="location"
                    rules={{ required: "this is required" }}
                    control={control}
                    defaultValue={location}
                    as={
                      <Select>
                        {businessLocations
                          ? Object.keys(businessLocations)
                            .map((locationKey) => <MenuItem
                              value={locationKey}>
                              {businessLocations[locationKey].locationConfig.name}
                            </MenuItem>
                            )
                          : null
                        }
                      </Select>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={2}>
                <FormControl className={classes.formFields}>
                  <TextField
                    name="startTime"
                    inputRef={register}
                    rules={{ required: "this is required" }}
                    control={control}
                    defaultValue={startTime}
                    label={texts.startTime}
                    type="time"
                    className={classes.textField}
                    onChange={(value) => changeStartTime(value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: { borderBottom: 'none', marginBottom: 0 }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2}>
                <FormControl className={classes.formFields}>
                  <TextField
                    name="endTime"
                    inputRef={register}
                    rules={{ required: "this is required" }}
                    control={control}
                    defaultValue={endTime}
                    label={texts.endTime}
                    type="time"
                    className={classes.textField}
                    onChange={(value) => changeEndTime(value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: { borderBottom: 'none', marginBottom: 0 }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={3}>
                <FormControl className={classes.formFields}>
                  <TextField
                    name="shiftHourlyPay"
                    inputRef={register}
                    rules={{ required: "this is required" }}
                    control={control}
                    defaultValue=""
                    label={texts.hourlyWage}
                    type="number"
                    defaultValue={shiftHourlyPay || hourlyWage}
                    className={classes.textField}
                    onChange={(value) => changeHourlyWage(value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0,
                      max: 1000,
                      style: { borderBottom: 'none', marginBottom: 0 }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} className={classes.buttonContainer}>
                <Button className={classes.button} type="submit" variant="contained" color="primary">{texts.saveChanges}</Button>
                <Button>{texts.deleteShift}</Button>

              </Grid>
            </form>
          </Grid>
        </CardContent>
      </Card >
    </Grid>
  )
}