import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { getBusinessLocationsConfig } from "../../../../data-store/actions/valet-actions";
import SimpleDialogLounge from "./SimpleDialogLounge";
import LiveViewDetailLounge from "./LiveViewDetailLounge";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function LiveViewContainer() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const userID = useSelector((state) => state.auth.userID);

  useEffect(() => {
    //dispatch(getBusinessLocationsConfig());
  }, [businessId, userID]);

  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid className={classes.spacing} item xs={12} md={12}>
        <Typography
          className={classes.text}
          gutterBottom
          variant={"h3"}
          component="h2"
        >
          {languageObject.authorizeUserdViews.liveView.menuName}
        </Typography>
        <Typography
          className={classes.text}
          gutterBottom
          variant={"h6"}
          component="h2"
        >
          {languageObject.authorizeUserdViews.liveView.description}
        </Typography>
      </Grid>
      <Grid className={classes.spacing} item xs={12} sm={12}>
        {businessLocations &&
          Object.keys(businessLocations).map((index) => (
            <div key={index} style={{ margin: "10px" }}>
              {businessLocations[index].authorized && (
                <SimpleDialogLounge
                  fullScreen={true}
                  buttonText={businessLocations[index].locationConfig.name}
                >
                  <LiveViewDetailLounge locationId={index} />
                </SimpleDialogLounge>
              )}
            </div>
          ))}
      </Grid>
    </Grid>
  );
}
