import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import RateStep from "./RateStep";
import { makeStyles } from "@material-ui/core/styles";
import EditableField from "../../../../../EditableField";
import SwitchField from "../../../../../SwichField";
import InputFileButton from "../../../../../Fields/InputFileButton1.1";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    width: "100%",
  },
  currentRate: {
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 20,
    width: "100%",
    display: "flex",
    marginTop: 15,
    marginBottom: 15,
    padding: 15,
  },
  table: {
    width: "80%",
  },
}));

export default function RateImportSteps(props) {
  const { rate, rateKey, onSubmit, languageObject } = props;
  const { timeUnit, name, hide, rateIdCustom } = rate;
  console.warn(`name: ${name}, hide ${hide}, timeUnit ${timeUnit}`);
  const classes = useStyles();
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;

  const [stepsList, setStepsList] = useState({});
  const [message, setMessage] = useState("");
  const [showButtom, setShowButtom] = useState(false);

  function onSubmitStep() {
    // console.log("onSubmitStep newSteps", steps);
    let newRate = { ...rate, steps: stepsList };
    console.log("onSubmitStep newRateConfig", newRate);
    onSubmit({ [rateKey]: newRate });
    setMessage("Steps Updated");
  }

  function onSubmitFile(file) {
    console.log("file File", file);

    String.prototype.splitCSV = function (sep) {
      for (
        var foo = this.split((sep = sep || ";")), x = foo.length - 1, tl;
        x >= 0;
        x--
      ) {
        if (foo[x].replace(/"\s+$/, '"').charAt(foo[x].length - 1) == '"') {
          if (
            (tl = foo[x].replace(/^\s+"/, '"')).length > 1 &&
            tl.charAt(0) == '"'
          ) {
            foo[x] = foo[x].replace(/^\s*"|"\s*$/g, "").replace(/""/g, '"');
          } else if (x) {
            foo.splice(x - 1, 2, [foo[x - 1], foo[x]].join(sep));
          } else foo = foo.shift().split(sep).concat(foo);
        } else foo[x].replace(/""/g, '"');
      }
      return foo;
    };

    if (file) {
      var filename = file.name;
      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      console.log(file.name);
      reader.onload = (evt) => {
        var result = evt.target.result;
        console.log("resultLines", result);
        var resultLines = result.split("\n");

        console.log("resultLines", resultLines);
        var titulos = resultLines[0].splitCSV(";");
        var indexPrice = null;
        var indextimeLessthan = null;
        var stepsNew = {};
        for (var e in titulos) {
          if (titulos[e] == "price" || titulos[e] == "price") {
            indexPrice = e;
          } else if (titulos[e] == "timeLessthan") {
            indextimeLessthan = e;
          }
        }
        if (indexPrice != null && indextimeLessthan != null) {
          for (var f in resultLines) {
            if (f > 0) {
              var datos = resultLines[f].splitCSV(";");
              console.log(datos);
              if (datos[indexPrice] >= 0 && datos[indextimeLessthan] >= 0) {
                stepsNew["step_" + f] = {
                  timeLessthan: datos[indextimeLessthan] / 1,
                  price: datos[indexPrice] / 1,
                };
                console.log(stepsNew);
                //setStepsList(stepsNew);
              } else {
                //setStepsList(stepsNew);
                //setMessage("Formato incorrecto");
                console.log("Wrong format");
              }
            }
          }
          setStepsList(stepsNew);
          setMessage("");
          setShowButtom(true);
        } else {
          setMessage("Incomplete Columns");
          console.log("Incomplete Columns");
        }
      };
      reader.onerror = (evt) => {
        setMessage("Error reading the file");
        console.log("Error reading the file");
      };
    }
  }
  const steps = rate.steps;

  return (
    <Grid container style={{ padding: 20 }}>
      <Grid item style={{ margin: 10 }} xs={12} sm={12} md={12}>
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Import Steps
        </Typography>
      </Grid>
      <Grid item style={{ margin: 20 }} xs={12} sm={12} md={12}>
        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          Add the steps to the file acording to to your unit time set rate
          (hours or minutes) the price for each step should be acumulative, for
          example step 1 - 1 hours, price 1 USD, step 2 - 2 hours, price 1 uds
          plus step 1, total 2 USD.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Button
          color="primary"
          variant="contained"
          href="https://firebasestorage.googleapis.com/v0/b/softicade.appspot.com/o/SamplesFile%2FSmartValet-Import%20Rates%20Example.csv?alt=media&token=462fdf14-e0de-40f2-8702-7cb5e37afcce"
        >
          Download sample file here
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InputFileButton
          type="csv"
          callback={onSubmitFile}
          uploadingPhotos={null}
          uploadingPhotosProgress={null}
          text="Select File to upload"
        />
      </Grid>
      {showButtom && stepsList !== {} && (
        <Grid style={{ margin: 30 }} item xs={12} sm={12} md={12}>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            The imported data will replace all prevous steps of this rate.
          </Typography>
          <Button
            style={{ width: "100%", padding: 20 }}
            onClick={() => onSubmitStep()}
            color="primary"
            variant="contained"
          >
            Save Steps
          </Button>
        </Grid>
      )}

      <Grid item style={{ margin: 10 }} xs={12} sm={12} md={12}>
        <Typography variant="h2" style={{ textAlign: "center" }}>
          {message}{" "}
        </Typography>
      </Grid>
      <Grid item style={{ margin: 10 }} xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Step</TableCell>
                <TableCell align="center">Time Less Than</TableCell>
                <TableCell align="center">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(stepsList).map((key) => (
                <TableRow key={key}>
                  <TableCell align="center" component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="center">
                    {stepsList[key].timeLessthan}
                  </TableCell>
                  <TableCell align="center">{stepsList[key].price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
