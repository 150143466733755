import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import Grid from "@material-ui/core/Grid";
import { Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useVehicleEmptyObject } from "./VehicleAddUtils";
import firebase from "firebase";
import { makeid } from "../../../../../services/utils";
import { useValidateVehicleData } from "./VehicleAddUtils";
import { saveVehicleData } from "../../../../../data-store/actions/vehicle-actions";
import VehicleAddCard from "./VehicleAddCard";
import EditableField from "../../../../EditableField/EditableField";
import M from "materialize-css";
import VehicleScanner from "./VehicleScanner";

export default function VehicleAddContainer(props) {
  const { locationConfig, locationId, languageObject, callback } = props;
  const dispatch = useDispatch();

  const [data, setData] = useState("No result");
  const [rate, setRate] = useState(false);
  const [gate, setGate] = useState(false);

  const [showScanner, setshowScanner] = useState(false);
  const [showNewVehicleDetail, setShowNewVehicleDetail] = useState(false);

  const carsCatalogue = useSelector((state) =>
    state.valet.catalogue != undefined ? state.valet.catalogue.cars : undefined
  );
  const [vehicle, setVehicle] = useState(useVehicleEmptyObject());
  const currentLocation = useSelector((state) => state.valet.currentLocation);
  const businessId = useSelector((state) => state.valet.businessId);
  const userID = useSelector((state) => state.auth.userID);
  const [enableButton, setEnableButton] = useState(true); // ver objeto vacio abajo
  const vehicles = useSelector((state) => state.vehicle.vehicles);

  const rateConfig = locationConfig.payment.rateConfig;
  const gateConfig = locationConfig.payment.gateConfig;

  useEffect(() => {
    if (rateConfig !== null && rateConfig !== undefined) {
      const rateDefault = getDefaultRate(rateConfig);
    }
    if (gateConfig !== null && gateConfig !== undefined) {
      const gateDefault = getDefaultGate(gateConfig);
    }
  }, [locationConfig]);

  function getDefaultGate(gateConfig) {
    const gateDefault = Object.keys(gateConfig).find(
      (gate) => gateConfig[gate].isDefault === true
    );

    Object.keys(gateConfig).map((key) => {
      console.log(gateConfig[key].name);
      console.log(key);
    });
    setGate(gateDefault);
    setVehicle({ ...vehicle, gate: gateDefault });
    return gateDefault;
  }
  function getDefaultRate(rateConfig) {
    const rateDefault = Object.keys(rateConfig).find(
      (rate) => rateConfig[rate].isDefault === true
    );

    Object.keys(rateConfig).map((key) => {
      console.log(rateConfig[key].name);
      console.log(key);
    });
    setRate(rateDefault);
    setVehicle({ ...vehicle, rate: rateDefault });

    return rateDefault;
  }

  console.log("Scan123", businessId, locationId);
  function callbackDataQR(data) {
    console.log("qr", showScanner);

    if (showScanner) {
      if (data.indexOf("card=") >= 0 && data.indexOf("key=") >= 0) {
        const businessIdURL = data.substr(
          data.indexOf("business=") + 9,
          data.indexOf("&location=") - data.indexOf("business=") - 9
        );
        const locationIdURL = data.substr(
          data.indexOf("location=") + 9,
          data.indexOf("&card=") - data.indexOf("location=") - 9
        );
        const cardURL = data.substr(data.indexOf("card=") + 5, 4);
        const key = data.substr(data.indexOf("key=") + 4, 4);

        const vehicleScaned = findCardInList(cardURL);
        console.log("Scan123", vehicleScaned);

        if (vehicleScaned) {
          //Open vehicle modal
          setshowScanner(false);
          setShowNewVehicleDetail(false);
          callback(vehicleScaned);
          //dispatch(setCurrentVehicle(vehicleScaned));
          //dispatch(toogleVehicleDetail(true, true));
        } else {
          setshowScanner(false);

          console.log("Scan123", "NO Registrar Card");
          if (businessIdURL == businessId && locationIdURL == locationId) {
            console.log("Scan123", "Registrar Card", cardURL);
            setVehicle({
              ...vehicle,
              card: cardURL,
              key: key,
              userScan: userID,
            });
            setshowScanner(false);
          } else {
            console.log("tarjeta inválida");
            //setShowMessage(true);
            // setTimeout(() => {
            //   setShowMessage(false);
            // }, 2000);
          }
        }
      } else if (data.indexOf("card=") >= 0) {
        const businessIdURL = data.substr(
          data.indexOf("business=") + 9,
          data.indexOf("&location=") - data.indexOf("business=") - 9
        );
        const locationIdURL = data.substr(
          data.indexOf("location=") + 9,
          data.indexOf("&card=") - data.indexOf("location=") - 9
        );
        const cardURL = data.substr(data.indexOf("card=") + 5, 4);

        const vehicleScaned = findCardInList(cardURL);
        console.log("Scan123", vehicleScaned);

        if (vehicleScaned) {
          //Open vehicle modal
          setshowScanner(false);
          setShowNewVehicleDetail(false);
          callback(vehicleScaned);
          //closeModal();
          //dispatch(setCurrentVehicle(vehicleScaned));
          //dispatch(toogleVehicleDetail(true, true));
        } else {
          if (businessIdURL == businessId && locationIdURL == locationId) {
            setVehicle({
              ...vehicle,
              card: cardURL,
              userScan: userID,
            });
          } else {
            console.log("tarjeta inválida");
            //setShowMessage(true);
            // setTimeout(() => {
            //   setShowMessage(false);
            // }, 2000);
          }
        }
      } else if (data.indexOf("key=") >= 0) {
        const key = data.substr(data.indexOf("key=") + 4, 4);
        const vehicleScaned = findKeyInList(key);
        console.log("Scan123", vehicleScaned);

        if (vehicleScaned) {
          //Open vehicle modal
          //closeModal();
          setshowScanner(false);
          setShowNewVehicleDetail(false);
          callback(vehicleScaned);
          //dispatch(setCurrentVehicle(vehicleScaned));
          //dispatch(toogleVehicleDetail(true, true));
        } else {
          console.log("Scan123", "Registrar Key", key, vehicle);

          setVehicle({
            ...vehicle,
            key: key,
          });
        }
      } else {
        //setShowMessage(true);
        // setTimeout(() => {
        //   setShowMessage(false);
        // }, 2000);
      }
      if (vehicle.card !== "" && vehicle.key !== "") {
        setshowScanner(false);
        setshowScanner(false);
      }
    }
  }
  function findCardInList(card) {
    console.log("############# ", vehicles);
    console.log("findCard");

    if (vehicles !== undefined) {
      const vehicleScanned = Object.values(vehicles).find(
        (vehicle) =>
          vehicle.card === card &&
          vehicle.locationId === locationId &&
          !vehicle.closed
      );
      if (vehicleScanned) {
        console.log("############# ", vehicleScanned);

        return vehicleScanned;
      }
    }
    return false;
  }
  function findKeyInList(key) {
    console.log("findKey");
    if (vehicles !== undefined) {
      const vehicleScanned = Object.values(vehicles).find(
        (vehicle) =>
          vehicle.key === key &&
          vehicle.locationId === locationId &&
          !vehicle.closed
      );
      if (vehicleScanned) {
        return vehicleScanned;
      }
    }
    return false;
  }

  function useRenderItem(item, qrObject, vehicle, locationConfig) {
    const date = vehicle.startTime.toDate();
    console.log("date", date);
    console.log("qrObject", qrObject);
    console.log("vehicle", vehicle);
    //return vehicle.key;

    if (!item.visible) {
      return "";
    }
    if (item.type == "text") {
      return item.value;
    } else if (item.type == "QR") {
      return useRenderQrString(qrObject, vehicle, locationConfig);
    } else if (item.type == "card") {
      return vehicle.card;
    } else if (item.type == "key") {
      return vehicle.key;
    } else if (item.type == "zone") {
      return vehicle.zone;
    } else if (item.type == "place") {
      return vehicle.place;
    } else if (item.type == "plate/model") {
      return vehicle.model;
    } else if (item.type == "brand") {
      return vehicle.brand;
    } else if (item.type == "color") {
      return vehicle.color;
    } else if (item.type == "rate") {
      if (
        vehicle.rate &&
        locationConfig &&
        locationConfig.payment &&
        locationConfig.payment.rateConfig &&
        locationConfig.payment.rateConfig[vehicle.rate]
      ) {
        return locationConfig.payment.rateConfig[vehicle.rate].name;
      }
      return "";
    } else if (item.type == "rateID") {
      return vehicle.rate || "";
    } else if (item.type == "rateIdCustom") {
      if (
        vehicle.rate &&
        locationConfig &&
        locationConfig.payment &&
        locationConfig.payment.rateConfig &&
        locationConfig.payment.rateConfig[vehicle.rate]
      ) {
        return locationConfig.payment.rateConfig[vehicle.rate].rateIdCustom;
      }
      return "";
    } else if (item.type == "date") {
      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      );
    } else if (item.type == "datetime") {
      return (
        date.getFullYear() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getDate() +
        " " +
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
        ":" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":" +
        (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds())
      );
    } else if (item.type == "CAME-PARKARE-Datetime") {
      const oneMinute = 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date("1993/01/01");
      //date = new Date(2023, 4, 15, 23, 0, 0, 0);
      const secondtDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      return (
        Math.round(
          (secondtDate.getTime() - firstDate.getTime()) / oneMinute / 24 / 60
        ) *
          24 *
          60 +
        date.getHours() * 60 +
        date.getMinutes()
      );
    } else if (item.type == "CAME-PARKARE-Seconds") {
      return date.getSeconds() < 10
        ? "0" + date.getSeconds()
        : date.getSeconds(); //"15";
    } else if (item.type == "CAME-PARKARE-Date") {
      const oneMinute = 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date("1993/01/01");
      //date = new Date(2023, 4, 15, 23, 0, 0, 0);
      const secondtDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      return (
        Math.round(
          (secondtDate.getTime() - firstDate.getTime()) / oneMinute / 24 / 60
        ) *
        24 *
        60
      );
    } else {
      return "";
    }
    //return "";
  }
  function useRenderQrString(qrObject, vehicle, locationConfig) {
    let string = "";
    for (var i in qrObject) {
      string =
        string + useRenderItem(qrObject[i], qrObject, vehicle, locationConfig);
    }
    return string;
  }

  function saveVehicle(print) {
    console.log("saveVehicle");

    const date = new Date();
    var vehicleNew = {
      ...vehicle,
      status: "scanned",
      startTime: firebase.firestore.Timestamp.fromDate(date),
      userScan: userID,
      rate: rate,
      gate: gate,
    };
    if (enableButton) {
      console.log("enabled");
      setEnableButton(false);
      console.log(enableButton);
      //const startTime = Math.round(Date.now() / 1000);
      let cardIdtoPrint = vehicle.card !== "" ? vehicle.card : makeid(4);

      if (print) {
        var qrObject =
          currentLocation &&
          currentLocation.locationConfig &&
          currentLocation.locationConfig.receipts != undefined &&
          currentLocation.locationConfig.receipts.printConfig != undefined &&
          currentLocation.locationConfig.receipts.printConfig.qr
            ? currentLocation.locationConfig.receipts.printConfig.qr
            : {};

        vehicleNew = {
          ...vehicleNew,
          card: cardIdtoPrint,
          printDefault: true,
          qrString: useRenderQrString(
            qrObject,
            {
              ...vehicleNew,
              card: cardIdtoPrint,
            },
            locationConfig
          ),
        };

        //     setVehicle({
        //   ...vehicle,
        //   status: "scanned",
        //   startTime: firestore.Timestamp.fromDate(date),
        //   date: date,
        //   card: cardIdtoPrint,
        //   userScan: userID,
        //   qrString: useRenderQrString(qrObject, {
        //     ...vehicle,
        //     date: date,
        //     card: cardIdtoPrint,
        //   }),
        // });
      }

      var validarObjeto = useValidateVehicleData(vehicleNew, languageObject);
      if (validarObjeto.status) {
        console.log("valid");
        console.log("vehicleNew", vehicleNew, businessId, locationId);

        var data = dispatch(
          saveVehicleData(businessId, locationId, vehicleNew)
        );
        setEnableButton(true);
        setshowScanner(false);
        setShowNewVehicleDetail(false);

        setVehicle(useVehicleEmptyObject());
        M.toast("Vehiculo Guardado", 4000);

        //closeModal();
      } else {
        console.log("No valid");
        for (var i in validarObjeto.list) {
          console.log(validarObjeto.list[i].message);
        }
        // Toast.show({
        //   text: validarObjeto.list[i].message,
        //   buttonText: "Ok",
        // });
        setEnableButton(true);
      }
    } else {
      M.toast("Error", 4000);
    }
  }
  const onSubmitRate = (values) => {
    console.log("values", values);
    setRate(values["rate"]);
    setVehicle({ ...vehicle, rate: values["rate"] });
    console.log("vehicle123", vehicle);
  };
  // const onSubmitPhone = (values) => {
  //   console.log("values", values);
  //   setRate(values["phone"]);
  //   setVehicle({ ...vehicle, phone: values["phone"], card: values["phone"] });
  //   console.log("vehicle123", vehicle);
  // };
  const onSubmitGate = (values) => {
    console.log("values", values);
    setGate(values["gate"]);
    setVehicle({ ...vehicle, gate: values["gate"] });
    console.log("vehicle123", vehicle);
  };
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          style={{ padding: 30, width: "100%" }}
          onClick={function (event) {
            setshowScanner(!showScanner);
            setShowNewVehicleDetail(!showScanner);
          }}
        >
          {showScanner
            ? "Ocultar Scanner"
            : "Buscar o registrar Vehículo con el escaner"}
        </Button>{" "}
      </Grid>
      {showScanner &&
        rateConfig &&
        Object.keys(rateConfig).filter((a) => !rateConfig[a].hide).length >
          1 && (
          <Grid item xs={12} sm={6}>
            <Typography component="h5" variant="h5">
              Selecciona la tarifa
            </Typography>
            <EditableField
              style={{ fontSize: 30 }}
              fieldObject={{
                label: "Tarifa",
                errorMessage: "",
                fieldName: "rate",
                type: "select",
                defaultValue: rate,
                optionsArray: Object.keys(rateConfig)
                  .filter((a) => !rateConfig[a].hide)
                  .map((key) => {
                    console.log(rateConfig[key].name);
                    console.log(key);

                    return { value: key, label: rateConfig[key].name };
                  }),
                regex: "",
                textSize: "h6",
              }}
              onSubmit={onSubmitRate}
            />
          </Grid>
        )}
      {showScanner &&
        gateConfig &&
        Object.keys(gateConfig).filter((a) => !gateConfig[a].hide).length >
          1 && (
          <Grid item xs={12} sm={6}>
            <Typography component="h5" variant="h5">
              Selecciona la Puerta
            </Typography>
            <EditableField
              style={{ fontSize: 30 }}
              fieldObject={{
                label: "Puerta",
                errorMessage: "",
                fieldName: "gate",
                type: "select",
                defaultValue: gate,
                optionsArray: Object.keys(gateConfig)
                  .filter((a) => !gateConfig[a].hide)
                  .map((key) => {
                    console.log(gateConfig[key].name);
                    console.log(key);

                    return { value: key, label: gateConfig[key].name };
                  }),
                regex: "",
                textSize: "h6",
              }}
              onSubmit={onSubmitGate}
            />
          </Grid>
        )}

      <Grid container direction="row" justify="center" alignItems="center">
        {showScanner && (
          <Grid item xs={12}>
            <VehicleScanner callbackDataQR={callbackDataQR} />
          </Grid>
        )}
        {/* {showScanner && (
          <Grid item xs={12} sm={6}>
            <Typography component="h5" variant="h5">
              Celular de contacto
            </Typography>
            <EditableField
              style={{ fontSize: 30 }}
              fieldObject={{
                label: "Celular de contacto",
                errorMessage: "",
                fieldName: "phone",
                type: "text",
                regex: "^([0-9]{12})$",
                defaultValue: vehicle.phone,
                textSize: "h6",
              }}
              onSubmit={onSubmitPhone}
            />
          </Grid>
        )} */}

        {showNewVehicleDetail && (
          <Grid item xs={12}>
            <VehicleAddCard vehicle={vehicle} locationId={locationId} />
            <Button
              color="primary"
              variant="contained"
              style={{ padding: 30, width: "100%" }}
              onClick={() => saveVehicle(false)}
              disabled={!enableButton}
            >
              Guardar Vehículo
            </Button>{" "}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
