import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  MenuItem,
  Select,
  FormControl,
  FormLabel,
  List,
  InputLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  root1: {},
  icon: {
    position: "absolute",
    right: 10,
  },
  select: {
    marginBottom: "15px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function LocationsSelectList({
  businessLocations,
  user,
  languageObject,
  handleSubmitAuth,
}) {
  const classes = useStyles();
  const userProfiles = languageObject.authorizeUserdViews.users.profiles;

  return (
    <div className="locations-checklist">
      <form style={{ flex: 1, display: "flex", height: "100%" }}>
        <FormControl
          variant="outlined"
          style={{ flex: 1, height: "100%" }}
          component="fieldset"
        >
          <FormLabel component="legend">{userProfiles.locations}</FormLabel>
          <List
            className={classes.outlinedList}
            style={{ maxHeight: "70%", overflow: "auto", flex: 1 }}
          >
            {Object.keys(businessLocations).map((locationKey) => (
              <div key={locationKey} className="input-row">
                <InputLabel htmlFor={`auth-${locationKey}`}>
                  {businessLocations[locationKey].locationConfig.name}
                </InputLabel>
                {/* Valor por default de la siguiente linea tal vez tendrá que cambiar una vez que haya el atrributo en el usuario */}
                <Select
                  fullWidth
                  className={classes.select}
                  inputProps={{
                    name: `auth-${locationKey}`,
                    id: `auth-${locationKey}`,
                  }}
                  label={businessLocations[locationKey].locationConfig.name}
                  value={
                    user.locationsAuth && user.locationsAuth[locationKey]
                      ? user.locationsAuth[locationKey].profile
                      : "unauthroized"
                  }
                  defaultValue={
                    user.locationsAuth && user.locationsAuth[locationKey]
                      ? user.locationsAuth[locationKey].profile
                      : "unauthroized"
                  }
                  onChange={(e) =>
                    handleSubmitAuth(locationKey, e.target.value)
                  }
                >
                  <MenuItem value="unauthroized">
                    {userProfiles.unauthorized}
                  </MenuItem>
                  <MenuItem value="master">{userProfiles.master}</MenuItem>
                  <MenuItem value="locationLeader">
                    {userProfiles.locationLeader}
                  </MenuItem>
                  <MenuItem value="valet">{userProfiles.valet}</MenuItem>
                  <MenuItem value="ticketsValidationAndReports">
                    {userProfiles.ticketsValidationAndReports}
                  </MenuItem>
                  <MenuItem value="ticketsValidation">
                    {userProfiles.ticketsValidation}
                  </MenuItem>
                  <MenuItem value="incomeReport">
                    {userProfiles.incomeReport}
                  </MenuItem>
                </Select>
              </div>
            ))}
          </List>
        </FormControl>
      </form>
    </div>
  );
}
