import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import {
  useGetBrandImage,
  useGetColorName,
  useGetColorValue,
} from "../utilsAuth/VehicleUtils";
import {
  useTotalAmount,
  useTotalAmountWidhValidations,
} from "../TicketsValidation/PaymentUtils";
import { timestampToDate } from "../../../../services/utils";
import { Paper } from "@material-ui/core";
import { updateVehicleAtribute } from "../../../../data-store/actions/vehicle-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "500px",
    height: 120,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
    width: "50%",
  },
  buttonStyle: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    flex: 1,
    marginBottom: 5,
    marginTop: 5,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function VehiclePaymentList(props) {
  const { vehicle } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const carsCatalogue = useSelector((state) => state.valet.catalogue.cars);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const userID = useSelector((state) => state.auth.userID);

  const charges = useTotalAmountWidhValidations(
    businessLocations[vehicle.locationId].locationConfig.payment.rateConfig,
    vehicle
  );
  const texts = languageObject.authorizeUserdViews.vehicleDetail.payment;

  function removePayment(currentVehicle, index) {
    console.log("removing payment");
    var paymentObject = currentVehicle.payment.list[index];
    if (paymentObject.deleted && paymentObject.deleted != undefined) {
      paymentObject.deleted = !paymentObject.deleted;
    } else {
      paymentObject.deleted = true;
    }
    var paymentNew = { ...currentVehicle.payment };
    paymentNew.list = {
      ...currentVehicle.payment.list,
      [index]: paymentObject,
    };
    dispatch(updateVehicleAtribute(currentVehicle, { payment: paymentNew }));
  }

  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid item xs={12} sm={12} style={{ textAlign: "center", marginTop: 30 }}>
        <Typography gutterBottom variant={"h3"} component="h2">
          {texts.title}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{texts.date}</TableCell>
                <TableCell align="center">{texts.user}</TableCell>
                <TableCell align="center">{texts.amount}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  padding={"default"}
                  component="th"
                  scope="row"
                >
                  {timestampToDate(charges.finalDate * 1000)}
                </TableCell>
                <TableCell padding={"default"} align="center">
                  System
                </TableCell>
                <TableCell padding={"default"} align="center">
                  {charges.charge}
                </TableCell>
              </TableRow>

              {vehicle.payment.list &&
                Object.values(vehicle.payment.list).map((pay) => (
                  <TableRow
                    key={pay.date}
                    style={
                      pay.deleted
                        ? { backgroundColor: "grey" }
                        : { backgroundColor: "" }
                    }
                  >
                    <TableCell
                      align="center"
                      padding={"default"}
                      component="th"
                      scope="row"
                    >
                      {timestampToDate(pay.date * 1000)}
                    </TableCell>
                    <TableCell padding={"default"} align="center">
                      {businessUsers[pay.user] !== undefined
                        ? businessUsers[pay.user].name
                        : pay.user}{" "}
                      {businessUsers[pay.user] !== undefined
                        ? businessUsers[pay.user].lastname
                        : ""}{" "}
                    </TableCell>
                    <TableCell padding={"default"} align="center">
                      {pay.amount}
                    </TableCell>
                    <TableCell padding={"default"} align="center">
                      {userID === pay.user && (
                        <Button
                          onClick={() => removePayment(vehicle, pay.date)}
                        >
                          {pay.deleted ? "Borrado" : "Borrar"}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell
                  align="center"
                  padding={"default"}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell padding={"default"} align="center">
                  {texts.pending}
                </TableCell>
                <TableCell padding={"default"} align="center">
                  {charges.pendingPay}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
