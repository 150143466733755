import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PlaceDetail from "./PlaceDetail";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 330,
    margin: 10,
  },
  media: {
    height: 70,
  },
  content: {
    height: 220,
  },
  paper: {
    padding: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
  },
  zone: {
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
    backgroundColor: "#e0e0e0",
    margin: 20,
  },
});

export default function ZonesAndPlacesDetail(props) {
  const {
    locationConfig,
    zonesData,
    activeUsers,
    vehicles,
    languageObject,
    callBackClickPlace,
  } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;

  const classes = useStyles();
  console.log("ZoneAndPlacesDetail zonesData", zonesData);

  const renderZones = () => {
    var zones = [];
    var zonesArray = Object.keys(locationConfig.zones)
      .map((a) => {
        return { ...locationConfig.zones[a], key: a };
      })
      .sort((a, b) =>
        a.zoneName > b.zoneName ? 1 : b.zoneName > a.zoneName ? -1 : 0
      );

    console.log("zonesArray", zonesArray);
    for (var i in zonesArray) {
      var placesView = [];
      if (zonesArray[i].placesNamed) {
        var places = Object.keys(zonesArray[i].zonePlaces)
          .map((a) => {
            return { ...zonesArray[i].zonePlaces[a], key: a };
          })
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

        for (var e in places) {
          // console.log("key zone", zonesArray[i].key);
          // console.log("key place ", e);
          // console.log("key place key", places[e].key);

          let status = "Vacio";
          let placeObject = undefined;
          if (
            zonesData &&
            zonesData[zonesArray[i].key] &&
            zonesData[zonesArray[i].key].zonePlaces != undefined &&
            zonesData[zonesArray[i].key].zonePlaces[places[e].key] != undefined
          ) {
            placeObject =
              vehicles[zonesData[zonesArray[i].key].zonePlaces[places[e].key]];
            // placeObject.vehicle = zonesData[i].zonePlaces[e].vehicle;
          }
          if (!places[e].hide) {
            placesView.push(
              <Grid key={e} item xs={3} sm={1}>
                <PlaceDetail
                  callBackClickPlace={callBackClickPlace}
                  zone={zonesArray[i]}
                  placeName={places[e]}
                  placeObject={placeObject}
                  ocupade={true}
                  languageObject={languageObject}
                />
              </Grid>
            );
          }
        }
        for (var v in vehicles) {
          if (
            vehicles[v].zone == zonesArray[i].key &&
            vehicles[v].place == ""
          ) {
            placesView.push(
              <Grid key={v} item xs={3} sm={1}>
                <PlaceDetail
                  callBackClickPlace={callBackClickPlace}
                  placeName={{ name: texts.OP }}
                  ocupade={true}
                  overPlace={true}
                  languageObject={languageObject}
                  placeObject={vehicles[v]}
                />
              </Grid>
            );
          }
        }
      } else {
        for (var e = 1; e <= zonesArray[i].zonePlacesNumber; e++) {
          let placeObject = undefined;
          if (
            zonesData &&
            zonesData[zonesArray[i].key] &&
            zonesData[zonesArray[i].key].zonePlaces != undefined &&
            zonesData[zonesArray[i].key].zonePlaces[e] != undefined
          ) {
            placeObject = vehicles[zonesData[zonesArray[i].key].zonePlaces[e]];
            // placeObject.vehicle = zonesData[i].zonePlaces[e].vehicle;
          }

          placesView.push(
            <Grid key={e} item xs={3} sm={1}>
              <PlaceDetail
                callBackClickPlace={callBackClickPlace}
                zone={zonesArray[i]}
                placeName={texts.placeDefaultName + " " + e}
                placeObject={placeObject}
                ocupade={false}
                languageObject={languageObject}
              />
            </Grid>
          );
        }
      }

      if (!zonesArray[i].hide) {
        zones.push(
          <Grid key={i} item xs={12}>
            <Paper className={classes.zone}>
              <Grid key={e} item xs={12} style={{ position: "relative" }}>
                <Typography gutterBottom variant="h4" component="h2">
                  {zonesArray[i].zoneName}
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {placesView}
              </Grid>
            </Paper>
          </Grid>
        );
      }
    }
    return zones;
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      {renderZones()}
    </Grid>
  );
}
