import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  getBusinessLocationsConfig,
  getBusinessUsers,
} from "../../../../data-store/actions/valet-actions";
import { getLocationsVehiclesRealTimeChanges } from "../../../../data-store/actions/vehicle-actions";
import EditableField from "../../../EditableField/EditableField";
import ValidationsVehicleTable from "./ValidationsVehicleTable";
import ValidationsVehicleCardList from "./ValidationsVehicleCardList";
import ValidationsVehicleTableUsers from "./ValidationsVehicleTableUsers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function TicketsValidationContainer() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currentLocation, setCurrentLocation] = useState({
    locationConfig: {},
  });
  const [locationId, setLocationId] = useState({});

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessLocationsReady = useSelector(
    (state) => state.valet.businessLocationsReady
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const userID = useSelector((state) => state.auth.userID);
  const userAccount = useSelector((state) => state.auth.userAccount);

  // useEffect(() => {
  //   console.log("getBusinessLocationsConfig");
  //   if (!businessLocationsReady) {
  //     console.log("getBusinessLocationsConfig");
  //     dispatch(getBusinessLocationsConfig());
  //   }
  //   if (!businessUsers) {
  //     dispatch(getBusinessUsers());
  //   }
  // }, [businessId, userAccount]);

  function masterOfAcount(userAccount) {
    // TODO: aqui debo agregar la lista de usuarios para que se pueda asigarn las carateristicas que puede validar el usuario
    const businessAuthorized = userAccount.businessAuthorized;
    let arrayMaster = [];
    for (var i in businessAuthorized) {
      if (businessAuthorized[i].profile == "master") {
        arrayMaster.push(i);
      }
    }
    return arrayMaster;
  }
  function validationsOfAccount(userAccount) {
    // TODO: aqui debo agregar la lista de usuarios para que se pueda asigarn las carateristicas que puede validar el usuario
    const locationsAuth =
      userAccount.businessAuthorized[businessId].locationsAuth;
    console.log("locationsAuth", locationsAuth);

    let arrayMaster = [];
    for (var i in locationsAuth) {
      console.log("locationsAuth", locationsAuth[i]);
      if (
        locationsAuth[i].authorized &&
        locationsAuth[i].validation !== undefined &&
        locationsAuth[i].validation !== "notAllowed"
      ) {
        arrayMaster.push(i);
      }
    }
    return arrayMaster;
  }

  function handleChange(locationIdVar) {
    console.log(locationIdVar.location);
    console.log(businessId);
    dispatch(
      getLocationsVehiclesRealTimeChanges(
        businessId,
        locationIdVar.location,
        false
      )
    );
    setLocationId(locationIdVar.location);
    setCurrentLocation(
      businessLocations[locationIdVar.location].locationConfig
    );
  }
  function locationsArray() {
    if (businessLocations) {
      let array = [];
      Object.keys(businessLocations).map((index) =>
        array.push({
          label: businessLocations[index].locationConfig.name,
          value: index,
        })
      );
      return array;
    } else {
      return [];
    }
  }
  //  console.log(businessUsers[userID].locationsAuth);
  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid className={classes.spacing} item xs={12} md={12}>
        <Typography
          className={classes.text}
          gutterBottom
          variant={"h3"}
          component="h2"
        >
          {languageObject.authorizeUserdViews.ticketsValidation.menuName}
        </Typography>
      </Grid>
      {/* <Grid className={classes.spacing} item xs={12} sm={6}>
        <EditableField
          fieldObject={{
            label: "Location",
            errorMessage: "",
            fieldName: "location",
            type: "select",
            defaultValue: locationId ? locationId : "NA",
            optionsArray: locationsArray(),
            regex: "",
            textSize: "h6",
          }}
          onSubmit={handleChange}
        />
      </Grid> */}
      {userAccount && masterOfAcount(userAccount).length > 0 && (
        <Grid className={classes.spacing} item xs={12} sm={6}>
          <ValidationsVehicleTableUsers locationId={locationId} />
        </Grid>
      )}
      {userAccount && validationsOfAccount(userAccount).length > 0 ? (
        <Grid
          className={classes.spacing}
          item
          xs={12}
          sm={userAccount && masterOfAcount(userAccount).length > 0 ? 6 : 12}
        >
          <ValidationsVehicleCardList locationId={locationId} />
        </Grid>
      ) : (
        <Grid
          className={classes.spacing}
          item
          xs={12}
          sm={userAccount && masterOfAcount(userAccount).length > 0 ? 6 : 12}
        >
          <Typography
            className={classes.text}
            gutterBottom
            variant={"h4"}
            component="h2"
          >
            {
              languageObject.authorizeUserdViews.ticketsValidation
                .usersValidation.notAuthorizedInLocationUser
            }
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
