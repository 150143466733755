import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { getBusinessLocationsConfig } from "../../../../data-store/actions/valet-actions";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import Slide from "@material-ui/core/Slide";
import ReactAudioPlayer from "react-audio-player";
import sound from "./iphone-notificacion.mp3";
import logo from "../../../../assets/logos/Frame.svg";
import { useGetColorName } from "../utilsAuth/VehicleUtils";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import IconButton from "@material-ui/core/IconButton";
import ReactHowler from "react-howler";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#191a2c",
    flex: 1,
  },
  text: {
    textAlign: "center",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: "10px",
    marginBottom: "10px",
    borderLeft: "0.5px dashed rgba(255, 255, 255, 0.8)",
    flex: 1,
  },
  spacing1: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: "10px",
    marginBottom: "10px",
    flex: 1,
  },
  paper: {
    backgroundColor: "transparent",
  },
  Box: {
    padding: "5px",
    borderRadius: "300px",
    margin: "10px",
    fontSize: "20px",
  },
  requestedBox: {
    padding: "8px",
    borderRadius: "300px",
    margin: "10px",
    marginLeft: "25px",
    marginRight: "25px",
    fontSize: "15px",
    backgroundColor: "white",
    color: "#1D2242",
    flex: 1,
  },
  recoveringBox: {
    padding: "8px",
    borderRadius: "300px",
    margin: "10px",
    marginLeft: "25px",
    marginRight: "25px",
    fontSize: "15px",
    backgroundColor: "#262e5c",
    color: "white",
    flex: 1,
  },
  platformBox: {
    padding: "8px",
    borderRadius: "300px",
    margin: "10px",
    marginLeft: "25px",
    marginRight: "25px",
    fontSize: "15px",
    backgroundColor: "#2ca8de",
    color: "white",
    flex: 1,
  },
  header: {
    textAlign: "center",
    backgroundColor: "#1D2242",
    padding: "15px",
  },
  info: {
    textAlign: "center",
    backgroundColor: "#2ca8de",
    padding: "8px",
    color: "white",
    fontSize: "20px",
    fontWeight: "100",
  },
  textTitle: {
    color: "white",
    fontSize: "20px",
    textAlign: "center",
    padding: "15px",
    fontWeight: "100",
  },
}));

export default function LiveViewDetailLounge(props) {
  const { locationId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [fontSize, setFontSize] = React.useState(20);

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const carsCatalogue = useSelector((state) => state.valet.catalogue.cars);

  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const [vehiclesList, setvehiclesList] = useState({});
  const [sound, setSound] = useState(false);

  let gateConfig = {};
  if (
    businessLocations &&
    businessLocations[locationId] &&
    businessLocations[locationId].locationConfig &&
    businessLocations[locationId].locationConfig.payment &&
    businessLocations[locationId].locationConfig.payment.gateConfig
  ) {
    gateConfig =
      businessLocations[locationId].locationConfig.payment.gateConfig;
  }

  useEffect(() => {
    var vehiclesTemp = {};
    for (var i in vehicles) {
      if (
        vehiclesList[i] !== undefined &&
        vehicles[i].status !== vehiclesList[i].status &&
        vehicles[i].locationId === locationId
      ) {
        vehiclesTemp[i] = {
          ...vehicles[i],
          change: true,
          changeDate: new Date(),
        };
        if (
          vehicles[i].status === "requested" ||
          vehicles[i].status === "recovering" ||
          vehicles[i].status === "platform"
        ) {
          setSound(true);
          setTimeout(function () {
            setSound(false);
          }, 2000);
        }
      } else {
        vehiclesTemp[i] = {
          ...vehicles[i],
          change: false,
          changeDate:
            vehiclesList[i] !== undefined ? vehiclesList[i].changeDate : 0,
        };
      }
    }
    setvehiclesList(vehiclesTemp);
  }, [vehicles]);

  function getFirtChar(string, gateConfig) {
    if (gateConfig[string] === undefined) {
      return "";
    }
    let StartCharUpper = "";
    const splitString = gateConfig[string].name.split(" ");
    for (var i in splitString) {
      StartCharUpper = StartCharUpper + splitString[i].charAt(0);
    }

    return StartCharUpper.toLocaleUpperCase();
  }

  function getStatusTime(vehicle, status) {
    const changes = vehicle.changes;
    for (var i in changes) {
    }
  }
  console.log(locationId);
  return (
    <Grid
      container
      className={classes.root}
      justify="start"
      direction="row"
      alignContent="center"
    >
      <IconButton
        onClick={() => setFontSize(fontSize - 2)}
        color="primary"
        aria-label="cerrar"
        style={{
          position: "absolute",
          right: "45px",
          top: "5px",
          color: "white",
        }}
      >
        <ZoomOutIcon />
      </IconButton>
      <IconButton
        onClick={() => setFontSize(fontSize + 2)}
        color="primary"
        aria-label="cerrar"
        style={{
          position: "absolute",
          right: "75px",
          top: "5px",
          color: "white",
        }}
      >
        <ZoomInIcon />
      </IconButton>
      <Grid className={classes.header} item xs={12} md={12}>
        <img src={logo} height={50} />
      </Grid>
      <Grid className={classes.info} item xs={12} md={12}>
        {languageObject.authorizeUserdViews.liveView.knowVehicleStatus}
      </Grid>
      <Grid className={classes.spacing1} item xs={12} md={4}>
        <Typography className={classes.textTitle} variant={"h4"} component="h2">
          {languageObject.authorizeUserdViews.liveView.vehicleRequested}
        </Typography>
        <Typography className={classes.text} variant={"h6"} component="h2">
          {vehicles &&
            Object.values(vehiclesList)
              .sort((a, b) => a.changeDate - b.changeDate)
              .map((value) => {
                if (
                  value.locationId !== undefined &&
                  value.locationId == locationId &&
                  value.status == "requested"
                ) {
                  if (value.change) {
                    return (
                      <Slide
                        className={classes.requestedBox}
                        key={value.vehicleId}
                        direction="up"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Paper style={{ fontSize: fontSize }} elevation={4}>
                          {value.brand &&
                            carsCatalogue.brands[value.brand].brand}
                          {" | "}
                          {value.color &&
                            useGetColorName(value.color, languageObject)}
                          {" | "}
                          {value.key}
                          {" | "}
                          {value.gate && getFirtChar(value.gate, gateConfig)}

                          {value.change && "*"}
                        </Paper>
                      </Slide>
                    );
                  } else {
                    return (
                      <Paper
                        style={{ fontSize: fontSize }}
                        key={value.vehicleId}
                        className={classes.requestedBox}
                        elevation={4}
                      >
                        {value.brand && carsCatalogue.brands[value.brand].brand}
                        {" | "}
                        {value.color &&
                          useGetColorName(value.color, languageObject)}
                        {" | "}
                        {value.key}
                        {" | "}
                        {value.gate && getFirtChar(value.gate, gateConfig)}

                        {value.change && "*"}
                      </Paper>
                    );
                  }
                }
              })}
        </Typography>
      </Grid>
      <Grid className={classes.spacing} item xs={12} md={4}>
        <Typography className={classes.textTitle} variant={"h4"} component="h2">
          {languageObject.authorizeUserdViews.liveView.vehicleRecovering}
        </Typography>
        <Typography className={classes.text} variant={"h6"} component="h2">
          {vehicles &&
            Object.values(vehiclesList)
              .sort((a, b) => a.changeDate - b.changeDate)
              .map((value) => {
                if (
                  value.locationId !== undefined &&
                  value.locationId == locationId &&
                  value.status == "recovering"
                ) {
                  if (value.change) {
                    return (
                      <Slide
                        className={classes.recoveringBox}
                        key={value.vehicleId}
                        direction="up"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Paper style={{ fontSize: fontSize }} elevation={4}>
                          {value.brand &&
                            carsCatalogue.brands[value.brand].brand}
                          {" | "}
                          {value.color &&
                            useGetColorName(value.color, languageObject)}
                          {" | "}
                          {value.key}
                          {" | "}
                          {value.gate && getFirtChar(value.gate, gateConfig)}

                          {value.change && "*"}
                        </Paper>
                      </Slide>
                    );
                  } else {
                    return (
                      <Paper
                        style={{ fontSize: fontSize }}
                        key={value.vehicleId}
                        className={classes.recoveringBox}
                        elevation={4}
                      >
                        {value.brand && carsCatalogue.brands[value.brand].brand}
                        {" | "}
                        {value.color &&
                          useGetColorName(value.color, languageObject)}
                        {" | "}
                        {value.key}
                        {" | "}
                        {value.gate && getFirtChar(value.gate, gateConfig)}

                        {value.change && "*"}
                      </Paper>
                    );
                  }
                }
              })}
        </Typography>
      </Grid>
      <Grid className={classes.spacing} item xs={12} md={4}>
        <Typography className={classes.textTitle} variant={"h4"} component="h2">
          {languageObject.authorizeUserdViews.liveView.vehiclePlatform}
        </Typography>
        <Typography className={classes.text} variant={"h6"} component="h2">
          {vehicles &&
            Object.values(vehiclesList)
              .sort((a, b) => a.changeDate - b.changeDate)
              .map((value) => {
                if (
                  value.locationId !== undefined &&
                  value.locationId == locationId &&
                  value.status == "platform"
                ) {
                  if (value.change) {
                    return (
                      <Slide
                        className={classes.platformBox}
                        key={value.vehicleId}
                        direction="up"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Paper style={{ fontSize: fontSize }} elevation={4}>
                          {value.brand &&
                            carsCatalogue.brands[value.brand].brand}
                          {" | "}
                          {value.color &&
                            useGetColorName(value.color, languageObject)}
                          {" | "}
                          {value.key}
                          {" | "}
                          {value.gate && getFirtChar(value.gate, gateConfig)}

                          {value.change && "*"}
                        </Paper>
                      </Slide>
                    );
                  } else {
                    return (
                      <Paper
                        style={{ fontSize: fontSize }}
                        key={value.vehicleId}
                        className={classes.platformBox}
                        elevation={4}
                      >
                        {value.brand && carsCatalogue.brands[value.brand].brand}
                        {" | "}
                        {value.color &&
                          useGetColorName(value.color, languageObject)}
                        {" | "}
                        {value.key}
                        {" | "}
                        {value.gate && getFirtChar(value.gate, gateConfig)}

                        {value.change && "*"}
                      </Paper>
                    );
                  }
                }
              })}
        </Typography>

        {/* {vehicles &&
          Object.values(vehicles).filter(
            (a) =>
              a.status === "requested" ||
              a.status === "recovering" ||
              a.status === "platform"
          ).length > 0 && (
            <ReactHowler
              src="https://firebasestorage.googleapis.com/v0/b/softicade.appspot.com/o/Audios%2Fiphone-notificacion.mp3?alt=media&token=331c535e-cb36-4eb3-ace4-60a12bea9a89"
              playing={true}
            />
          )} */}

        {sound && (
          <div style={{ display: "none" }}>
            <ReactHowler
              src="https://firebasestorage.googleapis.com/v0/b/softicade.appspot.com/o/Audios%2Fiphone-notificacion.mp3?alt=media&token=331c535e-cb36-4eb3-ace4-60a12bea9a89"
              playing={true}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
