import React from "react";
import { makeid } from "../../../../../services/utils";
// import canceled from '../../assets/icons/canceled.png';
// import canceledLight from '../../assets/icons/canceledLight.png';
// import parked from '../../assets/icons/parked.png';
// import parkedLight from '../../assets/icons/parkedLight.png';
// import parking from '../../assets/icons/parking.png';
// import parkingLight from '../../assets/icons/parkingLight.png';
// import platform from '../../assets/icons/platform.png';
// import platformLight from '../../assets/icons/platformLight.png';
// import recovering from '../../assets/icons/recovering.png';
// import recoveringLight from '../../assets/icons/recoveringLight.png';
// import requested from '../../assets/icons/requested.png';
// import requestedLight from '../../assets/icons/requestedLight.png';
// import scanned from '../../assets/icons/scanned.png';
// import scannedLight from '../../assets/icons/scannedLight.png';
// import taken from '../../assets/icons/taken.png';
// import takenLight from '../../assets/icons/takenLight.png';
// import arriving from '../../assets/icons/arriving.png';
// import arrivingLight from '../../assets/icons/arrivingLight.png';
// import paid from '../../assets/icons/paid.png';
// import paidLight from '../../assets/icons/paidLight.png';
// import unpaid from '../../assets/icons/unpaid.png';
// import unpaidLight from '../../assets/icons/unpaidLight.png';
// import {BluetoothEscposPrinter} from '@brooons/react-native-bluetooth-escpos-printer';

//import CircleIcon from '../Hepers/CircleIcon';

export function useGetBrandImage(brand, carsCatalogue) {
  if (carsCatalogue.brands[brand] != undefined) {
    return carsCatalogue.brands[brand].brandImage;
  }
  return false;
}
export function useGetBrandName(brand, carsCatalogue) {
  if (carsCatalogue.brands[brand] != undefined) {
    return carsCatalogue.brands[brand].brandImage;
  }
  return false;
}

export function useGetColorValue(color, carsCatalogue) {
  if (color == "") {
    return "#FFFFFF";
  }
  if (carsCatalogue.colors[color] != undefined) {
    return carsCatalogue.colors[color].value;
  }
  return false;
}

export function useGetColorName(color, languageObject) {
  if (color == "") {
    return languageObject.generalData.colors["empty"];
  }
  return languageObject.generalData.colors[color];
}

export function useSetStatusText(status, languageObject, carsCatalogue) {
  if (carsCatalogue.status[status] != undefined) {
    return languageObject.generalData.status[status].name;
  }
  return false;
}

// export function useGetStatusIcon({customStyle, light}) {
//   const style = customStyle;

//   var object = {
//     canceled: <CircleIcon style={style} icon={canceled} />,
//     parked: <CircleIcon style={style} icon={parked} />,
//     parking: <CircleIcon style={style} icon={parking} />,
//     platform: <CircleIcon style={style} icon={platform} />,
//     recovering: <CircleIcon style={style} icon={recovering} />,
//     requested: <CircleIcon style={style} icon={requested} />,
//     scanned: <CircleIcon style={style} icon={scanned} />,
//     taken: <CircleIcon style={style} icon={taken} />,
//     arriving: <CircleIcon style={style} icon={arriving} />,
//     paid: <CircleIcon style={style} icon={paid} />,
//     unpaid: <CircleIcon style={style} icon={unpaid} />,
//   };

//   const lightObject = {
//     canceled: <CircleIcon style={style} icon={canceledLight} />,
//     parked: <CircleIcon style={style} icon={parkedLight} />,
//     parking: <CircleIcon style={style} icon={parkingLight} />,
//     platform: <CircleIcon style={style} icon={platformLight} />,
//     recovering: <CircleIcon style={style} icon={recoveringLight} />,
//     requested: <CircleIcon style={style} icon={requestedLight} />,
//     scanned: <CircleIcon style={style} icon={scannedLight} />,
//     taken: <CircleIcon style={style} icon={takenLight} />,
//     arriving: <CircleIcon style={style} icon={arrivingLight} />,
//     paid: <CircleIcon style={style} icon={paidLight} />,
//     unpaid: <CircleIcon style={style} icon={unpaidLight} />,
//   };
//   if (light) {
//     return lightObject;
//   } else {
//     return object;
//   }
// }

export function useGetVehicleNotesText(notes, languageObject) {
  let texts = [];
  let count = 0;
  for (var i in notes) {
    count++;
    console.log(notes[i]);
    texts.push(notes[i].text);
  }

  console.log(notes);
  if (count > 0) {
    console.log("array notes");
    const notesText = texts.join(", ");
    return { text: notesText, count: count, array: Object.values(notes) };
  }
  return {
    text: languageObject.authorizeUserdViews.vehiclesDetail.placeholderNotes,
    count: 0,
    array: [],
  };
}

export function useCountPhotos(photos) {
  if (photos == null || photos == undefined) {
    return 0;
  }
  return Object.values(photos).length;
}

export function useValidateVehicleData(vehicle, languageObject) {
  var texts = languageObject.authorizeUserdViews.vehiclesAdd;
  var arrayAtributesRequired = ["card", "key", "startTime", "status", "rate"];
  //  "color",
  //  "brand",
  // "plateNumber",

  var arrayErrors = [];
  var status = true;
  for (var i in vehicle) {
    if (arrayAtributesRequired.indexOf(i) >= 0) {
      if (vehicle[i] == "" || vehicle[i] == 0) {
        arrayErrors.push({
          message: texts.checkRequiredData + "(" + i + ")",
          valid: false,
        });
        status = false;
      }
    }
  }
  return { status: status, list: arrayErrors };
}

export function updateChangesList(vehicle, changeObject, userID) {
  let changes = vehicle.changes;
  const atributesToSaveChanges = [
    "card",
    "key",
    "brand",
    "color",
    "plateNumber",
    "status",
    "place",
    "zone",
    "closed",
  ];
  const atributesToAdd = ["notes", "photos"];

  for (var i in changeObject) {
    if (atributesToSaveChanges.indexOf(i) >= 0) {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: vehicle[i] == undefined ? "" : vehicle[i],
        newValue: changeObject[i],
      };
    } else if (atributesToAdd.indexOf(i) >= 0) {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: "",
        newValue: i + " ADD",
      };
    } else if (i == "payment") {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      var previous = Object.values(vehicle[i].list);
      var newValue = Object.values(changeObject[i].list);
      var add = "ADD";
      if (previous.length == newValue.length) {
        add = "CHANGE";
      }
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: "",
        newValue: i + " " + add,
      };
    }
  }
  return changes;
}

export function useVehicleEmptyObject() {
  return {
    id: Date.now() + "-" + makeid(8),
    brand: "empty",
    card: "",
    closed: false,
    changes: {},
    color: "",
    key: "",
    model: "",
    payment: {
      paid: false,
      list: {},
    },
    place: "",
    plateNumber: "",
    startTime: { seconds: 0, nanoseconds: 0 },
    status: "arriving",
    endStatus: false,
    zone: "",
    exotic: false,
    manual: false,
    notes: {},
    photos: {},
  };
}
export function useArrayStatusNormalFlow(languageObject) {
  return {
    arriving: {
      nextStatus: "scanned",
      nextStatusText: languageObject.generalData.status["scanned"].name,
      nextStatusPlus1: "parking",
      nextStatusPlus1Text: languageObject.generalData.status["parking"].name,
    },
    scanned: {
      nextStatus: "parking",
      nextStatusText: languageObject.generalData.status["parking"].name,
      nextStatusPlus1: "parked",
      nextStatusPlus1Text: languageObject.generalData.status["parked"].name,
    },
    parking: {
      nextStatus: "parked",
      nextStatusText: languageObject.generalData.status["parked"].name,
      nextStatusPlus1: "recovering",
      nextStatusPlus1Text: languageObject.generalData.status["recovering"].name,
    },
    parked: {
      nextStatus: "recovering",
      nextStatusText: languageObject.generalData.status["recovering"].name,
      nextStatusPlus1: "platform",
      nextStatusPlus1Text: languageObject.generalData.status["platform"].name,
    },
    requested: {
      nextStatus: "recovering",
      nextStatusText: languageObject.generalData.status["recovering"].name,
      nextStatusPlus1: "platform",
      nextStatusPlus1Text: languageObject.generalData.status["platform"].name,
    },
    recovering: {
      nextStatus: "platform",
      nextStatusText: languageObject.generalData.status["platform"].name,
      nextStatusPlus1: "taken",
      nextStatusPlus1Text: languageObject.generalData.status["taken"].name,
    },
    platform: {
      nextStatus: "taken",
      nextStatusText: languageObject.generalData.status["taken"].name,
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
    taken: {
      nextStatus: "none",
      nextStatusText: "",
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
    canceled: {
      nextStatus: "none",
      nextStatusText: "",
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
    unpaid: {
      nextStatus: "paid",
      nextStatusText: languageObject.generalData.paymentStatus["paid"].name,
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
    paid: {
      nextStatus: "none",
      nextStatusText: "",
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
  };
}
//#################################################################
export function useRenderItem(item, qrObject, vehicle, locationConfig) {
  let date = vehicle.startTime.toDate();
  console.log("date", date);
  console.log("qrObject", qrObject);
  console.log("vehicle", vehicle);
  //return vehicle.key;

  if (!item.visible) {
    return "";
  }
  if (item.type == "text") {
    return item.value;
  } else if (item.type == "QR") {
    return useRenderQrString(qrObject, vehicle);
  } else if (item.type == "card") {
    return vehicle.card;
  } else if (item.type == "key") {
    return vehicle.key;
  } else if (item.type == "zone") {
    return vehicle.zone;
  } else if (item.type == "place") {
    return vehicle.place;
  } else if (item.type == "plate/model") {
    return vehicle.model;
  } else if (item.type == "brand") {
    return vehicle.brand;
  } else if (item.type == "color") {
    return vehicle.color;
  } else if (item.type == "rate") {
    if (
      vehicle.rate &&
      locationConfig &&
      locationConfig.payment &&
      locationConfig.payment.rateConfig &&
      locationConfig.payment.rateConfig[vehicle.rate]
    ) {
      return locationConfig.payment.rateConfig[vehicle.rate].name;
    }

    return "";
  } else if (item.type == "rateID") {
    return vehicle.rate || "";
  } else if (item.type == "rateIdCustom") {
    if (
      vehicle.rate &&
      locationConfig &&
      locationConfig.payment &&
      locationConfig.payment.rateConfig &&
      locationConfig.payment.rateConfig[vehicle.rate]
    ) {
      return locationConfig.payment.rateConfig[vehicle.rate].rateIdCustom;
    }

    return "";
  } else if (item.type == "date") {
    return (
      date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
    );
  } else if (item.type == "datetime") {
    return (
      date.getFullYear() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getDate() +
      " " +
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
      ":" +
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":" +
      (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds())
    );
  } else if (item.type == "CAME-PARKARE-Datetime") {
    const oneMinute = 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date("1993/01/01");
    //date = new Date(2023, 4, 15, 23, 0, 0, 0);
    const secondtDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    return (
      Math.round(
        (secondtDate.getTime() - firstDate.getTime()) / oneMinute / 24 / 60
      ) *
        24 *
        60 +
      date.getHours() * 60 +
      date.getMinutes()
    );
  } else if (item.type == "CAME-PARKARE-Seconds") {
    return date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //"15";
  } else if (item.type == "CAME-PARKARE-Date") {
    const oneMinute = 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date("1993/01/01");
    //date = new Date(2023, 4, 15, 23, 0, 0, 0);
    const secondtDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    return (
      Math.round(
        (secondtDate.getTime() - firstDate.getTime()) / oneMinute / 24 / 60
      ) *
      24 *
      60
    );
  } else {
    return "";
  }
  //return "";
}
export function useRenderQrString(qrObject, vehicle, locationConfig) {
  let string = "";
  for (var i in qrObject) {
    string =
      string + useRenderItem(qrObject[i], qrObject, vehicle, locationConfig);
  }
  return string;
}
