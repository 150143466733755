import React from "react";
import { connect } from "react-redux";

import AboutUs from "./About-us";
import ContactUs from "./Contact-us";

const FooterView = ({ languageObject }) => (
  <footer className="page-footer  #000000 black">
    <div className="container">
      <div className="row">
        <div className="col l6 s12">
          <AboutUs aboutUsFooterText={languageObject.footer.AboutUs} />
        </div>
        <div className="col l3 s12">
          <ContactUs contactUsFooterText={languageObject.footer.Contact} />
        </div>
      </div>
    </div>
    <div className="footer-copyright">
      <div className="container"></div>
    </div>
  </footer>
);

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    language: state.auth.language,
    languageObject: state.auth.languageObject,
  };
};

const Footer = connect(mapStateToProps, mapDispatchToProps)(FooterView);

export default Footer;
