import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  IconButton,
  Typography,
  Paper,
  Grid,
  Button
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import SwitchField from "../../../../../SwichField";

const useStyles = makeStyles({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  iconEditContainer: {
    // position: "absolute",
    // bottom: 0,
    // top: 0,
    // right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 20,
    paddingBottom: 20
  },
  gridItem: {
    whiteSpace: 'normal',
  },
  paper: {
    width: '100%',
    position: 'relative',
    paddingTop: 20,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
    maxWidth: '100%',

  },
  input: {
    paddingRight: 10,
    paddingLeft: 10,
    width: "100%",
  },
});

export default function ShopItem(props) {
  const {
    onSubmit,
    errorMessage,
    defaultName,
    defaultDescription,
    defaultPrice,
    hidden,
    textSize,
    min,
    max,
    texts,
    productKey,
    editable
  } = props;
  const classes = useStyles();
  const { register, handleSubmit, errors, setValue } = useForm();
  const [editMode, setEditMode] = useState(editable);

  function clearValues() {
    setValue('name', '');
    setValue('description', '');
    setValue('price', '');
  }

  function onSave(values) {
    console.warn('values', values);
    console.warn('productKey', productKey);
    onSubmit(values, productKey);
    editable ? clearValues() : setEditMode(false);
  }

  function handleToggleHide() {
    // console.log('toggleingHide item -', { name: defaultName, description: defaultDescription, price: defaultPrice, hidden: !hidden });
    onSubmit({ name: defaultName, description: defaultDescription, price: defaultPrice, hidden: !hidden }, productKey)
  }

  return editMode ? (
    <Paper className={classes.paper}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} className={classes.gridItem}>
            <TextField
              variant="outlined"
              margin="none"
              label={texts.productName}
              className={classes.textField}
              disabled={!editMode}
              helperText={Boolean(errors.name) ? errorMessage : null}
              error={Boolean(errors.name)}
              defaultValue={defaultName}
              inputRef={register({ required: true })}
              name="name"
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
            <TextField
              variant="outlined"
              margin="none"
              label={texts.productDescription}
              className={classes.textField}
              disabled={!editMode}
              helperText={Boolean(errors.description) ? errorMessage : null}
              error={Boolean(errors.description)}
              defaultValue={defaultDescription}
              inputRef={register({ required: true })}
              name="description"
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}  className={classes.gridItem}>
            <TextField
              variant="outlined"
              margin="none"
              label={texts.productPrice}
              className={classes.textField}
              disabled={!editMode}
              helperText={Boolean(errors.price) ? errorMessage : null}
              error={Boolean(errors.price)}
              defaultValue={defaultPrice}
              inputRef={register({ required: true })}
              name="price"
              type="number"
              InputProps={{
                className: classes.input,
                inputProps: {
                  max: max,
                  min: min,
                }
              }}
            />
          </Grid>
        </Grid>
      </form>
      <IconButton
        className={classes.iconSave}
        onClick={handleSubmit(onSave)}
      >
        <SaveIcon />
      </IconButton>
    </Paper>
  ) : (
      <div className={classes.textContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} md={2}>
            <div>
              <Typography
                className={classes.text}
                noWrap
                gutterBottom
                variant={"subtitle1"}
                component="h2"
              >
                {texts.productName}:
        </Typography>
              <Typography
                className={classes.text}
                noWrap
                gutterBottom
                variant={textSize || "subtitle1"}
                component="h2"
              >
                {defaultName || texts.newName}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <Typography
                className={classes.text}
                noWrap
                gutterBottom
                variant={"subtitle1"}
                component="h2"
              >
                {texts.productDescription}:
        </Typography>
              <Typography
                className={classes.text}
                noWrap
                gutterBottom
                variant={textSize || "subtitle1"}
                component="h2"
              >
                {defaultDescription || texts.newDescription}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <div>
              <Typography
                className={classes.text}
                noWrap
                gutterBottom
                variant={"subtitle1"}
                component="h2"
              >
                {texts.productPrice}:
        </Typography>
              <Typography
                className={classes.text}
                noWrap
                gutterBottom
                variant={textSize || "subtitle1"}
                component="h2"
              >
                {defaultPrice || 0}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <SwitchField
              onSubmit={handleToggleHide}
              value={hidden}
              atribute="hidden"
              label={texts.hidden}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <div className={classes.iconEditContainer}>
              <IconButton
                size={
                  !textSize ||
                    textSize === "h1" ||
                    textSize === "h2" ||
                    textSize === "h3" ||
                    textSize === "h4" ||
                    textSize === "h5"
                    ? "medium"
                    : "small"
                }
                onClick={() => setEditMode(true)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </Grid>

        </Grid>
      </div>
    );
}
