import React from "react";
import { connect } from "react-redux";
import { auth } from "../../firebase/firebasejs";
import { Link } from "react-router-dom";
import M from "materialize-css";
import logosideNav from "../../assets/logos/Logo Smart Valet.png";
import Grid from "@material-ui/core/Grid";

import { withRouter } from "react-router-dom";

class SesionViewView extends React.Component {
  constructor(props) {
    super(props);
  }

  LogOut = (e) => {
    auth.signOut().then(
      () => {
        // Sign-out successful.
        M.toast("Sesion Finalizada", 4000);

        console.log("sesion cerrada");
        this.props.handleDrawerClose;
        return this.props.history.push("/home");
      },
      function (error) {
        // An error happened.
        console.log("Error");
      }
    );
  };

  render() {
    const styles = {
      color: "#FFFFFF",
      fontSize: "15px",
    };
    let element = "";
    if (this.props.userAccount == null || this.props.userAccount == undefined) {
      element = (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={styles}
            onClick={this.props.handleDrawerClose}
          >
            <img src={logosideNav} height="60" />
          </Grid>
          <Grid item xs={12} style={styles}>
            <Link
              style={styles}
              to={"/login"}
              onClick={this.props.handleDrawerClose}
            >
              {this.props.languageObject.account.SesionView.logIn}
            </Link>
          </Grid>
          <Grid item xs={12} style={styles}>
            <Link
              style={styles}
              to={"/createaccount"}
              onClick={this.props.handleDrawerClose}
            >
              {this.props.languageObject.account.SesionView.registry}
            </Link>
          </Grid>
        </Grid>
      );
    } else {
      element = (
        <Grid container spacing={2}>
          <Grid item xs={12} style={styles}>
            <img src={logosideNav} height="60" />
          </Grid>
          <Grid item xs={12} style={styles}>
            {this.props.userAccount.name} {this.props.userAccount.lastname}
          </Grid>
          <Grid item xs={12} style={styles}>
            {this.props.userAccount.email}
          </Grid>
          <Grid item xs={12} style={styles}>
            <a style={styles} onClick={(e) => this.LogOut(e)}>
              {this.props.languageObject.account.SesionView.closeSesion}
            </a>
          </Grid>
        </Grid>
      );
    }

    return (
      <div
        style={{
          flexGrow: 1,
          padding: "30px",
          background: "#1d2242",
          color: "#FFFFFF",
        }}
      >
        {element}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    userAccount: state.auth.userAccount,
    languageObject: state.auth.languageObject,
  };
};

const SesionView = connect(mapStateToProps, mapDispatchToProps)(SesionViewView);

export default withRouter(SesionView);
