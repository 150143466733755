import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../Fields/FormRender";
import {
  updateBusinessAtribute,
  addNewUserToBusiness,
} from "../../../data-store/actions/valet-actions";
import formsConfig from "./formsConfig.json";
import EditableField from "../../EditableField/EditableField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function BusinessDetail(props) {
  const { business } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.auth.userID);
  const createEventObject = formsConfig.business;
  console.log(business);
  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    const data = { businessInfo: { ...value } };
    dispatch(updateBusinessAtribute(business.businessID, data));
  }
  function onSubmit(data) {
    console.log(data);
    dispatch(
      addNewUserToBusiness(
        data.email,
        true,
        business.businessID,
        business.businessInfo.businessName,
        "master",
        true
      )
    );
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Editar datos del negocio
            </Typography>
            <Typography gutterBottom variant={"subtitle1"} component="h2">
              ID: {business.businessID}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {Object.values(createEventObject).map((field, index) => {
              const defaultValue = business.businessInfo[field.fieldName];
              return (
                <EditableField
                  key={index}
                  fieldObject={{
                    ...field,
                    defaultValue: defaultValue,
                  }}
                  onSubmit={handleChange}
                />
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Agregar un usuario al negocio
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormRender
              callback={onSubmit}
              formObject={formsConfig.addUser}
              submitButtonText={"Guardar Usuario"}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Ver"}>{eventView}</SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
