import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { CSVLink } from "react-csv";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import '../../../../../src/index.css';
import { dateToYYYYMMDD, generateReport, savePDF, sixtyDaysAgo, today, yearAgo } from '../Reports/reportsUtils';
import { generatePayRollReport } from './payRollUtils';

const useStyles = makeStyles({
  root: {
    width: 700,
    height: 250,
    margin: 10,
    backgroundColor: "#fff",
    color: "#000"
  },
  media: {
    height: 140,
  },
  content: {
    height: 160,
  },
  button: {
    marginRight: 20,
    marginTop: 20
  },
  buttonText: {
    color: "#fff",
  }
});

export default function PayRollReportGenerator({ languageObject, businessLocations, userListArray, quincenaDates }) {
  const { quincenaStart, quincenaEnd } = quincenaDates;
  const texts = languageObject.authorizeUserdViews.users.payRoll.reportGenerator;
  const classes = useStyles();
  const { handleSubmit, errors, control, register } = useForm();
  const [reportData, setReportData] = useState(null);
  const [title, setTitle] = useState(null);

  const onSubmit = data => {
    let reports = generatePayRollReport({ languageObject, reportData: data, userListArray, businessLocations });
    setReportData(reports.csvData);
    reports.title ? setTitle(reports.title) : null;
  };

  const resetGenerator = () => {
    setReportData(null);
  };

  return (
    <Card className={classes.root}>
      <CardContent
        className={classes.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FormControl
              style={{ width: '100%' }}
            >
              <InputLabel>
                {texts.reportTypeLabel}
              </InputLabel>

              <Controller
                name="reportType"
                rules={{ required: "this is required" }}
                control={control}
                defaultValue="all_users"
                as={
                  <Select inputProps={{ onChange: () => resetGenerator() }} >
                    <MenuItem
                      value="all_users">
                      {texts.allUsers}
                    </MenuItem>
                    {/* Incluir reportes por location? Hay que agregar location atributo a cada shift */}
                    {businessLocations
                      ? Object.keys(businessLocations)
                        .map((locationKey) => <MenuItem
                          value={locationKey}>
                          {businessLocations[locationKey].locationConfig.name}
                        </MenuItem>
                        )
                      : null
                    }
                    {userListArray.map(
                      (user) => <MenuItem value={user.userId}>{user.name} {user.lastname}</MenuItem>
                    )}
                  </Select>
                }
              />
              <FormHelperText>
              </FormHelperText>
            </FormControl>

            <FormControl
              style={{ minWidth: 300 }}
            >
              <TextField
                name="startDate"
                inputRef={register}
                rules={{ required: "this is required" }}
                control={control}
                defaultValue=""
                label={texts.reportStartDateLabel}
                type="date"
                defaultValue={dateToYYYYMMDD(quincenaStart)}
                className={classes.textField}
                onChange={() => resetGenerator()}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: dateToYYYYMMDD(yearAgo),
                  max: dateToYYYYMMDD(today),
                  style: { borderBottom: 'none', marginBottom: 0 }
                }}
              />
            </FormControl>

            <FormControl
              style={{ minWidth: 300 }}
            >
              <TextField
                name="endDate"
                inputRef={register}
                rules={{ required: "this is required" }}
                control={control}
                defaultValue=""
                label={texts.reportEndDateLabel}
                type="date"
                defaultValue={dateToYYYYMMDD(quincenaEnd)}
                className={classes.textField}
                onChange={() => resetGenerator()}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: dateToYYYYMMDD(sixtyDaysAgo),
                  max: dateToYYYYMMDD(today),
                  style: { borderBottom: 'none', marginBottom: 0 }
                }}
              />
            </FormControl>
          </div>
        </form>

        {
          reportData === null
            ?
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!businessLocations}
              className={classes.button}
            >
              {texts.generateReport}
            </Button>
            : null
        }
        {
          reportData !== null
            ? <Button
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <CSVLink
                className={classes.buttonText}
                data={reportData}
              >
                {texts.CSVLink}
              </CSVLink>
            </Button>
            : null
        }

        {
          reportData !== null
            ? <Button
              variant="contained"
              color="primary"
              onClick={() => savePDF({ languageObject, reportData, title })}
              className={classes.button}
            >
              {texts.pdf}
            </Button>

            : null
        }
      </CardContent>

    </Card >
  );
}