import React from 'react';


	const AboutUs = ({aboutUsFooterText}) => (
		<div>
			<h5 className="white-text">{aboutUsFooterText.title}</h5>
			<p className="grey-text text-lighten-4 left-align">
				{aboutUsFooterText.paragraph1}
			</p>
			<p className="grey-text text-lighten-4 left-align">
				{aboutUsFooterText.paragraph2}
			</p>
		</div>
	);



export default AboutUs;
