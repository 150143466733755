
import React from 'react';
import { connect } from 'react-redux';
import {Button, Input, Icon, Row} from 'react-materialize';
import {auth} from '../../firebase/firebasejs';
import M from 'materialize-css'
import { Link } from  'react-router-dom';

import Grid from '@material-ui/core/Grid';


class PasswordRestoreView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
			email : ""
        };
    }
    getValue(e){
		var name = e.target.name;
		var value = e.target.value;
			this.setState({[name]: value});
	}

    PasswordRestoreFunc = (e) =>{
	  console.log("restore password");

	  auth.sendPasswordResetEmail(this.state.email).then(() => {
		// Email sent.
		   M.toast("Se ha enviado un correo a tu cuenta", 4000);
	 
	  }, error => {
		// An error happened.
			 M.toast("Error en correo", 4000);
	  
	  });
	console.log("rec contrasena");

	}

    render(){
		let strings =  this.props.languageObject.account.Login;

		if(this.props.userAccount != undefined && this.props.userAccount != null && this.props.userAccount.userProfiles != undefined){
			for(var i in this.props.userAccount.userProfiles){
				if(this.props.catalogue != undefined){
					var defaultView = this.props.catalogue.profiles[this.props.userAccount.userProfiles[i]].defaultView;
					this.props.history.push('/'+defaultView);
				}
			}
		}

        return(
			<div className="row" id="RegistroForm">
				<div className="col s12 m6 offset-m3 l6 offset-l3 z-depth-5">

					<Grid item xs={12}>
						<h4>{strings.title}</h4>
					</Grid>
					<Grid item xs={12}>
						<Input name="email" onChange={e => this.getValue(e)} type="email" label={strings.emailInput} error="Ingresa una dirección de correo válida" validate s={12} />
					</Grid>
					<Grid item xs={12}>
						<Button onClick={e => this.PasswordRestoreFunc(e)} waves='light'>{strings.passwordRestore}<Icon left>send</Icon></Button>
					</Grid>
					<br/>
					<Grid item xs={12}>
					<Link to={"/login"}>
						<Button  waves='light'>{strings.backToLogIn}</Button>
					</Link>
					</Grid>
					<br/>
					<br/>
					<br/>
				</div>
			</div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
	return {
		sendForm : (e, componentState) => {
			e.preventDefault();
			console.log(componentState);
		}
		}
	}

const mapStateToProps = (state) => {
	return {
		userAccount : state.auth.userAccount,
		languageObject : state.auth.languageObject,
		catalogue : state.valet.catalogue
	}
  }
  
const PasswordRestore = connect(
	mapStateToProps,
	mapDispatchToProps
)(PasswordRestoreView);

export default PasswordRestore;

