import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import FieldText from "./FieldText";
import FieldSelect from "./FieldSelect";
import FieldDate from "./FieldDate";
import FieldCheckbox from "./FieldCheckbox";
import FieldSwitch from "./FieldSwitch";
import FieldRadioButton from "./FieldRadioButton";
import FieldEvent from "./FieldEvent/FieldEvent";
import FieldHidden from "./FieldHidden";
import FieldPhoto from "./FieldPhoto";
import FieldFile from "./FieldFile";

export default function Field(props) {
  const { fieldObject, register, errors, watch } = props;

  if (fieldObject.type === "select") {
    return (
      <FieldSelect
        fieldObject={fieldObject}
        register={register}
        errors={errors}
      />
    );
  }
  if (fieldObject.type === "date") {
    return (
      <FieldDate
        fieldObject={fieldObject}
        register={register}
        errors={errors}
      />
    );
  }
  if (fieldObject.type === "checkbox") {
    return (
      <FieldCheckbox
        fieldObject={fieldObject}
        register={register}
        errors={errors}
      />
    );
  }
  if (fieldObject.type === "switch") {
    return (
      <FieldSwitch
        fieldObject={fieldObject}
        register={register}
        errors={errors}
      />
    );
  }
  if (fieldObject.type === "option") {
    return (
      <FieldRadioButton
        fieldObject={fieldObject}
        register={register}
        errors={errors}
      />
    );
  }
  if (fieldObject.type === "hidden") {
    return (
      <FieldHidden
        fieldObject={fieldObject}
        register={register}
        errors={errors}
      />
    );
  }
  if (fieldObject.type === "event") {
    return (
      <FieldEvent
        fieldObject={fieldObject}
        register={register}
        errors={errors}
        watch={watch}
      />
    );
  }
  if (fieldObject.type === "json") {
    return (
      <FieldEvent
        fieldObject={fieldObject}
        register={register}
        errors={errors}
        watch={watch}
      />
    );
  }
  if (fieldObject.type === "photo") {
    return (
      <FieldPhoto
        fieldObject={fieldObject}
        register={register}
        errors={errors}
        watch={watch}
      />
    );
  }
  if (fieldObject.type === "file") {
    return (
      <FieldFile
        fieldObject={fieldObject}
        register={register}
        errors={errors}
        watch={watch}
      />
    );
  }

  return (
    <FieldText fieldObject={fieldObject} register={register} errors={errors} />
  );
}
