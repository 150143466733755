import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Send from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { addNewUserToBusiness } from "../../../../data-store/actions/valet-actions";
import { List, ListItem, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function AddUsersForm(props) {
  const dispatch = useDispatch();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessId = useSelector((state) => state.valet.businessId);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessObject = useSelector((state) => state.valet.businessObject);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    dispatch(addNewUserToBusiness(data.email, true));
  };

  const texts = languageObject.authorizeUserdViews.users.registration;
  const errorMessages = languageObject.authorizeUserdViews.users.errorMessages;

  return (
    <div>
      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={8}>
          <Typography variant="h3">{texts.title}</Typography>
          <List>
            <ListItem>
              1. {texts.step1}{" "}
              <strong>https://admin.smartValet.app/createaccount</strong>
            </ListItem>
            <ListItem>2. {texts.step2}</ListItem>
            <ListItem>3. {texts.step3}</ListItem>
            <ListItem>
              4. {texts.step4} https://admin.smartValet.app/users.
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={8}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <TextField
              style={{ width: "70%", margin: 10 }}
              name="email"
              size="large"
              placeholder={texts.inputPlaceholder}
              inputProps={{ style: { borderBottom: "none", marginBottom: 0 } }}
              inputRef={register({
                required: true,
                pattern: /[^@\s]+@[^@\s]+\.[^@\s]+/,
              })}
              error={Boolean(errors.email)}
            />
            <Button type="submit" variant="contained" color="primary">
              {texts.addUserButtom}
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
