import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Launch from "@material-ui/icons/Launch";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormDialog from "../../../Modals/FormDialog";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  shortTable: {
    minWidth: 300,
    maxHeight: "80%",
  },
  shortPaper: {
    maxHeight: "90%",
  },
  container: {
    overflow: "scroll",
    maxHeight: "600px",
  },
});

export default function UserTable(props) {
  const { callback, counterPartId } = props;
  const classes = useStyles();
  const businessUsers = useSelector((state) => state.valet.businessUsers);

  return (
    <div>
      <List className={classes.container}>
        {businessUsers &&
          Object.values(businessUsers).map((row) => (
            <ListItem
              style={
                counterPartId === row.id ? { backgroundColor: "#ffcdd2" } : {}
              }
              onClick={() => callback(row.id)}
              key={row.name}
            >
              <ListItemAvatar>
                <Avatar alt={row.name} src={row.photoUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={row.name + " " + row.lastname}
                secondary={row.mail}
              />
            </ListItem>
          ))}
      </List>
    </div>
  );
}
