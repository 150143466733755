import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ReactAudioPlayer from "react-audio-player";
import { incidents } from "../../../../firebase/firebasejs";
import {
  getLocationsIncidents,
  saveIncident,
} from "../../../../data-store/actions/vehicle-actions";
import FormRender from "../../../Fields/FormRender";
import { Typography } from "@material-ui/core";
import { fecha, fechahora } from "../../../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function ValetIncidents(props) {
  const { locationId, callback } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const incidents = useSelector((state) => state.vehicle.incidentsReport);

  const userID = useSelector((state) => state.auth.userID);
  const catalogue = useSelector((state) => state.valet.catalogue);
  const carsCatalogue = catalogue !== undefined ? catalogue.cars : {};
  const [valueToFind, setValueToFind] = useState("");

  const texts = languageObject.authorizeUserdViews.ticketsValidation;
  const texts1 = languageObject.authorizeUserdViews.valetView;

  const [vehiclesRequested, setVehiclesRequested] = useState([]);
  const userProfile = businessUsers[userID].locationsAuth[locationId].profile;

  useEffect(() => {
    // aquí descagar las incidencias

    if (userProfile !== "valet") {
      dispatch(getLocationsIncidents(businessId, locationId, 1));
    }
  }, []);

  if (businessId === undefined) {
    return <div></div>;
  }

  function saveincident(data) {
    const incident = { ...data, date: new Date(), userId: userID };
    dispatch(saveIncident(businessId, locationId, incident));
  }
  return (
    <Paper
      style={{
        flex: 1,
        width: "100%",
        marginBottom: 20,
        padding: 10,
        height: "auto",
        backgroundColor: "#e0e0e0",
      }}
    >
      {userProfile !== "valet" && (
        <Typography component="h3" variant="h5">
          {texts1.incidents}
        </Typography>
      )}

      {incidents &&
        userProfile !== "valet" &&
        Object.values(incidents).map((incident) => (
          <Grid
            container
            justify="start"
            direction="row"
            alignContent="center"
            style={{ pading: "10px", margin: 10 }}
          >
            <Grid item xs={6}>
              {incident.incident}
            </Grid>
            <Grid item xs={3}>
              {fechahora(incident.date.seconds)}
            </Grid>
            <Grid item xs={3}>
              {businessUsers[incident.userId]
                ? businessUsers[incident.userId].name
                : incident.userId}
            </Grid>
          </Grid>
        ))}

      <Typography component="h5" variant="h5">
        {texts1.incidentsRegistry}
      </Typography>

      <Grid container justify="start" direction="row" alignContent="center">
        <FormRender
          callback={saveincident}
          formObject={{
            incident: {
              label: texts1.incidentsRegistry,
              errorMessage: "",
              fieldName: "incident",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
          }}
          submitButtonText={texts1.saveIncident}
        />
      </Grid>
    </Paper>
  );
}
