import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import EditableFieldText from "./EditableFieldText";
import EditableFieldSelect from "./EditableFieldSelect";
import EditableFieldDate from "./EditableFieldDate";
import EditableFieldCheckbox from "./EditableFieldCheckbox";
import EditableFieldSwitch from "./EditableFieldSwitch";
import EditableFieldRadioButton from "./EditableFieldRadioButton";
import EditableFieldJson from "./EditableFieldJson";
import EditableFieldPhoto from "./EditableFieldPhoto";

export default function EditableField(props) {
  const { fieldObject, onSubmit } = props;

  if (fieldObject.type === "select") {
    return (
      <EditableFieldSelect fieldObject={fieldObject} onSubmit={onSubmit} />
    );
  }
  if (fieldObject.type === "date") {
    return <EditableFieldDate fieldObject={fieldObject} onSubmit={onSubmit} />;
  }
  if (fieldObject.type === "checkbox") {
    return (
      <EditableFieldCheckbox fieldObject={fieldObject} onSubmit={onSubmit} />
    );
  }
  if (fieldObject.type === "switch") {
    return (
      <EditableFieldSwitch fieldObject={fieldObject} onSubmit={onSubmit} />
    );
  }
  if (fieldObject.type === "option") {
    return (
      <EditableFieldRadioButton fieldObject={fieldObject} onSubmit={onSubmit} />
    );
  }
  if (fieldObject.type === "json") {
    return <EditableFieldJson fieldObject={fieldObject} onSubmit={onSubmit} />;
  }
  if (fieldObject.type === "photo") {
    return <EditableFieldPhoto fieldObject={fieldObject} onSubmit={onSubmit} />;
  }

  return <EditableFieldText fieldObject={fieldObject} onSubmit={onSubmit} />;
}
