
import React from 'react';
import { connect } from 'react-redux';
import {firebaseInit} from '../../firebase/firebasejs';
import {Button, Input, Icon, Row} from 'react-materialize';
import {auth} from '../../firebase/firebasejs';
import M from 'materialize-css'
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';


class LoginView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
			email : "",
			password:""
        };
    }
    getValue(e){
		var name = e.target.name;
		var value = e.target.value;
			this.setState({[name]: value});
	}

    LoginFunc = (e) =>{
		auth.signInWithEmailAndPassword(this.state.email, this.state.password).then((data) => {
			console.log(data);
			M.toast('Bienvenido', 4000);
		}).catch(error => {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			// ...
			M.toast('Error al inicar Sesion intente de nuevo', 4000);
		  });
	  
	}

    render(){
		let strings =  this.props.languageObject.account.Login;

		if(this.props.userAccount != undefined && this.props.userAccount != null && this.props.userAccount.userProfiles != undefined){
			for(var i in this.props.userAccount.userProfiles){
				if(this.props.catalogue != undefined){
					var defaultView = this.props.catalogue.profiles[this.props.userAccount.userProfiles[i]].defaultView;
					this.props.history.push('/'+defaultView);
				}
			}
		}

        return(
			<div className="row" id="RegistroForm">
				<div className="col s12 m6 offset-m3 l6 offset-l3 z-depth-5">

					<Grid item xs={12}>
						<h4>{strings.title}</h4>
					</Grid>
					<Grid item xs={12}>
						<Input name="email" onChange={e => this.getValue(e)} type="email" label={strings.emailInput} error="Ingresa una dirección de correo válida" validate s={12} />
					</Grid>
					<Grid item xs={12}>
						<Input name="password" onChange={e => this.getValue(e)} type="password" label={strings.passwordInput} error="Mínimo 6 caracteres" validate s={12} />
					</Grid>
					<Grid item xs={12}>
						<Button onClick={e => this.LoginFunc(e)} waves='light'>{strings.logInButtom}<Icon left>send</Icon></Button>
					</Grid>
					<br/>
					<Grid item xs={12}>
					<Link to={"/password-restore"}>
						<Button  waves='light'>{strings.passwordRestore}</Button>
					</Link>
					</Grid>
					<br/>
					<br/>
					<br/>
				</div>
			</div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
	return {
		sendForm : (e, componentState) => {
			e.preventDefault();
			console.log(componentState);
		}
		}
	}

const mapStateToProps = (state) => {
	return {
		userAccount : state.auth.userAccount,
		languageObject : state.auth.languageObject,
		catalogue : state.valet.catalogue
	}
  }
  
const Login = connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginView);

export default Login;

