import React from "react";
import { connect } from "react-redux";
import { styled } from "@material-ui/core/styles";
import ValetLocationCard from "./ValetLocationCard";
import {
  getBusinessLocationsConfig,
  getBusinessUsers,
} from "../../../../data-store/actions/valet-actions";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Typography, Container } from "@material-ui/core";
import AuthorizeUserDetail from "../BusinessUsers/AuthorizeUserDetail";
import Launch from "@material-ui/icons/Launch";

const ButtonContainer = styled(Container)({
  width: 700,
});

class ValetLocationsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: null,
      addLocation: false,
    };
  }

  componentWillUpdate() {
    // if (!this.props.businessLocations) {
    //   this.props.businessLocationsFunc();
    // }
    // if (this.props.businessUsers == null) {
    //   this.props.setBusinessUser();
    // }
  }
  componentWillMount() {
    // if (this.props.businessLocations == null) {
    //   this.props.businessLocationsFunc();
    // }
    // if (this.props.businessUsers == null) {
    //   this.props.setBusinessUser();
    // }
  }

  render() {
    const texts = this.props.languageObject.authorizeUserdViews.locations
      .ValetLocations;
    function toggleAddLocation() {
      this.setState((prevState, props) => ({
        addLocation: !prevState.addLocation,
      }));
    }

    if (this.props.businessLocations == null) {
      return (
        <div>
          <Grid container direction="row" justify="center" alignItems="center">
            <CircularProgress color="secondary" />
          </Grid>
        </div>
      );
    }

    let locations = [];
    for (var i in this.props.businessLocations) {
      this.props.businessLocations[i].locationId = i;
      if (this.props.businessLocations[i].authorized) {
        locations.push(
          <Grid item xs={12} sm={6}>
            <ValetLocationCard
              key={i}
              languageObject={this.props.languageObject}
              locationConfig={this.props.businessLocations[i].locationConfig}
              locationId={i}
              vehicles={this.props.vehicles}
              zonesData={
                this.props.zonesData != undefined
                  ? this.props.zonesData[i]
                  : null
              }
              activeUsers={this.props.activeUsers}
            />
          </Grid>
        );
      }
    }

    return (
      <div>
        <Grid container direction="row" justify="center" alignItems="center">
          {locations}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageObject: state.auth.languageObject,
    businessId: state.valet.businessId,
    businessLocations: state.valet.businessLocations,
    activeUsers: state.valet.activeUsers,
    zonesData: state.vehicle.zonesData,
    vehicles: state.vehicle.vehicles,
    businessUsers: state.valet.businessUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // businessLocationsFunc: () => {
    //   dispatch(getBusinessLocationsConfig());
    // },
    // setBusinessUser: () => {
    //   dispatch(getBusinessUsers());
    // },
  };
};

const ValetLocations = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValetLocationsView);

export default ValetLocations;
