import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import MuiAlert from "@material-ui/lab/Alert";
import RenderZone from "./renderZone";
import EditableField from "../../../../../EditableField";
import { updateLocationAtribute } from "../../../../../../data-store/actions/valet-actions";
import SwitchField from "../../../../../SwichField";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 330,
    margin: 10,
  },
  iconSave: {
    position: "absolute",
    right: 10,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
  },
  media: {
    height: 70,
  },
  content: {
    height: 220,
  },
  paper: {
    padding: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
  },
  zone: {
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
    backgroundColor: "#e0e0e0",
    margin: 20,
    padding: 10,
    position: "relative",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ZoneDetail(props) {
  const dispatch = useDispatch();
  const {
    languageObject,
    locationConfig,
    zoneKey,
    zonesData,
    vehicles,
    locationId,
  } = props;
  const { register, handleSubmit, errors } = useForm();

  const classes = useStyles();
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const errorMessages = languageObject.authorizeUserdViews.users.errorMessages;
  const tableTitles =
    languageObject.authorizeUserdViews.users.userListTableTitles;

  console.log(zoneKey);
  console.log(locationId);
  
  const onSubmit = (values) => {
    const success = true;
    const changeObject = {
      zones: {
        [zoneKey]: values,
      },
    };
    dispatch(updateLocationAtribute(locationId, changeObject));

    console.log("update user with these changes...", changeObject);
    if (success) {
      openSuccessSnackBar();
    } else {
      openFailureSnackBar();
    }
  };

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const openSuccessSnackBar = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccessSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const openFailureSnackBar = () => {
    setOpenFailure(true);
  };
  const handleCloseFailureSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  function removeZone(zoneKey, event) {
    window.confirm(texts.alert.subtitle) && console.log("remove zone", zoneKey);
    event.stopPropagation();
  }

  if (zonesData.hide) {
    return (
      <Grid key={zoneKey} item xs={4}>
        <Paper className={classes.zone}>
          <Grid container direction="row">
            <Grid xs={12}>
              <Typography variant="h6">
                {texts.editZoneName} : {locationConfig.zones[zoneKey].zoneName}
              </Typography>
            </Grid>
            <Grid xs={12} style={{ position: "relative" }}>
              <SwitchField
                onSubmit={onSubmit}
                value={zonesData.hide}
                atribute="hide"
                label="Oculto"
              />
            </Grid>
          </Grid>
          <Snackbar
            open={openSuccess}
            autoHideDuration={6000}
            onClose={handleCloseSuccessSnackBar}
          >
            <Alert onClose={handleCloseSuccessSnackBar} severity="success">
              {errorMessages.saveSuccess}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openFailure}
            autoHideDuration={6000}
            onClose={handleCloseFailureSnackBar}
          >
            <Alert onClose={handleCloseFailureSnackBar} severity="success">
              {errorMessages.saveFailure}
            </Alert>
          </Snackbar>
        </Paper>
      </Grid>
    );
  }
  return (
    <Grid key={zoneKey} item xs={12}>
      <Paper className={classes.zone}>
        <Grid container direction="row" justify="center">
          <Grid item xs={12} md={3} sm={5}>
            <EditableField
              fieldName="zoneName"
              onSubmit={onSubmit}
              label={texts.editZoneName}
              errorMessage={errorMessages.zoneName}
              defaultValue={locationConfig.zones[zoneKey].zoneName}
              required={true}
            />
            <EditableField
              fieldName="priority"
              onSubmit={onSubmit}
              label={texts.editZonePriority}
              errorMessage={errorMessages.priority}
              defaultValue={locationConfig.zones[zoneKey].priority}
              required={true}
              min={0}
            />
            {locationConfig.zones[zoneKey].placesNamed && (
              <div>
                <Typography
                  noWrap
                  gutterBottom
                  variant="subtitle1"
                  component="h2"
                >
                  {texts.zonePlacesNumber}:
                </Typography>
                <Typography noWrap gutterBottom variant="h5" component="h2">
                  {locationConfig.zones[zoneKey].zonePlacesNumber}
                </Typography>
              </div>
            )}
            {!locationConfig.zones[zoneKey].placesNamed && (
              <EditableField
                fieldName="zonePlacesNumber"
                onSubmit={onSubmit}
                label={texts.zonePlacesNumber}
                errorMessage={errorMessages.zonePlacesNumber}
                defaultValue={locationConfig.zones[zoneKey].zonePlacesNumber}
                required={true}
                min={locationConfig.zones[zoneKey].zonePlacesNumber}
              />
            )}
            <EditableField
              fieldName="overPlaces"
              onSubmit={onSubmit}
              label={texts.editPlaces}
              errorMessage={errorMessages.overPlaces}
              defaultValue={locationConfig.zones[zoneKey].overPlaces}
              required={true}
              min={0}
            />
            <SwitchField
              onSubmit={onSubmit}
              value={zonesData.hide}
              atribute="hide"
              label="Oculto"
            />
          </Grid>
          <Grid item xs={12} md={9} sm={7}>
            <RenderZone
              zoneKey={zoneKey}
              zonesData={zonesData}
              locationConfig={locationConfig}
              vehicles={vehicles}
              languageObject={languageObject}
              locationId={locationId}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackBar}
        >
          <Alert onClose={handleCloseSuccessSnackBar} severity="success">
            {errorMessages.saveSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openFailure}
          autoHideDuration={6000}
          onClose={handleCloseFailureSnackBar}
        >
          <Alert onClose={handleCloseFailureSnackBar} severity="success">
            {errorMessages.saveFailure}
          </Alert>
        </Snackbar>
      </Paper>
    </Grid>
  );
}
