import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import AuthorizeUsersList from "./AuthorizeUsersList";
import AddAuthorizeUsers from "./AddAuthorizeUsers";
import { getBusinessUsers } from "../../../../data-store/actions/valet-actions";
import { Grid, Typography } from "@material-ui/core";

export default function BusinessUsersContainer(props) {
  const dispatch = useDispatch();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);

  useEffect(() => {
    if (!businessUsers) {
      //dispatch(getBusinessUsers());
    }
  }, []);

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          {languageObject.authorizeUserdViews.users.authorizeUsersTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AddAuthorizeUsers />
      </Grid>
      <Grid item xs={12}>
        <AuthorizeUsersList />
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Grid>
  );
}
