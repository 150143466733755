import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import RateStep from "./RateStep";
import { makeStyles } from "@material-ui/core/styles";
import EditableField from "../../../../../EditableField";
import SwitchField from "../../../../../SwichField";
import InputFileButton from "../../../../../Fields/InputFileButton1.1";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormRender from "../../../../../Fields/FormRender";
import {
  useTotalAmountTest,
  useTotalAmountWidhValidationsAndDates,
} from "../../../TicketsValidation/PaymentUtils";

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    width: "100%",
  },
  currentRate: {
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 20,
    width: "100%",
    display: "flex",
    marginTop: 15,
    marginBottom: 15,
    padding: 15,
  },
  table: {
    width: "80%",
  },
}));

export default function RateTestTime(props) {
  const { rate, rateKey, onSubmit, languageObject } = props;
  const { timeUnit, name, hide, rateIdCustom } = rate;
  console.warn(`name: ${name}, hide ${hide}, timeUnit ${timeUnit}`);
  const classes = useStyles();
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;

  const [step, setStep] = useState(false);
  const [price, setPrice] = useState(false);
  const [time, setTime] = useState(false);
  function onSubmitTime(data) {
    const startDate = 0;
    const segundos = data.hours * 3600 + data.minutes * 60 + data.seconds / 1;
    const charge = useTotalAmountTest(rate, rateKey, segundos);

    setTime(
      (data.hours < 10 ? "0" + data.hours : data.hours) +
        ":" +
        (data.minutes < 10 ? "0" + data.minutes : data.minutes) +
        ":" +
        (data.seconds < 10 ? "0" + data.seconds : data.seconds)
    );
    setPrice(charge.charge);
    setStep(charge.step);
  }

  return (
    <Grid container style={{ padding: 20 }}>
      <Grid item style={{ margin: 10 }} xs={12} sm={12} md={12}>
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Add time to test the price
        </Typography>
      </Grid>
      <Grid item style={{ margin: 10 }} xs={12} sm={12} md={12}>
        <FormRender
          callback={onSubmitTime}
          formObject={{
            hours: {
              label: "Hours",
              errorMessage: "",
              fieldName: "hours",
              type: "number",
              defaultValue: 0,
              regex: "",
              textSize: "h6",
              min: 0,
              required: true,
            },
            minutes: {
              label: "Minutes",
              errorMessage: "",
              fieldName: "minutes",
              type: "number",
              defaultValue: 0,
              regex: "",
              textSize: "h6",
              min: 0,
              required: true,
            },
            seconds: {
              label: "Seconds",
              errorMessage: "",
              fieldName: "seconds",
              type: "number",
              defaultValue: 0,
              regex: "",
              textSize: "h6",
              min: 0,
              required: true,
            },
          }}
          submitButtonText={"Test Time"}
        />
      </Grid>
      <Grid item style={{ margin: 10 }} xs={12} sm={12} md={12}>
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Time: {time}
        </Typography>
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Step: {step}
        </Typography>
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Price to Charge: {price}
        </Typography>
      </Grid>
    </Grid>
  );
}
