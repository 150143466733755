import React from 'react';
import MoneyIcon from '@material-ui/icons/Money';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ComputerIcon from '@material-ui/icons/Computer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

function getPaymentMethodIcon(method, props) {
  if (method === "cash") {
    // console.log('method', method)
    return (
      <MoneyIcon
        {...props}
      />
    )
  } else if (method === 'pos') {
    return (
      <CreditCardIcon
        {...props}
      />
    )
  } else if (method === 'onlinePayment') {
    return (
      <ComputerIcon
        {...props}
      />
    )
  }
  else {
    return (
      <MonetizationOnIcon
        {...props}
      />
    )
  }
}

export { getPaymentMethodIcon };