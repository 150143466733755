import React from 'react';
import { connect, dispatch} from 'react-redux'

const AboutView = ({languageObject}) => {

	return (
		<div>
			<h5>{languageObject.aboutView.menuName}</h5>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>

		</div>
	)
}


const mapStateToProps = (state) => {
	return {
		languageObject : state.auth.languageObject
	}
  }


  const mapDispatchToProps = (dispatch) => {
		return {
		}
  }

const About = connect(
	mapStateToProps,
	mapDispatchToProps
)(AboutView);

export default About;
