import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createBusiness,
  getBusinessList,
} from "../../../data-store/actions/valet-actions";
import BusinessList from "./BusinessList";
import CreateBusiness from "./CreateBusiness";
import Typography from "@material-ui/core/Typography";

export default function SVAuthorizedusers(props) {
  const dispatch = useDispatch();
  const businessList = useSelector((state) => state.valet.businessList);
  useEffect(() => {
    if (!businessList) {
      dispatch(getBusinessList());
    }
  }, []);

  return (
    <div>
      <Typography gutterBottom variant={"h4"} component="h2">
        Usuarios autorizados
      </Typography>

      <CreateBusiness />
      <BusinessList />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
