import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  Divider,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ShopItem from "./ShopItem";
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles(() => ({
  addButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
}));

export default function ShopItems(props) {
  const { languageObject, locationConfig, onChangeShopItem } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const shopItems = locationConfig.extraProducts || {};

  // function getVisibleShopItems() {
  //   const tempItemObject = {};
  //   if (shopItems) {
  //     let visibleItemKeys = Object.keys(shopItems).filter((item) => !shopItems[item].hidden);
  //     visibleItemKeys.forEach((itemKey) => tempItemObject[itemKey] = shopItems[itemKey]);
  //   }
  //   return tempItemObject;
  // }
  // const filteredShopItems = getVisibleShopItems();

  const classes = useStyles();

  function handleChangeShopItem(changedItem, productKey) {
    const newShopItems = { ...shopItems, [productKey]: { ...changedItem } };
    onChangeShopItem(newShopItems);
  }

  function getNextProductKey() {
    if (shopItems) {
      const keysArr = Object.values(shopItems);
      const max = keysArr.length;
      return max + 1;
    }
    return 1;
  }

  function addItem() {
    const emptyItem = {
      name: "New",
      description: "New Product",
      price: 0,
      hidden: false,
    };

    let newItemNumber = getNextProductKey();
    const newItems = { ...shopItems, [newItemNumber]: { ...emptyItem } };
    onChangeShopItem(newItems);
  }

  return (
    <Grid container direction="row" justify="flex-start">
      <Grid item xs={8}>
        <Typography variant="h4" component="h1">
          {texts.extraProducts}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => addItem()}
          aria-label={texts.addItem}
        >
          {texts.addItem}
        </Button>
      </Grid>
      <Divider className={classes.divider} />
      <Grid item xs={12}>
        <div className={classes.fullWidth}>
          <FormControl className={classes.fullWidth} variant="outlined">
            {/* <ShopItem
              onSubmit={handleChangeShopItem}
              errorMessage={texts.productErrorMessage}
              min={0}
              max={9999}
              texts={texts}
              productKey={getNextProductKey()}
              editable
            /> */}
            {shopItems && Object.keys(shopItems).length > 0
              ? Object.keys(shopItems).map((productKey) => {
                  // console.warn('product', shopItems[productKey]);
                  const product = shopItems[productKey];
                  const { name, description, price, hidden } = product;
                  return (
                    <ShopItem
                      key={productKey}
                      productKey={productKey}
                      onSubmit={handleChangeShopItem}
                      errorMessage={texts.productErrorMessage}
                      defaultName={name}
                      defaultDescription={description}
                      defaultPrice={price}
                      hidden={hidden}
                      min={0}
                      max={9999}
                      texts={texts}
                    />
                  );
                })
              : null}
          </FormControl>
        </div>
      </Grid>
    </Grid>
  );
}
