import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  getPaymentTotalsByType,
  useTotalAmount,
  useTotalAmountWidhValidationsAndDates,
} from "../TicketsValidation/PaymentUtils";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import { IconButton, TextField } from "@material-ui/core";
import {
  getZonePlaceName,
  useGetColorName,
  useSetStatusText,
} from "../utilsAuth/VehicleUtils";
import VehicleDetailContainer from "../VehicleDetail/VehicleDetailContainer";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { timestampToDate } from "../../../../services/utils";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useGetGateName } from "./LocationsUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0,
  },
  bottomMarign: {
    marginBottom: "50px",
  },
  spacing: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "start",
  },
  paper: {
    backgroundColor: "transparent",
  },
}));

export default function LocationVehicleTable(props) {
  const { locationId, vehiclesData } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );

  const businessId = useSelector((state) => state.valet.businessId);
  const userID = useSelector((state) => state.auth.userID);

  const catalogue = useSelector((state) => state.valet.catalogue);
  const carsCatalogue = catalogue !== undefined ? catalogue.cars : {};
  const [valueToFind, setValueToFind] = useState("");
  const [viewPaymentDetails, setviewPaymentDetails] = useState(false);
  const [viewVehicles, setviewVehicles] = useState(1);
  const [sortBy, setSortBy] = useState("dateTime");

  function setValuesToFind(value) {
    var valueUp = value.toUpperCase();
    var valuesArray = valueUp;
    return valuesArray;
  }

  function getStrings(object) {
    var string = "";
    for (var i in object) {
      if (i == "color") {
        string = string + useGetColorName(object[i], languageObject);
      } else if (i == "status") {
        string =
          string + useSetStatusText(object[i], languageObject, carsCatalogue);
      } else if (i == "notes") {
        var notes = object[i];
        for (var e in notes) {
          string = string + notes[e].text;
        }
      } else if (typeof object[i] == "object") {
      } else {
        string = string + object[i];
      }
    }
    return string.toUpperCase();
  }
  if (businessId === undefined) {
    return <div></div>;
  }

  function handleTextFieldChange(e) {
    console.log(e.target.value);
    setValueToFind(e.target.value);
  }

  const getPaymentsByTypeData = getPaymentTotalsByType(
    vehiclesData,
    viewVehicles
  );
  console.log("getPaymentsByTypeData", getPaymentsByTypeData);
  console.log("sortBy", sortBy);

  const texts = languageObject.authorizeUserdViews.ticketsValidation.table;
  const texts1 = languageObject.authorizeUserdViews.ticketsValidation;

  let vehicles = vehiclesData;
  if (vehiclesData && businessLocations && carsCatalogue && locationId) {
    vehicles = Object.keys(vehiclesData).map(
      (i) =>
        (vehiclesData[i] = {
          ...vehiclesData[i],
          colorNew: useGetColorName(vehiclesData[i].color, languageObject),
          gateNew: useGetGateName(
            businessLocations[locationId].locationConfig,
            vehiclesData[i].gate
          ),
          statusNew: `${useSetStatusText(
            vehiclesData[i].status,
            languageObject,
            carsCatalogue
          )} ${
            vehiclesData[i].status === "parked"
              ? " | " +
                getZonePlaceName(
                  businessLocations[locationId].locationConfig.zones,
                  vehiclesData[i].zone,
                  vehiclesData[i].place
                ).zoneName +
                " - " +
                getZonePlaceName(
                  businessLocations[locationId].locationConfig.zones,
                  vehiclesData[i].zone,
                  vehiclesData[i].place
                ).placeName
              : ""
          }`,
        })
    );
  }
  console.log("vehicles", vehicles);
  function getVehiclesByLocationChartData(vehiclesData, locationId) {
    let vehiclesCount = [];
    const locationsLabels = [];

    for (var i in vehiclesData) {
      if (
        vehiclesData[i].locationId === locationId &&
        (viewVehicles == 1 ||
          (viewVehicles == 2 && vehiclesData[i].closed) ||
          (viewVehicles == 3 && !vehiclesData[i].closed))
      ) {
        console.log("count1", vehiclesData[i]);
        const vehicle = vehiclesData[i];
        for (var e in vehicle) {
          console.log("count1", e);
          console.log("count1", vehicle[e]);
          if (vehiclesCount[e] === undefined) {
            vehiclesCount[e] = {};
          }
          if (vehiclesCount[e][vehicle[e]] === undefined) {
            vehiclesCount[e][vehicle[e]] = 0;
          }
          vehiclesCount[e][vehicle[e]] = vehiclesCount[e][vehicle[e]] + 1;
        }
      }
    }
    console.log("count1", vehiclesCount);

    return vehiclesCount;
  }
  const vehiclesCount = getVehiclesByLocationChartData(
    vehiclesData,
    locationId
  );

  function formatArray(object) {
    const data = [];
    for (var i in object) {
      data.push(
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={12}>
            {i}:{object[i]}
            <hr />
          </Grid>
        </Grid>
      );
    }
    return data;
  }

  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid className={classes.spacing} item xs={12} sm={12}>
        <Paper style={{ flex: 1, width: "500px", margin: "20px" }}>
          <Typography
            className={classes.text}
            gutterBottom
            variant={"h4"}
            component="h2"
          >
            {texts1.findVehicle}
          </Typography>
          <TextField
            style={{ flex: 1, width: "90%", margin: "10px" }}
            id="findVehicle"
            label={texts1.findeVehicleDetail}
            onChange={(e) => handleTextFieldChange(e)}
          />
        </Paper>
      </Grid>
      <Grid className={classes.spacing} item xs={12} sm={12}>
        <Grid
          container
          justify="space-around"
          direction="row"
          alignContent="center"
        >
          <Grid item xs={4} sm={4}>
            <Button
              onClick={() => setviewVehicles(1)}
              variant="contained"
              disabled={viewVehicles == 1 ? "disabled" : ""}
              color="primary"
            >
              {texts.allVehicles}
            </Button>
          </Grid>

          <Grid item xs={4} sm={4}>
            <Button
              onClick={() => setviewVehicles(2)}
              variant="contained"
              disabled={viewVehicles == 2 ? "disabled" : ""}
              color="primary"
            >
              {texts.closedVehicles}
            </Button>
          </Grid>

          <Grid item xs={4} sm={4}>
            <Button
              onClick={() => setviewVehicles(3)}
              variant="contained"
              disabled={viewVehicles == 3 ? "disabled" : ""}
              color="primary"
            >
              {texts.openVehicles}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.spacing} item xs={12} sm={12}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right" onClick={() => setSortBy("card")}>
                  {texts.card}
                  {sortBy == "card" && (
                    <IconButton aria-label="more">
                      <ArrowDownwardIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="right" onClick={() => setSortBy("key")}>
                  {texts.key}
                  {sortBy == "key" && (
                    <IconButton aria-label="more">
                      <ArrowDownwardIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="right" onClick={() => setSortBy("dateTime")}>
                  {texts.dateTime}
                  {sortBy == "dateTime" && (
                    <IconButton aria-label="more">
                      <ArrowDownwardIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell onClick={() => setSortBy("brand")}>
                  {texts.brand}
                  {sortBy == "brand" && (
                    <IconButton aria-label="more">
                      <ArrowDownwardIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="right" onClick={() => setSortBy("colorNew")}>
                  {texts.color}
                  {sortBy == "colorNew" && (
                    <IconButton aria-label="more">
                      <ArrowDownwardIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="right" onClick={() => setSortBy("gateNew")}>
                  {texts.gate}
                  {sortBy == "gateNew" && (
                    <IconButton aria-label="more">
                      <ArrowDownwardIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="right" onClick={() => setSortBy("statusNew")}>
                  {texts.status}
                  {sortBy == "statusNew" && (
                    <IconButton aria-label="more">
                      <ArrowDownwardIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="center">{texts.charges} </TableCell>
                <TableCell align="center">
                  {texts.payments}{" "}
                  <IconButton
                    aria-label="more"
                    onClick={() =>
                      setviewPaymentDetails(viewPaymentDetails ? false : true)
                    }
                  >
                    <AddCircleIcon />
                  </IconButton>
                </TableCell>
                {viewPaymentDetails && (
                  <TableCell align="center">
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      style={{ width: "200px" }}
                    >
                      <Grid item>Cash </Grid>
                      <Grid item>POS </Grid>
                      <Grid item>Online </Grid>
                      <Grid item>Auth </Grid>
                    </Grid>
                  </TableCell>
                )}
                <TableCell align="center">{texts.pending}</TableCell>
              </TableRow>
              <TableRow style={{ backgroundColor: "#D1D2D2" }}>
                <TableCell></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell>
                  {" "}
                  {/*formatArray(vehiclesCount["brand"])*/}
                </TableCell>
                <TableCell align="center">
                  {/*formatArray(vehiclesCount["colorNew"])*/}
                </TableCell>
                <TableCell align="center">
                  {formatArray(vehiclesCount["gateNew"])}
                </TableCell>
                <TableCell align="center">
                  {formatArray(vehiclesCount["status"])}
                </TableCell>
                <TableCell align="center"> </TableCell>
                <TableCell align="center"></TableCell>
                {viewPaymentDetails && (
                  <TableCell align="center">
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      style={{ width: "200px" }}
                    >
                      <Grid item> </Grid>
                      <Grid item> </Grid>
                      <Grid item> </Grid>
                      <Grid item> </Grid>
                    </Grid>
                  </TableCell>
                )}
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicles &&
                Object.keys(vehicles)
                  .filter(
                    (a) =>
                      vehicles[a].locationId == locationId &&
                      (viewVehicles == 1 ||
                        (viewVehicles == 2 && vehicles[a].closed) ||
                        (viewVehicles == 3 && !vehicles[a].closed)) &&
                      (getStrings(vehicles[a]).indexOf(
                        setValuesToFind(valueToFind),
                        0
                      ) >= 0 ||
                        valueToFind == "")
                  )
                  .sort((a, b) =>
                    vehicles[a][sortBy] > vehicles[b][sortBy]
                      ? 1
                      : vehicles[b][sortBy] > vehicles[a][sortBy]
                      ? -1
                      : 0
                  )
                  .map((index) => {
                    const charges = useTotalAmountWidhValidationsAndDates(
                      businessLocations[locationId].locationConfig.payment
                        .rateConfig,
                      vehicles[index]
                    );
                    return (
                      <TableRow key={index}>
                        <TableCell
                          padding={"default"}
                          component="th"
                          scope="row"
                        >
                          <SimpleDialogDemo buttonText={texts.openDetail}>
                            <VehicleDetailContainer vehicle={vehicles[index]} />
                          </SimpleDialogDemo>
                        </TableCell>
                        <TableCell padding={"default"} align="right">
                          {vehicles[index].card}
                        </TableCell>
                        <TableCell padding={"default"} align="right">
                          {vehicles[index].key}
                        </TableCell>
                        <TableCell padding={"default"} align="right">
                          {timestampToDate(
                            vehicles[index].startTime.seconds * 1000
                          )}
                        </TableCell>

                        <TableCell
                          padding={"default"}
                          component="th"
                          scope="row"
                        >
                          {vehicles[index].brand}
                        </TableCell>
                        <TableCell padding={"default"} align="right">
                          {vehicles[index].colorNew}
                        </TableCell>
                        <TableCell padding={"default"} align="right">
                          {vehicles[index].gateNew}
                        </TableCell>
                        <TableCell padding={"default"} align="right">
                          {vehicles[index].statusNew}
                        </TableCell>
                        <TableCell padding={"default"} align="center">
                          {charges.charge}
                        </TableCell>
                        <TableCell padding={"default"} align="center">
                          {charges.charge - charges.pendingPay}
                        </TableCell>
                        {viewPaymentDetails && (
                          <TableCell align="center">
                            <Grid
                              container
                              direction="row"
                              justify="space-around"
                              alignItems="center"
                              style={{ width: "200px" }}
                            >
                              <Grid item>{charges.payByType.cash} </Grid>
                              <Grid item>{charges.payByType.pos} </Grid>
                              <Grid item>
                                {charges.payByType.onlinePayment}{" "}
                              </Grid>
                              <Grid item>{charges.payByType.auth} </Grid>
                            </Grid>
                          </TableCell>
                        )}
                        <TableCell padding={"default"} align="center">
                          {charges.pendingPay}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              <TableRow>
                <TableCell
                  padding={"default"}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell padding={"default"} align="right"></TableCell>
                <TableCell padding={"default"} align="right"></TableCell>
                <TableCell padding={"default"} align="right"></TableCell>
                <TableCell padding={"default"} align="right"></TableCell>

                <TableCell
                  padding={"default"}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell padding={"default"} align="right"></TableCell>
                <TableCell padding={"default"} align="center">
                  Total
                </TableCell>
                <TableCell padding={"default"} align="right"></TableCell>
                <TableCell padding={"default"} align="center">
                  {-getPaymentsByTypeData.total}
                </TableCell>
                {viewPaymentDetails && (
                  <TableCell align="center">
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      style={{ width: "200px" }}
                    >
                      <Grid item>{getPaymentsByTypeData.cashTotal} </Grid>
                      <Grid item>{getPaymentsByTypeData.posTotal} </Grid>
                      <Grid item>
                        {getPaymentsByTypeData.onlinePaymentTotal}{" "}
                      </Grid>
                      <Grid item>{getPaymentsByTypeData.authTotal} </Grid>
                    </Grid>
                  </TableCell>
                )}
                <TableCell padding={"default"} align="center"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
