import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ShopItem from "./ShopItem";
import AdditionalItem from './AdditionalItem';
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles(() => ({
  addButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  }
}));

export default function AdditionalItems(props) {
  const { languageObject, locationConfig, onChangeAdditionalItem } = props;
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const classes = useStyles();
  const additionalItems = locationConfig.additionalItems;

  // function getVisibleItems() {
  //   const tempItemObject = {};
  //   if (additionalItems) {
  //     let visibleItemKeys = Object.keys(additionalItems).filter((item) => !additionalItems[item].hidden);
  //     visibleItemKeys.forEach((itemKey) => tempItemObject[itemKey] = additionalItems[itemKey]);
  //   }
  //   return tempItemObject;
  // }
  // const filteredAdditionalItems = getVisibleItems();

  function handleChangeItem(changedItem, productKey) {
    const newItems = { ...additionalItems, [productKey]: { ...changedItem } }
    onChangeAdditionalItem(newItems)
  }

  function handleRemoveItem(changedItem, productKey) {
    console.warn('remove item');
    const newItems = { ...additionalItems, [productKey]: { ...changedItem } }
    onChangeAdditionalItem(newItems)
  }

  function getNextItemKey() {
    if (additionalItems) {
      const keysArr = Object.keys(additionalItems).filter((itemKey) => Number.isInteger(Number.parseInt(itemKey)));
      const max = Math.max(...keysArr);
      return max + 1;
    }
    return 1;
  }

  function addItem() {
    const emptyItem = {
      name: "New",
      checkbox: false,
      hidden: false
    };

    let newItemNumber = getNextItemKey();
    const newItems = { ...additionalItems, [newItemNumber]: { ...emptyItem } }
    onChangeAdditionalItem(newItems);
  }

  return (
    <Grid container direction="row" justify="flex-start">
      <Grid item xs={8}>
        <Typography variant="h4" component="h1">
          {texts.additionalInfo}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => addItem()}
          aria-label={texts.addItem}
        >
          {texts.addItem}
        </Button>
      </Grid>
      <Divider className={classes.divider} />
      <Grid item xs={12}>
        <div className={classes.fullWidth}>
          <FormControl className={classes.fullWidth} variant="outlined">
            {/* <AdditionalItem
              onSubmit={handleChangeItem}
              onRemoveItem={handleRemoveItem}
              errorMessage={texts.additionalItemErrorMessage}
              texts={texts}
              productKey={getNextItemKey()}
              editable
            /> */}
            {additionalItems && Object.keys(additionalItems).length > 0
              ? Object.keys(additionalItems).map((productKey) => {
                const product = additionalItems[productKey];
                const { name, checkbox, hidden } = product;
                return (
                  <AdditionalItem
                    key={productKey}
                    onSubmit={handleChangeItem}
                    onRemoveItem={handleRemoveItem}
                    errorMessage={texts.additionalItemErrorMessage}
                    texts={texts}
                    productKey={productKey}
                    defaultCheck={checkbox}
                    defaultName={name}
                    hidden={hidden}
                  />
                )
              })
              : null
            }
          </FormControl>
        </div>
      </Grid>
    </Grid >
  )
}