import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlaceDetail from "./PlaceDetail";
import { updateLocationAtribute } from "../../../../../../data-store/actions/valet-actions";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    height: 330,
    margin: 10,
  },
  media: {
    height: 70,
  },
  content: {
    height: 220,
  },
  paper: {
    padding: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
  },
  zone: {
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: 3,
    backgroundColor: "#e0e0e0",
    margin: 20,
  },
});

export default function RenderZone(props) {
  const {
    zoneKey,
    zonesData,
    locationConfig,
    vehicles,
    languageObject,
    locationId,
  } = props;
  const classes = useStyles();
  const texts = languageObject.authorizeUserdViews.locations.locationDetails;
  const dispatch = useDispatch();

  const zonesDataNew = useSelector((state) =>
    state.vehicle.zonesData !== undefined
      ? state.vehicle.zonesData[locationId]
      : undefined
  );

  function selectOccupiedPlace() {
    console.log("you seleted a place with a vehicle");
  }
  console.log("zonesData", zonesDataNew);

  function getNewPlaceName(places) {
    const placeKeys = Object.keys(places);
    for (let i = 1; i <= placeKeys.length; i++) {
      if (!placeKeys.includes(`place_${i}`)) {
        return `place_${i}`;
      }
    }
  }

  const addNewPlace = () => {
    console.log("addPlace zoneKey", zoneKey);
    console.log("addPlace locationConfig", locationConfig);
    console.log("addPlace zonesData", zonesData);

    if (zonesData.placesNamed) {
      const newPlace = {
        name: "",
        priority: 0,
      };
      const newPlaceName = getNewPlaceName(zonesData.zonePlaces);
      console.log("addPlace zonesData", newPlaceName);
      const places = parseInt(zonesData.zonePlacesNumber) + 1;
      dispatch(
        updateLocationAtribute(locationId, {
          zones: {
            [zoneKey]: {
              ...zonesData,
              zonePlacesNumber: places,
              zonePlaces: { ...zonesData.zonePlaces, [newPlaceName]: newPlace },
            },
          },
        })
      );
      console.log("Adding New Zone", newPlace);
    } else {
      const places = zonesData.zonePlacesNumber + 1;
      dispatch(
        updateLocationAtribute(locationId, {
          zones: {
            [zoneKey]: {
              zonePlacesNumber: places,
            },
          },
        })
      );
      console.log("Adding New Zone without name");
    }
  };

  var placesView = [
    <Grid onClick={addNewPlace} key={`${zoneKey}-add`} item xs={3} sm={2}>
      <PlaceDetail
        callBackClickPlace={undefined}
        languageObject={languageObject}
        addPlace={true}
      />
    </Grid>,
  ];
  if (locationConfig.zones[zoneKey].placesNamed) {
    var placesObject = locationConfig.zones[zoneKey].zonePlaces;
    var places = Object.keys(placesObject)
      .map((a) => {
        return { ...placesObject[a], key: a };
      })
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    console.log("places", places);
    for (var e in places) {
      let status = "Vacio";
      let placeObject = undefined;

      // console.log("key zone", zoneKey);
      // console.log("key place ", e);
      // console.log("key place key", places[e].key);

      if (
        zonesDataNew &&
        zonesDataNew[zoneKey] &&
        zonesDataNew[zoneKey].zonePlaces != undefined &&
        zonesDataNew[zoneKey].zonePlaces[places[e].key] != undefined
      ) {
        console.log("renderZones - vehicles", vehicles);
        placeObject = vehicles[zonesDataNew[zoneKey].zonePlaces[places[e].key]];
        console.log("renderZone 2 - placeObject", placeObject);
        // placeObject = vehicles[zonesData[zoneKey].zonePlaces[e].vehicle];
        // placeObject.vehicle = zonesData[zoneKey].zonePlaces[e].vehicle;
      }
      console.log("key", placeObject);
      if (!places[e].hide) {
        placesView.push(
          <Grid key={`${zoneKey}-${e}`} item xs={3} sm={2}>
            <PlaceDetail
              editable
              locationId={locationId}
              callBackClickPlace={selectOccupiedPlace}
              zone={zoneKey}
              placeName={places[e]}
              placeObject={placeObject}
              ocupade={true}
              languageObject={languageObject}
              zonesData={zonesData}
              placeKey={places[e].key}
            />
          </Grid>
        );
      }
    }
  } else {
    for (var e = 1; e <= locationConfig.zones[zoneKey].zonePlacesNumber; e++) {
      let placeObject = undefined;

      // console.log("key zone", zoneKey);
      // console.log("key place", e);

      if (
        zonesDataNew &&
        zonesDataNew[zoneKey] &&
        zonesDataNew[zoneKey].zonePlaces != undefined &&
        zonesDataNew[zoneKey].zonePlaces[e] != undefined
      ) {
        placeObject = vehicles[zonesDataNew[zoneKey].zonePlaces[e]];
        // placeObject.vehicle = zonesData[zoneKey].zonePlaces["place_" + e].vehicle;
      }

      console.log("key", placeObject);

      placesView.push(
        <Grid key={`${zoneKey}-${e}`} item xs={3} sm={2}>
          <PlaceDetail
            editable
            locationId={locationId}
            callBackClickPlace={undefined}
            zone={zoneKey}
            placeName={texts.placeDefaultName + " " + e}
            placeObject={placeObject}
            ocupade={false}
            languageObject={languageObject}
            zonesData={zonesData}
            placeKey={e}
          />
        </Grid>
      );
    }
  }
  console.log("placesView", placesView);
  return (
    <Grid container direction="row" justify="center">
      {placesView}
    </Grid>
  );
}
