import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import SesionView from "../Authentication/SesionView";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Timeline from "@material-ui/icons/Timeline";
import LocationOn from "@material-ui/icons/LocationOn";
import Dashboard from "@material-ui/icons/Dashboard";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Chat from "@material-ui/icons/Chat";
import DoneAll from "@material-ui/icons/DoneAll";
import Receipt from "@material-ui/icons/Receipt";
import SvgIcon from "@material-ui/core/SvgIcon";

import Divider from "@material-ui/core/Divider";
import MenuItemRow from "./MenuItemRow";

const useStyles = makeStyles((theme) => ({
  linkcss: {
    margin: theme.spacing(1),
    color: "#000000",
  },
}));

const AuthorizedMenuItemsView = ({
  userAccount,
  handleDrawerClose,
  languageObject,
  catalogue,
}) => {
  // console.log(userAccount);

  const classes = useStyles();

  const getAvailableView = () => {
    // for(var i in userProfile){
    // }
  };
  let viewAccess = {};
  let viewAccessLinks = [];

  let viewAccessViews = {
    liveView: (
      <MenuItemRow
        handleDrawerClose={handleDrawerClose}
        key={languageObject.authorizeUserdViews.liveView.link}
        iconComponent={<Timeline />}
        link={languageObject.authorizeUserdViews.liveView.link}
        menuName={languageObject.authorizeUserdViews.liveView.menuName}
      />
    ),
    vehicleRequest: (
      <div></div>
      // <MenuItemRow
      //   handleDrawerClose={handleDrawerClose}
      //   key={languageObject.authorizeUserdViews.vehicleRequest.link}
      //   iconComponent={<DirectionsCar />}
      //   link={languageObject.authorizeUserdViews.vehicleRequest.link}
      //   menuName={languageObject.authorizeUserdViews.vehicleRequest.menuName}
      // />
    ),
    reports: (
      <MenuItemRow
        handleDrawerClose={handleDrawerClose}
        key={languageObject.authorizeUserdViews.reports.link}
        iconComponent={<Dashboard />}
        link={languageObject.authorizeUserdViews.reports.link}
        menuName={languageObject.authorizeUserdViews.reports.menuName}
      />
    ),
    locations: (
      <MenuItemRow
        handleDrawerClose={handleDrawerClose}
        key={languageObject.authorizeUserdViews.locations.link}
        iconComponent={<LocationOn />}
        link={languageObject.authorizeUserdViews.locations.link}
        menuName={languageObject.authorizeUserdViews.locations.menuName}
      />
    ),
    users: (
      <MenuItemRow
        handleDrawerClose={handleDrawerClose}
        key={languageObject.authorizeUserdViews.users.link}
        iconComponent={<VerifiedUser />}
        link={languageObject.authorizeUserdViews.users.link}
        menuName={languageObject.authorizeUserdViews.users.menuName}
      />
    ),
    ticketsValidation: (
      <MenuItemRow
        handleDrawerClose={handleDrawerClose}
        key={languageObject.authorizeUserdViews.ticketsValidation.link}
        iconComponent={<DoneAll />}
        link={languageObject.authorizeUserdViews.ticketsValidation.link}
        menuName={languageObject.authorizeUserdViews.ticketsValidation.menuName}
      />
    ),
    chat: (
      <MenuItemRow
        handleDrawerClose={handleDrawerClose}
        key={languageObject.authorizeUserdViews.chat.link}
        iconComponent={<Chat />}
        link={languageObject.authorizeUserdViews.chat.link}
        menuName={languageObject.authorizeUserdViews.chat.menuName}
      />
    ),
    valetView: (
      <MenuItemRow
        handleDrawerClose={handleDrawerClose}
        key={languageObject.authorizeUserdViews.valetView.link}
        iconComponent={<Chat />}
        link={languageObject.authorizeUserdViews.valetView.link}
        menuName={languageObject.authorizeUserdViews.valetView.menuName}
      />
    ),
    payRoll: (
      <div></div>

      // <MenuItemRow
      //   handleDrawerClose={handleDrawerClose}
      //   key={languageObject.authorizeUserdViews.payRoll.link}
      //   iconComponent={<Receipt />}
      //   link={languageObject.authorizeUserdViews.payRoll.link}
      //   menuName={languageObject.authorizeUserdViews.payRoll.menuName}
      // />
    ),
  };

  if (
    userAccount != undefined &&
    userAccount != null &&
    userAccount.userProfiles != undefined
  ) {
    for (var i in userAccount.userProfiles) {
      // console.log(userAccount.userProfiles[i]);
      // console.log(catalogue);
      if (catalogue != undefined) {
        var permisions = Object.values(
          catalogue.profiles[userAccount.userProfiles[i]].permisions
        ).sort();
        var permisions =
          catalogue.profiles[userAccount.userProfiles[i]].permisions;
        console.log("permisions", permisions);
        for (var e in permisions) {
          if (permisions[e].view) {
            if (!viewAccess[e]) {
              if (viewAccessViews[e] != undefined) {
                viewAccessLinks.push(viewAccessViews[e]);
              }
            }
            viewAccess[e] = true;
          }
        }
      }
    }
  }
  console.log("permisions", viewAccessLinks);

  let authorizedUsers;
  if (userAccount) {
    authorizedUsers = (
      <List>
        {viewAccessLinks.sort((a, b) =>
          a.key > b.key ? 1 : b.key > a.key ? -1 : 0
        )}
      </List>
    );
  }

  return <div>{authorizedUsers}</div>;
};

const mapStateToProps = (state, { match }) => {
  return {
    userAccount: state.auth.userAccount,
    smartValetProfile: state.auth.smartValetProfile,
    languageObject: state.auth.languageObject,
    catalogue: state.valet.catalogue,
    route: match,
  };
};

const AuthorizedMenuItems = connect(mapStateToProps)(AuthorizedMenuItemsView);

export default AuthorizedMenuItems;
