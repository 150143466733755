import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import { timestampToDate } from "../../../../../services/utils";
import { Paper } from "@material-ui/core";
import { updateVehicleAtribute } from "../../../../../data-store/actions/vehicle-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "500px",
    height: 120,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
    width: "50%",
  },
  buttonStyle: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    flex: 1,
    marginBottom: 5,
    marginTop: 5,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function VehiclePhotosList(props) {
  const { vehicle } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const carsCatalogue = useSelector((state) => state.valet.catalogue.cars);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const userID = useSelector((state) => state.auth.userID);

  const texts = languageObject.authorizeUserdViews.vehicleDetail.photos;

  return (
    <Grid container justify="start" direction="row" alignContent="center">
      <Grid item xs={12} sm={12} style={{ textAlign: "center", marginTop: 30 }}>
        <Typography gutterBottom variant={"h3"} component="h2">
          {texts.title}
        </Typography>
      </Grid>

      {!vehicle.photos ||
        (Object.values(vehicle.photos).length === 0 && (
          <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
            <Typography gutterBottom variant={"h5"} component="h2">
              {texts.notPhoto}
            </Typography>
          </Grid>
        ))}

      {vehicle.photos &&
        Object.values(vehicle.photos).map((photo) => (
          <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
            <img src={photo.photoUrl} alt="photo" width="70%" />
            <Typography gutterBottom variant={"subtitle1"} component="h2">
              {businessUsers[photo.userID] !== undefined
                ? businessUsers[photo.userID].name
                : photo.userID}{" "}
              {businessUsers[photo.userID] !== undefined
                ? businessUsers[photo.userID].lastname
                : ""}{" "}
            </Typography>

            <Typography gutterBottom variant={"subtitle1"} component="h2">
              {timestampToDate(photo.date)}
            </Typography>
          </Grid>
        ))}
    </Grid>
  );
}
