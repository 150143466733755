import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import {
  getBusinessLocationsConfig,
  getBusinessUsers,
} from "../../../../data-store/actions/valet-actions";
import AllEmployeesCard from "./AllEmployeesCard";
import UserTable from "../BusinessUsers/UserTable";
import EmployeePayRollDetail from "./EmployeePayRollDetail";
import PayRollReportGenerator from "./PayRollReportGenerator";
import { getCurrentQuincenaDates } from "./payRollUtils";
import EmployeePayRollCalendar from "./EmployeePayRollCalendar";

const useStyles = makeStyles({
  root: {
    margin: 10,
    backgroundColor: "#fff",
    color: "#000",
  },
});

const PayRollContainerView = ({ businessLocationsFunc, setBusinessUsers }) => {
  const classes = useStyles();
  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessUsers = useSelector((state) => state.valet.businessUsers);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const { quincenaStart, quincenaEnd } = getCurrentQuincenaDates(new Date());
  console.log("quincenaStart", quincenaStart);
  console.log("quincenaEnd", quincenaEnd);
  // No he podido usar esto en el useEffect de abajo.
  // const setBusinessUser = () => {
  // 	dispatch(getBusinessUsers())
  // }
  // const businessLocationsFunc = () => {
  // 	dispatch(getBusinessLocationsConfig());
  // }
  useEffect(() => {
    if (!businessLocations) {
      businessLocationsFunc();
    }
    if (!businessUsers) {
      setBusinessUsers();
    }
  });

  let userListArray = [];
  for (var i in businessUsers) {
    businessUsers[i].userId = i;
    userListArray.push(businessUsers[i]);
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <AllEmployeesCard
        languageObject={languageObject}
        businessUsers={businessUsers}
        businessLocations={businessLocations}
        quincenaDates={{ quincenaStart, quincenaEnd }}
        userListArray={userListArray}
      />
      <PayRollReportGenerator
        languageObject={languageObject}
        businessUsers={businessUsers}
        businessLocations={businessLocations}
        userListArray={userListArray}
        quincenaDates={{ quincenaStart, quincenaEnd }}
      />
      <EmployeePayRollCalendar />
      <Card className={classes.root}>
        <UserTable
          businessLocations={businessLocations}
          userListArray={userListArray}
          languageObject={languageObject}
          report={EmployeePayRollDetail}
        />
      </Card>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    businessLocationsFunc: () => {
      dispatch(getBusinessLocationsConfig());
    },
    setBusinessUsers: () => {
      dispatch(getBusinessUsers());
    },
  };
};

const PayRollContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PayRollContainerView);

export default PayRollContainer;
