import * as types from "../actions/action-types";

const initialState = [];
export default function valetReducer(state = initialState, action) {
  switch (action.type) {
    case types.CURRENT_VEHICLE:
      return Object.assign({}, state, {
        currentVehicle: action.currentVehicle,
      });
    case types.VEHICLES_INIT:
      return Object.assign({}, state, {
        vehicles: action.vehicles,
      });
    case types.VEHICLE_ADD:
      var vehicleId = action.vehicleId;
      var vehicle = action.vehicle;
      vehicle.id = vehicleId;
      return Object.assign({}, state, {
        vehicles: Object.assign({}, state.vehicles, {
          [vehicleId]: vehicle,
        }),
      });
    case types.VEHICLE_CHANGE:
      var vehicleId = action.vehicleId;
      var vehicle = action.vehicle;
      vehicle.id = vehicleId;
      return Object.assign({}, state, {
        vehicles: Object.assign({}, state.vehicles, {
          [vehicleId]: vehicle,
        }),
      });
    case types.VEHICLE_REMOVE:
      var vehicleId = action.vehicleId;
      var vehiclesNew = { ...state.vehicles };
      delete vehiclesNew[vehicleId];
      return Object.assign({}, state, {
        vehicles: vehiclesNew,
      });
    case types.VEHICLES_REPORT:
      return Object.assign({}, state, {
        vehiclesReport: action.vehiclesReport,
      });
    case types.INCIDENTS_REPORT:
      return Object.assign({}, state, {
        incidentsReport: action.incidentsReport,
      });
    case types.UPDATE_ZONES_DATA:
      return Object.assign({}, state, {
        zonesData: action.zonesData,
      });
    case types.SOUND_STATE:
      return Object.assign({}, state, {
        soundState: action.soundState,
      });
    default:
      return state;
  }
}
