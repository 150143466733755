import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import ValetNavigationContainer from "./ValetNavigationContainer";
import ValetScannerCard from "./ValetScannerCard";
import Paper from "@material-ui/core/Paper";
import VehicleAddContainer from "./VehicleAdd/VehicleAddContainer";

import { getBusinessLocationsConfig } from "../../../../data-store/actions/valet-actions";
import { connect, useSelector } from "react-redux";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import ValetVehicleCardList from "./ValetVehicleCardList";
import VehicleDetailContainer from "./VehicleDetail/VehicleDetailContainer";
import ValetRequested from "./ValetRequested";

export default function ValetContainerView({ match }) {
  const carsCatalogue = useSelector((state) =>
    state.valet.catalogue != undefined ? state.valet.catalogue.cars : undefined
  );
  const languageObject = useSelector((state) => state.auth.languageObject);
  const businessLocations = useSelector(
    (state) => state.valet.businessLocations
  );
  const currentLocation = useSelector((state) => state.valet.currentLocation);
  const businessId = useSelector((state) => state.valet.businessId);
  const userID = useSelector((state) => state.auth.userID);
  const [enableButton, setEnableButton] = useState(true); // ver objeto vacio abajo
  const vehicles = useSelector((state) => state.valet.vehicles);

  const locationId = match.params.locationId;
  const locationConfig =
    businessLocations != null
      ? businessLocations[match.params.locationId].locationConfig
      : null;

  const [findVehicle, setFindVehicle] = useState(false);

  function callbackFind(data) {
    console.log("callback", data);
    setFindVehicle(data);
  }

  return (
    <Paper>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <ValetRequested
            locationId={locationId}
            carsCatalogue={carsCatalogue}
            languageObject={languageObject}
            locationConfig={locationConfig}
            callback={callbackFind}
          />
        </Grid>{" "}
        <Grid item xs={12}>
          <VehicleAddContainer
            carsCatalogue={carsCatalogue}
            locationConfig={locationConfig}
            locationId={locationId}
            languageObject={languageObject}
            callback={callbackFind}
          />
        </Grid>{" "}
        <Grid item xs={12}>
          {findVehicle && (
            <VehicleDetailContainer
              carsCatalogue={carsCatalogue}
              locationData={locationConfig}
              locationId={locationId}
              languageObject={languageObject}
              vehicle={findVehicle}
              vehicles={vehicles}
              callback={callbackFind}
            />
          )}
        </Grid>{" "}
        <Grid item xs={12}>
          <ValetNavigationContainer
            locationId={locationId}
            carsCatalogue={carsCatalogue}
            languageObject={languageObject}
            locationConfig={locationConfig}
            callback={callbackFind}
          />
          {/* <ValetVehicleCardList
            locationId={locationId}
            carsCatalogue={carsCatalogue}
            languageObject={languageObject}
            locationConfig={locationConfig}
            callback={setFindVehicle}
          /> */}
        </Grid>{" "}
      </Grid>
    </Paper>
  );
}

// const mapStateToProps = (state, { match }) => {
//   return {
// //    languageObject: state.auth.languageObject,
//   //  businessId: state.valet.businessId,
//    // businessLocations: state.valet.businessLocations,
//     //locationId: match.params.locationId,
//     //locationConfig:
//       state.valet.businessLocations != null
//         ? state.valet.businessLocations[match.params.locationId].locationConfig
//         : null,
//     //carsCatalogue:
//       state.valet.catalogue != undefined
//         ? state.valet.catalogue.cars
//         : undefined,
//     //match: match,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     businessLocationsFunc: () => {
//       dispatch(getBusinessLocationsConfig());
//     },
//   };
// };

// const ValetContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ValetContainerView);
