import React from 'react';
import { connect, dispatch} from 'react-redux'

const ContactView = ({languageObject}) => {

	return (
		<div>
			<h5>{languageObject.contactView.menuName}</h5>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>

		</div>
	)
}


const mapStateToProps = (state) => {
	return {
		languageObject : state.auth.languageObject
	}
  }


  const mapDispatchToProps = (dispatch) => {
		return {
		}
  }

const Contact = connect(
	mapStateToProps,
	mapDispatchToProps
)(ContactView);

export default Contact;
